<template>
  <div v-show="maximizedcomp==''" class="wrapper wrapper-content">
    <div class="row">
      <div class="col-12 col-md-7 mb-2">
        <div class="row">
          <div class="col-12 col-md-6  pl-1">
            <div class="stat-card">
              <div class="stat-header">
                <span>{{ $t("navigations.total subjects") }}</span>
              </div>
              <div class="stat-body">
                <div class="stat-info">
                  <h1 class="mb-0">{{ resultCount.noOfSubjects }}</h1>
                  <small>{{ $t("navigations.subjects") }}</small>
                </div>
                <div class="stat-icon">
                  <img src="../../assets/img/stat_card_icon.png" alt="Icon" />
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6  pl-1">
            <div class="stat-card-white h-100">
              <div class="stat-header-white d-flex align-items-center">
                <div class="d-flex">
                  <span>{{ $t("navigations.data quality assessment") }}</span>
                  <div class="banner-info px-1">
                    <img class="cursor-pointer position-relative" src="../../assets/img/info.svg" alt="Info">
                    <div class="banner-description-block position-absolute" aria-hidden="true" focusable="false">Data quality assessment is the process of evaluating the accuracy, completeness, consistency, and reliability of data to ensure it meets the necessary standards for its intended use. This involves identifying and addressing issues like missing values, duplicates, errors, and inconsistencies in the data. The goal is to enhance the overall quality of the data, making it trustworthy and useful for decision-making, analysis, and reporting.</div>
                  </div>
                  
                </div>
               
                <div class="expand-block px-1 mb-1 position-relative cursor-pointer" @click.prevent="expandBlock=true">
                  <img  src="../../assets//img/expand.png" alt="Expand block">
                </div>
              </div>
              <div class="stat-body-white">
                <div class="stat-info-white w-100">
                  <arctween
                    :totalNumber="resultCount.noOfSubjects"
                    :passedNumber="resultCount.noOfSubjectsEnrolledByAI"
                    color1="#40D0A0"
                    color2="#FF6666"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-5 mb-2  pl-1">
        <percebtagechart
          :loader="loader"
          :data="data"
          :templateId="templateId"
          :fromPage="'patientScreening'"
        />
      </div>
      <!-- {{ historykeys }} -->
        <div class="col-md-12 pl-1 mb-2">
          <historytab
          :historyarray="historykeys"
          @gotohistorylevel="gotohistorylevel"
          />
        </div>
      <div class="col-md-6 mb-2  pl-1">
        <d3BarchartComparitive
          fromPage="patientScreen"
          @maximiseval="changetomaxcomponent"
          @minimiseval="changetominomponent"
          :loader="loader"
          :data="data"
          xaxiskeypassed=""
          :templateId="templateId"
          @opendrillpopup="drillpopupopened"
          @drilldowncurrentchart="drilldowncurrentchartforbar"
          @undobutton="undobutton"
        />
      </div>
      <div class="col-md-6 mb-2  pl-1">
        <D3PieChart
          :loader="loader"
          :tableData="data"
          @opendrillpopup="drillpopupopened"
          @drilldowncurrentchart="drilldowncurrentchartforbar"
          :data="data"
          :templateId="templateId"
          :fromPage="'patientScreening'"
          :chartlabel="$t('navigations.interactive analysis')" 
          :labelOne ="$t('navigations.acceptable')" 
          :labelTwo = "$t('navigations.rejectable')" 
          @undobutton="undobutton"
        />
      </div>
      <div class="col-md-12 mb-2  pl-1">
        <groupbarchart
          :loader="loader"
          :data="data"
          width=1200
          @opendrillpopup="drillpopupopened"
          @drilldowncurrentchart="drilldowncurrentchartforbar"
          :templateId="templateId"
          :fromPage="'patientScreening'"
        />
      </div>
      <div class="col-lg-12 mb-2  pl-1">
        <div class="row ml-0">
          <div class="col-lg-12 pl-0">
            <div class="stat-card-white minheight">
              <div class="stat-header-white">
                <span>{{$t("navigations.comparison of health metrics")}}</span>
              </div>
              <div class="stat-body-white">
                <d3table
                  :loader="loader"
                  :fromPage="'patientScreening'"
                  :data="data"
                  :templateId="templateId"
                />
                <div></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-12 mb-2  pl-1">
      <sunburst
        :loader="loader"
        :data="data"
        :templateId="templateId"
        :fromPage="'patientScreening'"
      />
    </div>
    </div>
   
  </div>
  <!-- <div v-if="maximizedcomp!=''" class="col-lg-12 mb-2  pl-1">
    <component :is="maximizedcomp" @minimiseval="changetominomponent" :loader="loader" :data="data" :templateId="templateId" :fromPage="'patientScreening'"/>
  </div> -->
  <chartdrill
    v-if="drillpopup"
    fromPage="patientscreening"
    :drilldata="drilldata"
    :templateId="templateId"
    @closepopup="onclosed()"
  >
  </chartdrill>
  <enlargerpopup v-if="expandBlock==true" @closeModal="expandBlock=false">
    <arctween
      :expandValue="expandBlock"
      :totalNumber="resultCount.noOfSubjects"
      :passedNumber="resultCount.noOfSubjectsEnrolledByAI"
      color1="#40D0A0"
      color2="#FF6666"
    />
  </enlargerpopup>
</template>
<script src="./aichart.js"></script>
<style scoped>
@import "../../custom_lib/AiCharts/charts.css";
.widget {
  margin-top: 0rem;
  margin-bottom: 0rem;
}
.widgetmargin {
  margin-top: -13px !important;
}

.style1 {
  background-color: black !important;
  color: #ffffff;
}
.btn-view {
  color: #fff !important;
  background-color: #bb458f !important;
  border-color: #bb458f !important;
}
.btn-view:hover {
  color: #fff;
  background-color: #666ccb;
  border-color: #666ccb;
}
.btn-view:active {
  color: rgb(255, 255, 255) !important;
  background-color: #dda2c7 !important;
  border-color: #dda2c7 !important;
}

.titlemsg {
  border-color: #e7eaec;
  margin-bottom: 0;
  padding: 20px 20px;
  position: relative;
  clear: both;
  right: 0;
  margin-right: 0px;
  margin-left: 0px !important;
  margin-top: 0px !important;
  padding-left: 10px;
}
.padding_bottom {
  padding-bottom: 2rem;
}
.ibox-title h5 {
  height: 0rem;
  padding: 0rem;
}
.ibox-title h5:hover {
  background-color: inherit;
  padding: 0;
  cursor: not-allowed;
}
.titlemsg {
  font-size: 15px;
  font-weight: bold;
  color: var(--primary-color);
}
.switchbtn {
  background-color: #bb458f !important;
  height: max-content;
  border-color: #bb458f !important;
  color: white !important;
  float: right;
}
.switchbtn:active {
  height: max-content;
  border-color: #bb458f !important;
  color: white !important;
  float: right;
  background-color: #bb458f;
}
.switchbtn:hover {
  height: max-content;
  border-color: #bb458f !important;
  color: white !important;
  float: right;
  background-color: #bb458f;
}
.queryhover:hover {
  color: #3c4192 !important;
  -webkit-transition: color ease-in 400ms;
  transition: color ease-in 400ms;
  cursor: pointer;
}

.widgetminheight {
  min-height: 102px;
}
.graphbutton {
  width: fit-content;
  padding: 3px;
  cursor: pointer;
}
.graphbtncontainer {
  display: flex;
  gap: 5px;
}
img {
  margin-left: 0px !important;
}
.selectdefault {
  left: 0;
}
.btn:focus {
  outline: none;
  box-shadow: none;
}
.btn-setdefault {
  position: relative;
  padding: 0.2rem 0.4rem;
  text-align: center;
  font-size: 0.7rem;
  background-color: #ec4758;
  color: white !important;
  border-radius: 3px;
}
.ibox-content h1 {
  font-weight: 400;
  color: #676a6c;
}
.progress {
  display: flex;
  height: 5px;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}
.progress-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.progress-mini {
  flex-grow: 1;
  margin-right: 10px;
}

.progress-bar {
  background-color: #5bc0de;
  height: 20px;
}

.progress-value {
  color: #333;
  font-size: 1.2em;
}
.average-title {
  margin-bottom: 5px;
  font-size: 1.2em;
  color: #888;
}

.stat-item h3 {
  margin-bottom: 5px;
  font-size: 1em;
  color: #888;
}

.stat-item h2 {
  margin-bottom: 5px;
  font-size: 2em;
  color: #333;
}

.stat-item small {
  color: #888;
}


.stat-info h2 {
  margin: 0;
  font-size: 3.5em;
}

.stat-info small {
  font-size: 1em;
}

.stat-icon img {
  width: 50px;
  height: 50px;
}

.stat-card-white {
  background: #ffffff;
  border-radius: 10px;
  color: #676a6c;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.stat-header-white {
  border-bottom: 2px solid rgba(241, 241, 241);
  padding-bottom: 6px;
  margin-bottom: 0px;
  padding-left: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height:2rem
}

.stat-header-white span {
  font-size: 1.2em;
  font-weight: 500;
}
.stat-body-dflex {
  margin: 5px 15px !important;
  justify-content: center;
  align-items: center;
  padding: 10px;
  min-height: 125px;
}
.stat-info-white h2 {
  margin: 0;
  font-size: 3.5em;
}

.stat-info-white small {
  font-size: 1em;
}
.stat-dropdown {
  position: relative;
  display: inline-block;
}

.stat-dropbtn {
  color: #333;
  font-size: 16px;
  cursor: pointer;
}

.stat-dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 10001;
  right: 0;
}

.stat-dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.stat-dropdown-content a:hover {
  background-color: #f1f1f1;
}

.stat-dropdown:hover .stat-dropdown-content {
  display: block;
}

.stat-dropdown:hover .dropbtn {
  color: #000;
}
.adjustheight {
  height: 220px;
}
.minheight {
  height: 280px;
}

</style>
