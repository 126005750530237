<template>
    <div class="stat-card-white">
      <div class="stat-header-white d-flex justify-content-between align-items-center">
        <span>Correlation Between Age and {{selectedYAxes[0]}}</span>
  
        <!-- Custom Multiselect Dropdown for Y-Axis Variables -->
        <div v-show="!loader" class="d-flex align-items-center" v-if="!loader">
          <select
            class="graph-select py-0"
            v-model="selectedYAxes[0]"
            id="attribute-select"
            @change="updateChart"
          >
            <option
              v-for="option in computedYaxisOptions"
              :key="option"
              :value="option"
            >
              {{ option }}
            </option>
          </select>
          <div v-if="!loader" class="expand-block px-2 mb-1 cursor-pointer position-relative">
            <img @click="showtable()" src="../../assets//img/table_view.png" alt="More block">
            <div class="more-expand">
              <span>Data Overview</span>
            </div>
          </div>
          <div class="expand-block px-2 mb-1 cursor-pointer" @click.prevent="expandBlock=true">
            <img src="../../assets//img/expand.png" alt="Expand block">
          </div>
        </div>
      </div>
  
      <div class="stat-body-white" :class="{'h-100': loader}">
        <div v-if="loader" class="loader"></div>
        <div class="overflow-x-scroll w-100" v-show="!loader">
          <!-- Scatter plot SVG -->
          <svg ref="chartContainer"></svg>
          <div ref="tooltip" class="tooltip"></div>
        </div>
      </div>
  
      <!-- Enlarger Popup for Expanded Chart View -->
      <enlargerpopup v-if="expandBlock" @closeModal="expandBlock=false; updateChart()">
        <div class="w-100 overflow-x-scroll">
          <svg ref="popupChartContainer"></svg>
        </div>
      </enlargerpopup>
    </div>
  </template>
  
  <script>
  import * as d3 from 'd3';
  import enlargerpopup from '../AiCharts/popupfiles/enlarge_popup.vue'; // Import your enlarger popup component
  
  export default {
    name: 'ScatterPlot',
    components: {
      enlargerpopup, // Register the enlarger popup
    },
    props: {
      data: {
        type: Array,
        required: true,
        default: () => [],
      },
      xaxisVariable: {
        type: String,
        required: true,
        default: 'LBSTNRLO',
      },
      yaxisOptions: {
        type: Array,
        default: () => [], // Default to empty array
      },
      loader: {
        type: Boolean,
        default: false,
      },
      xAxislabel: {
        type: String,
        default: 'Treatment Duration',
      },
      yAxislabel: {
        type: String,
        default: 'No of Adverse Events',
      },
      width: {
        type: Number,
        default: 1200,
      },
      height: {
        type: Number,
        default: 400,
      },
      fromPage: {
        type: String,
        defaut: ""
      }
    },
    data() {
      return {
        selectedYAxes: ['VSORRES'], // Selected Y-Axis variables
        showDropdown: false, // Controls the visibility of the dropdown
        expandBlock: false, // Controls whether the popup is open
        chartContainer: null,
        popupChartContainer: null,
      };
    },
    computed: {
      computedYaxisOptions() {
        // Use passed yaxisOptions if provided, otherwise compute from data
        if (this.yaxisOptions.length > 0) {
          return this.yaxisOptions;
        } else if (this.data.length > 0) {
          return Object.keys(this.data[0]);
        }
        return [];
      },
    },
    watch: {
      data() {
        this.updateChart(); // Correct function call
      },
      xaxisVariable() {
        this.updateChart(); // Correct function call
      },
      selectedYAxes() {
        this.updateChart(); // Update the chart when selected Y-axes change
      },
      expandBlock(newValue) {
        if (newValue) {
          this.$nextTick(() => {
            this.updateChart(); // Ensure the chart is updated when the popup is expanded
          });
        }
      },
    },
    mounted() {
      if (this.fromPage == 'adverseEventsCross') {
        this.selectedYAxes = ['AESEV']
      }
      this.chartContainer = this.$refs.chartContainer; // Assign chart container reference
      this.popupChartContainer = this.$refs.popupChartContainer; // Assign popup chart container reference
      this.createChart(); // Create the chart on mount
    },
    methods: {
      showtable() {
        this.showMore = false;
        this.$emit("opendrillpopup", this.data);
      },
      updateChart() {
        // Clear the existing chart content and re-render
        d3.select(this.$refs.chartContainer).selectAll('*').remove();
        d3.select(this.$refs.popupChartContainer).selectAll('*').remove();
        this.createChart(); // Recreate chart when data or axis changes
      },
      createChart() {
  const { width, height, data, xaxisVariable, selectedYAxes } = this;
  const chartContainer = this.expandBlock ? this.$refs.popupChartContainer : this.$refs.chartContainer;
  if (!chartContainer || !Array.isArray(data) || data.length === 0) {
    console.error('Data is invalid or empty:', data);
    return;
  }

  const validData = this.validateData(data);
  if (validData.length === 0 || selectedYAxes.length === 0) {
    console.error('Valid data is empty or no Y-axes selected');
    return;
  }

  const margin = { top: 20, right: 30, bottom: 40, left: 50 };
  const chartWidth = width - margin.left - margin.right;
  const chartHeight = height - margin.top - margin.bottom;

  const xScale = d3
    .scaleLinear()
    .domain([d3.min(validData, (d) => d[xaxisVariable]), d3.max(validData, (d) => d[xaxisVariable])])
    .nice()
    .range([margin.left, chartWidth + margin.left]);

  const colorScale = d3.scaleOrdinal(d3.schemeCategory10).domain(selectedYAxes);

  const svg = d3
    .select(chartContainer)
    .attr('width', width)
    .attr('height', height)
    .attr('viewBox', [0, 0, width, height]);

  const tooltip = d3.select(this.$refs.tooltip);

  selectedYAxes.forEach((yAxis) => {
    const sanitizedYAxis = this.sanitizeForCSS(yAxis);

    const yScale = d3.scaleLinear()
      .domain([d3.min(validData, (d) => d[yAxis]), d3.max(validData, (d) => d[yAxis])])
      .nice()
      .range([chartHeight + margin.top, margin.top]);

    // Scatter plot points
    svg.selectAll(`.dot-${sanitizedYAxis}`)
      .data(validData)
      .enter()
      .append('circle')
      .attr('class', `dot-${sanitizedYAxis}`)
      .attr('cx', (d) => xScale(d[xaxisVariable]))
      .attr('cy', (d) => yScale(d[yAxis]))
      .attr('r', 5)
      .attr('fill', colorScale(yAxis))
      .on('mouseover', function (event, d) {
        tooltip.style('opacity', 1);
        d3.select(this).attr('stroke', 'black');
      })
      .on('mousemove', (event, d) => {
        tooltip
          .html(`${yAxis}: ${d[yAxis]}<br>${xaxisVariable}: ${d[xaxisVariable]}`)
          .style('left', `${event.pageX - 300}px`)
          .style('top', `${event.pageY - 628}px`);
      })
      .on('mouseout', function () {
        tooltip.style('opacity', 0);
        d3.select(this).attr('stroke', 'none');
      });

    // Regression line
    const linearRegression = this.calculateLinearRegression(validData, xaxisVariable, yAxis);
    const line = d3.line()
      .x(d => xScale(d[xaxisVariable]))
      .y(d => yScale(linearRegression.predict(d[xaxisVariable])));

    svg.append('path')
      .datum(validData)
      .attr('fill', 'none')
      .attr('stroke', 'blue')
      .attr('stroke-width', 1.5)
      .attr('d', line);

    svg.append('g')
      .attr('class', 'y-axis')
      .attr('transform', `translate(${margin.left},0)`)
      .call(d3.axisLeft(yScale));

    // Add Y-axis label
    svg.append('text')
      .attr('text-anchor', 'middle')
      .attr('transform', 'rotate(-90)')
      .attr('x', -height / 2)
      .attr('y', margin.left - 40)
      .text(yAxis);
  });

  svg.append('g')
    .attr('class', 'x-axis')
    .attr('transform', `translate(0,${chartHeight + margin.top})`)
    .call(d3.axisBottom(xScale));

  // X-axis label
  svg.append('text')
    .attr('text-anchor', 'middle')
    .attr('x', width / 2)
    .attr('y', height + margin.bottom - 50)
    .text(this.xAxislabel);
},
calculateLinearRegression(data, xKey, yKey) {
  const n = data.length;
  const sumX = d3.sum(data, d => d[xKey]);
  const sumY = d3.sum(data, d => d[yKey]);
  const sumXY = d3.sum(data, d => d[xKey] * d[yKey]);
  const sumX2 = d3.sum(data, d => d[xKey] * d[xKey]);

  const slope = (n * sumXY - sumX * sumY) / (n * sumX2 - sumX * sumX);
  const intercept = (sumY - slope * sumX) / n;

  return {
    predict: x => slope * x + intercept
  };
},
      sanitizeForCSS(value) {
        return value.replace(/[^a-zA-Z0-9-_]/g, '_');
      },
      drilldowndata(filteredkeyword, filteredData) {
        const dataToEmit = {
          sortedData: filteredData,
          filterkeyword: filteredkeyword,
        };
        this.$emit("drilldowncurrentchart", dataToEmit);
      },
      validateData(data) {
        return data.filter(
          (d) =>
            d[this.xaxisVariable] !== undefined &&
            this.selectedYAxes.every((y) => d[y] !== undefined && d[y] !== null)
        );
      },
    },
  };
  </script>
  
  <style scoped>
  @import "../../custom_lib/AiCharts/charts.css";
  .scatter-plot {
    position: relative;
  }
  
  .custom-multiselect {
    position: relative;
  }
  
  .tooltip {
    position: absolute;
    text-align: center;
    width: 120px;
    height: auto;
    padding: 8px;
    font: 12px sans-serif;
    background: lightsteelblue;
    border: 0;
    border-radius: 8px;
    pointer-events: none;
    opacity: 0;
  }
  
  .dropdown-button {
    padding: 10px 15px;
    background-color: #ffffff;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .dropdown-content {
    display: block;
    position: absolute;
    background-color: #f9f9f9;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    padding: 12px 16px;
    z-index: 1;
    max-height: 200px;
    overflow-y: auto;
    border: 1px solid #ccc;
    width: 300px;
  }
  
  .dropdown-item {
    margin-bottom: 10px;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  </style>
  