<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>{{ $t("navigations.visit schedule") }}</h2>
    </div>

    <div class="col-lg-2">
      <i class="fa fa-times close my-2" title="Close" @click="onclosed()"></i>
    </div>
  </div>

  <div class="wrapper wrapper-content">
    <div class="ibox-body mb-1 p-2">
      <div class="row my-2 my-lg-0 mb-lg-0">
        <div class="col-lg-5">
          <div class="form-group mb-0">
            <label class="mb-0">{{ $t("navigations.visit name") }}</label>

            <input
              v-model="searchData.visitName"
              type="text"
              class="form-control my-2 my-lg-0"
            />
          </div>
        </div>

        <div class="col-lg-5">
          <div class="form-group mb-0">
            <label class="mb-0">{{ $t("navigations.visit schedule") }}</label>

            <select
              class="form-select my-2 my-lg-0"
              v-model="searchData.visitStatus"
            >
              <option value="" selected>{{ $t("navigations.all") }}</option>
              <option value="Completed">
                {{ $t("navigations.complete") }}
              </option>
              <option value="Imminent">{{ $t("navigations.imminent") }}</option>
              <option value="Overdue">{{ $t("navigations.overdue") }}</option>
              <option value="Planned">{{ $t("navigations.planned") }}</option>
            </select>
          </div>
        </div>

        <div class="col-lg-10">
          <div class="form-group mb-0">
            <label class="mb-0">{{ $t("navigations.visit date") }}</label>

            <div class="d-flex">
              <input
                type="date"
                max="9999-12-31"
                class="form-control"
                v-model="searchData.fromDate"
              />

              <span class="mx-1 mt-2">{{ $t("navigations.to") }}</span>

              <input
                type="date"
                max="9999-12-31"
                class="form-control"
                v-model="searchData.toDate"
              />
            </div>
          </div>
        </div>

        <div class="col-lg-2 align-self-end">
          <span
            type="button"
            @click="getShedules()"
            class="btn btn-primary save_btn mx-lg-1 px-5"
          >
            {{ $t("navigations.search") }}
          </span>
        </div>
      </div>
    </div>

    <div class="ibox-body">
      <div class="ibox-title style_2 d-flex justify-content-between">
        <h5 class="mt-1">{{ $t("navigations.visit schedule list") }}</h5>
        <span>
          <a
            class="UnscheduleBlock linkstyle hoverstyle ml-3"
            @click="completepatient(sheduleList[0].patientId)"
            v-if="
              !hideComplte &&
                patient.status != 'Completed' &&
                rollsprivilages.includes('Visit Schedule Complete Patient')
            "
          >
            {{ $t("navigations.complete subject") }}
          </a>
          <span>
            <a
              class="UnscheduleBlock linkstyle hoverstyle"
              @click="openModel(false)"
              v-if="
                isScheduled == true &&
                  patient.status != 'Completed' &&
                  patient.status != 'Withdrawn' &&
                  rollsprivilages.includes('Visit Schedule Unscheduled Visit')
              "
            >
              {{ $t("navigations.unscheduled visit") }}
            </a>
          </span>
        </span>
        <div
          class="editicon my-1"
          v-if="
            scheduleBlock == true &&
              rollsprivilages.includes('Visit Schedule Create Schedule')
          "
        >
          <img
            class="img-width"
            :class="{ disablePostSchedule: disableSchedule }"
            src="../../assets/img/schedule.png"
            alt="Schedule patient"
            @click.prevent="postSchedule()"
          />
          <span class="pointer px-0"
            >{{ $t("navigations.create schedule") }}
          </span>
        </div>
      </div>
      <div class="ibox-content">
        <div class="table-responsive">
          <table class="table table-bordered dataTables">
            <thead>
              <tr>
                <th>{{ $t("navigations.site code") }}</th>

                <th>{{ $t("navigations.subject id") }}</th>

                <th>{{ $t("navigations.visit no") }}</th>

                <th
                  class="sort_block"
                  @click="SortSelected('visitName', 4)"
                  id="4"
                >
                  <div class="d-flex flex-row justify-content-between">
                    {{ $t("navigations.visit name") }}
                    <span class="float-right">
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span>
                  </div>
                </th>
                <th
                  class="sort_block"
                  @click="SortSelected('visitType', 5)"
                  id="4"
                >
                  <div class="d-flex flex-row justify-content-between">
                    {{ $t("navigations.visit type") }}
                    <span class="float-right">
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span>
                  </div>
                </th>

                <th
                  class="sort_block"
                  @click="SortSelected('preVisit', 5)"
                  id="5"
                >
                  <div class="d-flex flex-row justify-content-between">
                    {{ $t("navigations.pre-visit") }}
                    <span class="float-right">
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span>
                  </div>
                </th>

                <th
                  class="sort_block"
                  @click="SortSelected('visitDate', 6)"
                  id="6"
                >
                  <div class="d-flex flex-row justify-content-between">
                    {{ $t("navigations.visit date") }}
                    <span class="float-right">
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span>
                  </div>
                </th>

                <th
                  class="sort_block"
                  @click="SortSelected('postVisit', 7)"
                  id="7"
                >
                  <div class="d-flex flex-row justify-content-between">
                    {{ $t("navigations.post-visit") }}
                    <span class="float-right">
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span>
                  </div>
                </th>

                <th
                  class="sort_block"
                  @click="SortSelected('postVisit', 8)"
                  id="8"
                >
                  <div class="d-flex flex-row justify-content-between">
                    {{ $t("navigations.visit status") }}
                    <span class="float-right">
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span>
                  </div>
                </th>

                <th
                  class="sort_block"
                  @click="SortSelected('postVisit', 9)"
                  id="9"
                >
                  <div class="d-flex flex-row justify-content-between">
                    {{ $t("navigations.investigator") }}
                    <span class="float-right">
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span>
                  </div>
                </th>

                <th
                  class="sort_block"
                  @click="SortSelected('postVisit', 10)"
                  id="10"
                >
                  <div class="d-flex flex-row justify-content-between">
                    {{ $t("navigations.visit complete date") }}
                    <span class="float-right">
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span>
                  </div>
                </th>

                <th
                  class="text-center"
                  v-if="rollsprivilages.includes('Visit Schedule Edit')"
                >
                  {{ $t("navigations.edit") }}
                </th>

                <th
                  class="text-center"
                  v-if="rollsprivilages.includes('Visit Schedule View Form')"
                >
                  {{ $t("navigations.view") }}
                </th>

                <th
                  class="text-center"
                  v-if="rollsprivilages.includes('Visit Schedule Withdraw')"
                >
                  {{ $t("navigations.withdraw") }}
                </th>
              </tr>
            </thead>

            <tbody>
              <tr
                v-for="shedule in sheduleList"
                :key="shedule.id"
                :class="{ bgcolor2: shedule.isUnScheduled }"
              >
                <td>{{ shedule.siteCode }}</td>

                <td>{{ shedule.patientNo }}</td>

                <td>{{ shedule.visitNo }}</td>

                <td>{{ shedule.visitName }}</td>
                <td>{{ shedule.visitType }}</td>

                <!-- pre visit -->

                <td>
                  <span v-if="shedule.preVisit">{{
                    filter(shedule.preVisit)
                  }}</span>

                  <span v-else>{{}}</span>
                </td>

                <!-- visit date -->

                <td>
                  <span v-if="shedule.sheduleId == editShedule.sheduleId">
                    <input
                      type="date"
                      max="9999-12-31"
                      class="form-control"
                      v-model="visitdate"
                    />
                  </span>

                  <span
                    v-if="
                      shedule.visitDate &&
                        shedule.sheduleId != editShedule.sheduleId
                    "
                  >
                    {{ filter(shedule.visitDate) }}
                  </span>

                  <span v-else> {{}} </span>
                </td>

                <!-- post visit -->

                <td>
                  <span v-if="shedule.postVisit">
                    {{ filter(shedule.postVisit) }}
                  </span>

                  <span v-else>{{}}</span>
                </td>

                <td>
                  <span
                    class="badge badge-grn"
                    v-if="shedule.visitStatus == 'Completed'"
                    >{{ shedule.visitStatus }}</span
                  >
                  <span
                    class="badge badge-danger"
                    v-if="shedule.visitStatus == 'Overdue'"
                    >{{ shedule.visitStatus }}</span
                  >
                  <span
                    class="badge badge-warning"
                    v-if="shedule.visitStatus == 'Imminent'"
                    >{{ shedule.visitStatus }}</span
                  >
                  <span
                    class="badge badge-genre"
                    v-if="
                      shedule.visitStatus == 'Withdrawn' ||
                        shedule.visitStatus == 'Planned'
                    "
                    >{{ shedule.visitStatus }}</span
                  >
                </td>

                <!-- <td>
                <span v-if="shedule.visitStatus=='Completed'"  class="statusColor">{{shedule.visitStatus}}</span>
                <span v-if="shedule.visitStatus=='Overdue'"  class="statusColor2">{{shedule.visitStatus}}</span>
                <span v-if="shedule.visitStatus=='Imminent'"  class="statusColor3">{{shedule.visitStatus}}</span>
                </td> -->
                <!-- Investigator -->

                <td style="">
                  <!-- Display nothing if investigatorName is 'null' -->
                  <span v-if="shedule.investigatorName === 'null'"> </span>

                  <!-- Display investigator's name if the schedule is not being edited -->
                  <span
                    v-if="
                      shedule.sheduleId !== editShedule.sheduleId &&
                        shedule.investigatorName !== 'null' &&
                        shedule.investigatorName !== ''
                    "
                  >
                    {{ shedule.investigatorName }}
                  </span>

                  <!-- Dropdown to select investigator when the schedule is being edited -->
                  <span v-if="shedule.sheduleId === editShedule.sheduleId">
                    <select
                      class="form-control"
                      name="account"
                      v-model="investigator"
                    >
                      <option selected value="" disabled>-Select-</option>
                      <option
                        v-for="user in users"
                        :key="user.userID"
                        :value="user.email"
                      >
                        {{ user.name }}
                      </option>
                    </select>
                  </span>
                </td>

                <td>
                  <span v-if="shedule.visitCompletedDate">
                    {{ filter(shedule.visitCompletedDate.slice(0, 10)) }}
                    <!-- {{shedule.visitCompletedDate}} -->
                  </span>

                  <span v-else>{{}}</span>
                </td>

                <td class="text-center">
                  <span
                    v-if="
                      shedule != editShedule &&
                        shedule.visitStatus != 'Completed'
                    "
                  >
                    <i
                      v-if="
                        shedule.visitStatus != 'Withdrawn' &&
                          rollsprivilages.includes('Visit Schedule Edit')
                      "
                      class="fa fa-edit edit-delete"
                      @click="editVisit(shedule)"
                    ></i>
                  </span>

                  <span
                    class="d-flex justify-content-between"
                    v-if="
                      shedule == editShedule &&
                        shedule.visitStatus != 'Completed'
                    "
                  >
                    <i
                      class="fa fa-save edit-delete my-1 mr-2"
                      @click="saveVisit()"
                    >
                      <span class="tooltip">Save</span>
                    </i>

                    <i
                      class="fa fa-times close my-2 ml-2"
                      @click="closebutton()"
                    >
                      <span class="tooltip">Cancel</span>
                    </i>
                  </span>
                </td>

                <td class="text-center">
                  <span
                    v-if="
                      shedule.visitStatus == 'Completed' &&
                        rollsprivilages.includes('Visit Schedule View Form')
                    "
                    ><i
                      class="fa fa-eye edit-delete"
                      @click.prevent="viewforms(shedule.formId)"
                    ></i>
                  </span>
                </td>

                <td
                  class="text-center"
                  v-if="rollsprivilages.includes('Visit Schedule Withdraw')"
                >
                  <span
                    v-if="
                      shedule.visitStatus == 'Overdue' ||
                        shedule.visitStatus == 'Planned' ||
                        shedule.visitStatus == 'Imminent'
                    "
                    ><i
                      class="fa fa-arrow-circle-o-left edit-delete"
                      @click.prevent="withdrawVisit(shedule.sheduleId)"
                    ></i>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- pagination -->

        <div class="row mx-0 mt-2">
          <div class="col-lg-12 mx-0 px-0">
            <div
              class="dataTables_paginate paging_simple_numbers pagination float-right"
            >
              <div class="col-lg-12 d-flex justify-content-lg-end px-0">
                <v-pagination
                  v-model="this.currentPage"
                  :pages="totalPage"
                  :range-size="0"
                  active-color="#618bd7"
                  @update:modelValue="loadPage"
                />
              </div>
            </div>
          </div>
        </div>

        <!-- pagination ends -->
      </div>
    </div>
  </div>

  <!-- modal starts -->

  <div class="modal__schedule__wrapper" v-if="modalSchedule">
    <div class="modal__schedule__container">
      <div
        class="modal__schedule__title d-flex justify-content-between py-2 px-3"
      >
        <h4 class="my-0">{{ $t("navigations.unscheduled visit") }}</h4>
        <div class="cursor-pointer">
          <i class="fa fa-times" title="Close" @click.prevent="closemodall()"> </i>
        </div>
      </div>
      <div class="form-group row p-3">
        <div class="col-lg-4 my-2">
          <label class="col-form-label"
            >{{ $t("navigations.visit no")
            }}<sup><i class="fa fa-asterisk required"></i></sup
          ></label>

          <input
            v-model="popupdata.visitNo"
            type="text"
            class="form-control my-2 my-lg-0"
          />

          <span class="errormsg" v-if="popuperrors[0] == false">{{
            $t("navigations.please enter visit no")
          }}</span>
        </div>

        <div class="col-lg-4 my-2">
          <label class="col-form-label">
            {{ $t("navigations.visit name")
            }}<sup><i class="fa fa-asterisk required"></i></sup
          ></label>

          <input
            v-model="popupdata.visitName"
            type="text"
            class="form-control my-2 my-lg-0"
          />

          <span class="errormsg" v-if="popuperrors[1] == false">{{
            $t("navigations.please enter visit/form name")
          }}</span>
        </div>
        <div class="col-lg-4 my-2">
          <label class="col-form-label">
            {{ $t("navigations.visit type")
            }}<sup><i class="fa fa-asterisk required"></i></sup
          ></label>
          <select
            class="form-select"
            placeholder="Visit Type"
            v-model="popupdata.visitType"
            valueProp="VisitTypeName"
          >
          <option  value="" selected>--Select--</option>
            <option v-for="options in visitList" :key="options.typename">
              {{ options.typename }}
            </option>
          </select>
          <span class="errormsg" v-if="popuperrors[5] == false">{{
            $t("navigations.please select a visit type")
          }}</span>
        </div>

        <div class="col-lg-4 my-2">
          <label class="col-form-label">
            {{ $t("navigations.pre-visit")
            }}<sup><i class="fa fa-asterisk required"></i></sup
          ></label>

          <input
            v-model="popupdata.preVisit"
            type="text"
            class="form-control my-2 my-lg-0"
            maxlength="3"
            @change.prevent="numberonlyvalidation('preVisit')"
          />

          <span class="errormsg" v-if="popupnumerrors['preVisit'] == false">{{
            $t("navigations.please enter a number")
          }}</span>
        </div>

        <div class="col-lg-4 my-2">
          <label class="col-form-label">
            {{ $t("navigations.post-visit")
            }}<sup><i class="fa fa-asterisk required"></i></sup
          ></label>

          <input
            v-model="popupdata.postVisit"
            type="text"
            class="form-control my-2 my-lg-0"
            maxlength="3"
            @change.prevent="numberonlyvalidation('postVisit')"
          />

          <span class="errormsg" v-if="popupnumerrors['postVisit'] == false">{{
            $t("navigations.please enter a number")
          }}</span>
        </div>

        <div class="col-lg-4 my-2">
          <label class="col-form-label"
            >{{ $t("navigations.investigator")
            }}<sup><i class="fa fa-asterisk required"></i></sup
          ></label>

          <select
            class="form-control"
            name="popupaccount"
            v-model="popupdata.investigator"
          >
           <option  value="" selected>--Select--</option>
            <option
              v-for="user in users"
              :key="user.userID"
              :value="user.email"
            >
              {{ user.name }}
            </option>
          </select>

          <span class="errormsg" v-if="popuperrors[2] == false"
            >{{ $t("navigations.please enter a investigator") }}r</span
          >
        </div>

        <div class="col-lg-4 my-2">
          <label class="col-form-label">
            {{ $t("navigations.visit date")
            }}<sup><i class="fa fa-asterisk required"></i></sup
          ></label>

          <input
            v-model="popupdata.visitDate"
            type="date"
            max="9999-12-31"
            min="minDate"
            class="form-control my-2 my-lg-0"
          />

          <span class="errormsg" v-if="popuperrors[3] == false">{{
            $t("navigations.please enter visit date")
          }}</span>
        </div>

        <div class="col-lg-4 my-2">
          <label class="col-form-label"
            >{{ $t("navigations.visit template")
            }}<sup><i class="fa fa-asterisk required"></i></sup
          ></label>

          <select
            class="form-control"
            placeholder="Select a template"
            v-model="selectedmodeltemplate"
          >
            <option value="" disabled selected>
              {{ $t("navigations.select template") }}
            </option>

            <option
              v-for="templates in templatelist"
              v-bind:value="{
                id: templates.templateId,
                text: templates.templateName,
              }"
              v-bind:key="templates.templateId"
            >
              {{ templates.templateName }} ({{ templates.version }})
            </option>
          </select>

          <span class="errormsg" v-if="popuperrors[4] == false">{{
            $t("navigations.please select a visit template")
          }}</span>
        </div>

        <div class="col-lg-4 align-self-end">
          <div class="i-checkss">
            <label class="mr-2">{{ $t("navigations.patient visit") }}</label>
            <input
              type="checkbox"
              :checked="popupdata.visitType == 'Patient Visit'"
              v-model="popupdata.IsPatientVisit"
              :disabled="popupdata.visitType == 'Patient Visit'"
            />
          </div>
        </div>
        <div class="text-right">
          <span
            type="button"
            class="save_btn py-1 px-4 mt-2"
            @click.prevent="addUnscheduledVisit()"
          >
            {{ $t("navigations.save") }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */

import axios from "axios";

import store from "../../store/index";

import moment from "moment";

import VPagination from "@hennge/vue3-pagination";

import "@hennge/vue3-pagination/dist/vue3-pagination.css";

export default {
  name: "visitSheduleList",

  props: { patientId: String },

  components: {
    VPagination,
  },

  data() {
    return {
      sProp: "",
      IsDesc: true,
      modalSchedule: false,
      defaultdate: "",
      siteId: "",
      patient: "",
      scheduleBlock: true,
      hideComplte: false,

      templatelist: [],

      selectedmodeltemplate: "",

      minDate: new Date().toISOString(),

      isScheduled: false,

      isWithdrawn: false,

      searchData: {
        visitName: "",

        fromDate: "",

        toDate: "",

        visitStatus: "",

        pageNo: 1,

        pageSize: 10,
      },

      popupdata: {
        studyId: "",

        patientId: "",

        visitNo: "",

        visitName: "",
        visitType: "",

        visitDate: "",

        preVisit: "",

        postVisit: "",

        visitStatus: "",

        investigator: "",

        templateId: "",

        templateName: "",

        active: true,

        createdUserEmail: "",

        createdUserId: "",

        lastModifiedUserEmail: "",

        lastModifiedUserId: "",
        IsPatientVisit: false,
      },

      oldprevisit: "",

      oldpostvisit: "",

      oldnursename: "",

      currentPage: 1,

      totalPage: 0,

      totalCount: 1,

      sheduleList: [],

      baseUrl: `${process.env.VUE_APP_Service_URL}/management/patientschedule/`,

      baseapi: process.env.VUE_APP_Service_URL,

      validation: [false],

      disableSchedule: false,

      popupnumerrors: { preVisit: true, postVisit: true },

      popupvalidations: [false, false, false, false, false, false],

      curntdate: new Date().toISOString().slice(0, 10),

      popuperrors: [],

      editShedule: "",

      investigator: "",

      visitdate: "",

      users: "",
      visitList: [],
      rollsprivilages: "",
    };
  },

  async mounted() {
    this.defaultdate = localStorage.getItem("dateformat");
    this.rollsprivilages = await store.getters.getRolesprivilegeData;
    this.gettemplatedata();

    this.getSchedule();

    this.getUsers();
    this.getVisitType();

    window.addEventListener("studyIdChanged", (event) => {
      console.log("StudyIDChanged into" + event.detail.storage);

      // window.location.reload();

      this.idToken = store.getters.getIdToken;
    });
    this.idToken = store.getters.getIdToken;
    this.headerValues = {
      headers: {
        Authorization: "Bearer " + this.idToken,
        "Content-Type": "application/json",
      },
    };

    this.getShedules();

    this.getifWithdrawn();

    this.getpatientdata(this.patientId);
    $(function() {
      $('[data-toggle="tooltip"]').tooltip();
    });
  },

  methods: {
    filter(data) {
      if (data) {
        return moment(data).format(this.defaultdate);
      }
    },
    async hideCompleteIcon() {
      this.hideComplte = false;

      if (this.sheduleList.length) {
        this.sheduleList.forEach((shedule) => {
          if (
            shedule.visitStatus != "Completed" &&
            shedule.visitStatus != "Withdrawn"
          ) {
            this.hideComplte = true;
          }
        });
      } else {
        this.hideComplte = true;
      }
    },
    async getVisitType() {
      const idtoken = store.getters.getIdToken;
      await axios
        .get(`${this.baseapi}/management/visittype/listvisittypes`, {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          console.log(res);
          this.visitList = res.data.entities;
          console.log("Result visit is", this.visitList);
        })
        .catch((err) => {
          console.log(err);
          alert(err.response.data.detail);
        });
    },
    async completepatient(patientId) {
      if (
        confirm(
          this.$t("navigations.are you sure you want to complete this subject?")
        )
      ) {
        const idtoken = store.getters.getIdToken;

        await axios
          .put(
            `${this.baseapi}/management/patient/completepatient?patientid=${patientId}`,
            {},
            this.headerValues
          )

          .then((res) => {
            console.log("Completed..", res);

            this.$router.push(`/patientList`);
          })

          .catch((err) => console.log("erroe..", err));

        this.getSchedule();
      }
    },
    async getifWithdrawn() {
      const idtoken = store.getters.getIdToken;

      axios

        .get(
          `${this.baseapi}/management/patient/ispatientwithdrawn?patientId=${this.patientId}`,

          //`https://localhost:5001/patient/ispatientwithdrawn?patientId=${this.patientId}`,

          {
            headers: {
              Authorization: "Bearer " + idtoken,
            },
          }
        )

        .then((res) => {
          this.isWithdrawn = res.data;

          console.log("The Withdrawn value is", this.isWithdrawn);

          if (this.isWithdrawn == true) {
            console.log("Condition entered");
            let y = document.getElementsByClassName("UnscheduleBlock");
            y[0].style.display = "none";
            this.scheduleBlock = false;
          }
        })

        .catch((err) => {
          console.log(err);
        });
    },
    async getSchedule() {
      const idtoken = store.getters.getIdToken;

      axios

        .get(
          `${this.baseapi}/management/patient/isschedule?patientId=${this.patientId}`,

          {
            headers: {
              Authorization: "Bearer " + idtoken,
            },
          }
        )

        .then((res) => {
          this.isScheduled = res.data;

          if (this.isScheduled == true) {
            this.scheduleBlock = false;
          }
        })

        .catch((err) => {
          console.log(err);
        });
    },

    async viewforms(id) {
      if (id == null) {
        alert("The id value is null");
      } else {
        const fromArchive = false;
        this.$router.push(`/showform/patientlist/${id}/${fromArchive}`);
      }
    },

    async postSchedule() {
      if (
        this.patient.enrollmentDate == null ||
        this.patient.enrollmentDate == ""
      ) {
        alert(
          this.$t(
            "navigations.please add subject enrollment date for scheduling the Visits"
          )
        );
      } else {
        if (this.disableSchedule == false) {
          this.disableSchedule = true;
          const idtoken = store.getters.getIdToken;
          const valnow = store.getters.getStudyIs;
          axios
            .post(
              `${this.baseapi}/management/patientschedule/schedule`,
              {
                studyId: valnow,
                patientId: this.patientId,
              },
              {
                headers: {
                  Authorization: "Bearer " + idtoken,
                },
              }
            )
            .then((res) => {
              alert(this.$t("navigations.schedule created successfully"));
              this.$router.push(`/patientList`);
              this.changeSiteStatus();
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }
    },

    async changeSiteStatus() {
      const idtoken = store.getters.getIdToken;

      await axios
        .put(
          `${this.baseapi}/management/site/changesitestatus?siteId=` +
            this.siteId +
            `&status=Enrolled`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,

              "Content-Type": "application/json",
            },
          }
        )

        .then((res) => {
          console.log(res);
          console.log("site status changed successfully...!");
        })

        .catch((err) => {
          console.log(err);
          console.log("error in change site status...!");
        });
    },
    SortSelected(sortProp, id) {
      console.log("SortPropChanged");
      const selectedElement = document.getElementById(id);
      const selectedElementID = document.getElementById(id).id;
      if (sortProp != null && id == selectedElementID) {
        this.sortDesc = !this.sortDesc;
        if (this.SortOrder == 0) {
          // if(selectedElement.classList.contains("sortDesc")){
          //   return
          // }
          if (selectedElement.classList.contains("sortAsc")) {
            selectedElement.classList.remove("sortAsc");
            selectedElement.classList.add("sortDesc");
          } else selectedElement.classList.add("sortDesc");
        } else {
          if (selectedElement.classList.contains("sortDesc")) {
            selectedElement.classList.remove("sortDesc");
            selectedElement.classList.add("sortAsc");
          }
          // else if(selectedElement.classList.contains("sortAsc"))
          // {
          //   return
          // }
          else selectedElement.classList.add("sortAsc");
        }
      }
      this.pageindex = 1;
      this.currentPage = 1;
      this.SortProperty = sortProp;
      this.SortOrder = this.SortOrder == 0 ? 1 : 0;
      this.currentPage = 1;
      this.sProp = sortProp;
      this.IsDesc = this.IsDesc == true ? false : true;
      this.getShedules();
    },
    async search() {
      this.setSearchData();
      this.pageindex = 1;
      this.currentPage = 1;
      this.getAndSetData();
    },

    async getShedules() {
      // if (this.searchData.visitStatus == "All") {
      //   this.searchData.visitStatus = "";
      // }
      this.validateDate(this.searchData.fromDate, this.searchData.toDate);

      if (this.validation.includes(true)) {
        alert(this.$t("navigations.from date should be less than to date"));
      } else {
        console.log("in list");

        await axios
          .get(
            this.baseUrl +
              "getpatientshedule" +
              "?patientID=" +
              this.patientId +
              "&visit=" +
              this.searchData.visitName +
              "&fromDate=" +
              this.searchData.fromDate +
              "&toDate=" +
              this.searchData.toDate +
              "&visitStatus=" +
              this.searchData.visitStatus +
              "&PageNo=" +
              this.searchData.pageNo +
              "&ItemCount=" +
              this.searchData.pageSize +
              "&sortprop=" +
              this.sProp +
              "&isDes=" +
              this.IsDesc,

            this.headerValues
          )

          .then((res) => {
            console.log("sheduleList result:", res.data.data);

            this.sheduleList = res.data.data;

            this.hideCompleteIcon();

            // console.log("invsti",this.popupdata.nursename);

            // this.popupdata.popstudyid

            this.totalCount = res.data.totalCount;

            this.totalPage = parseInt(
              this.totalCount / this.searchData.pageSize
            );

            if (this.totalCount % this.searchData.pageSize != 0) {
              this.totalPage = this.totalPage + 1;
            }
          })

          .catch((err) => {
            console.log("error is", err);
          })

          .catch((err) => {
            console.log("error is", err);
          });
      }
    },

    validateDate(fromDate, toDate) {
      if (fromDate > toDate) {
        this.validation[0] = true;
      } else {
        this.validation[0] = false;
      }
    },

    loadPage: function(page) {
      this.currentPage = page;

      this.searchData.pageNo = page;

      this.getShedules();
    },

    async getUsers() {
      const valnow = await store.getters.getStudyIs;

      const idtoken = store.getters.getIdToken;

      console.log("get recipients" + valnow);

      await axios

        .get(
          `${this.baseapi}/management/study/getusersinstudy?StudyId=${valnow}`,

          {
            headers: {
              Authorization: "Bearer " + idtoken,

              "Content-Type": "application/json",
            },
          }
        )

        .then((res) => {
          console.log(res);

          this.users = res.data;

          console.log("Users......", this.users);
        })

        .catch((err) => {
          console.log(err);
        });
    },

    async onclosed() {
      this.$router.push(`/patientList`);
    },

    async openModel(isedit) {
      this.modalSchedule = !this.modalSchedule;
      document.body.style.overflowY = "hidden";
      this.isEdit = isedit;
    },

    async closemodall() {
      document.body.style.overflowY = "auto";
      console.log("close modal");
      this.modalSchedule = !this.modalSchedule;
      this.popupdata.visitNo = "";

      this.popupdata.visitName = "";

      this.popupdata.visitDate = "";

      this.popupdata.preVisit = this.oldprevisit;

      this.popupdata.postVisit = this.oldpostvisit;

      this.popupdata.investigator = this.oldnursename;
      this.popupdata.IsPatientVisit = false;

      this.selectedmodeltemplate = "";
    },

    async getpatientdata(patientId) {
      const idtoken = store.getters.getIdToken;

      await axios
        .get(
          `${this.baseapi}/management/patient/getpatientbyid/` + patientId,

          {
            headers: {
              Authorization: "Bearer " + idtoken,
            },
          }
        )

        .then((res) => {
          console.log("The pat datazzz is", res.data);
          this.patient = res.data;

          this.siteId = res.data.siteId.toString();

          this.oldprevisit = res.data.preVisit.toString();

          this.oldpostvisit = res.data.postVisit.toString();

          if (res.data.nurseName != null) {
            const stringnurse = res.data.nurseName.toString();

            if (stringnurse != "" && stringnurse != "null") {
              console.log("The pat datazzz ENTRD", res.data);

              this.oldnursename = res.data.nurseName;

              this.popupdata.investigator = res.data.nurseName;
            }
          }

          this.popupdata.preVisit = res.data.preVisit.toString();

          this.popupdata.postVisit = res.data.postVisit.toString();
        })

        .catch((err) => {
          console.log(err);
        });
    },

    async popupvalidate() {
      //console.log("from popupo nurse",  this.popupdata.nursename);

      //console.log("from popupo vst nme",  this.popupdata.visitName);

      if (this.popupdata.visitNo != "") {
        this.popupvalidations[0] = true;
      } else {
        this.popupvalidations[0] = false;
      }

      if (this.popupdata.visitName != "") {
        console.log(" vst nme not null", this.popupdata.visitName);

        this.popupvalidations[1] = true;
      } else {
        this.popupvalidations[1] = false;
      }

      if (this.popupdata.investigator != "") {
        this.popupvalidations[2] = true;
      } else {
        this.popupvalidations[2] = false;
      }

      if (this.popupdata.visitDate != "") {
        this.popupvalidations[3] = true;
      } else {
        this.popupvalidations[3] = false;
      }

      if (this.selectedmodeltemplate != "") {
        this.popupvalidations[4] = true;
      } else {
        this.popupvalidations[4] = false;
      }
      if (this.popupdata.visitType != "") {
        this.popupvalidations[5] = true;
      } else {
        this.popupvalidations[5] = false;
      }
    },

    async numberonlyvalidation(fieldname) {
      console.log("fld name is", fieldname);

      let isInteger = true;

      let fieldval = this.popupdata[fieldname];

      if (fieldval == "" || fieldval == null) {
        this.popupnumerrors[fieldname] = false;
      } else {
        console.log("val is", fieldval);

        if (fieldval % 1 != 0) {
          isInteger = false;
        }

        //console.log(this.values[fieldname]);

        if (!isInteger) {
          this.popupdata[fieldname] = "";

          this.popupnumerrors[fieldname] = false;
        } else {
          if (parseInt(this.popupdata[fieldname]) >= 0) {
            this.popupnumerrors[fieldname] = true;
          } else {
            this.popupdata[fieldname] = "";
          }
        }
      }
    },

    async addUnscheduledVisit() {
      //console.log("in validtn");

      await this.popupvalidate();

      if (this.popupvalidations.includes(false)) {
        // console.log("in validtn 2");

        this.popuperrors = this.popupvalidations;

        //console.log("in validtn 3");
      } else {
        this.saveUnscheduledvisit();
      }
    },

    async saveUnscheduledvisit() {
      // this.values.studyId = store.getters.getStudyIs;

      //this.values.folderId = store.getters.getStudyIs;

      //if(this.selectedmodeltemplate.id==null){

      //   console.log("check for null",this.selectedmodeltemplate.id)

      // }

      //console.log("check for null2",this.selectedmodeltemplate.id)

      (this.popupdata.templateId = this.selectedmodeltemplate.id),
        (this.popupdata.templateName = this.selectedmodeltemplate.text),
        (this.popupdata.patientId = this.patientId);

      this.popupdata.studyId = store.getters.getStudyIs;

      const idtoken = store.getters.getIdToken;

      console.log("token is", idtoken);

      console.log("passing data", this.popupdata);

      await axios

        .post(
          //'https://localhost:5001/patientschedule/unscheduledvisit',
          `${this.baseapi}/management/patientschedule/unscheduledvisit`,
          this.popupdata,
          {
            headers: {
              Authorization: "Bearer " + idtoken,

              "Content-Type": "application/json",
            },
          }
        )

        .then((res) => {
          console.log("res from server", res);

          console.log("pat id is", this.patientId);

          //  this.$router.push(`/home`);

          alert(this.$t("navigations.unscheduled visit added successfully"));

          this.$router.go();

          // this.userList = res.data.data;

          // this.$router.push(`/visitSheduleList/${this.patientId}`);

          // alert("Unscheduledvisit added successfully.");
        })

        .catch((err) => {
          if (
            err.response.data.messages[0].includes(
              "Visit Number already exists"
            )
          ) {
            alert(this.$t("navigations.visit number already exists"));
          }

          //  alert("alrt "+err.response.data.detail);
        });
    },

    editVisit(editShedule) {
      console.log(editShedule);

      this.editShedule = editShedule;

      this.investigator = editShedule.investigator;

      this.visitdate = editShedule.visitDate.slice(0, 10);

      console.log("visit date", this.visitdate);
    },

    async gettemplatedata() {
      const idtoken = store.getters.getIdToken;

      console.log("template data function");

      await axios

        .get(
          `${this.baseapi}/forms/template/listtemplate?Status=Approved&StudyID=${store.getters.getStudyIs}&pagesize=100&SortProperty=templateName&SortOrder=0`,

          {
            headers: {
              Authorization: "Bearer " + idtoken,

              "Content-Type": "application/json",
            },
          }
        )

        .then((res) => {
          console.log("template data is");

          console.log(res.data.results);

         this.templatelist = res.data.results.sort((a, b) => {
    return a.templateName.localeCompare(b.templateName);
  });
})

        .catch((err) => {
          console.log("error");

          console.log(err);
        });
    },

    async saveVisit() {
      console.log("In visit date edit");
      const idtoken = store.getters.getIdToken;
      let preVisit = this.editShedule.preVisit;
      let VisitDate = this.visitdate + " 00:00:00Z";
      let PostVisit = this.editShedule.postVisit.slice(" ");
      console.log(
        "visittttttttttttttttttt dateeeeeeeeeeeeeeee",
        preVisit,
        VisitDate,
        PostVisit
      );
      if (VisitDate >= preVisit && VisitDate <= PostVisit) {
        await axios
          .put(
            `${this.baseUrl}updatepatientshedule?NurseMail=${this.investigator}&SubjectId=${this.editShedule.patientId}&Sheduleid=${this.editShedule.sheduleId}&date=${this.visitdate}`,
            {},
            this.headerValues
          )
          .then((res) => {
            console.log("shedule:", res);
          })
          .catch((err) => {
            console.log("error is", err);
          });
        this.editShedule = "";
        await this.getShedules();
      } else {
        alert(
          this.$t(
            "navigations.visit date should be between pre and post visit date"
          ),
          this.visitdate
        );
      }
    },

    async withdrawVisit(sheduleId) {
      if (
        confirm(
          this.$t("navigations.are you sure you want to withdraw this visit?")
        )
      ) {
        await axios
          .put(
            this.baseUrl + "updateshedulestatus?sheduleId=" + sheduleId,
            {},
            this.headerValues
          )

          .then((res) => {
            console.log(res);
          })

          .catch((err) => console.log("error..", err));

        this.getShedules();
      }
    },

    closebutton() {
      this.editShedule = "";
    },
  },
};
</script>

<style>
@import "../../assets/style.css";

@import "../../assets/formpage.css";

.editicon {
  position: relative;
}

.editicon .pointer {
  visibility: hidden;

  width: 150px;

  background-color: #616161;

  color: #fff;

  text-align: center;

  border-radius: 0.3em;

  padding: 0.3rem 0.2rem;

  right: 0;

  top: -35px;

  font-size: 0.8rem;

  font-weight: 500;

  /* Position the tooltip */

  position: absolute;

  z-index: 1;

  transition: visibility 0.4s ease-in;
}

.editicon:hover .pointer {
  visibility: visible;
}

/*pagination styles*/

.pagination {
  margin: 0;
}

.Page,
.PaginationControl {
  border: 1px solid #e7eaec;
}

.Page-active {
  padding: 1rem;
  color: #ffffff;
  border: 1px solid var(--pagination-active-clr);
  border-radius: inherit;
  margin: 0;
}

.Page {
  padding: 1rem;

  border-radius: inherit;

  margin: 0;
}

.PaginationControl {
  padding: 0.42rem;
}

.UnscheduleBlock {
  display: flex;

  justify-content: flex-end;
}

.bgcolor2 {
  background-color: #d0f1f1;
}

.badge-genre {
  background-color: #b6bdbf;
  color: #ffffff;
}

.badge-grn {
  background-color: #1ab394;
  color: #ffffff;
}

.titleClass {
  background-color: var(--pop-up-background);
  color: white;
  height: 35px;
  padding-left: 5px;
  padding-top: 5px;
}

.form-select {
  border-radius: 0 !important;
}

.form-select:focus {
  border-color: #145faf !important;

  box-shadow: 0 0 0 !important;
}

.modal__schedule__wrapper {
  position: fixed;
  inset: 0;
  background: rgb(0 0 0 / 18%);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 3000;
}

.modal__schedule__container {
  background: #fff;
  width: min(66%, 90%);
  border-radius: 4px;
  position: fixed;
  overflow-x: hidden;
  pointer-events: auto;
}

.modal__schedule__title {
  background-color: var(--pop-up-background);
  color: #ffffff;
}

.img-width {
  width: 23px;
}
.disablePostSchedule {
  opacity: 0.5;
}
i {
  position: relative;
  display: inline-block;
}

.tooltip {
  visibility: hidden;
  width: 58px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%; /* Position above the icon */
  left: 50%;
  margin-left: -30px; /* Center the tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

i:hover .tooltip {
  visibility: visible;
  opacity: 1;
}
</style>
