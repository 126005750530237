import axios from "axios";
import Multiselect from "@vueform/multiselect";
import arctween from "../../custom_lib/AiCharts/arctween.vue";
import percebtagechart from "../../custom_lib/AiCharts/percentagechart.vue";
import d3BarchartComparitive from "../../custom_lib/AiCharts/d3BarchartComparitive.vue";
import D3PieChart from '../../custom_lib/AiCharts/D3PieChart.vue';
import sunburst from '../../custom_lib/AiCharts/sunburst.vue';
import groupbarchart from '../../custom_lib/AiCharts/groupbarchartscreening.vue';
import d3table from '../../custom_lib/AiCharts/d3table.vue';
import historytab from '../../custom_lib/AiCharts/historytab.vue';
import chartdrill from '../../custom_lib/AiCharts/popupfiles/chartdrillPopup.vue';
export default{
    name: "RetentionDashboard",
    components: {
        Multiselect,
        arctween,
        percebtagechart,
        d3BarchartComparitive,
        D3PieChart,
        sunburst,
        groupbarchart,
        d3table,
        historytab,
        chartdrill
    },
    props: {

    },
    data(){
        return {
            baseUrl: `${process.env.VUE_APP_Service_URL}/forms-ml/`,
            totalCount: 0,
            retentionDetails: [],
            retentionCount: 0,
            datahistory:[],
            historykeys:[],
            historydata:{},
            drilldata:{},
            drilldowndata:[],
            searchData: {
                PageNo: 0,
                ItemCount: 0,
                SortBy: "",
                IsDescending: false,
                ML_confidenceMin: 0,
                ML_confidenceMax: 1,
                ML_prediction: null,
            },
            loader:true,
            drillpopup: false
        };
    },
    async mounted(){
        await this.getRetentionCount();
        await this.getPatientRetentionInfo();
        
    },
    methods:{
        async onclosed(){
            this.drillpopup = false;
          },
        async drillpopupopened(datafromchild) {
            this.drilldata = datafromchild;
            this.drillpopup = true;
         },
        async drilldowncurrentchartforbar(datafromchild){
            this.datahistory.push(this.retentionDetails);
            this.historykeys.push(datafromchild.filterkeyword);
            this.historydata[datafromchild.filterkeyword] = datafromchild.sortedData;
            this.retentionDetails = this.historydata[datafromchild.filterkeyword];
        },
        async undobutton(){
            if(this.datahistory.length > 1){
            this.datahistory.pop();
            this.retentionDetails=this.datahistory[this.datahistory.length-1];
            }
        },
        async gotohistorylevel(history){
            this.deleteAfterKeyvaluearrays(history);
            this.deleteAfterKeyarrays(history);
            this.retentionDetails = this.historydata[history];
        },
        async deleteAfterKeyvaluearrays(selectedKey) {
            const entries = Object.entries(this.historydata); // Convert the object to an array of entries
            const selectedIndex = entries.findIndex(([key, value]) => key === selectedKey);
          
            // If the key is found, slice the array to keep only the entries up to the selected key
            const newEntries = selectedIndex !== -1 ? entries.slice(0, selectedIndex + 1) : entries;
          
            // Convert the array of entries back into an object
            return Object.fromEntries(newEntries);
          },
          async deleteAfterKeyarrays(selectedKey) {
      
            let index = this.historykeys.indexOf(selectedKey);
      
            if (index !== -1) {
                // Slice the array up to the element (including the element)
                this.historykeys = this.historykeys.slice(0, index + 1);
            }
          },
        async getPatientRetentionInfo() {
            this.loader = true;
            await axios
              .post(`${this.baseUrl}retention/getretentioninfo`, this.searchData)
              .then((res) => {
                this.retentionDetails = res.data.data;
                this.datahistory.push(this.retentionDetails);
                this.historydata['Home'] = this.retentionDetails;
                this.historykeys.push('Home');
                this.totalCount = res.data.count;
                this.loader = false;
              })
              .catch((err) => console.log("error in getting retention result", err));
        },
        async getRetentionCount(){
            await axios.get(`${this.baseUrl}retention/getretentioncounts`)
            .then((res) => {
              this.retentionCount = res.data;
            })
            .catch(err => {
              console.log("error in get retention counts", err)
            });
        },
    }
};