<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>{{ $t("navigations.submit form") }}</h2>
    </div>
  </div>
  <div class="wrapper wrapper-content animated fadeInRight">
    <div class="row"></div>
    <div class="row">
      <div
        v-if="roleprivileges.includes('Submit Form Overdue Visits')"
        class="col-lg-6 mb-1"
      >
        <div class="ibox-body">
          <div class="ibox-title style_2 text-start">
            <h5 class="float-left">{{ $t("navigations.overdue visits") }}</h5>
          </div>
          <div class="ibox-content">
            <div class="table-responsive">
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th>
                      {{ $t("navigations.site code") }}
                    </th>
                    <th>
                      {{ $t("navigations.subject id") }}
                    </th>
                    <th
                      class="sort_block"
                      @click="SortSelected('visitName', 3)"
                      id="3"
                    >
                      <div class="d-flex flex-row justify-content-between">
                        {{ $t("navigations.visit name") }}
                        <span>
                          <i class="fa fa-long-arrow-up"></i>
                          <i class="fa fa-long-arrow-down"></i>
                        </span>
                      </div>
                    </th>
                    <th
                      class="sort_block"
                      @click="SortSelected('visitType', 4)"
                      id="4"
                    >
                      <div class="d-flex flex-row justify-content-between">
                        {{ $t("navigations.visit type") }}
                        <span>
                          <i class="fa fa-long-arrow-up"></i>
                          <i class="fa fa-long-arrow-down"></i>
                        </span>
                      </div>
                    </th>
                    <th
                      class="sort_block"
                      @click="SortSelected('visitDate', 5)"
                      id="5"
                    >
                      <div class="d-flex flex-row justify-content-between">
                        {{ $t("navigations.date") }}
                        <span>
                          <i class="fa fa-long-arrow-up"></i>
                          <i class="fa fa-long-arrow-down"></i>
                        </span>
                      </div>
                    </th>
                    <th class="text-center">
                      {{ $t("navigations.submit form") }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="list in overduevisits" :key="list.patientId">
                    <td>{{ list.siteCode }}</td>
                    <td>{{ list.patientNo }}</td>
                    <td>{{ list.visitName }}</td>
                    <td>{{ list.visitType }}</td>
                    <td>{{ filter(list.visitDate) }}</td>
                    <td class="text-center">
                      <i
                        class="fa fa-plus-square edit-delete"
                        aria-hidden="true"
                        @click.prevent="goTovisitTemplate(list)"
                      ></i>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="row mx-0 mt-2">
              <div class="col-lg-12 mx-0 px-0">
                <div
                  class="
                    dataTables_paginate
                    paging_simple_numbers
                    pagination
                    float-right
                  "
                >
                  <div class="col-lg-12 d-flex justify-content-lg-end px-0">
                    <v-pagination
                      v-model="currentoverduePage"
                      :pages="totaloverduePages"
                      :range-size="1"
                      active-color="#618bd7"
                      @update:modelValue="loadPage"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="roleprivileges.includes('Submit Form Imminent Visits')"
        class="col-lg-6 mb-1"
      >
        <div class="ibox-body">
          <div class="ibox-title style_2">
            <h5 class="float-left">{{ $t("navigations.imminent visits") }}</h5>
          </div>
          <div class="ibox-content">
            <div class="table-responsive">
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th>
                      {{ $t("navigations.site code") }}
                    </th>
                    <th>
                      {{ $t("navigations.subject id") }}
                    </th>
                    <th
                      class="sort_block"
                      @click="SortSelect('visitName', 8)"
                      id="8"
                    >
                      <div class="d-flex flex-row justify-content-between">
                        {{ $t("navigations.visit name") }}
                        <span>
                          <i class="fa fa-long-arrow-up"></i>
                          <i class="fa fa-long-arrow-down"></i>
                        </span>
                      </div>
                    </th>
                    <th
                      class="sort_block"
                      @click="SortSelect('visitType', 9)"
                      id="9"
                    >
                      <div class="d-flex flex-row justify-content-between">
                        {{ $t("navigations.visit type") }}
                        <span>
                          <i class="fa fa-long-arrow-up"></i>
                          <i class="fa fa-long-arrow-down"></i>
                        </span>
                      </div>
                    </th>
                    <th
                      class="sort_block"
                      @click="SortSelect('visitDate', 10)"
                      id="10"
                    >
                      <div class="d-flex flex-row justify-content-between">
                        {{ $t("navigations.date") }}
                        <span>
                          <i class="fa fa-long-arrow-up"></i>
                          <i class="fa fa-long-arrow-down"></i>
                        </span>
                      </div>
                    </th>
                    <th class="text-center">
                      {{ $t("navigations.submit form") }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="list in plannedvisits" :key="list.patientId">
                    <td>{{ list.siteCode }}</td>
                    <td>{{ list.patientNo }}</td>
                    <td>{{ list.visitName }}</td>
                    <td>{{ list.visitType }}</td>
                    <td>{{ filter(list.visitDate) }}</td>
                    <td class="text-center">
                      <i
                        class="fa fa-plus-square edit-delete"
                        aria-hidden="true"
                        @click.prevent="goTovisitTemplate(list)"
                      ></i>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="row mx-0 mt-2">
              <div class="col-lg-12 mx-0 px-0">
                <div
                  class="
                    dataTables_paginate
                    paging_simple_numbers
                    pagination
                    float-right
                  "
                >
                  <div class="col-lg-12 d-flex justify-content-lg-end px-0">
                    <v-pagination
                      v-model="currentplannedPage"
                      :pages="totalplannedPages"
                      :range-size="1"
                      active-color="#618bd7"
                      @update:modelValue="loadPlanned"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <v-jstree :data="data" show-checkbox multiple allow-batch whole-row @item-click="itemClick"></v-jstree> -->
      <div
        v-if="roleprivileges.includes('Submit Form Patient Schedule')"
        class="col-lg-6 mb-1"
      >
        <div class="ibox-body">
          <div class="ibox-title style_2">
            <h5 class="float-left">{{ $t("navigations.patient schedule") }}</h5>
          </div>
          <div class="ibox-content">
            <ul class="tree">
              <li v-for="(site, sIndex) in sites" :key="site.siteID">
                <input
                  type="checkbox"
                  :id="site.siteID"
                  @change="getpatient(site.siteID, sIndex)"
                />
                <label class="tree_label" :for="site.siteID">{{
                  site.siteCode
                }}</label>
                <ul
                  v-for="(patient, pIndex) in patientLists[sIndex]"
                  :key="patient.patientId"
                >
                  <li>
                    <input
                      type="checkbox"
                      :id="patient.patientId"
                      @change="getVisits(patient.patientId, pIndex)"
                    />
                    <label :for="patient.patientId" class="tree_label">{{
                      patient.subjectId
                    }}</label>
                    <ul
                      v-for="(visit, vIndex) in visitobj[patient.patientId][pIndex]"
                      :key="visit.sheduleId"
                    >
                      <li>
                        <input 
                          type="checkbox"
                          :id="visit.sheduleId"
                          @change="getFormsByVisit(patient.patientId,visit.visitNo, visit.visitName)"/> 
                        <label :for="visit.sheduleId" class="tree_label">
                          {{ visit.visitNo }} - {{ visit.visitName }} 
                          <!-- {{ filter(visit.visitDate) }}  -->
                          <!-- <span
                            class="badge badge-grn"
                            v-if="visit.visitStatus == 'Completed'"
                            >{{ visit.visitStatus }}</span
                          >
                          <span
                            class="badge badge-danger"
                            v-if="visit.visitStatus == 'Overdue'"
                            >{{ visit.visitStatus }}</span
                          >
                          <span
                            class="badge badge-warning"
                            v-if="visit.visitStatus == 'Imminent'"
                            >{{ visit.visitStatus }}</span
                          >
                          <span
                            class="badge badge-genre"
                            v-if="
                              visit.visitStatus == 'Withdrawn' ||
                              visit.visitStatus == 'Planned'
                            ">{{ visit.visitStatus }}</span> -->
                        </label>
                        <span 
                          class="position-relative cursor-pointer float-right px-2"
                            @click.prevent="toggleSidePopup(sIndex, pIndex, vIndex)"
                            v-on:clickout="closeSidePopup(sIndex, pIndex, vIndex)"
                           >
                          <i class="fa fa-ellipsis-v" ></i>
                          <div class="tool-tip">Options</div>
                        </span>
                        <div 
                          class="plan-side-popup" 
                          v-if="showSidePopup[sIndex] && showSidePopup[sIndex][pIndex] && showSidePopup[sIndex][pIndex][vIndex]">
                          <div 
                            @click.prevent="openModal(visit,patient.subjectId,site.siteCode)">
                            {{ $t("navigations.create form") }}
                          </div>
                        </div> 
                        <!-- Issue to be fixed -->
                        <template v-if="visitFormObj[visit.visitNo]">
                        <ul 
                            v-for="singlevisit in visitFormObj[visit.visitNo].data" 
                            :key="singlevisit.visitNo">
                            <!-- <li> {{ singlevisit }}</li> -->
                            <li>
                              <input 
                                v-if="singlevisit.templateName"
                                type="checkbox"
                                @click="gotoform(singlevisit,patient.subjectId,site.siteCode)"/>
                                <label @click="gotoform(singlevisit,patient.subjectId,site.siteCode)" class="tree_label">  {{singlevisit.templateName
                                }} 
                                <span
                            class="badge badge-grn"
                            v-if="singlevisit.visitStatus == 'Completed'"
                            >{{ singlevisit.visitStatus }}</span
                          >
                          <span
                            class="badge badge-danger"
                            v-if="singlevisit.visitStatus == 'Overdue'"
                            >{{ singlevisit.visitStatus }}</span
                          >
                          <span
                            class="badge badge-warning"
                            v-if="singlevisit.visitStatus == 'Imminent'"
                            >{{ singlevisit.visitStatus }}</span
                          > 
                          <span
                            class="badge badge-genre"
                            v-if="
                              singlevisit.visitStatus == 'Withdrawn' ||
                              singlevisit.visitStatus == 'Planned'
                            ">{{ singlevisit.visitStatus }}</span></label>
                            </li>
                         </ul>
                        </template>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div
        v-if="roleprivileges.includes('Submit Form Recent Form')"
        class="col-lg-6 mb-1"
      >
        <div class="ibox-body">
          <div class="ibox-title style_2">
            <h5 class="float-left">{{ $t("navigations.recent form") }}</h5>
          </div>
          <div class="ibox-content">
            <div class="table-responsive">
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th>{{ $t("navigations.site code") }}</th>
                    <th>{{ $t("navigations.subject id") }}</th>
                    <th
                      class="sort_block"
                      @click="SortSelectforms('visitName', 11)"
                      id="11"
                    >
                      <div class="d-flex flex-row justify-content-between">
                        {{ $t("navigations.visit name") }}
                        <span>
                          <i class="fa fa-long-arrow-up"></i>
                          <i class="fa fa-long-arrow-down"></i>
                        </span>
                      </div>
                    </th>
                    <th
                      class="sort_block"
                      @click="SortSelectforms('createdUserName', 12)"
                      id="12"
                    >
                      <div class="d-flex flex-row justify-content-between">
                        {{ $t("navigations.uploaded by") }}
                        <span>
                          <i class="fa fa-long-arrow-up"></i>
                          <i class="fa fa-long-arrow-down"></i>
                        </span>
                      </div>
                    </th>
                    <th
                      class="sort_block"
                      @click="SortSelectforms('createdAt', 13)"
                      id="13"
                    >
                      <div class="d-flex flex-row justify-content-between">
                        {{ $t("navigations.uploaded date") }}
                        <span>
                          <i class="fa fa-long-arrow-up"></i>
                          <i class="fa fa-long-arrow-down"></i>
                        </span>
                      </div>
                    </th>
                    <th class="text-center">{{ $t("navigations.view") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in recentformList" :key="item.createdAt">
                    <td>{{ item.siteId }}</td>
                    <td>{{ item.subjectId }}</td>
                    <td>{{ item.visitName }}</td>
                    <td>{{ item.createdUserName }}</td>
                    <td>{{ filter(item.createdAt) }}</td>
                    <td class="text-center">
                      <i
                        class="fa fa-eye editicon"
                        aria-hidden="true"
                        @click.prevent="viewforms(item.formId)"
                      ></i>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="row mt-3 mx-0">
              <div class="col-lg-12 mx-0 px-0">
                <div
                  class="
                    dataTables_paginate
                    paging_simple_numbers
                    pagination
                    float-right
                  "
                >
                  <div class="col-lg-12 d-flex justify-content-lg-end px-0">
                    <v-pagination
                      v-model="currentRecentformPage"
                      :pages="totalformsPages"
                      :range-size="1"
                      active-color="#618bd7"
                      @update:modelValue="loadrecentForm"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="roleprivileges.includes('Submit Form Study Form')"
            class="ibox-body"
          >
            <div class="ibox-title style_2">
              <h5 class="float-left">{{ $t("navigations.study forms") }}</h5>
            </div>
            <div class="ibox-content">
              <table class="table table-bordered toggle-arrow-tiny">
                <tbody>
                  <tr v-for="list in formList" :key="list.visitId">
                    <td
                      @click="
                        goToTemplate(
                          list.isPatientVisit,
                          list.template_ID,
                          list.studyId,
                          list.visitId
                        )
                      "
                    >
                      <a>{{ list.visitNo }} - {{ list.visitFormName }}</a>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="row mx-0">
                <div class="col-lg-12 mx-0 px-0">
                  <div
                    class="
                      dataTables_paginate
                      paging_simple_numbers
                      pagination
                      float-right
                    "
                  >
                    <div class="col-lg-12 d-flex justify-content-lg-end px-0">
                      <v-pagination
                        v-model="currentformPage"
                        :pages="totalformPages"
                        :range-size="1"
                        active-color="#618bd7"
                        @update:modelValue="loadForm"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

   <!-- modal starts -->
   <div class="modal-wrapper">
    <div class="modal-container">
      <div class="titleclass">
        <h4 class="ml-lg-2">{{ $t("navigations.add form") }}</h4>
        <div class="closebutton">
          <a style="color: white" class="close-link">
            <i class="fa fa-times" @click.prevent="closeModal"></i>
          </a>
        </div>
      </div>
      <div class="modelbody">
        <!-- test -->
        <div class="mb-3 p-2">
          <div class="form-group row my-lg-2">
            <div class="d-flex flex-column flex-lg-row my-2 mb-lg-0">
              <div class="col-lg-12 my-2">
                <select
                  class="form-control mx-lg-2 my-2 my-lg-0"
                  placeholder="Search by Status"
                  v-model="selectedTemplate"
                >
                  <option value="" disabled selected>
                    {{ $t("navigations.select template") }}
                  </option>
                  <option
                    v-for="templates in templateList"
                    :key="templates.templateId"
                    :value="templates.templateId"
                  >
                    {{ templates.templateName }} ( {{ templates.version }})
                  </option>
                </select>
              </div>
            </div>

            <div class="d-flex my-2 mb-lg-0">
              <div class="assignbtncontainer">
                <button
                  class="btn btn-primary px-5 mx-1"
                  @click.prevent="gotofillform(
                                    selectedVisit.visit,  selectedVisit.subjectId,
                                    selectedVisit.siteCode)"
                >
                  {{ $t("navigations.fill form") }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- test -->
      </div>
    </div>
  </div>
  <!-- modal ends -->
</template>
<script src="./submitForm.js"></script>
<style scoped>
@import "../../assets/css/style.css";
@import "../../assets/bootstrap.css";
@import "../../assets/font-awesome/css/font-awesome.css";
@import "./tree.css";
.linkstyle {
  cursor: pointer;
}
.plan-side-popup {
  position: absolute;
  text-align: left;
  right: 56px;
  border-radius: 5px;
  border: 1px solid #e7eaec;
  background-color: rgba(255, 255, 255, 1);
  width: min(124px, 90%);
  box-shadow: 3px 3px 3px rgba(160, 160, 160, 0.5);
  z-index: 200;
}

.plan-side-popup>div {
  cursor: pointer;
  padding: 0.5rem;
}

.plan-side-popup>div:hover {
  background-color: #e4eaf9;
}
.modal-container {
  background: #fff;
  width: min(50%, 90%);
  border-radius: 4px;
  position: fixed;
  transition: all 0.3s ease-in-out;
  height: auto !important;
  margin-top: 200px;
}
.modelbody {
  overflow: unset !important;
}

.modelbody {
  background: #fff;
  /* background-color: aquamarine;  */
  margin: 0;
  padding: 5%;
}
</style>
