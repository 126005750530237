import axios from "axios";
import historytab from '../../custom_lib/AiCharts/historytab.vue';
import barchart from '../../custom_lib/AiCharts/adbarchart.vue';
import stackedbarchart from '../../custom_lib/AiCharts/StackedBarchart.vue';
import scatteredploat from '../../custom_lib/AiCharts/D3ScatterPlot.vue'
import D3LineChart from '../../custom_lib/AiCharts/D3LineChart.vue'
import advtable from '../../custom_lib/AiCharts/advenenttable.vue';
import groupbarchart from '../../custom_lib/AiCharts/groupbarchart.vue';
import chartdrill from '../../custom_lib/AiCharts/popupfiles/chartdrillPopup.vue';
export default{
    name: "labResultTrend",
    components: {
        historytab,
        barchart,
        stackedbarchart,
        scatteredploat,
        D3LineChart,
        advtable,
        groupbarchart,
        chartdrill,
    },
    async mounted(){
        console.log("hiii.....")
        await this.getdata();
    },
    data() {
        return {
            basetestUrl: process.env.VUE_APP_Service_URL,
            data:[],
            historykeys:[],
            drillpopup: false,
            historydata:{},
            drilldata:{},
            loader:true,
            subjectKeys: ["STUDYID", "USUBJID", "SITEID", "VISIT", "VISITNUM", "LBTEST", "LBCAT",
                          "LBSCAT", "LBORRES", "LBSTRESC", "LBSTNRLO", "LBSTNRHI", "LBSTAT", "LBDTC",
                          "LBSPEC", "LBFAST", "LBORNRIND", "LBRFTD", "LBDOSTOT", "LBDOSU", "LBTRT",
                          "LBTRTCD", "LBELTM", "LBENDTC", "LBPOS", "LBRESULTFL", "LBPROTFL", "LBDUPS",
                          "LBCONF", "LBSPCCND", "LBREASND", "LBIND", "LBSTATCD", "LBDUR", "LBPHASE",
                          "LBBLFL", "LBEXCLFL", "LBINCLFL", "LBCRITFL", "LBVALID", "LBVALIDFL", "LBSOURCE",
                          "LBORRESU", "LBPERF", "LBCLSIG", "LBMETHOD", "LBSTNRIND"],
            groupchartkeys:{
                "xaxiskey":"VISITNUM",
                "groupkey":"LBTEST",
            },
        }
    },
    methods: {
        async getdata() {
            this.loader = true;
            await axios.get(
              `${this.basetestUrl}/forms-ml/report/getreports?table_name=LabReports`)
              .then((res) => {
                this.data = res.data;
                this.loader = false;
                this.historydata['Home'] = this.data;
                this.historykeys.push('Home');
              })
              .catch((err) => console.log("error in screen list", err));
        },
        async pageclosed(){
            this.$router.push(`/analyticalDashboard`);
        },
        async gotohistorylevel(history){
            this.deleteAfterKeyvaluearrays(history);
            this.deleteAfterKeyarrays(history);
            this.data = this.historydata[history];
        },
        async deleteAfterKeyvaluearrays(selectedKey) {
            const entries = Object.entries(this.historydata); // Convert the object to an array of entries
            const selectedIndex = entries.findIndex(([key, value]) => key === selectedKey);
          
            // If the key is found, slice the array to keep only the entries up to the selected key
            const newEntries = selectedIndex !== -1 ? entries.slice(0, selectedIndex + 1) : entries;

            // Convert the array of entries back into an object
            return Object.fromEntries(newEntries);
        },
        async deleteAfterKeyarrays(selectedKey) {
            let index = this.historykeys.indexOf(selectedKey);
            if (index !== -1) {
                // Slice the array up to the element (including the element)
                this.historykeys = this.historykeys.slice(0, index + 1);
            }
        },
        async drilldowncurrentchartforbar(datafromchild){
            const lastkey = this.historykeys[this.historykeys.length - 1];
            if(this.historydata[lastkey].length != datafromchild.sortedData.length){
             this.historykeys.push(datafromchild.filterkeyword);
            this.historydata[datafromchild.filterkeyword] = datafromchild.sortedData;
            this.data = this.historydata[datafromchild.filterkeyword];
            }
        },
        async onclosed(){
            this.drillpopup = false;
          },
        async drillpopupopened(datafromchild) {
            this.drilldata = datafromchild;
            this.drillpopup = true;
        },
    }
}