 <template>
  <div class="modal__upload__wrapper">
    <div class="modal__upload__container">
        <div class="modal__upload__title d-flex justify-content-between py-2 px-3">
          <h4 class="my-0">
            {{ $t("navigations.upload document") }}
          </h4>
          <div class="cursor-pointer" @click="onclosed('uploadDoc')">
            <i class="fa fa-times" title="Close"></i>
          </div>
        </div>
        <div class="modal__upload__content height-230 d-flex flex-column justify-content-between py-4 px-5">
          <div class="form-group">
            <textarea
              class="form-control"
              v-model="azuredata.DocumentDescription"
              placeholder="Description"
            ></textarea>
          </div>
          <div class="row">
            <div class="col-lg-12 pb-2">
              <Multiselect
                v-model="azuredata.Approver"
                mode="multiple"
                valueProp="email"
                placeholder="Select approver"
                label="userName"
                :options="approverlist"
                :hideSelected="false"
                :closeOnSelect="false"
                class="multiselect-theme"
              >
              </Multiselect>
            </div>
          </div>
          <div class="input-group">
            <input
              accept=".xls,.xlsx,.jpg,.jpeg,.png,.pdf,.docx"
              id="InPutFile"
              type="file"
              class="form-control my-2 my-lg-0"
              @change="previewFiles"
            />
            <span class="input-group-append">
              <button type="button" class="upload_btn mr-0" @click="AddOrEdit()" :disabled="showLoading">
                <div class="d-flex alogn-items-center" v-if="showLoading"><img width="20" src="../../assets/img/loading.webp" alt="Wait while the document is added"></div>
                <div v-else>{{ $t("navigations.add") }}</div>                
              </button>
            </span>
          </div>
          <span class="errorClass" v-if="validations[2] == false">{{
            $t("navigations.please upload a file")
          }}</span>
        </div>
      </div>
    </div>
</template>

 <script>
/* eslint-disable */

import axios from "axios";
import store from "../../store/index";
import Multiselect from "@vueform/multiselect";
import { onBeforeMount } from '@vue/runtime-core';

export default {
  name: "DocumentPopup",
  props: {
    uploadlibactive: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,

      default: "",
    },
    refid: {
      type: String,
      default: "",
    }, 
    versId: {
      type: String,
      default: "",
    },
    docdesc: {
      type: String,
      default: "",
    },
    editorupdate: {
      type: String,
      default: "update",
    },
    documentType: {
      type: String,
      default: "",
    },
    documenttypeid: {
      type: String,
      default: "",
    },
    siteCode: {
      type: String,
      default: "",
    },
    documentName:{
      type: String,
      default: "",
    },
  },
  data() {
    return {
      fileuploadurl: process.env.VUE_APP_File_upload_URL+"/document",
      baseurl: process.env.VUE_APP_Service_URL,
      baseapi: process.env.VUE_APP_Service_URL,
      values: {
        Approver: [],
        storageId: "",
        documentDescription: "",
      },
      approverlist: "",
      uploadpopupmodal: true,
      validations: [],
      doclist: [],
      showLoading:false,
      azuredata: {
        DocumentId: "",
        DocumentVersionId: "",
        DocumentTypeName: "",
        DocumentTitle: "",
        DocumentDescription: "",
        Document: "",
        OrganizationId: 2,
        Approver: [],
        documentVersions:{
          fileName:""
        }
      },
      SearchData: {
        DocName: "",
        FolderId: "",
        DocsearchName: "",
        CreatedDate: "",
        UploadedBy: "",
        DocumentTypeId: "",
        pageNo: 1,
        pagesize: 10,
      },
      documentVersionId: [],
      approveList: [],
      totalPage: 1,
      currentPage: 1,
      totalCount: 0,
      pageindex: 1,
      pagesize: 10,
      Document: "",
      SortProperty:"CreatedAt",
      SortOrder:1,
    };
  },
  components: {
    Multiselect,
  },
  async beforeMount(){
    await this.getApproverById(this.refid);
  },
  async mounted() {
    document.body.style.overflowY="hidden"
    if (this.editorupdate == "edit") {
      this.calldetails();
    }    
   
    //this.getreviewers();
  },
   async unmounted() {
    document.body.style.overflowY="auto"
  },
  methods: {
    async calldetails() {
      this.getDocumentById(this.refid);
      this.azuredata.DocumentDescription = this.Document.documentDescription;     
    },
    // async getreviewers() {
    //   const idtoken = store.getters.getIdToken;
    //   await axios
    //     .get(`${this.baseapi}/forms/digitalform/getapprovalusers`, {
    //       headers: {
    //         Authorization: "Bearer " + idtoken,
    //         "Content-Type": "application/json",
    //       },
    //     })
    //     .then((res) => {
    //       console.log(res);
    //       this.approverlist = res.data;         
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // },
    async getApproverById(docId) {
      console.log("site id ", this.siteCode);
      const idtoken = store.getters.getIdToken;
      var studyId = this.documentType == "study" ? store.getters.getStorageId : store.getters.getStudyIs;
      if(docId == 0){
        await axios
                    .get(`${this.baseurl}/account-core/user/getusersbysiteprivilege?studyid=${studyId}&siteCode=${this.siteCode}`,
                        {
                            headers: {
                                Authorization: "Bearer " + idtoken,
                                "Content-Type": "application/json",
                            }
                        })
                    .then((res) => {
                        this.approverlist = res.data;
                    })
                    .catch((err) => {
                        console.log(err);
                    });

      }
      else{
        await axios
        .get(
          `${this.baseapi}/account-core/user/getuserfordocumentapprover?documentId=${docId}&api-version=1.0`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
              responseType: "blob",
            },
          }
        )
        .then((res) => {
          this.approverlist = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
      }
      
    },
    async onclosed() {
      this.$emit("uploadlibactive");
      this.ApproversName = [];
    },
    async getDocumentById(docId) {
      this.currentdocId = docId;      
      const idtoken = store.getters.getIdToken;
      await axios
        .get(
          `${this.baseurl}/management/document/getdocumentbyid?id=` + docId,
          //  `https://localhost:5001/document/getdocumentbyid?id=`+ docId,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log("Document name",res.data.documentName);
          this.Document = res.data;
          this.loaddata = true;
          this.azuredata.DocumentDescription = res.data.documentDescription;
          this.azuredata.DocumentId = res.data.documentReferenceId;     
          this.azuredata.documentVersions.fileName=res.data.documentVersions.fileName
          console.log(this.azuredata.DocumentDescription,"Document Dis");
        });
    },
    async AddOrEdit() {     
      if (this.refid == 0) {
        this.submitFile();
      } else if (this.refid != 0 && this.azuredata.Document!=0) {
        this.updateFile();
      } else {
        if (this.azuredata.DocumentDescription != 0 && this.azuredata.Document==0) {
          this.dmsDocDetailsEdit();
        } else if (
          this.azuredata.Document == 0 &&
          this.azuredata.DocumentDescription == 0
        ) {
          alert("Please add document description or document");
        }
      }
    },
    async submitFile() {
      const folderId = store.getters.getFolderId;
      var formData = new FormData();
      formData.append("Document", this.azuredata.Document);
      formData.append("DocumentTypeName", this.documentType);
      formData.append("DocumentTitle", this.azuredata.DocumentTitle);
      formData.append(
        "DocumentDescription",
        this.azuredata.DocumentDescription
      );
      formData.append("OrganizationId", this.azuredata.OrganizationId);
      await this.validateDocumentIsNull();
      if (this.validations[2]) {
        if (folderId != 0) {
          await axios
            .post(`${this.fileuploadurl}/create`, formData, {
              headers: {
                Authorization: "Bearer " + this.idToken,

                "Content-Type": "multipart/form-data",
              },
            })

            .then((response) => {
              console.log("retreieved data", response);
              this.documentId = response.data.data[0];
              this.documentVersionId = [];
              this.documentVersionId = response.data.data[1];              
              this.Createdocument();
            })
            .catch((err) => {
              console.log(err.data);
            });
        } else {
          alert(this.$t("navigations.please select a folder"));
        }
      }
    },
    async Createdocument() {
      const storageId = store.getters.getStorageId;
      const folderId = store.getters.getFolderId;
      const idtoken = store.getters.getIdToken;
      const storageCode = store.getters.getStorageCode;
      await this.getFolderbyId();
      if(storageId!=0)
      {
      await axios
        .post(
          `${this.baseurl}/management/document/createdocument`,
          //  `https://localhost:5001/document/createdocument`,
          {
            documentReferenceId: this.documentId,
            documentTypeId: storageId,
            typeValue: storageCode,
            documentType: this.documentType,
            documentName: this.azuredata.Document.name,
            documentDescription: this.azuredata.DocumentDescription,
            folderId: folderId,
            folderName: this.folderbyid.folderName,
            documentVersions: [
              {
                documentVersionId: this.documentVersionId,
                versionNumber: "1",
                versionDescription: "string",
                status: "Pending",
                fileName: "string",
                uploadedBy: "string",
                uploadedOn: "2022-06-17T11:03:54.866Z",
                uploadedUserId: "",
                approveList: this.azuredata.Approver,
              },
            ],
          },
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          alert(this.$t("navigations.document created successfully"));
          console.log("retreieve data", response);
          this.onclosed();
          document.body.style.overflowY="auto"
          this.azuredata.Document = "";
          this.getDocumentsrefresh();
          
        })
        .catch((err) => {
          console.log("error", err);
          console.log(err.data);
        });
      }
      else{
        await axios
        .post(
          `${this.baseurl}/management/document/createdocument`,
          //  `https://localhost:5001/document/createdocument`,
          {
            documentReferenceId: this.documentId,
            documentType: "general",
            documentTypeId: "general",
            documentName: this.azuredata.Document.name,
            documentDescription: this.azuredata.DocumentDescription,
            folderId: folderId,
            folderName: this.folderbyid.folderName,
            documentVersions: [
              {
                documentVersionId: this.documentVersionId,
                versionNumber: 0,
                versionDescription: "string",
                status: "Pending",
                fileName: "string",
                uploadedBy: "string",
                uploadedOn: "2022-06-17T11:03:54.866Z",
                uploadedUserId: "",
                approveList:this.azuredata.Approver,
              },
            ],
          },
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          alert(this.$t("navigations.document created successfully"));
          console.log("retreieve data", response);
          this.onclosed();
          document.body.style.overflowY="auto"
          this.azuredata.Document = "";
          this.getDocumentsrefresh();          
        })
        .catch((err) => {
          console.log("error", err);
          console.log(err.data);
        });
      }
    },
    async updateFile() {
      this.showLoading=true
      var formsData = new FormData();
      formsData.append("DocumentId", this.azuredata.DocumentId);
      formsData.append("Document", this.azuredata.Document);
      formsData.append("DocumentDesc", this.azuredata.DocumentDescription);
      await axios
        .put(`${this.fileuploadurl}/newfile`, formsData, {
          headers: {
            Authorization: "Bearer " + this.idToken,

            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.documentUpdateId = response.data.data;
          this.documentVersionId = [];
          this.documentVersionId = response.data.data;
          this.openEdit();
        })
        .catch((err) => {
          console.log(err.data);
        });
    },
    async openEdit() {
     
      const storageId = store.getters.getStorageId;
      const folderId = store.getters.getFolderId;
      const idtoken = store.getters.getIdToken;
      await axios
        .put(
          `${this.baseurl}/management/document/update`,
          //`https://localhost:5001/document/update`,
          {
            id: this.refid,
            documentName: this.azuredata.Document.name,
            documentDescription: this.azuredata.DocumentDescription,
            documentVersions: {
              documentVersionId: this.documentVersionId,
              versionNumber: 0,
              versionDescription: this.azuredata.DocumentDescription,
              status: "Pending",
              fileName: this.documentName,
              uploadedBy: "string",
              uploadedOn: "2022-06-17T11:03:54.866Z",
              uploadedUserId: "",
              approveList: this.azuredata.Approver,
            },
          },
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.doclistupdate = res.data;
          alert(this.$t("navigations.document updated successfully"));
          this.azuredata.Document = "";
          this.onclosed();
          this.getDocumentsrefresh();
          this.showLoading=false
        })
        .catch((err) => {
          console.log("error..", err);
        });
    },
    async DocDetailsEdit() {
      this.showLoading=true
      const idtoken = store.getters.getIdToken;
      await axios
        .put(
          `${this.baseurl}/management/document/update`,
          {
            id: this.refid,
            documentDescription: this.azuredata.DocumentDescription,
            documentVersions: {
              fileName: this.documentName,
              documentVersionId: this.versId,
              versionDescription: this.azuredata.DocumentDescription,
              approveList: this.azuredata.Approver,
            },           
          },
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.docdiscriptionupdate = res.data;
          alert(this.$t("navigations.document updated successfully"));
          this.onclosed();
          this.getDocumentsrefresh();
          this.showLoading=false
        })
        .catch((err) => {
          console.log("error..", err);
        });
    },
    async dmsDocDetailsEdit() {
      await axios
        .put(
          `${this.fileuploadurl}/updatedocumentdetails?docId=${this.Document.documentReferenceId}&docdesc=${this.azuredata.DocumentDescription}`
        )
        .then((res) => {
          this.doclist = res.data;
          this.DocDetailsEdit();
        })
        .catch((err) => {
          console.log("error..", err);
        });
    },
    async getFolderbyId() {
      const folderId = store.getters.getFolderId;
      const idtoken = store.getters.getIdToken;
      await axios
        .get(`${this.baseurl}/management/folder/getfolderbyid/` + folderId, {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          this.folderbyid = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getDocumentsrefresh() {
      this.doclist = [];
      const storageId = store.getters.getStorageId;
      const folderId = store.getters.getFolderId;
      const idtoken = store.getters.getIdToken;
      if (folderId != 0) {
        this.SearchData.FolderId = folderId;
      } else {
        this.SearchData.FolderId = "";
      }
      await axios
        .get(
          ` ${this.baseurl}/management/document/listdocument?documentName=` +
            this.SearchData.DocsearchName +
            "&uploadedDate=" +
            this.SearchData.CreatedDate +
            "&uploadedBy=" +
            this.SearchData.UploadedBy +
            "&documentTypeId=" +
            storageId +
            "&folderId=" +
            this.SearchData.FolderId +
            "&pageindex=" +
            this.pageindex +
            "&pagesize=" +
            this.pagesize+
           "&SortProperty=" +
           this.SortProperty+
           "&SortOrder="+
           this.SortOrder,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.doclist = res.data.results;
          this.totalCount = res.data.totalCount;
          this.totalPage = parseInt(this.totalCount / this.SearchData.pageSize);
          if (this.totalCount % this.SearchData.pageSize != 0) {
            this.totalPage = this.totalPage + 1;
          }
        })
        .catch((err) => {
          console.log("error..", err);
        });
    },
    async validateDocumentIsNull() {
      if (this.azuredata.Document != "") {
        this.validations[2] = true;
        this.showLoading=true
      } else {
        this.validations[2] = false;        
      }
    },
    async previewFiles(event) {
      this.azuredata.Document = event.target.files[0];
    },
  },
};
</script>
    <style src="@vueform/multiselect/themes/default.css"></style>
<style scoped>
@import "../../assets/css/style.css";
@import "../../assets/bootstrap.css";
@import "../../assets/font-awesome/css/font-awesome.css";
@import "../../assets/formpage.css";
.modal__upload__wrapper {
  position: fixed;
  inset:0;
  background: rgb(0 0 0 / 18%);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index:3000;
}
.modal__upload__container {
  width:min(38%,80%);
  position: fixed;
  background: rgb(255, 255, 255);
  border-radius: 4px;
}
.modal__upload__title {
  border-top-left-radius: 4px;
  border-top-right-radius:4px;
  background-color: var(--pop-up-background);
  color:#ffffff;
}
.height-230{
  height:230px;
}
.upload_btn{
  text-align: center;
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
  color: white !important;
  padding: 0.43rem 0.4rem;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  transition: all 0.3s ease-in-out !important;    
}
.upload_btn img{
  mix-blend-mode: color-dodge;
}
</style> 