<template>
    <div class="row wrapper border-bottom white-bg page-heading">
      <div class="col-lg-6">
        <h2>Prior and Concomitant Treatment Summary</h2>
      </div>
      <div class="col-lg-2">
        <i class="fa fa-times close" @click="$router.push(`./analyticalDashboard`)"></i>
    </div>
    </div>
      <div class="wrapper wrapper-content">
        <div class="row">
          <div class="col-md-12 pl-1 mb-2">
            <historytab
            :historyarray="historykeys"
            @gotohistorylevel="gotohistorylevel"
            />
        </div>       
        <div class="col-12 col-md-6 mb-2 pl-1">
            <stackedbarchart :availableColumns="arrayofkeys" xAxisKey="TRTTP" yAxisKey="USUBJID"
                segmentKey="TRTSTAT" :loader="loader" :data="data" :templateId="''"
                :fromPage="'descriptiveStatistics'" chartlabel="Treatment Distribution by Type and Status"
                @drilldowncurrentchart="drilldowncurrentchartforbar" @opendrillpopup="drillpopupopened" />
        </div>  
          <div class="col-12 col-md-6 mb-2  pl-1">
            <piechart
              :loader="loader"
              :data="data"
              :tableData="data"
              :templateId="templateId"
              :fromPage="'descriptiveStatistics'"
              :displayValue="false"
              :xaxiskey="xaxiskey"
              chartlabel="Distribution of Therapeutic Classes Among Subjects "
              @setxaxiskey="retainxaxiskey"
              @drilldowncurrentchart="drilldowncurrentchartforbar"
              @opendrillpopup="drillpopupopened"
            />
          </div>
          <div class="col-12 mb-2 pl-1">
                <heatmap
                :availableColumns=arrayofkeys
                :loader="loader"
                :data="data"
                :templateId="templateId"
                xAxisKey="TRTNAME"
                yAxisKey="TRTSDY"
                valueKey="count"
                chartlabel="Frequency of Treatments by Start Day and Medication "
                :fromPage="'descriptiveStatistics'"
                @drilldowncurrentchart="drilldowncurrentchartforbar"
                @opendrillpopup="drillpopupopened"
            />
          </div>
         <div class="col-lg-12 mb-1 pl-0">
          <div class="stat-card-white ">
            <div class="stat-header-white">
              <span>Patient Distribution</span>
            </div>
            <div class="stat-body-white">
             <advtable 
                :loader="loader"
                :data="data"
                fromPage="patientRetention"
                @drilldowncurrentchart="drilldowncurrentchartforbar"/>
            </div>
          </div>
        </div>
       </div>
      </div>
      <chartdrill
      v-if="drillpopup"
      :drilldata="drilldata"
      :templateId="templateId"
      :fromPage="'adverseEventsPop'"
       @closepopup="drillpopup=false"
    />
    </template>
    <script src="./priorConcomitantSummary.js"></script>
    <style>
    @import url("../../custom_lib/AiCharts/charts.css");
</style>