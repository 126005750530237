/* eslint-disable */
import axios from "axios";
import "/src/assets/css/animate.css";
import "/src/assets/css/style.css";
import { useStore } from "vuex";
import Chart from "chart.js";
import jwt_decode from "jwt-decode";
import store from "@/store";
// let testarray= [];
export default {
	name: "studysummary",
	async mounted() {
		window.addEventListener("studyIdChanged", async (event) => {
			this.defaultdashboard = store.getters.getdefaultdashboard;
			console.log("StudyIDChanged into" + event.detail.storage);
			// window.location.reload();
			this.siteCode=""
			await this.loadstudy();
			await this.setdefaultstudy();
			this.getcount();
			this.GetFormData();
			this.sndcharts.update();
		});
		// window.addEventListener(this.siteCode, async (event) => {
		//   console.log("Site changed into" + event.detail.storage);
		//   await this.patientSitebased();
		//   this.sndcharts.update();
		// });
		this.defaultdashboard = store.getters.getdefaultdashboard;
		this.sndcharts = null;
		await this.loadstudy();
		await this.setdefaultstudy();
		this.getcount();
		const snd = document.getElementById("lineChart");
		this.sndcharts = new Chart(snd, {
			type: "bar",
			data: this.sendData,
			options: this.options,
		});
		this.sndcharts;
	},
	data() {
		return {
			defaultdashboard: "",
			firstpiechartoptions: {
				chart: {
					width: "100%",
					type: "pie",
				},
				labels: [this.$t('navigations.new'), this.$t('navigations.open'), this.$t('navigations.enrolled'), this.$t('navigations.completed'), this.$t('navigations.terminated')],
				responsive: [
					{
						breakpoint: 480,
						options: {
							pie: {
								size: 200,
							},
							chart: {
								toolbar: {
									show: false,
								},
								width: 200,
							},
							legend: {
								position: "bottom",
							},
						},
					},
				],
			},
			//horizontalbarchart first starts
			firstbarchart: {
				charttype: "bar",
				chartOptions: {
					stroke: {
						width: 2,
					},
					chart: {
						height: "100%",
						width: "100%",
						toolbar: {
							show: true,
						},
						id: "firstchart-example",
						events: {
							click: function(event, chartContext, config) {
								console.log(config);
								alert(`You clicked on bar: ${config.seriesIndex}, ${config.dataPointIndex}`);
							}
						}
					},
					dataLabels: {
						enabled: false,
					},
					plotOptions: {
						bar: {
							horizontal: true,
						},
					},
					xaxis: {
						categories: [this.$t('navigations.new'), this.$t('navigations.open'), this.$t('navigations.enrolled'), this.$t('navigations.completed'), this.$t('navigations.terminated')],
					},
				},
				series: [
					{
						name:this.$t('navigations.sites'),
						data: [0, 0, 0, 0],
					},
				],
				dataseries: "",
			},

			//horizondalbarchart first ends
			firstchart: {
				charttype: "line",
				chartOptions: {
					stroke: {
						width: 2,
					},
					chart: {
						height: "100%",
						width: "100%",
						toolbar: {
							show: false,
						},
						id: "firstchart-example",
						events: {
							click: function(event, chartContext, config) {
								console.log(config);
								alert(`You clicked on bar: ${config.seriesIndex}, ${config.dataPointIndex}`);
							}
						}
					},
					dataLabels: {
						enabled: false,
					},
					plotOptions: {
						bar: {
							borderRadius: 4,
							horizontal: false,
						},
					},
					xaxis: {
						categories: [this.$t('navigations.new'), this.$t('navigations.open'), this.$t('navigations.enrolled'), this.$t('navigations.completed'),this.$t('navigations.terminated')],
					},
				},
				series: [
					{
						name:this.$t('navigations.sites'),
						data: [0, 0, 0, 0],
					},
				],
				dataseries: "",
				piechartOptions: "",
				piedataseries: [2, 3, 2, 1],
			},

			patientChart: {
				charttype: "line",
				chartOptions: {
					stroke: {
						width: 2,
					},
					chart: {
						toolbar: {
							show: false,
						},
						id: "vuechart-example",
					},
					dataLabels: {
						enabled: false,
					},
					plotOptions: {
						bar: {
							borderRadius: 4,
							horizontal: false,
						},
					},
					xaxis: {
						categories: [this.$t('navigations.new'), this.$t('navigations.enrolled'), this.$t('navigations.withdrawn'), this.$t('navigations.completed'),this.$t('navigations.visit ongoing')],
					},
				},
				series: [
					{
						name:this.$t('navigations.patient'),
						data: [0, 0, 0, 0],
					},
				],
				dataseries: "",
				piechartOptions: {
					chart: {
						width: "100%",
						type: "pie",
					},
					labels: [this.$t('navigations.new'), this.$t('navigations.enrolled'), this.$t('navigations.withdrawn'), this.$t('navigations.completed'),this.$t('navigations.visit ongoing')],
					responsive: [
						{
							breakpoint: 380,
							options: {
								chart: {
									toolbar: {
										show: false,
									},
									width: 100,
								},
								legend: {
									position: "bottom",
								},
							},
						},
					],
				},
				piedataseries: [2, 3, 2, 1],
			},
			patientbarchart: {
				charttype: "bar",
				chartOptions: {
					stroke: {
						width: 2,
					},
					chart: {
						toolbar: {
							show: false,
						},
						id: "vuechart-example",
					},
					dataLabels: {
						enabled: false,
					},
					plotOptions: {
						bar:{
							horizontal: true,
						},
					},
					xaxis: {
						categories: [this.$t('navigations.new'), this.$t('navigations.enrolled'), this.$t('navigations.withdrawn'), this.$t('navigations.completed'),this.$t('navigations.visit ongoing')]
					},
				},
				series: [
					{
						name:this.$t('navigations.patient'),
						data: [0,0,0,0],
					},
				],
				dataseries:[],
			},
			querypiechartoptions: {
				chart: {
					width: "100%",
					type: "pie",
				},
				colors:['#e61a44', '#00e396'],
				labels: [this.$t('navigations.open'), this.$t('navigations.closed')],
				responsive: [
					{
						breakpoint: 480,
						options: {
							pie: {
								size: 200,
							},
							chart: {
								toolbar: {
									show: false,
								},
								width: 200,
							},
							legend: {
								position: "bottom",
							},
						},
					},
				],
			},
			//horizontalbarchart first starts
			querybarchart: {
				charttype: "bar",
				chartOptions: {
					stroke: {
						width: 2,
					},
					chart: {
						height: "100%",
						width: "100%",
						toolbar: {
							show: false,
						},
						id: "firstchart-example",
					},

					dataLabels: {
						enabled: false,
					},
					plotOptions: {
						bar: {
							horizontal: true,
						},
					},
					xaxis: {
						categories: [],
					},
					fill:{
						colors:['#e61a44', '#00e396']
					  },
					  colors:['#e61a44', '#00e396'],
					},
				series: [
					{
						
						data: [0, 0, 0, 0],
					},
				],
				dataseries: "",
			},
			//horizondalbarchart first ends
			querychart: {
				charttype: "bar",

				chartOptions: {
					stroke: {
						width: 2,
						colors:['#e61a44', '#00e396'],
					},
					chart: {
						height: "100px",
						width: "100%",
						toolbar: {
							show: false,
						},
						id: "firstchart-example",
					},
					dataLabels: {
						enabled: false,
					},
					plotOptions: {
						bar: {
							borderRadius: 4,
							horizontal: false,
						},
					},
					xaxis: {
						categories: [],
					},
					fill:{
						colors:['#e61a44', '#00e396']
					  },
					  colors:['#e61a44', '#00e396'],
				},
				series: [
					{
						data: [0, 0, 0, 0],
					},
				],
				dataseries: "",
				piechartOptions: "",
				piedataseries: [],
			},


			activeformcount: "",
			patientscountstd: "",
			sites: [],
			sitecountstd: "",
			userscountstd: "",
			formcountstd: "",
			querycountstd: "",
			totalquerycount: 0,
			siteCode: "",
			formlists: [],
			queryArray: [],
			pageNumber: 1,
			currentStudy: "",
			userId: "",
			baseUrl: `${process.env.VUE_APP_Service_URL}/management/study/`,
			baseapi: process.env.VUE_APP_Service_URL,
			studies: "",
			valnow: "",
			sndcharts: "",
			secndcharts: "",
			senddatacoppy: "",
			seconsdatacoppy: "",
			sndoptions: {
				scales: {
					y: {
						beginAtZero: true,
					},
				},
			},
			options: {
				scales: {
					y: {
						beginAtZero: true,
					},
				},
			},
		};
	},
	methods: {
		async setasdefault(){
			// alert("set as default");
			  const idtoken = store.getters.getIdToken;
			  let userIdfordefault = {
				"userId": this.jwtDecrypt(idtoken).sub,
				"email": jwt_decode(idtoken).email,
				"defaultDashBoard": "studysummary"
				};
			  await axios
				.put(`${this.baseapi}/account-core/settings/userdashboard`,
				userIdfordefault,
				{
				  headers: {
					Authorization: "Bearer " + idtoken,
					"Content-Type": "application/json",
				  },
				})
				.then((res) => {
					store.dispatch("setdefaultdashboard", "studysummary");
					alert("Default dashboard updated successfully");
					this.defaultdashboard = store.getters.getdefaultdashboard;
				})
				.catch((err) => {
				  console.log(err);
				});
		  },
		async firstchartchange(val) {
			if (val == "barhorizondal") {
				await this.switchfirstbar(true);
				this.firstchart.charttype = "bar";
			} else {
				await this.switchfirstbar(false);
				this.firstchart.charttype = val;
			}
		},
		async secondchartchange(val) {
			if (val == "barhorizondal") {
				await this.switchbar(true);
				this.patientChart.charttype = "bar";
			} else {
				await this.switchbar(false);
				this.patientChart.charttype = val;
			}
		},
		async thirdchartchange(val) {
			if (val == "barhorizondal") {
				await this.switchthirdbar(true);
				this.querychart.charttype = "bar";
			} else {
				await this.switchthirdbar(false);
				this.querychart.charttype = val;
			}
		},
		async switchfirstbar(res) {
			this.firstchart.chartOptions.plotOptions = { bar: { horizontal: res } };
			this.firstchart.charttype = "";
		},
		async switchbar(res) {
			this.patientChart.chartOptions.plotOptions = { bar: { horizontal: res } };
			this.patientChart.charttype = "";
		},
		async switchthirdbar(res) {
			this.querychart.chartOptions.plotOptions = { bar: { horizontal: res } };
			this.querychart.charttype = "";
		},
		async raisequery() {
			this.$router.push(`/generalQuery`);
		},
		async GetChartData(currentstudyid, idtoken) {
			await axios
				.get(
					`${this.baseapi}/management/site/sitesummery?studyId=${currentstudyid}`,
					{
						headers: {
							Authorization: "Bearer " + idtoken,
							"Content-Type": "application/json",
						},
					}
				)
				.then(async (res) => {
					console.log(res);
					this.firstchart.dataseries = [
						{
							name: this.$t('navigations.sites'),
							data: [
								res.data.New == null ? 0 : res.data.New,
								res.data.Open == null ? 0 : res.data.Open,
								res.data.Enrolled == null ? 0 : res.data.Enrolled,
								res.data.Completed == null ? 0 : res.data.Completed,
								res.data.Terminated == null ? 0 : res.data.Terminated,
							],
						},
					];
					this.firstchart.piedataseries = this.firstchart.dataseries[0].data;
				})
				.catch((err) => {
					console.log(err);
				});
		},
		async patientSitebased(siteID) {
			let patientArray=[]
			const idtoken = store.getters.getIdToken;
			const StudyId = store.getters.getStudyIs;
			await axios
				.get(
					`${this.baseapi}/management/patient/patientsummery?studyId=${StudyId}&siteId=${siteID}`,
					{
						headers: {
							Authorization: "Bearer " + idtoken,
							"Content-Type": "application/json",
						},
					}
				)
				.then((res) => {
					patientArray=[
						res.data.New  == null ? 0 : res.data.New ,
						res.data.Enrolled == null ? 0 : res.data.Enrolled,
						res.data.Withdrawn == null ? 0 : res.data.Withdrawn,
						res.data.Completed == null ? 0 : res.data.Completed,
						res.data["Visit Ongoing"] == null ? 0 : res.data["Visit Ongoing"]
					]
					this.patientChart.dataseries = [
						{
							name: this.$t('navigations.patient'),
							data: [
								res.data.New == null ? 0 : res.data.New,
								res.data.Enrolled == null ? 0 : res.data.Enrolled,
								res.data.Withdrawn == null ? 0 : res.data.Withdrawn,
								res.data.Completed == null ? 0 : res.data.Completed,
								res.data["Visit Ongoing"] == null ? 0 : res.data["Visit Ongoing"]
							],
						},
					];
					this.patientbarchart.dataseries=  [{
						data: patientArray
					  }]
					this.patientChart.piedataseries = this.patientChart.dataseries[0].data;
				})
				.catch((err) => {
					console.log(err);
				});
		},
		async GetpatientChartData(currentstudyid, idtoken) {
			let patientArray=[]
			await axios
				.get(
					`${this.baseapi}/management/patient/patientsummery?studyId=${currentstudyid}`,
					{
						headers: {
							Authorization: "Bearer " + idtoken,
							"Content-Type": "application/json",
						},
					}
				)
				.then((res) => {
					console.log(res);
					patientArray=[
						res.data.New  == null ? 0 : res.data.New ,
						res.data.Enrolled == null ? 0 : res.data.Enrolled,
						res.data.Withdrawn == null ? 0 : res.data.Withdrawn,
						res.data.Completed == null ? 0 : res.data.Completed,
						res.data["Visit Ongoing"] == null ? 0 : res.data["Visit Ongoing"]
					]
					this.patientChart.dataseries = [
						{
							name: this.$t('navigations.subjects'),
							data: [
								res.data.New == null ? 0 : res.data.New,
								res.data.Enrolled == null ? 0 : res.data.Enrolled,
								res.data.Withdrawn == null ? 0 : res.data.Withdrawn,
								res.data.Completed == null ? 0 : res.data.Completed,
								res.data["Visit Ongoing"] == null ? 0 : res.data["Visit Ongoing"]
							],
						},
					];
					this.patientChart.piedataseries = this.patientChart.dataseries[0].data;
					this.patientbarchart.dataseries=  [{
						name:this.$t('navigations.subjects'),
						data: patientArray
					  }]
				})
				.catch((err) => {
					console.log(err);
				});
			this.patientChart.charttype="barhorizondal"
		},
		async sitedata(currentstudyid, idtoken) {
			this.sites = [];
			await axios
				.get(
					`${this.baseapi}/management/site/getallsitesbyuser?studyId=${currentstudyid}&sortProperty=siteCode&IsDes=false&getAll=false`,
					{
						headers: {
							Authorization: "Bearer " + idtoken,
							"Content-Type": "application/json",
						},
					}
				)
				.then((res) => {
					console.log("The sites are", res.data);
					res.data.filter((item) => {
						this.sites.push(item);
					});
					console.log("Site is", this.sites);
				})
				.catch((err) => {
					console.log(err);
				});
		},
		async GetqueryChartData(currentstudyid, idtoken) {
			await axios
				.get(
					`${this.baseapi}/forms/query/getopenclosedcount?StudyID=${currentstudyid}`,
					{
						headers: {
							Authorization: "Bearer " + idtoken,
							"Content-Type": "application/json",
						},
					}
				)
				.then(async (res) => {
					this.queryArray = res.data;
					console.log("");
					let sitesarray = [];
					let openarray = [];
					let closedarray = [];
					await this.queryArray.filter((item) => {
						sitesarray.push(
							item.site == null || item.site == "" ? 0 : item.site
						);
						openarray.push(item.openQuery);
						closedarray.push(item.closedQuery);
					});
					this.options = sitesarray;
					console.log("The open array is", openarray);
					this.querychart.dataseries = [
						{
							name: this.$t('navigations.open'),
							data: openarray,
						},
						{
							name: this.$t('navigations.closed'),
							data: closedarray,
						},
					];
					console.log("data series is", this.querychart.dataseries);
					// this.querychart.chartOptions.xaxis = {
					// 	categories: sitesarray,
					// };
					this.querychart.chartOptions = {
					stroke: {
						width: 2,
						colors:['#e61a44', '#00e396'],
					},
					chart: {
						height: "100%",
						width: "100%",
						toolbar: {
							show: false,
						},
						id: "firstchart-example",
					},
					dataLabels: {
						enabled: false,
					},
					plotOptions: {
						bar: {
							borderRadius: 4,
							horizontal: false,
						},
					},
					xaxis: {
						categories: this.options,
					},
					fill:{
						colors:['#e61a44', '#00e396']
					  },
						colors:['#e61a44', '#00e396']
				};
					console.log("Sitesarray", sitesarray);
					let openform = "";
					let closedform = "";
					sumopen(openarray);
					sumofclosed(closedarray);
					async function sumopen(array) {
						let sum = 0;
						for (let i = 0; i < array.length; i++) {
							sum += array[i];
						}
						openform = sum;
					}
					async function sumofclosed(array) {
						let sum = 0;
						for (let i = 0; i < array.length; i++) {
							sum += array[i];
						}
						closedform = sum;
					}
					this.querychart.piedataseries = [openform, closedform];
				})
				.catch((err) => {
					console.log(err);
				});
			this.querychart.charttype = "bar";
		},
		jwtDecrypt(token) {
			const base64Url = token.split(".")[1];
			const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
			const jsonPayload = decodeURIComponent(
				atob(base64)
					.split("")
					.map(function(c) {
						return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
					})
					.join("")
			);
			return JSON.parse(jsonPayload);
		},
		async getcount() {
			const idtoken = store.getters.getIdToken;
			this.valnow = await store.getters.getStudyIs;
			console.log("current study value is " + this.valnow);
			this.getformcount(this.valnow, idtoken);
			this.getquerycount(this.valnow, idtoken);
			this.getactiveformcount(this.valnow, idtoken);
			this.gettotalquerycount(this.valnow, idtoken);
			this.GetChartData(this.valnow, idtoken);
			this.GetpatientChartData(this.valnow, idtoken);
			this.GetqueryChartData(this.valnow, idtoken);
			this.sitedata(this.valnow, idtoken);
			//patientscountstd
		},
		async getactiveformcount(currentstudyid, idtoken) {
			await axios
				.get(
					`${this.baseapi}/forms/forms/activecounts?studyId=${currentstudyid}`,
					{
						headers: {
							"Access-Control-Allow-Origin": "localhost:8080",
							Authorization: "Bearer " + idtoken,
							"Content-Type": "application/json",
						},
					}
				)
				.then((res) => {
					this.activeformcount = res.data;
				})
				.catch((err) => {
					console.log(err);
				});
		},
		async getquerycount(currentstudyid, idtoken) {
			await axios
				.get(
					`${this.baseapi}/forms/query/getcounts?studyId=${currentstudyid}`,
					{
						headers: {
							"Access-Control-Allow-Origin": "localhost:8080",
							Authorization: "Bearer " + idtoken,
							"Content-Type": "application/json",
						},
					}
				)
				.then((res) => {
					this.querycountstd = res.data;
				})
				.catch((err) => {
					console.log(err);
				});
		},
		async gettotalquerycount(currentstudyid, idtoken) {
			await axios
				.get(
					`${this.baseapi}/forms/query/getallcounts?studyId=${currentstudyid}`,
					{
						headers: {
							"Access-Control-Allow-Origin": "localhost:8080",
							Authorization: "Bearer " + idtoken,
							"Content-Type": "application/json",
						},
					}
				)
				.then((res) => {
					this.totalquerycount = res.data.openQuries + res.data.closedQuries;
					console.log(idtoken);
				})
				.catch((err) => {
					console.log(err);
				});
		},
		async getformcount(currentstudyid, idtoken) {
			await axios
				.get(
					`${this.baseapi}/forms/forms/getcounts?studyId=${currentstudyid}`,
					{
						headers: {
							"Access-Control-Allow-Origin": "localhost:8080",
							Authorization: "Bearer " + idtoken,
							"Content-Type": "application/json",
						},
					}
				)
				.then((res) => {
					this.formcountstd = res.data;
				})
				.catch((err) => {
					console.log(err);
				});
		},
		async setdefaultstudy() {
			if (this.valnow == "" || this.valnow == null) {
				const idtoken = store.getters.getIdToken;
				this.userId = await this.jwtDecrypt(idtoken).sub;
				await axios
					.get(this.baseUrl + "getdefaultstudy?id=" + this.userId, {
						headers: {
							Authorization: "Bearer " + idtoken,
							"Content-Type": "application/json",
						},
					})
					.then((res) => {
						if (res.statusText == "No Content") {
							console.log("no default study");
						} else {
							this.currentStudy = res.data.studyID;
							store.dispatch("setStudyID", res.data.studyID);
						}
					})
					.catch((err) => {
						console.log(err);
					});
			}
			this.valnow = store.getters.getStudyIs;
		},
		async studychange(val) {
			// store.commit(setStudy,val);
			await store.dispatch("setStudyID", val);
			await this.getcount();
			await this.GetFormData();
			await this.GetChartData();
			await this.GetpatientChartData();
			await this.GetqueryChartData();
		},
		// loadstudy function
		/* filter(data){
            if(data){
                return moment(data).format('DD/MM/YYYY hh:mm')
            }
        },*/
		// loadstudy function
		async loadstudy() {
			const idtoken = store.getters.getIdToken;
			const usermail = jwt_decode(idtoken).email;
			await axios
				.get(`${this.baseapi}/management/study/getallstudy?user=${usermail}`, {
					headers: {
						Authorization: "Bearer " + idtoken,
						"Content-Type": "application/json",
					},
				})
				.then((res) => {
					this.studies = res.data;
				})
				.catch((err) => {
					console.log(err);
				});
			this.valnow = store.getters.getStudyIs;
		},
	},
};
