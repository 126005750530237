<template>
  <div class="margin-left-20">
    <span class="text-right"><a href="/"><i class="fa fa-angle-left"> </i> Back to login</a>
    </span>
  </div>
  <div class="tablebox-design middlebox-design text-center reset-screen-blockp-3">
    <div>
      <ul class="d-flex reset-tab-list" role="tablist">
        <li :class="{ activeTab: showBlock[0] === true }" data-toggle="tab"
        role="button" tabindex="0" @keyup.enter="(showBlock[0] = true),
                  (showBlock[1] = false),errorMsg=''"
         @click.prevent="
                (showBlock[0] = true),
                  (showBlock[1] = false),errorMsg=''
              ">
          <a>
            {{ $t("navigations.password") }}
          </a>
        </li>
        <li :class="{ activeTab: showBlock[1] === true }" data-toggle="tab" 
        role="button" tabindex="0" @keyup.enter="(showBlock[0] = false),
                  (showBlock[1] = true),errors[0]=true"
        @click.prevent="
                (showBlock[0] = false),
                  (showBlock[1] = true),errors[0]=true             
              ">
          <a>{{ $t("navigations.username") }}</a>
        </li>
      </ul>
      <div class="white-bg p-4">
        <div role="tabpanel" v-if="showBlock[0]">
          <div class="text-design">Reset Your Password</div>
          <div class="text-two-design">
            Please enter your email address. We'll send you a link to reset your
            password.
          </div>
          <form class="m-t" role="form" action="index.html">
            <div class="form-group">
              <input type="email" class="form-control" placeholder="Account Email" v-model="check.emailId" required="" />
            </div>
            <div class="error-block mb-2"  v-if="errors[0] == false">
              <span>Please enter a valid email address.</span>
            </div>
            <button type="submit" class="btn-primary btn-block m-b btn-colour" @click.prevent="CheckEmailId()">
              Submit
            </button>
          </form>
          <div class="small-text2">
            ** If you do not receive this mail, Please contact us.
          </div>
        </div>
        <div role="tabpanel" v-if="showBlock[1]">
          <div class="text-design">Recover Your Username</div>
          <div class="text-two-design">
            Please enter your email address. We'll send you a mail containing username.
          </div>
          <form class="m-t" role="form" action="index.html">
            <div class="form-group">
              <input type="email" class="form-control" placeholder="Account Email" v-model="check.emailId" required="" />
            </div>
            <div class="error-block mb-2" v-if="errorMsg!=''">
              <span>{{errorMsg}}</span>
            </div>
            <button type="submit" class="btn-primary btn-block m-b btn-colour" @click.prevent="CheckEmailId()">
              Submit
            </button>
          </form>
          <div class="small-text2">
            ** If you do not receive this mail, Please contact us.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import axios from "axios";
import store from "../../store/index";
import "../../assets/style.css";
export default {
  name: "CheckEmail",
  data() {
    return {
      baseapi: process.env.VUE_APP_Service_URL,
      accountBaseUrl: `${process.env.VUE_APP_Service_URL}/account-core/user/`,
      check: {
        emailId: "",
      },
      username: "",
      firstname: "",
      lastname: "",
      data: [],
      validations: [false],
      errors: [],
      errorMsg:"",
      activeTab: false,
      showBlock: [true, false]
    };
  },
  async mounted() {
    await this.Disablemenu();
  },
  async unmounted() {
    await this.Enablemenu();
  },

  methods: {
    async Disablemenu() {
      store.dispatch("setNavbar", false);
      const navbardefault = document.querySelector("#page-wrapper");
      navbardefault.style.width = "100%";
      navbardefault.style.paddingRight = "0px";
      navbardefault.style.paddingTop = "50px";
    },
    async Enablemenu() {
      store.dispatch("setNavbar", true);
    },
    async CheckEmailId() {
      if(this.showBlock[0]){

        await this.validate();
          if (this.validations.includes(false)) {
            this.errors = this.validations;
          } else {
            this.getAndSetProfileData();
          }
        }
      else{
        await axios.post(this.accountBaseUrl +"forgotusername",
          {
            "emailId": this.check.emailId
          },
          this.Headers)
          .then((res) => {
            if (res.data != null) {
              this.errorMsg=""
            alert(
              "We have send you a mail containing username check your mail"
            );
            this.$router.push(`/`);
            }
          })
          .catch((err)=>{
            console.log(err.response)
            if(err.response.data.errors.DomainValidations.length!=0)
            {
              if(err.response.data.errors.DomainValidations && err.response.data.errors.DomainValidations[0]==="User not found. please enter a valid email")
              this.errorMsg = "Please enter a valid email"
              else if(err.response.data.errors.DomainValidations && err.response.data.errors.DomainValidations[0]==="Username not found.")
              this.errorMsg = "Username not found"
            }
          })
         }
     
    },
    async getAndSetProfileData() {
      console.log("")
      await axios
        .get(
          this.accountBaseUrl +
          "isuserfound?emailId=" +
          this.check.emailId,
          this.Headers
        )
        .then((res) => {
          this.data = res.data;
          if (res.data != null) {
            alert(
              "We have sent you a password reset link. Please check your email."
            );
            this.firstname = this.data.firstName;
            this.lastname = this.data.lastName;
            this.username = this.firstname + " " + this.lastname;
            this.emailId = this.data.emailIdPrimary;
            this.SendPassResetMail();
            this.$router.push(`/`);
          }
        })
        .catch((err) => {
          console.log(err);
          alert("No user found");
        });
    },

    async SendPassResetMail() {
      await axios
        .post(this.accountBaseUrl + "forgotpasswordemail", {
          emailId: this.check.emailId,
          username: this.username,
          source: "Webapp"
        })
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async validate() {
      this.ValidatefirstEmail(this.check.emailId);
    },

    async ValidatefirstEmail(mail) {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
        this.validations[0] = true;
      } else {
        this.validations[0] = false;
      }
    },
  },
};
</script>

<style>
.error-block{
  color: red;
  font-size: 14px;
  text-align:right;
}
.white-bg {
  background-color: #fff;
}

.activeTab {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}

.reset-tab-list {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  border-bottom: 1px solid #dee2e6;
}

.reset-tab-list li {
  font-weight: 600;
  padding: 8px 20px 7px 20px;
  flex-basis: 50%;
}
.tablebox-design {
  width: 400px;
  margin-top: 100px;
}

.middlebox-design {
  display: grid;
  place-content: center;
}

.reset-screen-block {
  width: min(90%, 400px);
  height: calc(100dvh - 70px)
}

.text-design {
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: 600;
  color: #145faf;
}

.text-two-design {
  margin-top: 0;
  margin-bottom: 1rem;
  color: #676a6c;
}

.small-text2 {
  font-size: 80%;
  font-weight: 400;
}

.msg {
  margin-bottom: 10px;
}

.margin-left-20 {
  margin-left: calc(100% - 200px);
}
</style>
