<template>
  <div
    v-if="this.closedoption != 'displayform'"
    class="row wrapper border-bottom white-bg page-heading"
  >
    <div class="col-lg-11">
      <ol v-if="previousPage == 'reviewlist'" class="breadcrumb p-1 px-3 mb-0">
        <li class="breadcrumb-item">
          <a @click="formdesigner()">Design Form</a>
        </li>
        <li class="breadcrumb-item">
          <strong>View form</strong>
        </li>
      </ol>
    </div>
    <div class="col-lg-1 text-end align-self-center">
      <a class="close-button" title="Close" href="#" @click="gotopage()"
        ><i class="fa fa-times"></i
      ></a>
    </div>
  </div>
  <div class="wrapper wrapper-content">
    <div class="row">
      <div class="col-lg-12">
        <div
          class="ibox-content-new-form mainbgchangeform bottommargin"
          id="getdfcontent"
        >
          <div id="formElements mt-3 ">
            <form>
              <div
                class="form-group"
                v-for="input in htmlarray"
                :key="input.folder_Id"
              >
                <div v-for="(item, pageIndex) in input.pages" :key="pageIndex">
                  <div
                    v-if="item.pageNo == pageno"
                    class="pageContainer mt-2 mainbgchangeform"
                  >
                    <div>
                      <div
                        class="groupsdiv"
                        v-for="(groupno, groupIndex) in groups[pageno]" :key="groupIndex"
                      >
                        <div class="flexContainer mainbgchangeform">
                          <template
                            v-for="(newfield, fieldIndex) in item.field" :key="fieldIndex"
                          >
                            <div
                              class="flex-sub-container mainbgchangeform"
                              v-if="newfield.groupOrderNo == groupno"
                            >
                              <!-- headder starts -->
                              <div
                                v-if="newfield.type == 'header'"
                                class="headerdiv"
                              >
                                <h6 class="text-center">{{ newfield.label }}</h6>
                              </div>
                              <!-- headder ends -->
                              <!-- input starts -->
                              <div
                                v-else-if="inputElements.includes(newfield.type)"
                                class="flex-item-forms"
                                @load="testfun()"
                              >
                                <baseinput
                                  v-model="formdata[`pageNo${item.pageNo}`][newfield.fieldName]"
                                  :label="newfield.label"
                                  :requiredname="newfield.validation[0]"
                                  :inputtype="newfield.type"
                                  type="text"
                                />
                                <span v-if="isvalidate" class="errormsg">{{errormsg[newfield.fieldName]}}</span>
                              </div>
                              <!-- input ends -->
                              <!-- date element starts -->
                              <div
                                v-else-if="newfield.type == 'date'"
                                class="flex-item-forms"
                                @load="testfun()"
                              >
                                <basedate
                                  :classname="
                                    errorclasslist[newfield.fieldName]
                                  "
                                  v-model="
                                    formdata[`pageNo${item.pageNo}`][
                                      newfield.fieldName
                                    ]
                                  "
                                  :requiredname="newfield.validation[0]"
                                  :label="newfield.label"
                                  type="text"
                                />
                                <span class="errormsg">{{
                                  errormsg[newfield.fieldName]
                                }}</span>
                              </div>
                              <!-- date element ends -->
                              <!-- dateandtime element starts -->
                              <div
                                v-else-if="newfield.type == 'dateandtime'"
                                class="flex-item-forms bgblue"
                              >
                                <div class="labelclass">
                                  {{ newfield.label }}<sup v-if="newfield.validation[0] == 'required' || newfield.validation[0] == 'Required'"><i class="fa fa-asterisk imp"></i></sup>
                                </div>
                                <input
                                  class="form-control"
                                  classname="date"
                                  :label="newfield.label"
                                  type="datetime-local"
                                />
                              </div>
                              <!-- date element ends -->
                              <!-- qr code starts -->
                              <div
                                class="elem"
                                v-else-if="newfield.type == 'qrcode'"
                              >
                                <div hidden>
                                  {{
                                    qrcodefun(
                                      newfield.fieldName,
                                      newfield.qrData
                                    )
                                  }}
                                </div>
                                <div>  {{ newfield.label }} </div>   
                                <iframe
                                  :src="qrcodetxt[newfield.fieldName]"
                                  title="qr code"
                                >
                                </iframe>
                              </div>
                              <!-- qr code ends -->
                              <!-- numberanddate starts -->
                              <div
                                v-else-if="newfield.type == 'numberanddate'"
                                class="flex-item-forms"
                              >
                                <div class="labelclass">
                                  {{ newfield.label }}<sup v-if="newfield.validation[0] == 'required' || newfield.validation[0] == 'Required'"><i class="fa fa-asterisk imp"></i></sup>
                                </div>
                                <div class="twoitemflex">
                                  <input
                                    class="form-control"
                                    :label="newfield.label"
                                    type="number"
                                  />
                                  <input
                                    class="form-control"
                                    :label="newfield.label"
                                    type="date"
                                  />
                                </div>
                              </div>
                              <!-- numberanddate ends -->
                              <!-- scales starts -->
                              <div
                                v-else-if="newfield.type == 'scales'"
                                class="flex-item-forms"
                              >
                                <div>
                                  <div class="labelclass">
                                    {{ newfield.label }}<sup v-if="newfield.validation[0] == 'required' || newfield.validation[0] == 'Required'"><i class="fa fa-asterisk imp"></i></sup>
                                  </div>
                                  <input
                                    class="form-control scaleval"
                                    type="number"
                                    :min="newfield.limit[0].min"
                                    :max="newfield.limit[0].max"
                                    v-model="newfield.value"
                                  />
                                  <input
                                    type="range"
                                    class="rangeclass"
                                    :min="newfield.limit[0].min"
                                    :max="newfield.limit[0].max"
                                    step="1"
                                    v-model="newfield.value"
                                  />

                                  <span v-text="total"></span>
                                </div>
                              </div>
                              <!-- scales ends -->
                              <!-- file upload starts -->
                              <div
                                v-else-if="newfield.type == 'uploadfile'"
                                class="flex-item-forms"
                              >
                                <div class="labelclass">
                                  {{ newfield.label }}<sup v-if="newfield.validation[0] == 'required' || newfield.validation[0] == 'Required'"><i class="fa fa-asterisk imp"></i></sup>
                                </div>
                                <div class="twoitemflex">
                                  <input
                                    class="form-control"
                                    :label="newfield.label"
                                    type="file"
                                  />
                                </div>
                              </div>
                              <!-- file upload ends -->
                              <!-- image starts -->
                              <div
                                v-else-if="newfield.type == 'image'"
                                class="flex-item-forms displayflex"
                              >
                                <div>
                                  <div class="labelclass marginauto">
                                    {{ newfield.label }}
                                  </div>
                                </div>
                                <img
                                  class="templatepreview"
                                  :src="`${fileuploadurl}/file/${
                                    newfield.imagePath.split(',')[0]
                                  }?versionid=${
                                    newfield.imagePath.split(',')[1]
                                  }`"
                                />
                              </div>
                              <!-- image ends -->
                              <!-- Grid starts -->
                              <div
                                    class="elem"
                                    v-else-if="newfield.type == 'grid'"
                                  >
                                
                                    <div class="notesstyle gridbackground">
                                      <div class="ibox-content-edit flextable gridbackground">
                                        <div class="addfieldtable">
                                          <div
                                            class="rowcolmn"
                                            v-for="(gridrow, index) in gridoptarray[newfield.fieldName][0].Rows"
                                            :key="gridrow"
                                          >
                                            <div class="rowlabelgrid rowcolmnc">
                                              {{ gridrow }}
                                            </div>
                                            <div
                                              class="rowcolmnc"
                                              v-for="gridcolumn in gridoptarray[newfield.fieldName][0].Columns"
                                              :key="gridcolumn"
                                            >
                                              <div v-if="index == 0">
                                                {{ gridcolumn }}
                                              </div>
                                              <div v-if="gridoptarray[newfield.fieldName][0].Fieldtypes[index] == 'text'">
                                              <input
                                                class="form-control"
                                                type="text"
                                              />
                                              </div>
                                              <div v-if="gridoptarray[newfield.fieldName][0].Fieldtypes[index] == 'number'">
                                              <input
                                                class="form-control"
                                                type="number"
                                              />
                                              </div>
                                              <div v-if="gridoptarray[newfield.fieldName][0].Fieldtypes[index] == 'date'">
                                              <input
                                                class="form-control"
                                                type="date"
                                              />
                                              </div>
                                              <div v-if="gridoptarray[newfield.fieldName][0].Fieldtypes[index] == 'time'">
                                              <input
                                                class="form-control"
                                                type="time"
                                              />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                              </div> 
                              <!-- Grid ends -->
                              <!-- year starts -->
                              <div
                                v-else-if="newfield.type == 'year'"
                                class="flex-item-forms"
                              >
                                <div class="labelclass">
                                  {{ newfield.label }}<sup v-if="newfield.validation[0] == 'required' || newfield.validation[0] == 'Required'"><i class="fa fa-asterisk imp"></i></sup>
                                </div>
                                <input
                                  class="form-control"
                                  :label="newfield.label"
                                  type="number"
                                  min="1900"
                                  max="2099"
                                  step="1"
                                />
                              </div>
                              <!-- year ends -->
                              <!-- summery starts -->
                              <div
                                class="elem font-15"
                                v-else-if="newfield.type == 'summary'"
                              >
                                <i>{{ newfield.label }}</i>
                              </div>
                              <!-- summery ends -->
                              <!-- add survey starts -->
                              <div
                                class="elem"
                                v-else-if="newfield.type == 'addsurvey'"
                              >
                                <div class="survey">
                                  <button
                                    type="button"
                                    class="btn btn-outline-primary"
                                    @click.prevent="addsurvey()"
                                  >
                                    {{ newfield.label }}
                                  </button>
                                </div>
                              </div>
                              <!-- add survey ends -->
                              <!-- dropdown menu starts -->
                              <div
                                v-else-if="newfield.type == 'dropdown'"
                                class="flex-item-forms"
                              >
                                <baseselect
                                  :options="newfield.options"
                                  v-model="formdata[`pageNo${item.pageNo}`][newfield.fieldName]"
                                  :requiredname="newfield.validation[0]"
                                  :label="newfield.label"
                                />
                                <span v-if="isvalidate" class="errormsg">{{ errormsg[newfield.fieldName] }}</span>
                              </div>
                              <!-- dropdown menu ends -->
                              <!-- text area starts -->
                              <div
                                class="elem"
                                v-else-if="
                                  (newfield.type == 'description') |
                                    (newfield.type == 'textarea')
                                "
                              >
                                <div class="txtarealabel">
                                  <label
                                    :for="newfield.fieldName"
                                    class="textsize"
                                    >{{ newfield.label }}<sup v-if="newfield.validation[0] == 'required' || newfield.validation[0] == 'Required'"><i class="fa fa-asterisk imp"></i></sup></label
                                  >
                                </div>
                                <div class="txtar">
                                  <textarea
                                    :id="newfield.fieldName"
                                    :name="newfield.fieldName"
                                    class="textbox"
                                    v-model="
                                      formdata[`pageNo${item.pageNo}`][
                                        newfield.fieldName
                                      ]
                                    "
                                  />

                                  <span v-if="isvalidate" class="errormsg">{{
                                    errormsg[newfield.fieldName]
                                  }}</span>
                                </div>
                              </div>
                              <!-- text area ends -->
                              <!-- repeatedmeasure starts -->
                              <div
                                v-else-if="newfield.type == 'repeatedmeasure'  || newfield.type == 'RepeatingMeasure' || newfield.type == 'repeatingfield'" >
                                 <h6 class="headerdiv">{{ newfield.label }}</h6> 
                                <form>
                                  <div class="form-group">
                                    <div class="pageContainer mt-2">
                                      <div class="groupsdiv mainbgchange"
                                           v-for="(repgroupno, key) in repeatingGroupOrders[pageIndex][fieldIndex].groupOrders"
                                          :key="key"
                                      >
                                      <div class="flexContainer mainbgchange">
                                        <template
                                            v-for="(newrepeatingFields, key) in newfield.repeatingFields" :key="key">
                                            <div
                                              v-if="newrepeatingFields.groupOrderNo == repgroupno"
                                              class="flex-sub-container mainbgchange"
                                            >
                                    <!-- Repeating headder starts -->
                                    <div
                                      v-if="newrepeatingFields.type == 'header'"
                                      class="headerdiv"
                                    >
                                      <h4 class="text-center">{{ newrepeatingFields.label }}</h4>
                                    </div>
                                    <!-- Repeating headder ends -->
                                    <!-- Repeating input starts -->
                                    <div
                                      v-else-if="
                                        inputElements.includes(newrepeatingFields.type)
                                      "
                                      class="flex-item bgblue"
                                    >
                                      <div class="labelclass text-start">
                                        {{ newrepeatingFields.label}}<sup
                                          v-if="
                                            newrepeatingFields.validation[0] == 'required' ||
                                            newrepeatingFields.validation[0] == 'Required'
                                          "
                                          ><i class="fa fa-asterisk imp"></i></sup>
                                      </div>
                                      <input
                                        class="form-control text-start"
                                        :label="newrepeatingFields.label"
                                        type="text"
                                      />
                                    </div>
                                    <!-- Repeating input ends -->
                                    <!-- Repeating image starts -->
                                    <div
                                      v-else-if="newrepeatingFields.type == 'image'"
                                      class="flex-item displayflex"
                                    >
                                      <div>
                                        <div
                                          class="labelclass marginauto"
                                        ></div>
                                      </div>
                                      <img
                                        class="templatepreview"
                                        :src="
                                          `${fileuploadurl}/file/${
                                            newrepeatingFields.imagePath.split(',')[0]
                                          }?versionid=${
                                            newrepeatingFields.imagePath.split(',')[1]
                                          }`
                                        "
                                      />
                                    </div>
                                    <!-- Repeating image ends -->
                                    <!-- Repeating scales starts -->
                                    <div
                                      v-else-if="newrepeatingFields.type == 'scales'"
                                      class="flex-item"
                                    >
                                      <div>
                                        <div class="labelclass text-start">
                                          {{ newrepeatingFields.label}}<sup
                                            v-if="
                                              newrepeatingFields.validation[0] == 'required' ||
                                              newrepeatingFields.validation[0] == 'Required' "
                                            ><i class="fa fa-asterisk imp"></i
                                          ></sup>
                                        </div>
                                        <input
                                          class="form-control scaleval"
                                          type="number"
                                          :min="parseInt(newrepeatingFields.limit[0].min)"
                                          :max="parseInt(newrepeatingFields.limit[0].max)"
                                          v-model="newrepeatingFields.value"
                                        />
                                        <input
                                          type="range"
                                          class="rangeclass"
                                          :min="parseInt(newrepeatingFields.limit[0].min)"
                                          :max="parseInt(newrepeatingFields.limit[0].max)"
                                          step="1"
                                          v-model="newrepeatingFields.value"
                                        />

                                        <span v-text="total"></span>
                                      </div>
                                    </div>
                                    <!-- Repeating scales ends -->
                                    <!-- Repeating time element starts -->
                                    <div
                                      v-else-if="newrepeatingFields.type == 'time'"
                                      class="flex-item bgblue"
                                    >
                                      <div class="labelclass start-text">
                                        {{ newrepeatingFields.label }}<sup
                                          v-if="
                                            newrepeatingFields.validation[0] ==
                                              'required' ||
                                              newrepeatingFields.validation[0] ==
                                                'Required'
                                          "
                                          ><i class="fa fa-asterisk imp"></i
                                        ></sup>
                                      </div>
                                      <input
                                        class="form-control"
                                        classname="time"
                                        :label="newrepeatingFields.label"
                                        type="time"
                                      />
                                    </div>
                                    <!-- Repeating time element ends -->
                                    <!-- Repeating date element starts -->
                                    <div
                                      v-else-if="newrepeatingFields.type == 'date'"
                                      class="flex-item bgblue"
                                    >
                                      <div class="labelclass text-start">
                                        {{ newrepeatingFields.label
                                        }}<sup
                                          v-if="
                                            newrepeatingFields.validation[0] ==
                                              'required' ||
                                              newrepeatingFields.validation[0] ==
                                                'Required'
                                          "
                                          ><i class="fa fa-asterisk imp"></i
                                        ></sup>
                                      </div>
                                      <input
                                        class="form-control"
                                        classname="date"
                                        :label="newrepeatingFields.label"
                                        type="date"
                                      />
                                    </div>
                                    <!-- Repeating date element ends -->
                                    <!-- Repeating dateandtime element starts -->
                                    <div
                                      v-else-if="newrepeatingFields.type == 'dateandtime'"
                                      class="flex-item bgblue"
                                    >
                                      <div class="labelclass text-start">
                                        {{ newrepeatingFields.label}}<sup
                                          v-if="
                                            newrepeatingFields.validation[0] ==
                                              'required' ||
                                              newrepeatingFields.validation[0] ==
                                                'Required'
                                          "
                                          ><i class="fa fa-asterisk imp"></i
                                        ></sup>
                                      </div>
                                      <input
                                        class="form-control"
                                        classname="date"
                                        :label="newrepeatingFields.label"
                                        type="datetime-local"
                                      />
                                    </div>
                                    <!-- Repeating date element ends -->
                                    <!-- Repeating text area starts -->
                                    <div
                                      class="elem"
                                      v-else-if="
                                        (newrepeatingFields.type == 'description') |
                                        (newrepeatingFields.type == 'textarea')"
                                    >
                                      <div class="labelclass text-start">
                                        <label
                                          :for="newrepeatingFields.fieldName"
                                          >{{ newrepeatingFields.label
                                          }}<sup
                                            v-if="
                                              newrepeatingFields.validation[0] ==
                                                'required' ||
                                                newrepeatingFields.validation[0] ==
                                                  'Required'
                                            "
                                            ><i
                                              class="fa fa-asterisk imp"
                                            ></i></sup
                                        ></label>
                                      </div>
                                      <div class="txtar">
                                        <textarea
                                          :id="newrepeatingFields.fieldName"
                                          :name="newrepeatingFields.fieldName"
                                          class="textbox"
                                        />
                                      </div>
                                    </div>
                                    <!-- Repeating text area ends -->
                                    <!-- Repeating checkboxes starts -->
                                    <div
                                      v-else-if="newrepeatingFields.type == 'checkbox'"
                                      class="form-group sticktobottom checkboxcontainer"
                                    >
                                      <div class="checkboxentry">
                                        <input
                                          type="checkbox"
                                          :id="newrepeatingFields.fieldName"
                                          :name="newrepeatingFields.fieldName"
                                        /><span class="checklabel">
                                          {{ newrepeatingFields.label }}
                                          <sup
                                            v-if="newrepeatingFields.validation[0] == 'required' ||
                                                  newrepeatingFields.validation[0] == 'Required'  ">
                                                <i class="fa fa-asterisk imp"></i>
                                          </sup>
                                        </span>
                                      </div>
                                    </div>
                                    <!-- Repeating checkboxes ends -->
                                    <!-- Repeating radio button starts -->
                                    <div
                                      v-else-if="newrepeatingFields.type == 'radio'"
                                      class="form-group labelclass sticktobottom"
                                    >
                                      <div class="radiogroupclass">
                                        {{ newrepeatingFields.label
                                        }}<sup
                                          v-if="
                                            newrepeatingFields.validation[0] ==
                                              'required' ||
                                              newrepeatingFields.validation[0] ==
                                                'Required'
                                          "
                                          ><i class="fa fa-asterisk imp"></i
                                        ></sup>
                                        <div
                                          class="radiogroupclass"
                                          v-for="options in newrepeatingFields.options"
                                          :key="options"
                                        >
                                          <input
                                            type="radio"
                                            class="mr-2"
                                            :name="newrepeatingFields.fieldName"
                                            :id="newrepeatingFields.fieldName"
                                          />{{ options.optionname }}
                                        </div>
                                      </div>
                                    </div>
                                    <!-- Repeating radio button ends -->
                                    <!-- Repeating radio button horizondal starts -->
                                    <div
                                      v-else-if="
                                        newrepeatingFields.type == 'radiohorizondal'
                                      "
                                      class="form-group labelclass sticktobottom"
                                    >
                                      <div class="radiohorizondalclass">
                                        <div class="radiolabelclass">
                                          {{ newrepeatingFields.label }}
                                        </div>
                                        <div
                                          class="radiogroupclass"
                                          v-for="options in newrepeatingFields.options"
                                          :key="options"
                                        >
                                          <input
                                            type="radio"
                                            class="mr-2 ml-2"
                                            :name="newrepeatingFields.fieldName"
                                            :id="newrepeatingFields.fieldName"
                                          />{{ options.optionname }}
                                        </div>
                                      </div>
                                    </div>
                                    <!-- Repeating radio button ends -->
                                    <!-- Repeating dropdown menu starts -->
                                    <div
                                      v-else-if="newrepeatingFields.type == 'dropdown'"
                                      class="flex-item"
                                    >
                                      <div class="labelclass text-start">
                                        {{ newrepeatingFields.label }}
                                        <sup
                                          v-if="
                                            newrepeatingFields.validation[0] == 'required' ||
                                            newrepeatingFields.validation[0] == 'Required' ">
                                            <i class="fa fa-asterisk imp"></i>
                                        </sup>
                                      </div>
                                      <div
                                        class="baseselect mainbgchange selectzindex"
                                      >
                                        <input
                                          class="form-control"
                                          :label="newrepeatingFields.label"
                                          :value="newrepeatingFields.options[0].optionname "
                                          type="text"
                                          readonly
                                        />
                                      </div>
                                    </div>
                                    <!-- Repeating dropdown menu ends -->
                                    <!-- Repeating notes starts -->
                                    <div
                                      class="elem"
                                      v-else-if="newrepeatingFields.type == 'notes'"
                                    >
                                      <div class="notesstyle">
                                        {{ newrepeatingFields.label }}
                                      </div>
                                    </div>
                                    <!-- Repeating notes ends -->
                                    <!-- Repeating file upload starts -->
                                    <div
                                      v-else-if="newrepeatingFields.type == 'uploadfile'"
                                      class="flex-item"
                                    >
                                      <div class="labelclass text-start">
                                        {{ newrepeatingFields.label
                                        }}<sup
                                          v-if="
                                            newrepeatingFields.validation[0] ==
                                              'required' ||
                                              newrepeatingFields.validation[0] ==
                                                'Required'
                                          "
                                          ><i class="fa fa-asterisk imp"></i
                                        ></sup>
                                      </div>
                                      <div class="twoitemflex">
                                        <input
                                          class="form-control"
                                          :label="newrepeatingFields.label"
                                          type="file"
                                        />
                                      </div>
                                    </div>
                                    <!-- Repeating file upload ends -->
                                    <!-- Repeating year starts -->
                                    <div
                                      v-else-if="newrepeatingFields.type == 'year'"
                                      class="flex-item"
                                    >
                                      <div class="labelclass text-start">
                                        {{ newrepeatingFields.label}}
                                        <sup
                                          v-if="newrepeatingFields.validation[0] == 'required' ||
                                                newrepeatingFields.validation[0] == 'Required'  ">
                                              <i class="fa fa-asterisk imp"></i>
                                        </sup>
                                      </div>
                                      <input
                                        class="form-control"
                                        :label="newrepeatingFields.label"
                                        type="number"
                                        min="1900"
                                        max="2099"
                                        step="1"
                                      />
                                    </div>
                                    <!-- Repeating year ends -->
                                    <!-- Repeating calculation starts -->
                                    <div
                                      v-else-if="newrepeatingFields.type == 'calculation'"
                                      class="flex-item"
                                    >
                                      <div class="labelclass text-start">
                                        {{ newrepeatingFields.label }}
                                      </div>
                                      {{}}
                                    </div>
                                    <!-- Repeating calculation ends -->
                                    <!-- Repeating summery starts -->
                                    <div
                                      class="elem font-15"
                                      v-else-if="newrepeatingFields.type == 'summary'"
                                    >
                                      <i>{{ newrepeatingFields.label }}</i>
                                    </div>
                                    <!-- Repeating summery ends -->
                                    <!-- Repeating qr code starts -->
                                    <div
                                            class="elem"
                                            v-else-if="
                                              newrepeatingFields.type == 'qrcode'
                                            "
                                          >
                                            <div hidden>
                                              {{
                                                qrcodefun(
                                                  newrepeatingFields.fieldName,
                                                  newrepeatingFields.qrData
                                                )
                                              }}
                                            </div>
                                            <iframe
                                              class="qrcodeframe"
                                              :src="
                                                qrcodetxt[newrepeatingFields.fieldName]
                                              "
                                              title="qr code"
                                            >
                                            </iframe>
                                    </div>
                                    <!-- Repeating qr code ends -->
                                    <!-- Repeating Grid starts -->
                                    <div
                                      class="elem"
                                      v-else-if="newrepeatingFields.type == 'grid'"
                                    >
                                      <div
                                        v-if="
                                          gridoptarray[newrepeatingFields.fieldName] ==
                                            undefined
                                        "
                                        hidden
                                      >
                                        {{
                                          gridoptfun(
                                            newrepeatingFields.fieldName,
                                            newrepeatingFields.options[0]
                                          )
                                        }}
                                      </div>
                                      <div
                                        class="notesstyle gridbackground gridelem"
                                      >
                                        <div
                                          class="ibox-content-edit flextable gridbackground"
                                        >
                                          <div class="addfieldtable">
                                            <div
                                              class="rowcolmn"
                                              v-for="(gridrow,
                                              index) in gridoptarray[
                                              newrepeatingFields.fieldName
                                              ][0].Rows"
                                              :key="gridrow"
                                            >
                                              <div class="rowlabelgrid">
                                                {{ gridrow }}
                                              </div>
                                              <div
                                                class="rowcolmnc"
                                                v-for="gridcolumn in gridoptarray[
                                                  newrepeatingFields.fieldName
                                                ][0].Columns"
                                                :key="gridcolumn"
                                              >
                                                <div v-if="index == 0">
                                                  {{ gridcolumn }}
                                                </div>
                                                <input
                                                  class="form-control"
                                                  v-model="
                                                    griddata[0].Columns[
                                                      gridcolumn - 1
                                                    ]
                                                  "
                                                  type="text"
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <!-- Repeating Grid ends -->
                                    <!-- Repeating numberanddate starts -->
                                    <div
                                      v-else-if="
                                        newrepeatingFields.type == 'numberanddate'
                                      "
                                      class="flex-item"
                                    >
                                      <div class="labelclass text-start">
                                        {{ newrepeatingFields.label
                                        }}<sup
                                          v-if="
                                            newrepeatingFields.validation[0] ==
                                              'required' ||
                                              newrepeatingFields.validation[0] ==
                                                'Required'
                                          "
                                          ><i class="fa fa-asterisk imp"></i
                                        ></sup>
                                      </div>
                                      <div class="d-flex">
                                        <input
                                          class="form-control"
                                          :label="newrepeatingFields.label"
                                          type="number"
                                        />
                                        <input
                                          class="form-control"
                                          :label="newrepeatingFields.label"
                                          type="date"
                                        />
                                      </div>
                                    </div>
                                    <!-- Repeating numberanddate ends -->
                                    <!-- Repeating subHeader starts -->
                                    <div
                                      v-else-if="newrepeatingFields.type == 'subHeader'"
                                      class="subheadder"
                                    >
                                      <h4>{{ newrepeatingFields.label }}</h4>
                                    </div>
                                     <!-- Repeating subHeader ends -->
                                            </div>
                                        </template>
                                      </div>
                                      </div>
                                    </div>
                                  </div>
                                </form>
                              </div>
                              <!-- repeatedmeasure ends -->
                              <!-- subHeader starts -->
                              <!-- notes starts -->
                              <div
                                class="elem"
                                v-else-if="newfield.type == 'notes'"
                              >
                                <div class="notesstyle">
                                  {{ newfield.label }}
                                </div>
                              </div>
                              <!-- notes ends -->
                              <!-- checkboxes starts -->
                              <div
                                v-else-if="newfield.type == 'checkbox'"
                                class="form-group sticktobottom"
                              >
                                <label class="labelstyle">&nbsp;</label>
                                <div class="checkboxentry">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    :id="newfield.fieldName"
                                    :name="newfield.fieldName"
                                    v-model="
                                      formdata[`pageNo${item.pageNo}`][
                                        newfield.fieldName
                                      ]
                                    "
                                  /><span class="checklabel">
                                    {{ newfield.label }}
                                  </span>
                                </div>
                                <span v-if="isvalidate" class="errormsg">{{
                                  errormsg[newfield.fieldName]
                                }}</span>
                              </div>
                              <!-- checkboxes ends -->
                              <!-- radio button starts -->
                              <div
                                v-else-if="newfield.type == 'radio'"
                                class="form-group sticktobottom"
                              >
                                <div>
                                  <baseradiogroup
                                    :label="newfield.label"
                                    :name="newfield.fieldName"
                                    :options="newfield.options"
                                    :requiredname="newfield.validation[0]"
                                    v-model="
                                      formdata[`pageNo${item.pageNo}`][
                                        newfield.fieldName
                                      ]
                                    "
                                  >
                                  </baseradiogroup>
                                </div>
                                <span v-if="isvalidate" class="errormsg">{{
                                  errormsg[newfield.fieldName]
                                }}</span>
                              </div>
                              <!-- radio button ends -->
                              <!-- radio button starts -->
                              <div
                              v-else-if="newfield.type == 'radiohorizondal'"
                              class="form-group sticktobottom"
                            >
                              <div>
                                <baseradiogroup
                                  :fromarchive="fromArchive"
                                  :label="newfield.label"
                                  :name="newfield.fieldName"
                                  :options="newfield.options"
                                  orientation="horizondal"
                                  v-model="
                                    formdata[`pageNo${item.pageNo}`][
                                      newfield.fieldName
                                    ]
                                  "
                                >
                                </baseradiogroup>
                              </div>
                              <span v-if="isvalidate" class="errormsg">{{
                                errormsg[newfield.fieldName]
                              }}</span>
                              </div>
                              <!-- radio button ends -->
                              <!-- subHeader starts -->
                              <div
                                v-else-if="newfield.type == 'subHeader'"
                                class="subheadder"
                              >
                                <h4>{{ newfield.label }}</h4>
                              </div>
                              <!-- subHeader ends -->
                            </div>
                          </template>
                        </div>
                      </div>
                      <!------ pagination -------->
                      <div class="row mx-0 paginationpadding">
                        <div class="col-lg-12 mx-0 px-0 mt-4">
                          <div
                            class="dataTables_paginate paging_simple_numbers pagination float-right"
                          >
                            <div
                              class="col-lg-12 d-flex justify-content-lg-end px-0"
                            >
                              <v-pagination
                                v-model="pageno"
                                :pages="totalpages"
                                :range-size="1"
                                active-color="#f4f9ff"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <!-- <div class="col-lg-4" > 
          <div class="ibox white-bg">
            <div class="ibox-title-user">
              <h5>Comments</h5>
            </div>
            <div class="ibox-content form-group">
              <div class="input-group">
                <textarea rows="5" v-model="comments" cols="200"></textarea>
              </div>
              
            </div>
          </div>
        </div> -->
    </div>
  </div>

  <!-- modal starts -->
  <!-- Query Open model starts -->
</template>

<script src="./viewform.js"></script>

<style scoped>
.close-button {
  color: #9e9595;
}
.rowcolmnc {
    flex: 1;
}
.close-button:hover {
  color: black;
}
.flex-sub-container {
  border: none !important;
}
.Page-active {
  padding: 1rem;
  color: #ffffff;
  border: 1px solid #6d57ce !important;
  border-radius: inherit;
  margin: 0;
}
.paginationpadding {
  padding-bottom: 10px;
  padding-right: 10px;
}
.pageContainerform {
  margin-top: 30px;
}
.seperator {
  font-size: 23px !important;
}
.linkstyleleft {
  font-weight: bold;
  color: #d42ca2 !important;
  font-size: 16px !important;
  float: left;
}
.linkcontent {
  width: 50%;
}
.errorborder {
  border-width: 2px !important;
  border-color: red !important;
  border-style: double;
}
.radioerror {
  margin-left: 16% !important;
}
.maximumwidth {
  inline-size: 150px;
  overflow-wrap: break-word;
  max-width: 150px;
}
/* .titleclass{
background-color:#6198fc;
}
.titlestyle {
    background-color: ());
} */
.hiddenvisibility {
  visibility: hidden;
}
.maincontainer {
  height: 100px;
  width: 100%;
  z-index: -2;
  position: absolute;
  background-color: blue;
  overflow: hidden;
}
.maincontainertwo {
  width: 100%;
  z-index: -1;
  position: absolute;
  background-color: white;
}
.bottommargin {
  margin-bottom: 25px;
}

.btn-approve {
  background-color: #1ab394;
}
.btn-reject {
  background-color: #ed5565;
}
.btn-circle {
  width: 30px;
  height: 30px;
  padding: 6px 0;
  border-radius: 15px;
  text-align: center;
  font-size: 12px;
  line-height: 1.428571429;
  background-color: #1ab394;
}
.btn-circle-reject {
  width: 30px;
  height: 30px;
  padding: 6px 0;
  border-radius: 15px;
  text-align: center;
  font-size: 12px;
  line-height: 1.428571429;
  background-color: #ed5565;
}
.rowcolmn {
    margin-top: 0px;
    display: flex;
    gap: 5px;
    margin: 10px;
}
.rowlabelgrid {
    margin-top: auto;
}
.ibox-tools-review {
  display: block;
  float: none;
  margin-top: 0;
  position: absolute;
  top: 3px;
  right: 15px;
  padding: 0;
  text-align: right;
}
.ibox-title-user {
  background-color: #ffffff;
  border-color: #dedede;
  border-image: none;
  border-style: solid solid none solid;
  border-width: 1px;
  color: #4a4b4b;
  margin-bottom: 0;
  padding: 9px 90px 3px 15px;
  min-height: 36px;
  position: relative;
  clear: both;
  -webkit-border-radius: 3px 3px 0 0;
  -moz-border-radius: 3px 3px 0 0;
  border-radius: 0px 0px 0 0;
}
.approvereject {
  text-align: right;
}
.ibox-content-new-form {
  color: inherit;
  padding: 15px 15px 20px 20px;
  border-color: #eeeeee;
  border-image: none;
  border-style: solid;
  border-width: 1px;
}
.mainbgchangeform {
  background-color: #f0f0ff !important;
}
.btn-approve,
.btn-reject {
  position: relative;
  width: 30px;
  height: 30px;
  padding: 4px 0;
  border-radius: 30px;
  text-align: center;
  font-size: 15px;
  line-height: 1.428571429;
  color: #ffffff;
}
.btn-approve:hover,
.btn-reject:hover {
  color: #ffffff !important;
}
.btn-approve .pointer,
.btn-reject .pointer {
  visibility: hidden;
  width: max-content;
  color: #fff;
  text-align: center;
  border-radius: 0.3em;
  padding: 0.3rem 0.8rem;
  right: 0;
  top: -35px;
  font-size: 0.8rem;
  background-color: #616161;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  -webkit-transition: visibility 75ms ease-in;
  transition: visibility 75ms ease-in;
}
.rangeclass {
  width: 100%;
}
.btn-approve:hover .pointer,
.btn-reject:hover .pointer {
  visibility: visible;
  -webkit-transition: visibility 30ms ease-in;
  transition: visibility 30ms ease-in;
}
.commentstyle {
  width: 100%;
}
.wrapper-content {
  margin-left: 15px;
}
.labelclass {
  text-align: left;
  font-size: 13px;
  font-weight: 600;
}
</style>
