<template>
  <div id="survivalchart">
    <div class="row">
      <div class="col-lg-12 mb-1 pr-0 pl-1">
        <div class="row ml-0">
          <div class="col-lg-12 pl-0">
            <div class="stat-card-white maxheight">
              <div class="stat-header-white">
                <span>{{ $t("navigations.kaplan-meier curve") }}</span>
                <button class="btn btn-primary py-0" @click="showData()">{{ $t("navigations.data") }}</button>
              </div>
              <div class="stat-body-white">
                <kaplermodel />
                <div></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 mb-1 pr-0 pl-1">
        <div class="row ml-0">
          <div class="col-lg-12 pl-0">
            <div class="stat-card-white minheight">
              <div class="stat-header-white">
                <span>{{ $t("navigations.hazard ratios") }}</span>
              </div>
              <div class="stat-body-white">
                <hazardus :data="data" />
                <div></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 mb-1 pr-0 pl-1">
        <div class="row ml-0">
          <div class="col-lg-12 pl-0">
            <div class="stat-card-white minheight">
              <div class="stat-header-white">
                <span>{{ $t("navigations.p-values of covariates") }}</span>
              </div>
              <div class="stat-body-white">
                <survivalhorizondal :data="data" :fromPage="'survivalReport'" :chartLabel="'p-values of covariates'"/>
                <div></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 mb-1 pr-0 pl-1">
        <div class="row ml-0">
          <div class="col-lg-12 pl-0">
            <div class="stat-card-white minheight">
              <div class="stat-header-white">
                <span>{{ $t("navigations.coefficient of covariates") }}</span>
              </div>
              <div class="stat-body-white">
                <Survivalreport :analysisdata="data" />
                <div></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 mb-1 pr-0 pl-1">
        <div class="row ml-0">
          <div class="col-lg-12 pl-0">
            <div class="stat-card-white minheight">
              <div class="stat-header-white">
                <span>{{ $t("navigations.confidence intervals of coefficient") }}</span>
              </div>
              <div class="stat-body-white">
                <coifficientchart :data="data" />
                <div></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 mb-1 pr-0 pl-1">
        <div class="row ml-0">
          <div class="col-lg-12 pl-0">
            <div class="stat-card-white minheight">
              <div class="stat-header-white">
                <span>{{ $t("navigations.forest plot") }}</span>
              </div>
              <div class="stat-body-white">
                <forestploat :data="data" />
                <div></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 mb-1 pr-0 pl-1">
        <div class="row ml-0">
          <div class="col-lg-12 pl-0">
            <div class="stat-card-white minheight">
              <div class="stat-header-white">
                <span>{{ $t("navigations.significance plot") }}</span>
              </div>
              <div class="stat-body-white">
                <SignificancePlot :data="data" />
                <div></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <survivaldata_popup
    v-if="popactive"
     @libactive="popactive = false"
  >
  </survivaldata_popup>
</template>

<script>
import Survivalreport from "./survivalreport.vue";
import kaplermodel from "./kaplermodel.vue";
import coifficientchart from "./coefficient_plot.vue";
import forestploat from "./forest_ploat.vue";
import SignificancePlot from "./significance_ploat.vue";
import survivalhorizondal from "./horizondalbarsurvival.vue";
import hazardus from "./hazardratiocoif.vue";
import survivaldata_popup from "../../components/survivaldata_popup/survivaldata_popup.vue";
import axios from "axios";
export default {
  name: "survivalchart",
  components: {
    kaplermodel,
    coifficientchart,
    forestploat,
    SignificancePlot,
    Survivalreport,
    survivalhorizondal,
    hazardus,
    survivaldata_popup
  },
  async mounted() {
    await this.getdata();
  },
  data() {
    return {
      data: {},
      basetestUrl: process.env.VUE_APP_Service_URL,
      popactive: false,
    };
  },
  methods: {
    async getdata() {
      await axios
        .get(
          `${this.basetestUrl}/forms-ml/report/getsurvivalreports?table_name=SurvivalReport`
        )
        .then((res) => {
          this.data = res.data;
          console.log("data for main survival chart is", res.data);
        })
        .catch((err) => console.log("error in screen list", err));
    },
    showData(){
      this.popactive=true
    }
  },
};
</script>
<style scoped>
.widget {
  margin-top: 0rem;
  margin-bottom: 0rem;
}
.widgetmargin {
  margin-top: -13px !important;
}

.style1 {
  background-color: black !important;
  color: #ffffff;
}
.btn-view {
  color: #fff !important;
  background-color: #bb458f !important;
  border-color: #bb458f !important;
}
.btn-view:hover {
  color: #fff;
  background-color: #666ccb;
  border-color: #666ccb;
}
.btn-view:active {
  color: rgb(255, 255, 255) !important;
  background-color: #dda2c7 !important;
  border-color: #dda2c7 !important;
}

.titlemsg {
  border-color: #e7eaec;
  margin-bottom: 0;
  padding: 20px 20px;
  position: relative;
  clear: both;
  right: 0;
  margin-right: 0px;
  margin-left: 0px !important;
  margin-top: 0px !important;
  padding-left: 10px;
}
.padding_bottom {
  padding-bottom: 2rem;
}
.ibox-title h5 {
  height: 0rem;
  padding: 0rem;
}
.ibox-title h5:hover {
  background-color: inherit;
  padding: 0;
  cursor: not-allowed;
}
.titlemsg {
  font-size: 15px;
  font-weight: bold;
  color: var(--primary-color);
}
.switchbtn {
  background-color: #bb458f !important;
  height: max-content;
  border-color: #bb458f !important;
  color: white !important;
  float: right;
}
.switchbtn:active {
  height: max-content;
  border-color: #bb458f !important;
  color: white !important;
  float: right;
  background-color: #bb458f;
}
.switchbtn:hover {
  height: max-content;
  border-color: #bb458f !important;
  color: white !important;
  float: right;
  background-color: #bb458f;
}
.queryhover:hover {
  color: #3c4192 !important;
  -webkit-transition: color ease-in 400ms;
  transition: color ease-in 400ms;
  cursor: pointer;
}

.widgetminheight {
  min-height: 102px;
}
.graphbutton {
  width: fit-content;
  padding: 3px;
  cursor: pointer;
}
.graphbtncontainer {
  display: flex;
  gap: 5px;
}
img {
  margin-left: 0px !important;
}
.selectdefault {
  left: 0;
}
.btn:focus {
  outline: none;
  box-shadow: none;
}
.btn-setdefault {
  position: relative;
  padding: 0.2rem 0.4rem;
  text-align: center;
  font-size: 0.7rem;
  background-color: #ec4758;
  color: white !important;
  border-radius: 3px;
}
.ibox-content h1 {
  font-weight: 400;
  color: #676a6c;
}
.progress {
  display: flex;
  height: 5px;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}
.progress-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.progress-mini {
  flex-grow: 1;
  margin-right: 10px;
}

.progress-bar {
  background-color: #5bc0de;
  height: 20px;
}

.progress-value {
  color: #333;
  font-size: 1.2em;
}
.average-title {
  margin-bottom: 5px;
  font-size: 1.2em;
  color: #888;
}

.stat-item h3 {
  margin-bottom: 5px;
  font-size: 1em;
  color: #888;
}

.stat-item h2 {
  margin-bottom: 5px;
  font-size: 2em;
  color: #333;
}

.stat-item small {
  color: #888;
}

.stat-info h2 {
  margin: 0;
  font-size: 3.5em;
}

.stat-info small {
  font-size: 1em;
}

.stat-icon img {
  width: 50px;
  height: 50px;
}


.stat-body-dflex {
  margin: 5px 15px !important;
  justify-content: center;
  align-items: center;
  padding: 10px;
  min-height: 125px;
}
.stat-info-white h2 {
  margin: 0;
  font-size: 3.5em;
}

.stat-info-white small {
  font-size: 1em;
}
.stat-dropdown {
  position: relative;
  display: inline-block;
}

.stat-dropbtn {
  color: #333;
  font-size: 16px;
  cursor: pointer;
}

.stat-dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 10001;
  right: 0;
}

.stat-dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.stat-dropdown-content a:hover {
  background-color: #f1f1f1;
}

.stat-dropdown:hover .stat-dropdown-content {
  display: block;
}

.stat-dropdown:hover .dropbtn {
  color: #000;
}
.adjustheight {
  height: 220px;
}
.minheight {
  height: 405px;
}
.maxheight {
  height: 100%;
}
</style>
