<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>Roles</h2>
    </div>
  </div>
  <div class="wrapper wrapper-content">
    <div class="ibox-content mb-1 px-3 py-2">
      <div class="form-group row mb-0">
        <div class="d-flex flex-column flex-lg-row my-2">
          <div class="col-lg-5 pl-lg-0 pr-lg-1">
            <div class="form-group mb-md-0">
              <label class="mb-0">Role Name</label>
              <input
                v-model="searchData.rolename"
                type="text"
                class="form-control my-md2 my-lg-0"
              />
            </div>
          </div>
          <div class="col-lg-5 pr-lg-1">
            <div class="form-group mb-md-0">
              <label class="mb-0">Status</label>
              <select
                class="form-select my-md2 my-lg-0"
                placeholder="Search by status"
                v-model="searchData.status"
              >
                <option value="All">All</option>
                <option value="ACTIVE">Active</option>
                <option value="INACTIVE">Inactive</option>
              </select>
            </div>
          </div>
          <div class="col-12 col-lg-2 align-self-end">
            <span
              type="button"
              @click="OnSearchButtonClicked()"
              class="save_btn w-100"
            >
              Search
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="ibox-body">
      <div class="ibox-title style_2 d-flex justify-content-between">
        <h5>Role Lists</h5>
        <div
          class="position-relative align-self-center"
          v-if="!roleprivileges || roleprivileges.includes('Roles Create Role')"
          aria-label="Create role"
          tabindex="0"
        >
          <img
          width="20"
            class="cursor-pointer"
            src="../../assets/img/create.png"
            alt="Create role"
            @click="editCreateRole(0)"
          />
          <div class="tool-tip">Create Role</div>
        </div>
      </div>
      <div class="ibox-content">
        <div class="table-responsive">
          <table class="table table-striped table-bordered dataTables">
            <thead>
              <tr>
                <th
                  class="sort_block"
                  @click="SortSelected('role_Name', 1)"
                  id="1"
                >
                  
                  <div class="d-flex flex-row justify-content-between">   
                    Role Name           
                    <span>
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span>
                  </div>
                </th>
                <!-- <th>Role Name</th> -->

                <th
                  class="sort_block"
                  @click="SortSelected('description', 2)"
                  id="2"
                >
                  <div class="d-flex flex-row justify-content-between"> 
                    Role Description             
                    <span>
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span>
                  </div>
                </th>
                <!-- <th>Role Description</th> -->
                <th class="text-center">Active</th>
                <th
                  class="text-center"
                  v-if="
                    !roleprivileges || roleprivileges.includes('Roles Edit')
                  "
                >
                  Edit
                </th>
                <th
                  class="text-center"
                  v-if="
                    !roleprivileges || roleprivileges.includes('Roles Delete')
                  "
                >
                  Delete
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="role in roleLists" :key="role.id">
                <td>{{ role.role_Name }}</td>
                <td>{{ role.description }}</td>
                <td class="text-center">
                  <div class="i-checks">
                    <input
                      type="checkbox"
                      v-model="role.active"
                      onclick="return false"
                    />
                  </div>
                </td>
                <td
                  class="text-center"
                  v-if="
                    !roleprivileges || roleprivileges.includes('Roles Edit')
                  "
                >
                  <i
                    class="fa fa-edit edit-delete"
                    @click="editCreateRole(role.id)"
                  ></i>
                </td>
                <td
                  class="text-center"
                  v-if="
                    !roleprivileges || roleprivileges.includes('Roles Delete')
                  "
                >
                  <i
                    class="fa fa-trash edit-delete"
                    @click="deleteRole(role.id)"
                  ></i>
                </td>
              </tr>
            </tbody>
          </table>
          <!-- pagination -->
          <div class="row mx-0">
            <div class="col-lg-12 mx-0 px-0">
              <div
                class="
                  dataTables_paginate
                  paging_simple_numbers
                  pagination
                  float-right
                "
              >
                <div class="col-lg-12 d-flex justify-content-lg-end px-0">
                  <v-pagination
                    v-model="this.currentPage"
                    :pages="totalPage"
                    :range-size="0"
                    active-color="#618bd7"
                    @update:modelValue="loadPage"
                  />
                </div>
              </div>
            </div>
          </div>
          <!-- pagination ends -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import axios from "axios";
import store from "../../store/index";

import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";

export default {
  name: "roleList",
  components: {
    VPagination,
  },
  data() {
    return {
      roleprivileges: "",
      searchData: {
        roleId: "",
        rolename: "",
        status: "",
        pageNo: 1,
        pageSize: 10,
      },
      totalPage: 1,
      currentPage: 1,

      sortDesc: "",
      SortOrder: 1,
      SortProperty: "",

      totalCount: 0,
      pageindex: 1,
      roleLists: "",
      baseapi: process.env.VUE_APP_Service_URL,
    };
  },
  async mounted() {
    window.addEventListener("studyIdChanged", (event) => {
      console.log("StudyIDChanged into" + event.detail.storage);
      this.idToken = store.getters.getIdToken;
      this.getRoles();
    });
    await this.setroleprivileges();
    this.idToken = store.getters.getIdToken;
    this.headerValues = {
      headers: {
        Authorization: "Bearer " + this.idToken,
        "Content-Type": "application/json",
      },
    };
    await this.getRoles();
  },
  methods: {
    async setroleprivileges() {
      this.roleprivileges = await store.getters.getRolesprivilegeData;
      console.log(this.roleprivileges);
    },
    pageChange(page) {
      this.page = page;
      console.log(page);
    },
    rangeChange(start, end) {
      console.log(start, end);
    },
    async getRoles() {
      var isDes = true;
      isDes = this.SortOrder == 0 ? true : false;
      console.log("IS Descending is ", isDes);
      await axios
        .get(
          `${this.baseapi}/account-core/roles/search?rolename=` +
            this.searchData.rolename +
            "&status=" +
            this.searchData.status +
            "&pageNo=" +
            this.searchData.pageNo +
            "&pageSize=" +
            this.searchData.pageSize +
            "&api-version=1" +
            "&sortProperty=" +
            this.SortProperty +
            "&isDes=" +
            isDes,
          this.headerValues
        )
        .then((res) => {
          console.log("result", res.data);
          this.roleLists = res.data.data;
          this.totalCount = res.data.count;
          this.totalPage = parseInt(this.totalCount / this.searchData.pageSize);
          if (this.totalCount % this.searchData.pageSize != 0) {
            this.totalPage = this.totalPage + 1;
          }
          console.log("roles..", this.roleLists);
        })
        .catch((err) => {
          console.log("error..", err);
        });
    },
    async OnSearchButtonClicked() {
      this.searchData.pageNo = 1;
      this.currentPage = 1;
      this.searchRoles();
    },
    SortSelected(sortProp, id) {
      console.log("SortPropChanged");
      const selectedElement = document.getElementById(id);
      const selectedElementID = document.getElementById(id).id;
      if (sortProp != null && id == selectedElementID) {
        this.sortDesc = !this.sortDesc;
        if (this.SortOrder == 0) {

          // if (selectedElement.classList.contains("sortDesc")) {
          //   return;

          // } 
           if (selectedElement.classList.contains("sortAsc")) {
            selectedElement.classList.remove("sortAsc");
            selectedElement.classList.add("sortDesc");
          } else selectedElement.classList.add("sortDesc");
        } else {
          if (selectedElement.classList.contains("sortDesc")) {
            selectedElement.classList.remove("sortDesc");
            selectedElement.classList.add("sortAsc");
          } 
          // else if (selectedElement.classList.contains("sortAsc")) {
          //   return;

          // } 
          else selectedElement.classList.add("sortAsc");
        }
      }
      this.pageindex = 1;
      this.currentPage = 1;
      this.SortProperty = sortProp;
      this.SortOrder = this.SortOrder == 0 ? 1 : 0;
      this.searchRoles();
    },
    async searchRoles() {
      if (this.searchData.status == "All") {
        this.searchData.status = "";
      }
      var isDes = true;
      isDes = this.SortOrder == 0 ? true : false;
      console.log("IS Descending is ", isDes);
      await axios
        .get(
          `${this.baseapi}/account-core/roles/search?rolename=` +
            this.searchData.rolename +
            "&status=" +
            this.searchData.status +
            "&pageNo=" +
            this.searchData.pageNo +
            "&pageSize=" +
            this.searchData.pageSize +
            "&api-version=1" +
            "&sortProperty=" +
            this.SortProperty +
            "&isDes=" +
            isDes,

          this.headerValues
        )
        .then((res) => {
          this.roleLists = res.data.data;
          this.totalCount = res.data.count;
          this.totalPage = 1 + this.totalCount / this.searchData.pageSize;
          console.log("search result role..", this.roleLists);
        })
        .catch((err) => console.log("error..", err));
      console.log("in search");
    },
    async getnextpage() {
      console.log("NextPage");
      console.log(this.totalCount);
      console.log(this.totalCount / 10 > this.searchData.pageNo);
      if (this.totalCount / 10 > this.searchData.pageNo) {
        this.searchData.pageNo++;
        await this.getRoles();
      }
    },
    async getpreviouspage() {
      console.log("PreviousPage");
      if (this.searchData.pageNo > 1) {
        this.searchData.pageNo--;
      }
      await this.getRoles();
    },
    editCreateRole(id) {
      this.$router.push(`/manageRole/${id}`);
    },
    async deleteRole(id) {
      console.log("in delete");
      console.log(id);
      this.searchData.roleId = id;
      console.log("thisssssssssssss", this.searchData.roleId);
      if (confirm("Are you sure you want to delete this role?")) {
        await axios
          .delete(
            `${this.baseapi}/account-core/roles/delete`,
            {
              data: {
                role_Id: this.searchData.roleId,
              },
            },
            this.headerValues
          )
          .then((res) => {
            console.log("deleted...", res);
            alert(res.data);
            this.searchRoles();
          })
          .catch((err) => {
            console.log("error...", err);
            alert("Roles assigned to users cannot be deleted");
          });
      } else {
        console.log("else");
      }
    },
    updateHandler() {
      console.log("update handler");
    },
    loadPage: function (page) {
      this.currentPage = page;
      this.searchData.pageNo = page;
      this.getRoles();
    },
  },
};
</script>

<style>
@import "../../assets/style.css";
@import "../../assets/formpage.css";
.v-pagination.li {
  color: red;
}
/*pagination styles*/
.pagination {
  margin: 0;
}
.Page,
.PaginationControl {
  border: 1px solid #e7eaec;
}
.Page-active {
  padding: 1rem;
  color: #ffffff;
  border: 1px solid var(--pagination-active-clr);
  border-radius: inherit;
  margin: 0;
}
.Page {
  padding: 1rem;
  border-radius: inherit;
  margin: 0;
}
.PaginationControl {
  padding: 0.42rem;
}
.create-block {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0.25rem;
}
.form-select {
  border-radius: 0 !important;
}
.form-select:focus {
  border-color: #145faf !important;
  box-shadow: 0 0 0 !important;
}

.cursor-pointer {
  cursor: pointer;
}
.position-relative {
  position: relative;
}
.position-relative .tool-tip {
  visibility: hidden;
  width: max-content;
  background-color: #616161;
  color: #fff;
  text-align: center;
  border-radius: 0.3em;
  padding: 0.3rem 0.8rem;
  right: 0;
  top: -30px;
  font-size: 0.8rem;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  -webkit-transition: visibility 75ms ease-in;
  transition: visibility 75ms ease-in;
}
.position-relative:hover .tool-tip,
.position-relative:focus-visible .tool-tip {
  visibility: visible;
  -webkit-transition: visibility 30ms ease-in;
  transition: visibility 30ms ease-in;
}
</style>