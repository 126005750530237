<template>
    <!-- testwrapper -->
    <!-- <div v-if="defaultdashboard != 'home'" class="row wrapper border-bottom white-bg page-heading">
<div class="col-lg-12 text-right">
<h2><button type="button" class="btn btn-outline-success fontprop" @click="setasdefault()">Set as default</button></h2>
</div>
</div> -->
<!-- testwrapper -->
  <div class="row wrapper border-bottom white-bg page-heading">
      <div class="col-lg-10 align-self-center">
          <h2 class="mt-0">{{ $t('navigations.main dashboard')}}</h2>
      </div>
            <div class="col-lg-2 text-end align-self-center" v-if="defaultdashboard != 'home'">
        <button type="button" class="btn btn-insights fontprop" @click="setasdefault()">{{ $t('navigations.set as default')}}</button>
      </div>
  </div>
  <div class="wrapper wrapper-content">
    <div class="row">   
      <div class="col-lg-3 mb-1 pr-0 pl-1">
        <div class="dash-widget cursor-pointer" @click.prevent="$router.push('/listform')">
            <div class="row">
              <div class="col-8 text-left">
                  <span> <h4>{{ $t('navigations.new forms')}}</h4></span>
                      <h1 class="font-bold">{{formcountstd}}</h1>
              </div>
              <div class="col-4 justify-content-center align-self-center">
                  <img src="../../assets/img/db_newforms.png" alt="New Forms">
              </div>
            </div>
        </div>
      </div>  
      <div class="col-lg-3 mb-1 pr-0 pl-1">
        <!-- <div class="ibox-body">
          <div class="ibox-title style_2 d-flex justify-content-between">
              <div class="ibox-tools">
                <img src="../../assets/img/total_users.png" alt="Total Users">
              </div>
              <h5>{{ $t('navigations.total users')}}</h5>
          </div>
          <div class="ibox-content">
              <h1 class="no-margins">{{userscountstd}}</h1>
              <small>{{ $t('navigations.total users')}}</small>
          </div>
        </div> -->
        <div class="dash-widget cursor-pointer" @click.prevent="$router.push('/StudyBasedUserList')">
        <div class="row">
            <div class="col-8 text-left">
              <span> <h4>{{ $t('navigations.total users')}}</h4></span>
              <h1 class="font-bold">{{userscountstd}}</h1>
            </div>
              <div class="col-4 justify-content-center align-self-center">
              <img src="../../assets/img/db_totalusers.png" alt="Total Users">
            </div>
        </div>
        </div>  
    </div>
      <div class="col-lg-3 mb-1 pr-0 pl-1">
        <!-- <div class="ibox-body">
          <div class="ibox-title style_2 d-flex justify-content-between">
              <div class="ibox-tools">
                <img src="../../assets/img/total_sites.png" alt="Total Sites">
              </div>
              <h5>{{ $t('navigations.total sites')}}</h5>
          </div>
          <div class="ibox-content">
              <h1 class="no-margins">{{sitecountstd}}</h1>
          <small>{{ $t('navigations.total sites')}}</small>  
          </div>
        </div> -->
        <div class="dash-widget cursor-pointer" @click.prevent="$router.push('/sitelist')">
        <div class="row">
            <div class="col-8 text-left">
              <span> <h4>{{ $t('navigations.total sites')}}</h4></span>
              <h1 class="font-bold">{{sitecountstd}}</h1>
            </div>
              <div class="col-4 justify-content-center align-self-center">
                <img src="../../assets/img/db_totalsites.png" alt="Total Sites">
            </div>
        </div>
      </div>  
      </div>  
      <div class="col-lg-3 mb-1 pr-0 pl-1">
        <!-- <div class="ibox-body">
          <div class="ibox-title style_2 d-flex justify-content-between">
              <div class="ibox-tools">
                <img src="../../assets/img/total_patients.png" alt="Total Patients">
              </div>
              <h5>{{ $t('navigations.total subjects')}}</h5>
          </div>
          <div class="ibox-content">
              <h1 class="no-margins">{{patientscountstd}}</h1>
              <small>{{ $t('navigations.total subjects')}}</small>
          </div>
        </div> -->
        <div class="dash-widget cursor-pointer" @click.prevent="$router.push('/patientlist')">
        <div class="row">
            <div class="col-8 text-left">
              <span> <h4>{{ $t('navigations.total subjects')}}</h4></span>
              <h1 class="font-bold">{{patientscountstd}}</h1>
            </div>
              <div class="col-4 justify-content-center align-self-center">
                <img src="../../assets/img/db_totalsubjects.png" alt="Total Sites">
            </div>
        </div>
      </div>  
      </div>   
        <!-- {{sendData.data.datasets[0].data}} and {{sendData.data.datasets[1].data}} and queries {{seconsData.data.datasets[0].data}} -->
        <div class="col-lg-6 pr-0 pl-1">
            <div class="ibox-body">
                <div class="ibox-title style_2 d-flex justify-content-between py-1">
                  <h5 class="mt-2">{{ $t('navigations.form activities')}}</h5>
                  <div class="graphbtncontainer">
                    <div class="graphbutton"><img src="../../assets/images/bar_clr.png" @click.prevent="firstchartchange('bar')"></div>
                    <div class="graphbutton"><img src="../../assets/images/horiz_bar_clr.png" @click.prevent="firstchartchange('barhorizondal')"></div>
                    <div class="graphbutton"><img src="../../assets/images/Line_clr.png" @click.prevent="firstchartchange('line')"></div>
                    <div class="graphbutton"><img src="../../assets/images/Pie_clr.png" @click.prevent="firstchartchange('pie')"></div>
                    <div class="graphbutton"><img src="../../assets/images/bubble_clr.png" @click.prevent="firstchartchange('scatter')"></div>
                  </div>
                </div>
                <div class="ibox-content">
                    <div>
                      <apexchart v-if="firstchart.charttype != 'pie' && firstchart.charttype != 'barhorizondal'" height="250px" width="100%" :type="firstchart.charttype" :options="firstchart.chartOptions" :series="firstchart.dataseries"></apexchart>
                      <apexchart v-if="firstchart.charttype == 'barhorizondal'" height="250px" width="100%" type="bar" :options="firstbarchart.chartOptions" :series="firstbarchart.dataseries"></apexchart>
                      <apexchart v-if="firstchart.charttype == 'pie'" height="250px" width="100%" :type="firstchart.charttype" :options="firstpiechartoptions" :series="firstchart.piedataseries"></apexchart>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-lg-6 pr-0 pl-1">
            <div class="ibox-body">
                <div class="ibox-title style_2 d-flex justify-content-between py-1">
                  <h5 class="mt-2">{{ $t('navigations.queries')}}</h5>
                  <div class="graphbtncontainer">
                    <div class="graphbutton"><img src="../../assets/images/bar_clr.png" @click.prevent="secondchartchange('bar')"></div>
                    <div class="graphbutton"><img src="../../assets/images/horiz_bar_clr.png" @click.prevent="secondchartchange('barhorizondal')"></div>
                    <div class="graphbutton"><img src="../../assets/images/Line_clr.png" @click.prevent="secondchartchange('line')"></div>
                    <div class="graphbutton"><img src="../../assets/images/Pie_clr.png" @click.prevent="secondchartchange('pie')"></div>
                    <div class="graphbutton"><img src="../../assets/images/bubble_clr.png" @click.prevent="secondchartchange('scatter')"></div>
                  </div>
                </div>
                <div class="ibox-content">
                    <div>
                      <apexchart v-if="secondchart.charttype != 'pie'" height="250px" width="100%" :type="secondchart.charttype" :options="secondchart.chartOptions" :series="secondchart.dataseries"></apexchart>
                      <apexchart v-if="secondchart.charttype == 'pie'" height="250px" width="100%" :type="secondchart.charttype" :options="secondchart.piechartOptions" :series="secondchart.piedataseries"></apexchart>
                        <!-- <canvas id="barChart" height="140"></canvas> -->
                    </div>
                </div>
            </div>
        </div>
      <!--new form table-->
      <div class="col-lg-12 my-1 pl-1 pr-0">
        <div class="ibox-body">
          <div class="ibox-title style_2">
            <h5 class="float-left">{{ $t('navigations.recent forms')}}</h5>
          </div>
          <div class="ibox-content">
            <div class="table-responsive">
                <table class="table table-striped table-bordered dataTables" >
                    <thead>
                    <tr>
                        <th>{{ $t('navigations.site code')}}</th>
                        <th>{{ $t('navigations.subject id')}}</th>
                        <th>{{ $t('navigations.visit name')}}</th>
                        <th>{{ $t('navigations.status')}}</th>
                        <th>{{ $t('navigations.uploaded by')}}</th>
                        <th>{{ $t('navigations.uploaded date')}}</th>
                        <th class="text-center" v-if="roleprivileges.includes('Forms View Form')">{{ $t('navigations.view')}}</th>
                    </tr>
                    </thead>

                    <tbody>
                        <tr  v-for="list in formlists" :key="list.formId">
                            <td>{{list.siteId}}</td>
                            <td>{{list.subjectId}}</td>
                            <td>{{list.visitName}}</td>
                            <td>{{list.formStatus}}</td>
                            <td>{{list.createdUserName}}</td>
                            <td>{{filter(list.createdAt)}}</td>
                            <td class="text-center" v-if="roleprivileges.includes('Forms View Form')"><i class="fa fa-eye editicon" @click.prevent="viewforms(list.formId)"></i></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
      </div>
            <!--new form table-->
      </div>


    </div>
    
  </div>
</template>

<script>
/* eslint-disable */
import axios from "axios";
import "/src/assets/css/animate.css";
import "/src/assets/css/style.css";
import { useStore } from "vuex";
import moment from "moment";
import Chart from "chart.js";
import jwt_decode from "jwt-decode";
import store from "@/store";
// let testarray= [];
export default {
  name: "formDashBoard",
  async mounted() {
    this.defaultdate = localStorage.getItem("dateformat");
    this.defaultTime = localStorage.getItem("timeformat");
    console.log("in mounted",  this.defaultdate);
    window.addEventListener("studyIdChanged", async (event) => {
      this.defaultdashboard = store.getters.getdefaultdashboard;
      this.roleprivileges = await store.getters.getRolesprivilegeData;
      this.valnow = await store.getters.getStudyIs;
      this.getcount();
      this.GetFormData();
      await this.GetChartData();
      await this.GetqueryChartData();
      this.sndcharts.update();
      
    });
    this.roleprivileges = await store.getters.getRolesprivilegeData;
    this.defaultdashboard = store.getters.getdefaultdashboard;
    this.sndcharts = null;
    // await this.loadstudy();
    this.valnow = await store.getters.getStudyIs;
    this.getcount();
    this.GetFormData();
    this.GetChartData();
    this.GetqueryChartData();
    const snd = document.getElementById("lineChart");
    this.sndcharts = new Chart(snd, {type: "bar", data: this.sendData, options: this.options});
    this.sndcharts;
  },
  data() {
    return {
      defaultdate: "",
      defaultTime :"",
      defaultdashboard: "",
      roleprivileges:"",
      firstpiechartoptions: {
            'chart': {
              'width': '100%',
              'type': 'pie',
            },
            'labels': [this.$t('navigations.new/unverified forms'), this.$t('navigations.verified forms')],
            'responsive': [{
              'breakpoint': 480,
              'options': {
                pie: {
      size: 200
    },
                'chart': {
                  'toolbar': {
                      'show': false
                    },
                  'width': 200
                },
                'legend': {
                  'position': 'bottom'
                }
              }
            }]
          },
          //horizondalbarchart first starts
          firstbarchart: {
        charttype: 'bar',
      chartOptions: {
        stroke: {
            width: 2
                },
          chart: {
            height: '100%',
            width: '100%',
            id: 'firstchart-example',
          },
          dataLabels: {
          enabled: false,
        },
          plotOptions: {
              bar: {
                horizontal: true,
              }
            },
          xaxis: {
            categories: [],
          },
        },
        series: [{
          data: [0, 0, 0, 0 ]
        }],
        dataseries: "",
      },
           //horizondalbarchart first ends
      firstchart: {
        charttype: 'line',
      chartOptions: {
        stroke: {
            width: 2
                },
          chart: {
             height: '100%',
              width: '100%',
            id: 'firstchart-example',
          },
          plotOptions: {
              bar: {
                borderRadius: 4,
                horizontal: false,
              }
            },
          xaxis: {
            categories: [],
          }
        },
        series: [{
          data: [0, 0, 0, 0 ]
        }],
        dataseries: "",
        piechartOptions: '',
        piedataseries: [2,3,2,1]
      },
      secondchart: {
        charttype: 'line',
      chartOptions: {
        stroke: {
            width: 2
                },
          chart: {
            height:"100px",
            toolbar: {
                show: false
              },
            id: 'vuechart-example',
          },
          dataLabels: {
          enabled: false,
        },
          plotOptions: {
              bar: {
                borderRadius: 4,
                horizontal: false,
              }
            },
          xaxis: {
            categories: [this.$t('navigations.open'), this.$t('navigations.closed')],
          },     
          },
        series: [{
          name: this.$t('navigations.queries'),
          data: [0, 0]
        }],
        dataseries: "",
        piechartOptions: {
            chart: {
              width: '100%',
              type: 'pie',
            },
            labels: [this.$t('navigations.open'), this.$t('navigations.closed')],
            responsive: [{
              breakpoint: 380,
              options: {
                chart: {
                  toolbar: {
                      show: false
                    },
                  width: 100
                },
                legend: {
                  position: 'bottom'
                }
              }
            }]
          },
        piedataseries: [2,3]
        },
        
      activeformcount: "",
      patientscountstd: "",
      sitecountstd: "",
      userscountstd: "",
      formcountstd: "",
      querycountstd: "",
      formlists: [],
      pageNumber: 1,
      currentStudy: "",

      userId: "",
      baseUrl: `${process.env.VUE_APP_Service_URL}/management/study/`,
      baseapi: process.env.VUE_APP_Service_URL,
      studies: "",
      valnow: "",
      sndcharts: "",
      secndcharts: "",
      senddatacoppy: "",
      seconsdatacoppy: "",
      sndoptions: {
            scales: {
                       y: {
                         beginAtZero: true
                          }
                    }
               },
      options: {
            scales: {
                       y: {
                         beginAtZero: true
                          }
                    }
               },
    };
  },
  methods: {
    async setasdefault(){
      // alert("set as default");
        const idtoken = store.getters.getIdToken;
        let userIdfordefault = {
          "userId": this.jwtDecrypt(idtoken).sub,
          "email": jwt_decode(idtoken).email,
          "defaultDashBoard": "home"
                                };
        await axios
          .put(`${this.baseapi}/account-core/settings/userdashboard`,
          userIdfordefault, 
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          })
          .then((res) => {
            store.dispatch("setdefaultdashboard", "home");
            alert(this.$t("navigations.default dashboard updated successfully"));
            this.defaultdashboard = store.getters.getdefaultdashboard;
          })
          .catch((err) => {
            console.log(err);
          });
    },
    async firstchartchange(val) {
        this.firstchart.charttype = val;
      },
    async secondchartchange(val) {
      if(val == "barhorizondal"){
        await this.switchbar(true);
        this.secondchart.charttype = 'bar';
      }
      else {
        await this.switchbar(false);
        this.secondchart.charttype = val;
      }
    },
    async switchfirstbar(res){
      this.firstchart.chartOptions.plotOptions = {'bar': { 'horizontal': res}};
      this.firstchart.charttype = '';
    },
    async switchbar(res){
      this.secondchart.chartOptions.plotOptions = {'bar': { 'horizontal': res}};
      this.secondchart.charttype = '';
    },
   
    async GetqueryChartData() {
      const idtoken = store.getters.getIdToken;
      const currentstudyid = await store.getters.getStudyIs;
      await axios
        .get(
          `${this.baseapi}/forms/query/getallcounts?studyId=${currentstudyid}`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log("Secondchart date",res.data);
          this.secondchart.dataseries = [{  
            name:this.$t('navigations.queries'),
            data:[
                res.data.openQuries,
                res.data.closedQuries
              ]
          }];
          this.secondchart.piedataseries = this.secondchart.dataseries[0].data;
        })
        .catch((err) => {
          console.log(err);
        });
      this.secondchart.charttype="pie"
    },
    async GetChartData() {
      const idtoken = store.getters.getIdToken;
      const currentstudyid = await store.getters.getStudyIs;
      await axios
        .get(
          `${this.baseapi}/forms/forms/getweeklyformscount?studyId=${currentstudyid}&api-version=1.0`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then(async (res) => {
          console.log(res);
          // this.sendData.data.labels = res.data.lastweekDates;
          await this.trimdates(res.data.lastweekDates);
          this.firstchart.dataseries = [{
            'name': this.$t('navigations.new/unverified forms'),
            'data': res.data.newformscount
          },{
            'name': this.$t('navigations.verified forms'),
            'data':  res.data.verifiedformscount
          }
        ];
        this.firstbarchart.dataseries = [{
            'name': this.$t('navigations.new/unverified forms'),
            'data': res.data.newformscount
          },{
            'name': this.$t('navigations.verified forms'),
            'data':  res.data.verifiedformscount
          }
        ];
        let newforms = '';
        let verifiedforms = '';
        sumis(res.data.newformscount);
        sumofverifiedis(res.data.verifiedformscount);
        async function sumis(array){
          let sum = 0;
          for (let i = 0; i < array.length; i++) {
        sum += array[i];
        }
          newforms = sum;
        };
        async function sumofverifiedis(array){
          let sum = 0;
          for (let i = 0; i < array.length; i++) {
        sum += array[i];
        }
          verifiedforms = sum;
        };
        this.firstchart.piedataseries =[newforms, verifiedforms];
        })
        .catch((err) => {
          console.log(err);
        });
        this.firstchart.charttype = 'bar';
    },
    async sumofarray(array){
      let sum = 0;
      for (let i = 0; i < array.length; i++) {
    sum += array[i];
    }
    console.log("sum is " + sum);
      return sum;
    },
    async trimdates(arrayofdates) {
      // console.log(arrayofdates);
      let trimmeddates = [];
      await arrayofdates.forEach((element) => {
        trimmeddates.push(this.filter(element.slice(0, 10)));
      });
      // console.log(trimmeddates);
      this.firstchart.chartOptions.xaxis = {
            'categories': trimmeddates,
          };
        this.firstbarchart.chartOptions.xaxis = {
            'categories': trimmeddates,
          };

        // this.firstchart.piechartOptions.labels=  [`verified (${trimmeddates[6]} to ${trimmeddates[0]})`, `Unverified (${trimmeddates[6]} to ${trimmeddates[0]})`];
    },
    jwtDecrypt(token) {
      const base64Url = token.split(".")[1];
      const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );
      return JSON.parse(jsonPayload);
    },
    async getcount() {
      const idtoken = store.getters.getIdToken;
      this.valnow = await store.getters.getStudyIs;
      console.log("current study value is " + this.valnow);
      this.getformcount(this.valnow, idtoken);
      this.getsitecount(this.valnow, idtoken);
      this.getpatientscount(this.valnow, idtoken);
      this.getuserscount(this.valnow, idtoken);
      this.getactiveformcount(this.valnow, idtoken);
      //patientscountstd
    },
    async getpatientscount(currentstudyid, idtoken) {
      await axios
        .get(
          `${this.baseapi}/management/patient/getpatientcountinstudy?StudyId=${currentstudyid}`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.patientscountstd = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getactiveformcount(currentstudyid, idtoken) {
      await axios
        .get(
          `${this.baseapi}/forms/forms/activecounts?studyId=${currentstudyid}`,
          {
            headers: {
              "Access-Control-Allow-Origin": 'localhost:8080', 
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.activeformcount = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getsitecount(currentstudyid, idtoken) {
      await axios
        .get(
          `${this.baseapi}/management/site/getsitescount?studyId=${currentstudyid}`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.sitecountstd = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getuserscount(studyid, idtoken) {
      await axios
        .get(
          `${this.baseapi}/management/study/getusercountinstudy?StudyId=${studyid}`,
          {
            headers: {
             "Access-Control-Allow-Origin": 'localhost:8080', 
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.userscountstd = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  
    async getformcount(currentstudyid, idtoken) {
      await axios
        .get(
          `${this.baseapi}/forms/forms/getcounts?studyId=${currentstudyid}`,
          {
            headers: {
              "Access-Control-Allow-Origin": 'localhost:8080', 
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.formcountstd = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async studychange(val) {
      // store.commit(setStudy,val);
      await store.dispatch("setStudyID", val);
      await this.getcount();
      await this.GetFormData();
      await this.GetChartData();
      await this.GetqueryChartData();
    },
    // loadstudy function
    filter(data){
      if(data){
          return moment(data).format(this.defaultdate)
      }
    },
    async viewforms(id) {
        const fromArchive = false
      this.$router.push(`/showform/home/${id}/${fromArchive}`);
    },
    async GetFormData() {
      const idtoken = store.getters.getIdToken;
      const stdid = store.getters.getStudyIs;
      await axios
        .get(`${this.baseapi}/forms/forms/getrecentforms?studyId=${stdid}`, {
          headers: {
           "Access-Control-Allow-Origin": 'localhost:8080', 
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          if (response.data) {
            this.formlists = response.data;
            console.log("table data", this.formlists);
          } else {
            console.log("no forms found");
          }
        })
        .catch((err) => {
          console.log(err);
          this.enableNext = false;
          this.enablePrevious = false;
        });
    },
    // loadstudy function
    async loadstudy() {
      const idtoken = store.getters.getIdToken;
      const usermail = jwt_decode(idtoken).email;
      await axios
        .get(`${this.baseapi}/management/study/getallstudy?user=${usermail}`, {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          this.studies = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
      this.valnow = store.getters.getStudyIs;
    },
  },
};
</script>

<style scoped>
.widget {
  margin-top: 0rem;
  margin-bottom: 0rem;
}
.widgetmargin {
  margin-top: -13px !important;
}

.style1 {
  background-color: black !important;
  color: #ffffff;
}
.btn-view {
  color: #fff !important;
  background-color: #bb458f !important;
  border-color: #bb458f !important;
}
.btn-view:hover {
  color: #fff;
  background-color: #666ccb;
  border-color: #666ccb;
}
.btn-view:active {
  color: rgb(255, 255, 255) !important;
  background-color: #dda2c7 !important;
  border-color: #dda2c7 !important;
}

.titlemsg {
  border-color: #e7eaec;
  margin-bottom: 0;
  padding: 20px 20px;
  position: relative;
  clear: both;
  right: 0;
  margin-right: 0px;
  margin-left: 0px !important;
  margin-top: 0px !important;
  padding-left: 10px;
}
.padding_bottom {
  padding-bottom: 2rem;
}
.ibox-title h5 {
  height: 0rem;
  padding: 0rem;
}
.ibox-title h5:hover {
  background-color: inherit;
  padding: 0;
  cursor: not-allowed;
}
.titlemsg {
  font-size: 15px;
  font-weight: bold;
  color: var(--primary-color);
}
.switchbtn {
  background-color: #bb458f !important;
  height: max-content;
  border-color: #bb458f !important;
  color: white !important;
  float: right;
}
.switchbtn:active {
  height: max-content;
  border-color: #bb458f !important;
  color: white !important;
  float: right;
  background-color: #bb458f;
}
.switchbtn:hover {
  height: max-content;
  border-color: #bb458f !important;
  color: white !important;
  float: right;
  background-color: #bb458f;
}
.queryhover:hover {
  color: #3c4192 !important;
  -webkit-transition: color ease-in 400ms;
  transition: color ease-in 400ms;
  cursor: pointer;
}

.widgetminheight {
  min-height: 102px;
}
.graphbutton{
width: fit-content;
padding: 3px;
cursor:pointer;
}
.graphbtncontainer{
display: flex;
gap: 5px;
}
img{
  margin-left: 0px !important;
}
.selectdefault{
  left: 0;
}
.btn:focus {
  outline: none;
  box-shadow: none;
}
.btn-setdefault {
  position: relative;
  padding:0.2rem 0.4rem;
  text-align: center;
  font-size:0.7rem;
  background-color: #ec4758;
  color: white !important;
  border-radius:3px;
}
.btn-insights{
  position: relative;
  color: white !important;
  padding: 2px !important;
}
.btn-insights:hover{
  background-color: #f3f4fd;
  padding: 2px !important;

}
.ibox-content h1{
  font-weight:400;
  color:#676a6c
}
</style>
