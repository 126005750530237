<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>{{ $t("navigations.add visit") }}</h2>
    </div>
    <div class="col-lg-2">
      <i class="fa fa-times close my-2" @click="onclosed()"></i>
    </div>
  </div>
  <div class="wrapper wrapper-content">
    <div class="ibox-content p-3">
      <div class="form-group row mb-0">
        <div class="col-lg-4 my-2">
          <label class="col-form-label"
            >Visit No. <sup><i class="fa fa-asterisk imp"></i></sup>
          </label>
          <input
            type="text"
            class="form-control"
            placeholder="Visit No."
            v-model="values.visitNo"
            maxlength="3"
          />
          <span class="errormsg" v-if="errors[0] == false">
            {{ $t("navigations.please enter visit no") }}
          </span>
        </div>
        <div class="col-lg-4 my-2">
          <label class="col-form-label"
            >Visit Name <sup><i class="fa fa-asterisk imp"></i></sup
          ></label>
          <input
            type="text"
            class="form-control"
            placeholder="Visit Name"
            v-model="values.visitFormName"
            maxlength="20"
          />
          <span class="errormsg" v-if="errors[1] == false"
            >Please enter visit/form name</span
          >
        </div>
        <div class="col-lg-4 my-2">
          <label class="col-form-label">Visit Type  <sup><i class="fa fa-asterisk imp"></i></sup
          ></label>
          <select
            class="form-select"
            placeholder="Visit Type"
            v-model="values.visitType"
             valueProp="VisitTypeName"
            
          >
            <option
              v-for="options in visitList"
              :key="options.typename"
            >
            {{options.typename}}
              
            </option>
          </select>
           <span class="errormsg" v-if="errors[2] == false"
            >Please select a visit type</span
          >
        </div>
        <div class="col-lg-4 my-2">
          <label class="col-form-label">Pre-Visit </label>
          <input
            type="text"
            class="form-control"
            placeholder="Pre-visit "
            v-model="values.preVisit"
            maxlength="3"
            @change.prevent="numberonlyvalidation('preVisit')"
          />
          <span class="errormsg" v-if="numerrors['preVisit'] == false"
            >Please enter a number</span
          >
        </div>
        <div class="col-lg-4 my-2">
          <label class="col-form-label">Post-visit </label>
          <input
            type="text"
            class="form-control"
            placeholder="Post-visit "
            v-model="values.postVisit"
            maxlength="3"
            @change.prevent="numberonlyvalidation('postVisit')"
          />
          <span class="errormsg" v-if="numerrors['postVisit'] == false"
            >Please enter a number</span
          >
        </div>
        <!--          <div class="col-lg-4 my-2">
                    <label class="col-form-label"> Visit period </label>
                    <input type="text" class="form-control" placeholder="Visit period " v-model="values.visitPeriod" @change.prevent="numberonlyvalidation('visitPeriod')">
                    <span class="errormsg" v-if="numerrors['visitPeriod'] == false">Please enter a number</span>
                </div>
-->
        <div class="col-lg-4 my-2">
          <label class="col-form-label"> Visit Period(Days) </label>
          <input
            type="number"
            class="form-control"
            placeholder="Visit Period Days"
            v-model="values.visitDayPeriod"
            @input.prevent="limitInputLength('visitDayPeriod', 4)"
            @change.prevent="numberonlyvalidation('visitDayPeriod')"
          />
          <span class="errormsg" v-if="numerrors['visitDayPeriod'] == false"
            >Please enter a number</span
          >
        </div>

        <div class="col-lg-4 my-2">
          <label class="col-form-label"> Visit Period(Weeks) </label>
          <input
            type="number"
            class="form-control"
            placeholder="Visit Period Weeks"
            v-model="values.visitWeekPeriod"
            @input.prevent="limitInputLength('visitWeekPeriod', 4)"
            @change.prevent="numberonlyvalidation('visitWeekPeriod')"
          />
          <span class="errormsg" v-if="numerrors['visitWeekPeriod'] == false"
            >Please enter a number</span
          >
        </div>
        <div class="col-lg-4 my-2">
          <label class="col-form-label"> Visit Period(Months) </label>
          <input
            type="number"
            class="form-control"
            placeholder="Visit Period Months"
            v-model="values.visitMonthPeriod"
            @input.prevent="limitInputLength('visitMonthPeriod', 4)"
            @change.prevent="numberonlyvalidation('visitMonthPeriod')"
          />
          <span class="errormsg" v-if="numerrors['visitMonthPeriod'] == false"
            >Please enter a number</span
          >
        </div>
        <div class="col-lg-4 my-2">
          <label class="col-form-label"> Visit Period(Years) </label>
          <input
            type="number"
            class="form-control"
            placeholder="Visit Period years"
            v-model="values.visitYearPeriod"
            @input.prevent="limitInputLength('visitYearPeriod', 4)"
            @change.prevent="numberonlyvalidation('visitYearPeriod')"
          />
          <span class="errormsg" v-if="numerrors['visitYearPeriod'] == false"
            >Please enter a number</span
          >
        </div> <div class="col-lg-12 my-2">
          <label class="col-form-label">Visit Notes </label>
          <input
            type="text"
            class="form-control"
            placeholder="Visit Notes"
            v-model="values.visitNotes"
          />
          </div>
        <div class="col-md-2 my-2">
          <div class="i-checks">
            <label class="mr-2">Active</label>
            <input type="checkbox" value="" v-model="values.active" />
          </div>
        </div>
        <div class="col-md-2 my-2">
          <div class="i-checkss">
            <label class="mr-2">{{ $t("navigations.patient visit") }}</label>
            <input type="checkbox" :checked="values.visitType == 'Patient Visit'" v-model="values.isPatientVisit" :disabled="values.visitType == 'Patient Visit'"/>
          </div>
        </div>

        <div class="col-lg-12 mb-2 d-flex justify-content-lg-end">
          <button
            class="cancel_btn btn-cor_rad py-1 px-4 mx-3"
            @click.prevent="clear()"
          >
            Clear
          </button>
          <button
            class="save_btn py-1 px-4"
            type="submit"
            @click.prevent="addVisit()"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script src="./addVisitComponent.js"></script>
<style scoped>
@import "../../assets/style.css";
@import "../../assets/formpage.css";
@import "../../assets/savepages.css";
.wrapper-content {
  width: 100%;
}
textarea {
  width: 100%;
}
.imp {
  color: #ff0000;
  font-size: 7px;
}
.impcl {
  color: #ff4747;
  font-size: 25px;
  float: right;
}
.impcl:hover {
  color: #a30202;
  font-size: 25px;
  float: right;
}
.col-form-label {
  font-size: 14px;
}
.address {
  width: 100%;
}
.page-heading {
  display: flex !important;
  justify-content: space-between;
}
.form-select {
  border-radius: 0 !important;
}
.form-select:focus {
  border-color: #145faf !important;
  box-shadow: 0 0 0 !important;
}
</style>
