<template>
    <div class="row wrapper border-bottom white-bg page-heading">
      <div class="col-lg-6 align-self-center">
        <h2>Adverse Events Overlay Labs</h2>
      </div>
      <div class="col-lg-2">
        <i class="fa fa-times close" @click="pageclosed()"></i>
      </div>
    </div>
    <div class="wrapper wrapper-content">
      <div class="row">
            <div class="col-md-12 pl-1 mb-2">
                <historytab
                :historyarray="historykeys"
                @gotohistorylevel="gotohistorylevel"
                />
            </div>
            <div class="col-md-6 mb-2  pl-1">
                <barchart
                :availableColumns="arrayofkeys"
                :loader="loader"
                :data="data"
                :templateId="templateId"
                :tableData="data"
                xAxisKey="AESEV"
                yAxisKey="count"
                :fromPage="'descriptiveStatistics'"
                chartlabel="AE Severity and Lab Test Abnormalities"
                @drilldowncurrentchart="drilldowncurrentchartforbar"
                @opendrillpopup="drillpopupopened"
                />
            </div>
            <div class="col-6 pl-1 mb-2">
                <D3LineChart
                 :data="data"
                 :loader="loader" 
                 :templateId="templateId" wa
                 :chartlabel="'AE Trend with Lab Overlay'" 
                  @drilldowncurrentchart="drilldowncurrentchartforbar"
                 @opendrillpopup="drillpopupopened"
                />
            </div>
            <div class="col-lg-12 mb-1 pr-0 pl-1">  
                <sunburst 
                    :loader="loader"
                    :data="data"
                    fromPage="descriptiveStatistics"
                    labelOne="Possible to Retain"
                    labelTwo="Possible to dropout"
                    title="Adverse Event Lab Test Hierarchy"
                    @drilldowncurrentchart="drilldowncurrentchartforbar"
                    @opendrillpopup="drillpopupopened"
                    /> 
            </div>
            <div class="col-lg-12 pl-1 mb-1">
                <div class="stat-card-white ">
                    <div class="stat-header-white">
                        <span>Patient Distribution</span>
                    </div>
                    <div class="stat-body-white">
                        <advtable 
                            :loader="loader" 
                            :data="data" 
                            fromPage="patientRetention"
                            @drilldowncurrentchart="drilldowncurrentchartforbar" />
                    </div>
                </div>
            </div>
      </div>
    </div>
    <chartdrill 
        v-if="drillpopup" 
        :drilldata="drilldata" 
        :templateId="templateId" 
        :fromPage="'adverseEventsPop'"
        @closepopup="onclosed()" />
</template>

<script src="./AE_OverlayLabsReport.js"></script>

<style>
@import "../../custom_lib/AiCharts/charts.css";
</style>
