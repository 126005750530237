<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>{{ $t("navigations.document approval") }}</h2>
    </div>
  </div>
  <div class="wrapper wrapper-content">
    <div class="ibox-content mb-1 px-3 py-2">
      <div class="form-group row mb-1">
        <div class="d-flex flex-column flex-lg-row my-2 my-lg-0 mb-lg-0">
          <div class="form-group mb-lg-0 w-100 px-1 pl-lg-0">
            <label class="mb-0">{{ $t("navigations.document name") }}</label>
            <input
              type="text"
              class="form-control my-md-2 my-lg-0"
              v-model="searchData.docName"
            />
          </div>
          <div class="col-lg-5 d-flex flex-column pl-lg-2">
            <div class="form-group mb-0">
              <label class="mb-0">{{ $t("navigations.assigned on") }}</label>
              <div class="d-flex mr-0 pr-0">
                <input
                  type="date"
                  max="9999-12-31"
                  class="form-control"
                  v-model="searchData.fromDate"
                />
                <div class="align-self-center px-1">
                  {{ $t("navigations.to") }}
                </div>
                <input
                  type="date"
                  max="9999-12-31"
                  class="form-control"
                  v-model="searchData.toDate"
                />
              </div>
            </div>
          </div>

          <span
            typr="button"
            class="
              col-sm-12 col-lg-2
              save_btn
              my-1 my-lg-0
              mx-lg-1
              align-self-end
            "
            @click="OnSearchButtonClicked()"
          >
            {{ $t("navigations.search") }}
          </span>
        </div>
      </div>
    </div>
    <div class="ibox-body">
      <div class="ibox-title style_2 d-flex justify-content-between">
        <h5 class="mt-1">{{ $t("navigations.document approval list") }}</h5>
      </div>
      <div class="ibox-content">
        <div class="table-responsive">
          <table class="table table-striped table-bordered dataTables">
            <thead>
              <tr>
                <th
                  class="sort_block"
                  @click="SortSelected('documentName', 1)"
                  id="1"
                >
                  <div class="d-flex flex-row justify-content-between">      
                    {{ $t("navigations.document name") }}        
                    <span>
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span>
                  </div>
                </th>
                <th
                  class="sort_block"
                  @click="SortSelected('addedBy', 2)"
                  id="2"
                >
                  <div class="d-flex flex-row justify-content-between"> 
                    {{ $t("navigations.assigned by") }}             
                    <span>
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span>
                  </div>
                  
                </th>
                <th
                  class="sort_block"
                  @click="SortSelected('addedOn', 3)"
                  id="3"
                >
                  <div class="d-flex flex-row justify-content-between">       
                    {{ $t("navigations.assigned date") }}       
                    <span>
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span>
                  </div>
                </th>
                <th
                  class="sort_block"
                  @click="SortSelected('versionNumber', 4)"
                  id="4"
                >
                  <div class="d-flex flex-row justify-content-between">    
                    {{ $t("navigations.version") }}          
                    <span>
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span>
                  </div>
                  
                </th>
                <th class="text-center" v-if="roleprivileges.includes('Document Approval Approve Document')">{{ $t("navigations.approvals") }}</th>
                <th class="text-center" v-if="roleprivileges.includes('Document Approval View Document')">{{ $t("navigations.view") }}</th>
                <th class="text-center" v-if="roleprivileges.includes('Document Approval Download Document')">{{ $t("navigations.download") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="approval in docapprovallist" :key="approval.id">
                <td>{{ approval.documentName }}</td>
                <td>{{ approval.addedBy }}</td>
                <td>{{ filter(approval.addedOn) }}</td>
                <td class="text-center">{{ approval.versionNumber }}</td>
                <td class="text-center" v-if="roleprivileges.includes('Document Approval Approve Document')">
                  <i
                    class="fa fa-check-circle-o edit-delete"
                    @click="
                      openModel(
                        approval.documentId,
                        approval.documentVersionId,
                        approval.versionNumber
                      )
                    "
                  ></i>
                </td>
                <td
                  class="text-center"
                  @click="
                    goToApproval(approval.documentId, approval.versionNumber)
                  "
                  v-if="roleprivileges.includes('Document Approval View Document')"
                >
                  <i class="fa fa-eye edit-delete"></i>
                </td>
                <td class="text-center" v-if="roleprivileges.includes('Document Approval Download Document')">
                  <i
                    class="fa fa-download edit-delete"
                    @click="
                      downloadfile(
                        approval.documentId,
                        approval.documentVersionId
                      )
                    "
                  ></i>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="row mx-0">
            <div class="col-lg-12 mx-0 px-0">
              <div
                class="
                  dataTables_paginate
                  paging_simple_numbers
                  pagination
                  float-right
                "
              >
                <div class="col-lg-12 d-flex justify-content-lg-end px-0">
                  <v-pagination
                    v-model="this.currentPage"
                    :pages="totalPages"
                    :range-size="0"
                    active-color="#f4f9ff"
                    @update:modelValue="loadPage"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- pagin -->
      </div>
      <!-- modal starts -->
      <div v-if="modeldisplay" class="modal__wrapperNew">
        <div class="modal__approver__container">
          <div class="titleclass d-flex justify-content-between py-2 px-3">
            <h4 class="my-0">Approval</h4>
            <div class="cursor-pointer" @click.prevent="closemodall()">
              <i class="fa fa-times"></i>
            </div>
          </div>
          <!-- Popup -->
          <div class="modal__content p-2 animated fadeIn">
            <div
              v-if="approvedstatusData.approvedStatus == 'Pending'"
              class="chat-form"
            >
              <form role="form">
                <div class="form-group">
                  <textarea
                    class="form-control"
                    placeholder="Comment"
                    v-model="commentTextArea"
                  ></textarea>
                </div>
                <div class="d-flex justify-content-end mb-2">
                  <div
                    class="
                      position-relative
                      cursor-pointer
                      align-self-center
                      mr-2
                    "
                  >
                    <img
                      src="../../assets/img/icons/reject.png"
                      alt="Reject"
                      @click="rejectDoc('Approved')"
                    />
                    <div class="tool-tip">Reject</div>
                  </div>
                  <div
                    class="position-relative cursor-pointer align-self-center"
                  >
                    <img
                      src="../../assets/img/icons/approve.png"
                      alt="Approve"
                      @click="ApproveDoc('Rejected')"
                    />
                    <div class="tool-tip">Approve</div>
                  </div>
                </div>
              </form>
            </div>
            <div class="list-group scrollable">
              <ul
                v-for="approve in approverList"
                :key="approve.id"
                class="sortable-list2 connectList agile-list ui-sortable"
                id="todo"
              >
                <li
                  v-if="approve.approvedStatus == 'Approved'"
                  class="success-element ui-sortable-handle"
                  id="task2"
                >
                  <div class="media-body">
                    <medium class="float-right"
                      ><span class="badge badge-primary">{{
                        approve.approvedStatus
                      }}</span>
                    </medium>
                    <strong>{{ approve.approvedByName }}</strong> <br />
                    <small class="text-muted">{{
                      filter(approve.assignedDateTime.slice(0, 10))
                    }}</small>
                    <p class="approver_cmnt_text">
                      {{ approve.comments }}
                    </p>
                  </div>
                </li>
                <li
                  v-if="approve.approvedStatus == 'Rejected'"
                  class="danger-element ui-sortable-handle"
                  id="task2"
                >
                  <div class="media-body">
                    <medium class="float-right">
                      <span class="badge badge-danger">{{
                        approve.approvedStatus
                      }}</span>
                    </medium>
                    <strong>{{ approve.approvedByName }}</strong> <br />
                    <small class="text-muted">{{
                      filter(approve.assignedDateTime.slice(0, 10))
                    }}</small>
                    <p class="approver_cmnt_text">
                      {{ approve.comments }}
                    </p>
                  </div>
                </li>
                <li
                  v-if="approve.approvedStatus == 'Pending'"
                  class="warning-element ui-sortable-handle"
                  id="task2"
                >
                  <div class="media-body">
                    <medium class="float-right">
                      <span class="badge badge-warning">{{
                        approve.approvedStatus
                      }}</span>
                    </medium>
                    <strong>{{ approve.approvedByName }}</strong> <br />
                    <small class="text-muted">{{
                      filter(approve.assignedDateTime.slice(0, 10))
                    }}</small>
                    <p class="approver_cmnt_text">
                      {{ approve.comments }}
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <!--  popup-->
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import store from "../../store/index";
import axios from "axios";
import moment from "moment";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
export default {
  name: "patientList",
  components: {
    VPagination,
  },
  data() {
    return {
      approveFun: false,
      approvedstatusData: [],
      approverversionNo: "",
      approverDocId: "",
      commentTextArea: "",
      modeldisplay: false,
      approverList: "",
      defaultdate: "",
      xlsFile: "",
      currentPage: 1,
      documentVersionId: [],
      validations: [],
      validation: [false],
      baseUrl: `${process.env.VUE_APP_Service_URL}/management/patient/`,
      baseapi: process.env.VUE_APP_Service_URL,
      fileuploadurl: process.env.VUE_APP_File_upload_URL+"/document",
      docapprovallist: "",
      // enablePrevious: true,
      // enableNext: true,
      totalPages: 1,
      fromDate: "",
      toDate: "",
      pageIndex: 1,
      pageSize: 5,
      SortProperty: 0,
      SortOrder: 0,
      currentdocId: "",

      searchData: {
        docName: "",
        pageIndex: 1,
        pageNo: 1,
        pageSize: 10,
        fromDate: "",
        toDate: "",
      },
      requestData: {
        docName: "",
        pageSize: 10,
        pageNo: 1,
        fromDate: "",
        toDate: "",
      },
      totalCount: 0,
      pageindex: 1,
      roleprivileges:""
    }
    
  },
  async mounted() {
    console.clear();
    this.getdocumentapproval();
    this.defaultdate = localStorage.getItem("dateformat");
    const idToken = store.getters.getIdToken;
    this.headerValues = {
      headers: {
        Authorization: "Bearer " + idToken,

        "Content-Type": "application/json",
      },
    };
    window.addEventListener("studyIdChanged", (event) => {
      console.log("StudyIDChanged into" + event.detail.storage);
      this.roleprivileges = store.getters.getRolesprivilegeData;
      this.filter();
      this.getdocumentapproval();
    });
    this.roleprivileges = await store.getters.getRolesprivilegeData;
    this.filter();
  },

  methods: {
    async findApproverStatus() {
      const idtoken = store.getters.getIdToken;
      await axios
        .get(
          `${this.baseapi}/management/document/approvedstatus?documentid=${this.approverDocId}&VersionNo=${this.approverversionNo}`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
              responseType: "blob",
            },
          }
        )
        .then((res) => {
          this.approvedstatusData = res.data;
          console.log("approvedstatusData", this.approvedstatusData);
          console.log(
            "approvedstatusData-approvedStatus",
            this.approvedstatusData.approvedStatus
          );
        })
        .catch((err) => {
          console.log(err);
        });
    },
    SortSelected(sortProp, id) {
      console.log("SortPropChanged", sortProp);
      const selectedElement = document.getElementById(id);
      const selectedElementID = document.getElementById(id).id;
      if (sortProp != null && id == selectedElementID) {
        this.sortDesc = !this.sortDesc;
        if (this.SortOrder == 0) {
          if (selectedElement.classList.contains("sortAsc")) {
            selectedElement.classList.remove("sortAsc");
            selectedElement.classList.add("sortDesc");
          } else selectedElement.classList.add("sortDesc");
        } else {
          if (selectedElement.classList.contains("sortDesc")) {
            selectedElement.classList.remove("sortDesc");
            selectedElement.classList.add("sortAsc");
          } else selectedElement.classList.add("sortAsc");
        }
      }
      this.pageindex = 1;
      this.currentPage = 1;
      this.SortProperty = sortProp;
      this.SortOrder = this.SortOrder == 0 ? 1 : 0;
      this.getdocumentapproval();
    },
    async rejectDoc() {
      if (
        confirm(
          this.$t("navigations.are you sure you want to reject this document?")
        )
      ) {
        const idToken = store.getters.getIdToken;
        await axios
          .put(
            `${this.baseapi}/management/document/approver`,

            {
              documentId: this.approverDocId,
              versionNumber: this.approverversionNo,
              status: "Rejected",
              comments: this.commentTextArea,
            },

            {
              headers: {
                Authorization: "Bearer " + idToken,
                "Content-Type": "application/json",
                responseType: "blob",
              },
            }
          )
          .then((res) => {
            console.log("APPROVER ENTRY", res);
            alert(this.$t("navigations.document rejected"));
            this.modeldisplay = !this.modeldisplay;
            document.body.style.overflowY = "auto";
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    async ApproveDoc() {
      if (
        confirm(
          this.$t("navigations.are you sure you want to approve this document?")
        )
      ) {
        const idToken = store.getters.getIdToken;
        await axios
          .put(
            `${this.baseapi}/management/document/approver`,

            {
              documentId: this.approverDocId,
              versionNumber: this.approverversionNo,
              status: "Approved",
              comments: this.commentTextArea,
            },

            {
              headers: {
                Authorization: "Bearer " + idToken,
                "Content-Type": "application/json",
                responseType: "blob",
              },
            }
          )
          .then((res) => {
            console.log("APPROVER ENTRY", res);
            alert(this.$t("navigations.document approved"));
            this.modeldisplay = !this.modeldisplay;
            document.body.style.overflowY = "auto";
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },

    async closemodall() {
      console.log("close modal");
      this.modeldisplay = !this.modeldisplay;
      document.body.style.overflowY = "auto";
      this.commentTextArea = "";
    },
    async getApproverById(docId, versionId) {
      const idtoken = store.getters.getIdToken;
      console.log("docId", docId);
      console.log("versionId", versionId);
      await axios
        .get(
          `${this.baseapi}/management/document/getdocumentapprover?documentid=${docId}&versionId=${versionId}`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,

              "Content-Type": "application/json",
              responseType: "blob",
            },
          }
        )
        .then((res) => {
          this.approverList = res.data;
          console.log("Docdownld............", this.approverList);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async openModel(docId, versionId, versionNo) {
      document.body.style.overflowY = "hidden";
      this.modeldisplay = !this.modeldisplay;
      console.log("docID", docId);
      console.log("versionID", versionId);
      this.approverDocId = docId;
      this.approverversionNo = versionNo;
      this.getApproverById(docId, versionId);
      this.findApproverStatus();
    },

    goToApproval(documentId, versionNumber) {
      this.$router.push(`/viewdocument/${documentId}/${versionNumber}`);
    },
    async previewFiles(event) {
      this.xlsFile = event.target.files[0];
      console.log(this.xlsFile);
      this.submitFile();
    },
    async folderList(id) {
      await store.dispatch("setStorageId", id);
      const storageId = store.getters.getStorageId;
      console.log("wowwwwwww", storageId);
      this.$router.push(`/document_patient/${id}`);
    },
    pageChange(page) {
      this.page = page;
      console.log(page);
    },
    rangeChange(start, end) {
      console.log(start, end);
    },
    async submitFile() {
      var formData = new FormData();
      formData.append("item", this.xlsFile, this.xlsFile.name);
      console.log(formData);
      const idtoken = store.getters.getIdToken;
      const currentstudy = store.getters.getStudyIs;
      //TODO Change here
      await axios
        .post(
          // `https://localhost:5001/patient/upload?StudyID=${currentstudy}`,
          `${this.baseapi}/management/patient/upload?StudyID=${currentstudy}`,
          formData,
          {
            responseType: "blob",
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          },
          {
            onUploadProgress: (uploadEvent) => {
              console.log("Helllo" + uploadEvent.loaded);
            },
          }
        )
        .then((response) => {
          const blob = new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = "Results";
          link.click();
          URL.revokeObjectURL(link.href);
          window.location.reload();
        })
        .catch((err) => {
          alert(this.$t("navigations.bad request"));
          window.location.reload();
        });
    },
    async setroleprivileges() {
      this.roleprivileges = await store.getters.getRolesprivilegeData;
      console.log(this.roleprivileges);
    },
    filter(data) {
      if (data) {
        return moment(data).format(this.defaultdate);
      }
    },
    async OnSearchButtonClicked() {
      this.searchData.pageNo = 1;
      this.currentPage = 1;
      this.getdocumentapproval();
    },
    async getdocumentapproval() {
      this.docapprovallist = [];
      const idtoken = store.getters.getIdToken;

      this.requestData.docName = this.searchData.docName;
      this.requestData.fromDate = this.searchData.fromDate;
      this.requestData.toDate = this.searchData.toDate;
      this.validateDate(this.searchData.fromDate, this.searchData.toDate);
      if (this.validation.includes(true)) {
        alert(this.$t("navigations.from date should be less than to date"));
      } else {
        await axios
          .get(
            `${this.baseapi}/management/document/getapproverdocumentlist?docname=${this.requestData.docName}&FromDate=${this.requestData.fromDate}&ToDate=${this.requestData.toDate}&pageindex=${this.searchData.pageNo}&pagesize=${this.searchData.pageSize}&SortProperty=${this.SortProperty}&SortOrder=${this.SortOrder}`,
            {
              headers: {
                Authorization: "Bearer " + idtoken,
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            console.log("result is ", res);
            res.data.entities.filter((item) => {
              if (item) {
                this.docapprovallist.push(item);
              }
            });
            this.totalCount = res.data.totalCount;
            console.log("tcount is ", this.totalCount);
            this.totalPages = parseInt(
              this.totalCount / this.searchData.pageSize
            );
            if (this.totalCount % this.searchData.pageSize != 0) {
              this.totalPages = this.totalPages + 1;
              console.log("helloooooooooooooooooo", this.totalPages);
            }
            // validateDate;
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },

    async documentPage(patientId) {
      this.$router.push(`/patientdocument/${patientId}`);
    },
    validateDate(fromDate, toDate) {
      if (fromDate > toDate) {
        this.validation[0] = true;
      } else {
        this.validation[0] = false;
      }
    },
    async getnextpage() {
      console.log("NextPage");
      console.log(this.totalCount);
      console.log(this.totalCount / 10 > this.searchData.pageNo);
      if (this.totalCount / 10 > this.searchData.pageNo) {
        this.searchData.pageNo++;
        await this.getdocumentapproval();
      }
    },
    async getpreviouspage() {
      console.log("PreviousPage");
      if (this.searchData.pageNo > 1) {
        this.searchData.pageNo--;
      }
      await this.getdocumentapproval();
    },
    loadPage: function (page) {
      this.currentPage = page;
      this.searchData.pageNo = page;
      this.getdocumentapproval();
    },
    async downloadfile(docId, versionID) {
      this.ShowDocName = false;
      var versionId;
      await this.getDocumentById(docId);
      await this.getdmsdocument(docId);
      if (versionID == 0) {
        versionId =
          this.Document.documentVersions[
            this.Document.documentVersions.length - 1
          ].documentVersionId;
      } else {
        versionId = versionID;
      }
      var a = document.createElement("a");
      a.href = `${this.fileuploadurl}/file/${this.Document.documentReferenceId}?versionid=${versionId}`;
      a.download = "FILENAME";
      a.click();
    },
    async getDocumentById(docId) {
      this.currentdocId = docId;
      console.log("docccccccccccccc..", docId);
      console.log("docccccccccccccc..", this.currentdocId);
      const idtoken = store.getters.getIdToken;
      await axios
        .get(
          `${this.baseapi}/management/document/getdocumentbyid?id=` + docId,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.Document = res.data;
          this.loaddata = true;
          this.documentName = this.Document.documentName;
        });
    },
    async getdmsdocument() {
      this.documentById = "";
      console.log("document download", this.Document);
      await axios
        .get(
          `${this.fileuploadurl}/getallfiles/${this.Document.documentReferenceId}`,
          {
            headers: {
              Authorization: "Bearer " + this.idToken,

              "Content-Type": "application/json",
              responseType: "blob",
            },
          }
        )
        .then((res) => {
          this.documentById = res.data;
          console.log("Docdownld............", this.documentById);
        })
        .catch((err) => {
          alert(this.$t("navigations.document is not available"));
          console.log(err);
        });
    },
  },
};
</script>
<style scoped>
@import "../../assets/style.css";
@import "../../assets/formpage.css";
.previousbtn,
.nextbtn {
  margin: 0;
  border-radius: 0px;
  border: 0.5px solid #eeeeee;
}
.sortable-list2 {
  padding: 0;
}
.page-links {
  border-radius: 0px;
}
/*pagination styles*/
.pagination {
  margin: 0;
}
.Page,
.PaginationControl {
  border: 1px solid #e7eaec;
}
.Page-active {
  padding: 1rem;
  color: #ffffff;
  border: 1px solid var(--pagination-active-clr);
  border-radius: inherit;
  margin: 0;
}
.Page {
  padding: 1rem;
  border-radius: inherit;
  margin: 0;
}
.PaginationControl {
  padding: 0.42rem;
}
.create-block {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0.25rem;
}
.form-control {
  padding: 0.2rem 0.75rem !important;
}
.form-select {
  border-radius: 0 !important;
  padding: 0.2rem 0.75rem !important;
}
.form-select:focus {
  border-color: #145faf !important;
  box-shadow: 0 0 0 !important;
}
.modal__container22 {
  width: 80%;
  max-width: 38%;
  position: fixed;
  background: rgb(255, 255, 255);
  border-radius: 4px;
  transition: all 0.3s ease-in-out 0s;
}
.titleClass {
  background-color: var(--pop-up-background);
  color: white;
  height: 35px;
  padding-left: 5px;
  padding-top: 5px;
}
.modal-approver {
  position: absolute;
  top: 4% !important;
  left: 100% !important;
  z-index: 1050;
  width: 100%;
}

.editicon {
  position: relative;
}
.modelbody {
  background-color: white !important ;
}
.tdtext {
  text-align: center;
}
.editicon .pointer {
  visibility: hidden;
  width: 150px;
  background-color: #616161;
  color: #fff;
  text-align: center;
  border-radius: 0.3em;
  padding: 0.3rem 0.2rem;
  right: 0;
  top: -35px;
  font-size: 0.8rem;
  font-weight: 500;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  transition: visibility 0.4s ease-in;
}
.editicon:hover .pointer {
  visibility: visible;
}

.scrollable {
  overflow-y: scroll;
  height: 350px;
}
.modal__wrapperNew {
  position: fixed;
  background: rgb(0 0 0 / 18%);
  height: 100%;
  width: 100%;
  inset: 0;
  z-index: 3000;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: cubic-bezier;
  overflow: hidden;
}
.modal__approver__container {
  width: min(38%, 80%);
  position: fixed;
  background: rgb(255, 255, 255);
  border-radius: 4px;
  overflow-x: hidden;
}
.modal__content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
}
</style>
