<template>
  <div class="stat-card-white " :class="{ 'sunburst-minheight': fromPage == 'patientScreening' }">
    <div class="stat-header-white">
      <span @click="alertfun">{{ title }}</span>
      <div v-if="!loader" class="expand-block px-2 mb-1 cursor-pointer position-relative">
          <img @click="showtable()" src="../../assets//img/table_view.png" alt="More block">
          <div class="more-expand">
            <span>Data Overview</span>
          </div>
        </div>
        <div class="expand-block px-2 mb-1 cursor-pointer" @click.prevent="expandBlock=true">
          <img src="../../assets//img/expand.png" alt="Expand block">
        </div>
    </div>

    <div v-if="!loader && selectedGroups.length!=0" class="selectdrill d-flex flex-row-reverse gap-2 w-100">
        <img class="cursor-pointer" src="../../assets/img/chart_cancel.png" @click="cancelselection()" alt="Save profile"/>
        <img class="cursor-pointer" src="../../assets/img/chart_submit.png" @click="recreatechart()" alt="Save profile"/>
         

      </div>
    <div class="stat-body-white sunburst-chart-wrapper" :class="{'svg-container' :  fromPage=='descriptiveStatistics'}">
      <div v-if="loader" class="loader"></div>
      
      <svg ref="chartContainer" v-show="!loader" :class="{'responsive-svg ' :  fromPage=='descriptiveStatistics'}"></svg>
    </div>
  </div>
  <enlargerpopup v-if="expandBlock==true" @closeModal="expandBlock=false,cancelselection()">
    <div
      class="stat-body-white d-flex flex-column col-md-12 maxheight">
      <div v-if="!loader && selectedGroups.length!=0" class="selectdrill d-flex flex-row-reverse gap-2 w-100">
        <img class="cursor-pointer" src="../../assets/img/chart_cancel.png" @click.prevent="cancelselection()" alt="Save profile"/>
        <img class="cursor-pointer" src="../../assets/img/chart_submit.png" @click.prevent="recreatechart()" alt="Save profile"/>
      </div>
      <svg ref="popupChartContainer"></svg>      
  </div>
</enlargerpopup>
</template>

<script>
import * as d3 from "d3";
import axios from "axios";
import store from "../../store/index";
import enlargerpopup from "../AiCharts/popupfiles/enlarge_popup.vue"

export default {
  name: "ZoomableSunburstChart",
  components:{
    enlargerpopup
  },
  props: {
    data: {
      type: Array,
      required: true,
      default: () => [],
    },
    templateId: {
      type: String,
      default: "",
    },
    fromPage: {
      type: String,
      default: "",
    },
    labelOne: {
      type: String,
      default: 'Acceptable'
    },
    labelTwo: {
      type: String,
      default: 'Rejected'
    },
    loader: {
      type: Boolean,
      default: false,
    },
    title:{
        type:String,
        default:'Interactive Patient Outcome Visualization'
    }
  },
  data() {
    return {
      idtoken: "",
      baseApi: process.env.VUE_APP_Service_URL,
      mappings: {},
      baseColors: {},
      svg: null,
      chartContainer:null,
      loading: true,
      showMore:true,
      expandBlock:false,
      resizeObserver: null,
      sortdata: [],
      filterkeyword:"",
      filterarray:[],
      selectedGroups: [],
    };
  },
  watch: {
    data: {
      handler() {
        d3.select(this.chartContainer).selectAll("*").remove();
        this.selectedGroups = [];
        this.sortdata = [];
        this.createSunburst();
        if (this.data.length != 0) {
          this.loading = false;
        }
      },
      deep: true,
    },
    expandBlock(newValue) {
      if (newValue) {
        this.$nextTick(() => {
          this.createSunburst();
        });
      }
    }
  },
  async beforeMount() {
    this.idtoken = store.getters.getIdToken;
    if (this.fromPage == 'patientScreening') {
      await this.createmapping();
    }

  },
  async mounted() {
    this.chartContainer = this.$refs.chartContainer;
    await this.createSunburst();
       // Add resize observer to make SVG responsive
    
  },
  methods: {
    showtable(){
      this.showMore=false
      this.$emit("opendrillpopup", this.data);
    },
    async cancelselection() {
  this.selectedGroups = [];
  this.sortdata = [];
  this.chartContainer = this.expandBlock ? this.$refs.popupChartContainer : this.$refs.chartContainer;
  d3.select(this.chartContainer).selectAll("*").remove();
  this.createSunburst();
},
    async createmapping() {
      try {
        const response = await axios.get(
          `${this.baseApi}/forms/templatedesign/getpagesbyid?formId=${this.templateId}&version=1.0`,
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          }
        );
        if (response.data) {
          const pages = response.data.pages;
          pages.forEach((page) => {
            page.field.forEach((field) => {
              if (field.options && field.options.length > 0) {
                this.mappings[field.fieldName] = field.options.reduce(
                  (map, option) => {
                    map[option.codevalue] = option.optionname;
                    return map;
                  },
                  {}
                );
              }
            });
          });
        }
      } catch (error) {
        console.log("Error creating mappings", error);
      }
    },
    createSunburst() {
      this.chartContainer = this.expandBlock ? this.$refs.popupChartContainer : this.$refs.chartContainer;
  const width = this.fromPage == 'patientScreening' ? 600 :
                this.fromPage == 'patientRetention' ? 850 : 500;
  const radius = this.fromPage == 'patientScreening' ? width / 14 :
                 this.fromPage == 'patientRetention' ? width / 11 : width / 7;
    
  if (this.fromPage == 'descriptiveStatistics') {
    this.color = d3.scaleOrdinal(
      d3.quantize(d3.interpolateRainbow, this.data.length + 1)
    );
  }
  if (this.fromPage == 'patientScreening' || this.fromPage == 'patientRetention') {
    this.baseColors = {
      Acceptable: "#1f77b4", // Base color for Acceptable
      Rejected: "#ff7f0e",   // Base color for Rejected
    };
    this.color = d3.scaleOrdinal()
      .domain([this.labelOne, this.labelTwo])
      .range([this.baseColors.Acceptable, this.baseColors.Rejected]);
  }

  const partition = (data) => {
    const root = d3.hierarchy(data)
      .sum(d => d.value)
      .sort((a, b) => b.value - a.value);
    return d3.partition()
      .size([2 * Math.PI, root.height + 1])(root);
  };

  const processedData = this.processData(this.data);
  if (!processedData) {
    console.error("Processed data is empty or invalid.");
    return;
  }

  const root = partition(processedData);
  root.each((d) => (d.current = d));

  const svg = d3.select(this.chartContainer)
    .attr("viewBox", this.fromPage == 'patientScreening' ? [0, 140, width, width-280] :
                      this.fromPage == 'patientRetention' ? [0, 140, width, width-350] : 
                                                          [0, 140, width, width])
    .style("font", "10px sans-serif");

  const g = svg.append("g")
    .attr("transform", this.fromPage == 'patientScreening' ? `translate(${width / 2},${width / 2})` : `translate(${width / 2},${370})`);

  const arc = d3.arc()
    .startAngle((d) => d.x0)
    .endAngle((d) => d.x1)
    .padAngle((d) => Math.min((d.x1 - d.x0) / 2, 0.005))
    .padRadius(radius * 1.5)
    .innerRadius((d) => d.y0 * radius)
    .outerRadius((d) => Math.max(d.y0 * radius, d.y1 * radius - 1));

  const path = g.append("g")
    .selectAll("path")
    .data(root.descendants().slice(1))
    .join("path")
    .attr("fill", (d) => {
      while (d.depth > 1) d = d.parent;
      return this.color(d.data.name);
    })
    .attr("fill-opacity", (d) =>
      arcVisible(d.current) ? (d.children ? 0.6 : 0.4) : 0
    )
    .attr("d", (d) => arc(d.current))
    // Set opacity based on selectedGroups
    .style("opacity", (d) => {
      // If selectedGroups is not empty and this node is not in selectedGroups, set opacity to 0.35
      if (this.selectedGroups.length !== 0 && !this.selectedGroups.includes(d.data.name)) {
        return 0.35;
      }
      return 1; // Full opacity if it's in selectedGroups or if selectedGroups is empty
    });

  // Handle clicking on arcs
  path.style("cursor", "pointer")
    .on("click", (event, d) => {
      const currentVisibleNodes = root.descendants().filter(node => arcVisible(node.current));
      const allAreLeafNodes = currentVisibleNodes.every(node => !node.children);

      if (allAreLeafNodes) {
        // If all visible nodes are leaf nodes, reduce opacity for unselected nodes
        // this.handleSelectedGroup(d.data.name); 
        this.logDataArray(d);
        this.updateOpacity(path); // Call function to update the opacity of the chart
      } else if (d.children) {
        // If it's not a leaf node, perform the normal drill-down behavior
        clicked(event, d);
      }
    });

  path.append("title").text((d) => `${d.data.name}\n${d.value}`);

  const label = g.append("g")
    .attr("pointer-events", "none")
    .attr("text-anchor", "middle")
    .style("user-select", "none")
    .selectAll("text")
    .data(root.descendants().slice(1))
    .join("text")
    .attr("dy", "0.35em")
    .attr("fill-opacity", (d) => +labelVisible(d.current))
    .attr("transform", (d) => labelTransform(d.current))
    .text((d) => d.data.name)
    .style("font-size", "8px");

  const parent = g.append("circle")
    .datum(root)
    .attr("r", radius)
    .attr("fill", "none")
    .attr("pointer-events", "all")
    .on("click", clicked);

  function clicked(event, p) {
    parent.datum(p.parent || root);

    root.each(
      (d) =>
      (d.target = {
        x0:
          Math.max(0, Math.min(1, (d.x0 - p.x0) / (p.x1 - p.x0))) *
          2 *
          Math.PI,
        x1:
          Math.max(0, Math.min(1, (d.x1 - p.x0) / (p.x1 - p.x0))) *
          2 *
          Math.PI,
        y0: Math.max(0, d.y0 - p.depth),
        y1: Math.max(0, d.y1 - p.depth),
      })
    );

    const t = g.transition().duration(750);

    path.transition(t)
      .tween("data", (d) => {
        const i = d3.interpolate(d.current, d.target);
        return (t) => (d.current = i(t));
      })
      .filter(function (d) {
        return +this.getAttribute("fill-opacity") || arcVisible(d.target);
      })
      .attr("fill-opacity", (d) =>
        arcVisible(d.target) ? (d.children ? 0.6 : 0.4) : 0
      )
      .attrTween("d", (d) => () => arc(d.current));

    label.filter(function (d) {
      return +this.getAttribute("fill-opacity") || labelVisible(d.target);
    })
    .transition(t)
    .attr("fill-opacity", (d) => +labelVisible(d.target))
    .attrTween("transform", (d) => () => labelTransform(d.current));
  }

  function arcVisible(d) {
    return d.y1 <= 3 && d.y0 >= 1 && d.x1 > d.x0;
  }

  function labelVisible(d) {
    return d.y1 <= 3 && d.y0 >= 1 && d.x1 - d.x0 > 0.03;
  }

  function labelTransform(d) {
    const x = (((d.x0 + d.x1) / 2) * 180) / Math.PI;
    const y = ((d.y0 + d.y1) / 2) * radius;
    return `rotate(${x - 90}) translate(${y},0) rotate(${x < 180 ? 0 : 180})`;
  }
},

handleSelectedGroup(groupName) {
  if (this.selectedGroups.includes(groupName)) {
    // Remove group if already selected
    this.selectedGroups = this.selectedGroups.filter(name => name !== groupName);
  } else {
    // Add group if not already selected
    this.selectedGroups.push(groupName);
  }
},

updateOpacity(path) {
  // Update opacity based on selectedGroups
  path.style("opacity", (d) => {
    if (this.selectedGroups.length !== 0 && !this.selectedGroups.includes(d.data.name)) {
      return 0.35;
    }
    return 1; // Full opacity for selected nodes or when nothing is selected
  });
},
logDataArray(node) {
    const category = node.data.name;
    const filteredData = this.data.filter(item => {
      return Object.values(item).includes(category);
    });

    // Log the filtered array of data
    console.log('Filtered category:', category);
    console.log('Filtered data array for clicked node:', filteredData);
    this.clickbarinchart(filteredData, category);
  },
  clickbarinchart(filteredData, filteredkeyword) {
    if (this.selectedGroups.includes(filteredkeyword)) {
        this.selectedGroups = this.selectedGroups.filter((k) => k !== filteredkeyword);
        let result = this.sortdata.filter(item => !filteredData.includes(item));
        this.sortdata = result;
        let filterarraydeleted = this.filterarray.filter(item => `(${filteredkeyword})` != item);
        this.filterarray = filterarraydeleted;
        this.filterkeyword = `(${this.selectedGroups})`;
      } else {
    this.selectedGroups.push(filteredkeyword);
        this.filterarray.push(`(${filteredkeyword})`)
        this.filterkeyword = `(${this.selectedGroups})`;
        this.sortdata = this.sortdata.concat(filteredData);
      }
  },
  recreatechart() {
      const dataToEmit = {
    sortedData: this.sortdata,
    filterkeyword: this.filterkeyword
  };
  console.log("dataToEmit",dataToEmit);
      this.$emit("drilldowncurrentchart", dataToEmit);
    },
    processData(data) {
      if (!data || !data.length) {
        return null;
      }
      if (this.fromPage == 'patientScreening' || this.fromPage == 'patientRetention') {
        const features = data[0].features;
        const attributes = features
          ? Object.keys(features).filter((key) => {
            const uniqueValues = new Set(data.map((item) => item.features[key]))
              .size;
            return uniqueValues < 5 && key !== "AIPrediction" && key !== 'status';
          })
          : [];

        if (!attributes.length) {
          return null;
        }

        const root = {
          name: "Prediction",
          children: [],
        };

        const aiPredGroups = d3.group(data, (d) => d.features.AIPrediction);

        aiPredGroups.forEach((group, aiPred) => {
          const aiPredNode = {
            name: aiPred ? this.labelOne : this.labelTwo,
            children: [],
          };

          attributes.forEach((attr) => {
            const attrGroups = d3.group(group, (d) => d.features[attr]);
            const attrNode = {
              name: attr,
              children: [],
            };
            attrGroups.forEach((group, attrValue) => {
              const attrValueNode = {
                name: this.getMappedValue(attrValue, attr),
                value: group.length,
              };
              attrNode.children.push(attrValueNode);
            });
            aiPredNode.children.push(attrNode);
          });

          root.children.push(aiPredNode);
        });

        return root;
      }
      if (this.fromPage == 'descriptiveStatistics') {
        const features = data[0]
        const attributes = features
          ? Object.keys(features).filter((key) => {
            const uniqueValues = new Set(data.map((item) => item[key]))
              .size;
            return uniqueValues < 10 && key !== "AIPrediction";
          })
          : [];

        if (!attributes.length) {
          return null;
        }

        const root = {
          name: "Prediction",
          children: [],
        };

        const aiPredGroups = d3.group(data, (d) => d.AIPrediction);
        console.log("groups ara", JSON.stringify(aiPredGroups));
        // aiPredGroups = {aiPredGroups[undefined]}

        aiPredGroups.forEach((group, aiPred) => {
          const aiPredNode = {
            name: aiPred ? this.labelOne : this.labelTwo,
            children: [],
          };

          attributes.forEach((attr) => {
            const attrGroups = d3.group(group, (d) => d[attr]);
            const attrNode = {
              name: attr,
              children: [],
            };
            attrGroups.forEach((group, attrValue) => {
              const attrValueNode = {
                name: this.getMappedValue(attrValue, attr),
                value: group.length,
              };
              attrNode.children.push(attrValueNode);
            });
            root.children.push(attrNode);
          });

          root.children.push(aiPredNode);
        });

        return root;
      }

    },
    getMappedValue(value, key) {
      return this.mappings[key] ? this.mappings[key][value] || value : value;
    },
  },
};
</script>

<style scoped>
@import "../AiCharts/charts.css";
.sunburst-chart-wrapper {
  overflow-x: auto;
  width: 100%;
}

.sunburst-chart {
  position: relative;
}

.sunburst-minheight {
  min-height: 400px;
}

.svg-container {
  height: 0;
  padding-bottom: 50%;
  /* This sets the aspect ratio, here it is 1:2 */
  position: relative
}

.responsive-svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.loader {
  border: 6px solid #f3f3f3;
  border-top: 6px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: 0 auto;
}
.maxheight{
  max-height: 80vh;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
