<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>Manage User</h2>
    </div>
   <div class="col-lg-2">
        <i class="fa fa-times close my-2" @click="onclosed()"></i>
    </div>
  </div>
  <div class="wrapper wrapper-content">
    <div class="ibox-content p-3">
      <div class="form-group row">
        <div class="col-lg-4 mb-1">
          <label class="col-form-label"
            >First Name<sup><i class="fa fa-asterisk imp"></i></sup>
          </label>
          <input
            type="text"
            class="form-control"
            placeholder="Enter your first name"
            maxlength="150"
            v-model="values.firstName"
          />
          <span class="errormsg" v-if="errors[0] == false"
            >Please enter first name.</span
          >
        </div>
        <div class="col-lg-4 mb-1">
          <label class="col-form-label"
            >Last Name <sup><i class="fa fa-asterisk imp"></i></sup
          ></label>
          <input
            type="text"
            class="form-control"
            placeholder="Enter your last name"
            maxlength="150"
            v-model="values.lastName"
          />
          <span class="errormsg" v-if="errors[1] == false"
            >Please enter last name.</span
          >
        </div>
        <div class="col-lg-4 mb-1">
          <label class="col-form-label"
            >User Name <sup><i class="fa fa-asterisk imp"></i></sup
          ></label>
          <input
            type="text"
            class="form-control"
            placeholder="Enter your user name"
            maxlength="20"
            v-model="values.userName"
          />
          <span class="errormsg" v-if="errors[8] == false"
            >Username should be 5 to 10 characters</span
          >
        </div>
        <div class="col-lg-4 mb-1">
          <label class="col-form-label"
            >Primary Email <sup><i class="fa fa-asterisk imp"></i></sup
          ></label>
          <input
            type="email"
            class="form-control"
            autocomplete="off"
            placeholder="Enter your primary email id"
            v-model="values.emailIdPrimary"
            :disabled="selecteduser != 0"
          />
          <span class="errormsg" v-if="errors[2] == false"
            >Please enter a valid email address.</span
          >
        </div>
        <div class="col-lg-4 mb-1">
          <label class="col-form-label">Secondary Email</label>
          <input
            type="email"
            class="form-control"
            placeholder="Enter your secondary email id"
            v-model="values.emailIdSecondary"
          />
          <span class="errormsg" v-if="errors[3] == false"
            >Please enter a valid email address.</span
          >
        </div>
        <div class="col-lg-4 mb-1">
          <label class="col-form-label"
            >User Type <sup><i class="fa fa-asterisk imp"></i></sup
          ></label>
          <select
            placeholder="Select user type"
            class="form-select"
            v-model="values.userType"
          >
            <option value="" selected>Select user type</option>
            <option value="Internal">Internal</option>
            <option value="External">External</option>
          </select>
          <span class="errormsg" v-if="errors[4] == false"
            >Please select a user type.</span
          >
        </div>
        <div class="col-lg-4 mb-1">
          <label class="col-form-label"
            >Role<sup><i class="fa fa-asterisk imp"></i></sup
          ></label>
          <select
            name="account"
            placeholder="Select roles"
            v-model="multiSelectList[0]"
            valueProp="roleID"
            class="form-select"
          >
          
            <!-- <option value="" disabled >Select a role</option> -->
            <!-- <option :value=" multiSelectList[0] " selected > {{values.role[0]}}</option> -->
            <option
              v-for="options in roleList"
              :value="options.roleID"
              :key="options.role"
              :options="roleList"
              :hideSelected="false"
              :closeOnSelect="false"
              :searchable="true"
              trackBy="roleName"
            >
            {{options.roleName}}
              
            </option>
          </select>

          <!-- diabled multi select -->
          <!-- <Multiselect
            v-model="multiSelectList"
            mode="multiple"
            valueProp="roleID"
            placeholder="Select roles"
            label="roleName"
            :options="roleList"
            :hideSelected="false"
            :closeOnSelect="false"
            :searchable="true"
            trackBy="roleName"
            class="multiselect-theme"
          >
          </Multiselect> -->
          <!-- disabled multiselect -->
          <span class="errormsg" v-if="errors[6] == false"
            >Please select role.</span
          >
        </div>
        <div class="col-lg-4 mb-1">
          <label class="col-form-label">Select Your Country</label>
          <select
            class="form-select"
            name="account"
            v-model="values.country"
          >
            <option selected value="">Select country</option>
            <option>United States</option>
            <option>India</option>
            <option>Japan</option>
            <option>China</option>
          </select>
        </div>
        <div class="col-lg-4 mb-1" id="data_1">
          <label class="col-form-label">Date of Birth</label>
          <div class="input-group date">
            <input
              type="date"
              max="9999-12-31"
              class="form-control"
              v-model="values.dateOfBirth"
            />
          </div>
          <span class="errormsg" v-if="errors[7] == false">Invalid Date</span>
        </div>
        <div class="col-lg-4 mb-1">
          <label class="col-form-label">Phone Number</label>
          <div class="input-group date">
            <input
              type="text"
              class="form-control"
              maxlength="10"
              v-model="values.phoneNumber"
            />
          </div>
          <span class="errormsg" v-if="errors[5] == false"
            >You have entered an invalid phone number!</span
          >
        </div>
        <div class="col-lg-8 mb-1">
          <label class="col-form-label col-lg-12 px-0">Address 1</label>
          <div class="input-group date">
            <input
              type="text"
              class="form-control address"
              v-model="values.addressLineOne"
              maxlength="500"
            />
          </div>
        </div>
        <div class="col-lg-12 mb-1">
          <label class="col-form-label col-lg-12 px-0">Address 2</label>
          <div class="input-group date">
            <input
              type="text"
              class="form-control address"
              v-model="values.addressLineTwo"
              maxlength="500"
            />
          </div>
        </div>
        <div class="col-lg-6 my-1 d-flex">
          <div class="input-group date">
            <div class="i-checks aligncheckbox">
              <label class="mr-2">Active</label>
              <input type="checkbox" value="" v-model="values.active" />
            </div>
          </div>
          <div class="input-group date">
            <div class="i-checks aligncheckbox">
              <label class="mr-2">Lock</label><input type="checkbox" value="" />
            </div>
          </div>
        </div>
        <div class="col-lg-6 my-1 d-flex justify-content-lg-end">
          <button
            class="btn btn-primary cancel_btn py-2 px-5 mx-3"
            @click.prevent="clear"
          >
            Clear
          </button>
          <button
            class="btn btn-primary save_btn py-2 px-5"
            type="submit"
            @click.prevent="addUser"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script src="./createUserComponent.js"></script>
<style scoped>
@import "../../assets/style.css";
@import "../../assets/savepages.css";
.wrapper-content {
  width: 100%;
}
textarea {
  width: 100%;
}
.imp {
  color: #ff0000;
  font-size: 7px;
}
.impcl {
  color: #ff4747;
  font-size: 25px;
  float: right;
}
.impcl:hover {
  color: #a30202;
  font-size: 25px;
  float: right;
}
.col-form-label {
  font-size: 14px;
}
.address {
  width: 100%;
}
.multiselect-theme {
  --ms-tag-bg: rgb(210, 210, 210);
  --ms-tag-color: rgb(60, 60, 60);
  --ms-option-bg-selected-pointed: transparent;
  --ms-option-bg-selected: transparent;
  --ms-option-color-selected: #000;
  --ms-option-color-selected-pointed: #000;
}
</style>
