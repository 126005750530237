<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>{{ $t("navigations.e-consent") }}</h2>
    </div>
  </div>
  <div class="wrapper wrapper-content">
    <div class="ibox-content mb-1 px-3 py-2">
      <div class="form-group row mb-1">
        <div class="d-flex flex-column flex-lg-row my-2 my-lg-0 mb-lg-0">
          <div class="form-group mb-lg-0 w-100 px-1 pl-lg-0">
            <label class="mb-0">{{ $t("navigations.site code") }}</label>
            <select
              class="form-select my-md-2 my-lg-0"
              name="account"
              placeholder="Site code"
              v-model="searchData.siteId"
            >
              <option selected value="">{{ $t("navigations.all") }}</option>
              <option v-for="site in sites" :key="site.siteID" :value="site.siteID">
                {{ site.siteCode }}
              </option>
              <!--<option>Archived</option>-->
            </select>
          </div>
          <div class="form-group mb-lg-0 w-100 px-1">
            <label class="mb-0">{{ $t("navigations.subject id") }}</label>
            <input
              type="text"
              class="form-control my-md-2 my-lg-0"
              v-model="searchData.subjectid"
            />
          </div>

          <span
            typr="button"
            class="col-sm-12 col-lg-2 save_btn my-1 my-lg-0 mx-lg-1 align-self-end"
            @click="SearchButtonClicked()"
          >
            {{ $t("navigations.search") }}
          </span>
        </div>
      </div>
    </div>
    <div class="ibox-body">
      <div class="ibox-title style_2 d-flex justify-content-between">
        <h5 class="mt-1">{{ $t("navigations.e-consent list") }}</h5>
      </div>
      <div class="ibox-content">
        <div class="table-responsive">
          <table class="table table-striped table-bordered dataTables">
            <thead>
              <tr>
                <th>
                  {{ $t("navigations.site code") }}
                </th>
                <th>
                  {{ $t("navigations.subject id") }}
                </th>
                <th @click="SortSelected('status', 1)" id="1">
                  <div class="sort_block">
                    <div class="d-flex flex-row justify-content-between">
                      {{ $t("navigations.status") }}
                      <span>
                        <i class="fa fa-long-arrow-up"></i>
                        <i class="fa fa-long-arrow-down"></i>
                      </span>
                    </div>
                  </div>
                </th>
                <th class="sort_block" @click="SortSelected('enrollmentDate', 2)" id="2">
                  <div class="d-flex flex-row justify-content-between">
                    {{ $t("navigations.enrolment date") }}
                    <span>
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span>
                  </div>
                </th>
                <th class="sort_block" @click="SortSelected('enrollmentDate', 3)" id="3">
                  <div class="d-flex flex-row justify-content-between">
                    {{ $t("navigations.invite status") }}
                    <span>
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span>
                  </div>
                </th>
                <th class="sort_block" @click="SortSelected('enrollmentDate', 4)" id="4">
                  <div class="d-flex flex-row justify-content-between">
                    {{ $t("navigations.consent status") }}
                    <span>
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="patient in patientLists" :key="patient.studyId">
                <td>{{ patient.siteCode }}</td>
                <td>{{ patient.subjectId }}</td>
                <td>{{ patient.status }}</td>
                <td>{{ filter(patient.enrollmentDate) }}</td>
                <td v-if="patient.inviteStatus == 'Pending'">
                  <button
                    class="btn-outline btn-default py-1 px-3 border-radius-9"
                    @click.prevent="openPopup(patient.patientId,patient.studyRef)"
                  >
                    {{ patient.inviteStatus }}
                    <i class="fa fa-chevron-right right-arrow-sm-icon"></i>
                  </button>
                </td>
                <td v-else>{{ patient.inviteStatus }}</td>
                <td>{{ patient.consentStatus }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <!------ pagination -------->
        <div class="row mt-3 mx-0">
          <div class="col-lg-12 mx-0 px-0">
            <div class="dataTables_paginate paging_simple_numbers pagination float-right">
              <div class="col-lg-12 d-flex justify-content-lg-end px-0">
                <v-pagination
                  v-model="currentPage"
                  :pages="totalPages"
                  :range-size="1"
                  active-color="#618bd7"
                  @update:modelValue="loadPage"
                />
              </div>
            </div>
          </div>
        </div>

        <!-- pagin -->
      </div>
    </div>
  </div>
  <div class="patient__invite__modal__wrapper" v-if="showPopup">
    <div class="patient__invite__modal__container">
      <div class="modal__title d-flex justify-content-between py-2 px-3">
        <h4 class="my-0 align-self-center">Invite Subject</h4>
        <span
          type="button"
          class="align-self-center"
          data-dismiss="modal"
          aria-hidden="true"
          @click.prevent="showPopup = false"
          ><i class="fa fa-times modal-close align-self-center" title="Close"></i
        ></span>
      </div>
      <div class="d-flex justify-content-between p-4">
        <input
          class="btn-outline btn-default w-100 align-self-center py-2 px-3 mr-3"
          
          type="email"
          @input="OnUserTypeOnEmailField()"
          placeholder="Email"
          maxlength="50"
          v-model="inviteEmail"
        /><button
        :disabled="inviteButtonstats"
          class="btn-outline btn-default align-self-center py-2 px-3"
          @click.prevent="inviteSubject()"
        >
        <i class="fa fa-check px-1 color-green"></i>Invite
        </button>
        <div class="error-msg">
          {{ err }}
        </div>
      </div>
    </div>
  </div>
</template>
<script src="./eConsent.js"></script>
<style>
.border-radius-9 {
  border-radius: 9px;
  border: 1px solid #618bd7;
}
.modal__title {
  background-color: var(--pop-up-background);
  color: hsl(0, 0%, 100%);
}
.patient__invite__modal__wrapper {
  position: fixed;
  inset: 0;
  background: rgb(0 0 0/18%);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  z-index: 3000;
}
.patient__invite__modal__container {
  position: fixed;
  width: min(500px, 90%);
  border-radius: 4px;
  background: #fff;
  overflow-x: hidden;
}
.color-green{
  color:hsl(120, 100%, 25%)
}
</style>
