<template>
  <div class="w-100">
    <div class="row">
      <div class="col-12 col-md-1" v-if="fromPage == 'adverseEventsPop'">
        <div class="filter-bg d-flex align-items-center justify-content-center h-100">
          <img src="../../assets/img/filter_icon.png" alt="filter">
        </div>
      </div>
      <div class="col-12 col-md-2 "
        :class="{ 'px-0': fromPage == 'adverseEventsPop', 'py-1': fromPage == 'adverseEventsPop', 'column-selection': fromPage != 'adverseEventsPop' }">
        <div class="multi-select-dropdown" @click.stop="toggleDropdown">
          <div class="selected-options">
            <span>Select Columns</span>
          </div>
          <div class="dropdown-arrow"></div>
        </div>
        <div v-if="dropdownOpen" class="dropdown-content" @click.stop>
          <label v-for="(column, index) in availableColumns" :key="index">
            <input type="checkbox" v-model="selectedcolumnobj[column]" :value="true" /> {{ column }}
          </label>
        </div>
      </div>
      <!-- Dynamic Filters -->
      <div class="col-12" :class="{
        'col-md-9': isNumberField || isStringField,
        'col-md-4': !isNumberField || !isStringField,
        'row': fromPage == 'adverseEventsPop',
        'py-1': fromPage == 'adverseEventsPop',
        'filters': fromPage != 'adverseEventsPop'
      }">
        <!-- Field Selection -->
        <select class="graph-select ml-2 py-0"
          :class="{ 'w-50': fromPage != 'adverseEventsPop', 'col-4': fromPage == 'adverseEventsPop' }"
          v-model="selectedField" id="fieldFilter" @change="updateFilterValues">
          <option value="" disabled>Select Field</option>
          <option v-for="(column, index) in availableColumns" :key="index" :value="column">
            {{ column }}
          </option>
        </select>

        <!-- Number Filter Controls -->
        <div class="d-flex align-items-center" :class="{ 'col-4 mx-1': fromPage == 'adverseEventsPop' }"
          v-if="datatypeobj[selectedField] === 'number'">
          <label class="mr-1 mb-0">Min:</label>
          <input class="form-control py-1" type="number" v-model="filterMin" />
          <label class="mr-1 ml-1 mb-0">Max:</label>
          <input class="form-control py-1" type="number" v-model="filterMax" />
        </div>

        <!-- String Filter Controls -->
        <div class="d-flex align-items-center col-5" v-if="datatypeobj[selectedField] === 'string'">
          <select class="graph-select" v-model="stringFilterValue" id="stringFilter">
            <option value="">All</option>
            <option v-for="(value, index) in uniqueStringValues" :key="index" :value="value">
              {{ value }}
            </option>
          </select>
        </div>


        <!-- Search Button -->
        <button v-if="isNumberField || isStringField" type="button" class="col-2 btn btn-primary btn-sm py-0"
          @click="searchbtn">Search</button>
      </div>
    </div>

    <!-- Data Table -->
    <div class="max-height-300" :class="{ 'ibox-content': fromPage == 'adverseEventsPop' }">
      <table :key="displayedColumns" class="table table-striped table-bordered"
        :class="{'chart-popup-drill-table' : fromPage == 'adverseEventsPop',
        'chart-drill-table': fromPage != 'adverseEventsPop'}">
        <thead>
          <tr>
            <th v-for="column in displayedColumns" :key="column" @click="sortTable(column)">
              {{ column }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in filteredData" :key="item._id">
            <td v-for="column in displayedColumns" :key="column">
              {{ item[column] }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import * as d3 from 'd3';

export default {
  name: 'FilterableTable',
  props: {
    data: {
      type: Array,
      required: true,
    },
    fromPage: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      filtval: "",
      selectedField: '', // Selected field for filtering
      filterMin: null, // Minimum value for numeric filter
      filterMax: null, // Maximum value for numeric filter
      stringFilterValue: '', // Selected value for string filter
      sortedData: [...this.data],
      sortDirection: {},
      availableColumns: [], // All available columns
      selectedcolumnobj: {},
      dropdownOpen: false, // Dropdown open state
      uniqueStringValues: [], // Unique values for string filtering
      datatypeobj: {}, // Store the datatype for each column
    };
  },
  watch: {
    data: {
      handler() {
        this.initializeColumns();
        this.filterMin = null;
        this.filterMax = null;
        this.stringFilterValue = '';
        this.applyFilters();
      },
      immediate: true,
      deep: true,
    },
    selectedField(newField) {
      this.updateFilterValues();
    },
  },
  methods: {
    async searchbtn() {
      await this.applyFilters();
      await this.senddatatoparent();
    },
    initializeColumns() {
      if (this.data.length > 0) {
        this.availableColumns = Object.keys(this.data[0]);
        this.selectedColumns = [...this.availableColumns]; // Select all columns by default

        // Initialize selected column object
        this.selectedcolumnobj = this.selectedColumns.reduce((obj, key) => {
          obj[key] = true;
          return obj;
        }, {});

        // Initialize datatype object
        this.datatypeobj = this.availableColumns.reduce((obj, key) => {
          obj[key] = this.checkFieldType(key); // Determine if the field is number or string
          return obj;
        }, {});
      }
    },
    checkFieldType(fieldtocheck) {
      // Check if the field is valid
      if (!fieldtocheck) return "string";

      // Iterate through the data and return the type based on the first valid value
      for (let i = 0; i < this.data.length; i++) {
        const value = this.data[i][fieldtocheck];
        if (value !== null && value !== undefined) {
          // Return number or string based on the type
          return typeof value === 'number' ? "number" : "string";
        }
      }
      return "string"; // Default to string if no valid number found
    },
    updateFilterValues() {
      // Reset filters
      this.filterMin = null;
      this.filterMax = null;
      this.stringFilterValue = '';

      // Update unique values for string filtering if the selected field is a string
      if (this.isStringField) {
        this.uniqueStringValues = Array.from(new Set(this.data.map(item => item[this.selectedField])));
      }

      this.applyFilters();
    },
    applyFilters() {
      let filtered = [...this.data];

      if (this.isNumberField) {
        if (this.filterMin !== null) {
          filtered = filtered.filter(item => item[this.selectedField] >= this.filterMin);
        }
        if (this.filterMax !== null) {
          filtered = filtered.filter(item => item[this.selectedField] <= this.filterMax);
        }
      } else if (this.isStringField) {
        if (this.stringFilterValue !== '') {
          filtered = filtered.filter(item => item[this.selectedField] === this.stringFilterValue);
        }
      }
      this.filtval = this.isNumberField
        ? `${this.selectedField}(${this.filterMin},${this.filterMax})`
        : `${this.selectedField}(${this.stringFilterValue})`;
      this.sortedData = filtered;
    },
    senddatatoparent() {
      const dataToEmit = {
        sortedData: this.sortedData,
        filterkeyword: this.filtval
      };
      this.$emit("drilldowncurrentchart", dataToEmit);
    },
    sortTable(key) {
      const direction = this.sortDirection[key] || false;
      this.sortedData = d3.sort(this.sortedData, d => d[key]);
      if (direction) {
        this.sortedData.reverse();
      }
      this.sortDirection[key] = !direction;
    },
    toggleDropdown() {
      this.dropdownOpen = !this.dropdownOpen;
    },
    closeDropdown() {
      this.dropdownOpen = false;
    },
  },
  computed: {
    displayedColumns() {
      if (Object.keys(this.selectedcolumnobj).length > 0) {
        return Object.keys(this.selectedcolumnobj).filter(column => this.selectedcolumnobj[column]);
      }
      return [];
    },
    filteredData() {
      return this.sortedData;
    },
    isNumberField() {
      return this.selectedField && this.datatypeobj[this.selectedField] === "number";
    },
    isStringField() {
      return this.selectedField && this.datatypeobj[this.selectedField] === "string";
    },
  },
  mounted() {
    document.addEventListener('click', this.closeDropdown);
  },
  beforeUnmount() {
    document.removeEventListener('click', this.closeDropdown);
  },
};
</script>

<style scoped>
@import "../AiCharts/charts.css";

.filters {
  display: flex;
  gap: 20px;
  margin-bottom: 10px;
}

.filters label {
  font-weight: bold;
}

.column-selection {
  position: relative;
  margin-bottom: 10px;
}

.multi-select-dropdown {
  position: relative;
  border: 1px solid #ddd;
  padding: 0.2rem 0.8rem 0.2rem 1rem;  ;
  cursor: pointer;
  border-radius: 4px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.selected-options {
  flex-grow: 1;
}

.dropdown-arrow {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #333;
}

.dropdown-content {
  display: block;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 200px;
  border: 1px solid #ddd;
  z-index: 1;
  max-height: 200px;
  overflow-y: auto;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 10px;
  border-radius: 4px;
  top: 100%;
  left: 0;
}

.dropdown-content label {
  display: block;
  padding: 8px 12px;
  cursor: pointer;
}

.dropdown-content label:hover {
  background-color: #f1f1f1;
}

.dropdown-content input[type="checkbox"] {
  margin-right: 10px;
}

.max-height-300 {
  max-height: 300px;
  overflow: scroll;
}
</style>