<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>{{ $t("navigations.subject schedule") }}</h2>
    </div>
  </div>
  <div class="wrapper wrapper-content">
    <div class="ibox-content mb-1 px-3 py-1">
      <div class="form-group row mb-1">
        <div class="col-lg-3 my-2">
          <div class="form-group mb-0">
            <label class="mb-0">{{ $t("navigations.site code") }}</label>
            <select
              class="form-select my-lg-0"
              name="account"
              v-model="selectedsite"
              @change.prevent="sitechange($event.target.value)"
            >
              <option selected value="">--{{ $t("navigations.select") }}--</option>
              <option
                v-for="site in sites"
                :key="site.siteID"
                :value="site.siteID"
              >
                {{ site.siteCode }}
              </option>
            </select>
            <span class="errormsg" v-if="errors[0] == false"
              >{{$t('navigations.please select a site code')}}</span
            >
          </div>
        </div>
        <div class="col-lg-3 my-2">
          <div class="form-group mb-0">
            <label class="mb-0">{{ $t("navigations.subject id") }}</label>
            <select
              class="form-select my-lg-0"
              name="account"
              @change="onChange($event)"
              v-model="selectedsubject"
            >
              <option selected value="">--{{ $t("navigations.select") }}--</option>
              <option
                v-for="subject in subjects"
                :key="subject.patientId"
                :value="subject.subjectId"
              >
                {{ subject.subjectId }}
              </option>
            </select>
            <span class="errormsg" v-if="errors[1] == false"
              >{{$t('navigations.please select subject id')}}</span
            >
          </div>
        </div>
      </div>
    </div>
    <div class="ibox-content mb-1 px-3 py-1">
      <div class="form-group row mb-1">
        <div
          class="
            d-flex
            flex-column flex-lg-row
            px-0 px-md-2
            my-2 my-lg-0
            mb-lg-0
          "
        >
          <div class="col-lg-2 pl-lg-2">
            <div class="form-group mb-md-0">
              <label class="mb-0">{{ $t("navigations.visit name") }}</label>
              <input
                type="search"
                v-model="searchData.visitName"
                class="form-control my-md-2 my-lg-0"
              />
            </div>
          </div>
          <div class="col-lg-3 pl-lg-2">
            <div class="form-group mb-md-0">
              <label class="mb-0">{{ $t("navigations.status") }}</label>
              <select class="form-select my-md-2 my-lg-0" v-model="searchData.visitStatus" name="account">
                <option selected value="">{{ $t("navigations.all") }}</option>
                <option value="Completed">{{ $t("navigations.completed") }}</option>
                <option value="Imminent">{{ $t("navigations.imminent") }}</option>
                <option value="Overdue">{{ $t("navigations.overdue") }}</option>
                <option value="Planned">{{ $t("navigations.planned") }}</option>
              </select>

            </div>
          </div>
          <div class="col-lg-5 d-flex flex-column pl-lg-2">
            <div class="form-group mb-0">
              <label class="mb-0">{{ $t("navigations.visit date") }}</label>
              <div class="d-flex mr-0 pr-0">
                <input
                  type="date"
                  max="9999-12-31"
                  class="form-control"
                  v-model="searchData.fromDate"
                />
                <div class="align-self-center px-1">
                  {{ $t("navigations.to") }}
                </div>
                <input
                  type="date"
                  max="9999-12-31"
                  class="form-control"
                  v-model="searchData.toDate"
                />
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-2 mt-2 mt-md-0 px-0 px-md-2 align-self-end">
            <span
              type="button"
              @click.prevent="getschedule()"
              class="save_btn mx-md-1 w-100"
            >
              {{ $t("navigations.search") }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="ibox-body">
      <div class="ibox-title style_2">
        <h5 class="float-left">
          {{ $t("navigations.subject schedule list") }}
        </h5>
      </div>
      <div class="ibox-content">
        <div class="table-responsive">
          <table class="table table-bordered dataTables">
            <thead>
              <tr>
                <th>                  
                  {{ $t("navigations.site code") }}
                </th>
                <th>
                  {{ $t("navigations.subject id") }}
                </th>
                <th class="sort_block" @click="SortSelected('visitNo',3)" id="3">
                  <div class="d-flex flex-row justify-content-between">    
                    {{ $t("navigations.visit no") }}          
                    <span>
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span>
                  </div>                  
                </th>
                <th class="sort_block" @click="SortSelected('visitName',4)" id="4">
                  <div class="d-flex flex-row justify-content-between">   
                    {{ $t("navigations.visit name") }}           
                    <span>
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span>
                  </div>                  
                </th>
                <th class="sort_block" @click="SortSelected('visitType',5)" id="5">
                  <div class="d-flex flex-row justify-content-between"> 
                    {{ $t("navigations.visit type") }}             
                    <span>
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span>
                  </div>                  
                </th>
                <th class="sort_block" @click="SortSelected('preVisit',6)" id="6">
                  <div class="d-flex flex-row justify-content-between">   
                    {{ $t("navigations.pre-visit") }}           
                    <span>
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span>
                  </div>                  
                </th>
                <th class="sort_block" @click="SortSelected('visitDate',7)" id="7">
                  <div class="d-flex flex-row justify-content-between">   
                    {{ $t("navigations.visit date") }}           
                    <span>
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span>
                  </div>                  
                </th>
                <th class="sort_block" @click="SortSelected('postVisit',8)" id="8">
                  <div class="d-flex flex-row justify-content-between">   
                      {{ $t("navigations.post-visit") }}           
                      <span>
                        <i class="fa fa-long-arrow-up"></i>
                        <i class="fa fa-long-arrow-down"></i>
                      </span>
                  </div>                  
                </th>
                <th class="sort_block" @click="SortSelected('visitStatus',9)" id="9">
                  <div class="d-flex flex-row justify-content-between">      
                    {{ $t("navigations.visit status") }}        
                    <span>
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span>
                  </div>                 
                </th>
                <th class="sort_block" @click="SortSelected('investigatorName',10)" id="10">
                  <div class="d-flex flex-row justify-content-between">  
                    {{ $t("navigations.investigator") }}            
                    <span>
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span>
                  </div>
                </th>
                <th class="sort_block" @click="SortSelected('visitCompletedDate',11)" id="11">
                  <div class="d-flex flex-row justify-content-between">     
                    {{ $t("navigations.visit completed date") }}         
                    <span>
                    <i class="fa fa-long-arrow-up"></i>
                    <i class="fa fa-long-arrow-down"></i>
                  </span>
                  </div>
                </th>
                <th
                  v-if="roleprivileges.includes('Subject Schedule View Form')"
                  class="text-center"
                >
                  {{ $t("navigations.view") }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="shedule in sheduleList"
                :key="shedule.id"
                :class="{ bgcolor: shedule.isUnScheduled }"
              >
                <td>{{ shedule.siteCode }}</td>
                <td>{{ shedule.patientNo }}</td>
                <td>{{ shedule.visitNo }}</td>
                <td>{{ shedule.visitName }}</td>
                <td>{{ shedule.visitType }}</td>
                <!-- pre visit -->
                <td>
                  <span v-if="shedule.preVisit">{{
                    filter(shedule.preVisit)
                  }}</span>
                  <span v-else>{{}}</span>
                </td>
                <!-- visit date -->
                <td>
                  <span v-if="shedule.sheduleId == editShedule.sheduleId">
                    <input
                      type="date"
                      max="9999-12-31"
                      class="form-control"
                      v-model="visitdate"
                    />
                  </span>

                  <span
                    v-if="
                      shedule.visitDate &&
                      shedule.sheduleId != editShedule.sheduleId
                    "
                  >
                    {{ filter(shedule.visitDate) }}
                  </span>

                  <span v-else> {{}} </span>
                </td>
                <!-- post visit -->
                <td>
                  <span v-if="shedule.postVisit">
                    {{ filter(shedule.postVisit) }}
                  </span>
                  <span v-else>{{}}</span>
                </td>

                <td>
                  <span
                    class="badge badge-grn"
                    v-if="shedule.visitStatus == 'Completed'"
                    >{{ shedule.visitStatus }}</span
                  >
                  <span
                    class="badge badge-danger"
                    v-if="shedule.visitStatus == 'Overdue'"
                    >{{ shedule.visitStatus }}</span
                  >
                  <span
                    class="badge badge-warning"
                    v-if="shedule.visitStatus == 'Imminent'"
                    >{{ shedule.visitStatus }}</span
                  >
                  <span
                    class="badge badge-genre"
                    v-if="
                      shedule.visitStatus == 'Withdrawn' ||
                      shedule.visitStatus == 'Planned'
                    "
                    >{{ shedule.visitStatus }}</span
                  >
                </td>

                <!-- Investigator -->
                <td>
                  <span v-if="shedule.sheduleId != editShedule.sheduleId">
                    {{ shedule.investigatorName }}
                  </span>

                  <span v-if="shedule.sheduleId == editShedule.sheduleId">
                    <select
                      class="form-control"
                      name="account"
                      v-model="investigator"
                    >
                      <option v-for="user in users" :key="user.userID">
                        {{ user.name }}
                      </option>
                    </select>
                  </span>
                </td>
                <td>
                  <span v-if="shedule.visitCompletedDate">
                    {{ filter(shedule.visitCompletedDate) }}
                  </span>
                  <span v-else>{{}}</span>
                </td>
                <td
                  v-if="roleprivileges.includes('Subject Schedule View')"
                  class="text-center"
                >
                  <span v-if="shedule.visitStatus == 'Completed'">
                    <i
                      class="fa fa-eye edit-delete"
                      @click.prevent="viewforms(shedule.formId)"
                    ></i
                  ></span>
                  <span v-else>{{}}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- pagination -->
        <div class="row mx-0 mt-2">
          <div class="col-lg-12 mx-0 px-0">
            <div
              class="
                dataTables_paginate
                paging_simple_numbers
                pagination
                float-right
              "
            >
              <div class="col-lg-12 d-flex justify-content-lg-end px-0">
                <v-pagination
                  v-model="this.currentPage"
                  :pages="totalPage"
                  :range-size="0"
                  active-color="#618bd7"
                  @update:modelValue="loadPage"
                />
              </div>
            </div>
          </div>
        </div>
        <!-- pagination ends -->
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import axios from "axios";
import store from "../../store/index";
import moment from "moment";

import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
const idtoken = store.getters.getIdToken;

export default {
  name: "PatientScheduleView",
  components: {
    VPagination,
  },
  async mounted() {
    this.defaultdate = localStorage.getItem("dateformat");
    window.addEventListener("studyIdChanged", (event) => {
      console.log("StudyIDChanged into" + event.detail.storage);
      this.getsites();
      this.selectedsubject = "";
      this.selectedsite = "";
      this.sheduleList = [];
    });
    await this.setroleprivileges();
    await this.getsites();
  },
  data() {
    return {
      defaultdate: "",
      roleprivileges: "",
      sProp:"",
      IsDesc:true,
      sites: [],
      subjects: [],
      selectedsite: "",
      selectedsubject: "",
      patientsId: "",
      patientdata: [],
      minDate: new Date().toISOString(),
      isScheduled: false,
      searchData: {
        visitName: "",
        fromDate: "",
        toDate: "",
        visitStatus: "",
        pageNo: 1,
        pageSize: 10,
      },

      oldprevisit: "",
      oldpostvisit: "",
      oldnursename: "",
      currentPage: 1,
      totalPage: 0,
      totalCount: 1,
      sheduleList: "",
      baseUrl: `${process.env.VUE_APP_Service_URL}/management/`,
      baseUrls: `${process.env.VUE_APP_Service_URL}/management/patientschedule/`,
      baseapi: process.env.VUE_APP_Service_URL,
      curntdate: new Date().toISOString().slice(0, 10),

      editShedule: "",
      investigator: "",
      visitdate: "",
      users: "",
      validations: [false, false],
      errors: [],
      siteData: "",
      subjectData: "",
    };
  },

  methods: {
    async setroleprivileges() {
      this.roleprivileges = await store.getters.getRolesprivilegeData;
    },
    filter(data) {
      if (data) {
        return moment(data).format(this.defaultdate);
      }
      console.log(data);
    },
    async viewforms(id) {
      if (id == null) {
        alert(this.$t('navigations.no records found'));
      } else {
        const fromArchive = false
        this.$router.push(`/showform/PatientScheduleView/${id}/${fromArchive}`);
      }
    },
    async validate() {
      this.validatesite(this.selectedsite, 0);
      this.validatesubject(this.selectedsubject, 1);
    },

    async validatesite() {
      if (this.selectedsite == "") {
        this.validations[0] = false;
        alert(this.$t("navigations.please select a site code"));
      } else {
        this.validations[0] = true;
      }
    },
    async validatesubject() {
      if (this.selectedsubject == "") {
        this.validations[1] = false;
        alert(this.$t('navigations.please select a subject id'));
      } else {
        this.validations[1] = true;
      }
    },
    async onChange(event) {
      console.log(event.target.value);
      this.patientsId="";
      await this.getpatientId(event.target.value);
    },
    async studychange(val) {
      await this.getsites(val);
      this.sheduleList = [];
    },
    async sitechange(val) {
      if (this.selectedsite == "") {
        this.selectedsubject = "";
        this.selectedsite = "";
      }
      await this.getsubject(val);
      this.sheduleList = [];
    },

    SortSelected(sortProp,id){
      console.log('SortPropChanged');
      const selectedElement=document.getElementById(id)
      const selectedElementID=document.getElementById(id).id
      if(sortProp!=null && id==selectedElementID)
      {       
        if(this.IsDesc == true)
        {
          // if(selectedElement.classList.contains("sortDesc")){
          //   return
          // }
           if(selectedElement.classList.contains("sortAsc"))
          {
            selectedElement.classList.remove("sortAsc")
            selectedElement.classList.add("sortDesc")
          }    
          else selectedElement.classList.add("sortDesc")
        }
        else
        {
          if(selectedElement.classList.contains("sortDesc")){
            selectedElement.classList.remove("sortDesc")
            selectedElement.classList.add("sortAsc")
          }
          // else if(selectedElement.classList.contains("sortAsc"))
          // {
          //   return
          // }          
          else selectedElement.classList.add("sortAsc")
        }
      }
      this.pageindex = 1;
      this.currentPage = 1;
      this.sProp=sortProp;
      this.IsDesc = this.IsDesc==true?false:true;
      this.searchSchedule();
    },

    async getsites() {
      const idtoken = store.getters.getIdToken;
      const currentstudy = store.getters.getStudyIs;
      console.log("currentstudycccc", currentstudy);
      await axios
        .get(
          `${this.baseapi}/management/site/getallsitesbyuser?studyId=${currentstudy}&sortProperty=siteCode&IsDes=false&getAll=false`, 
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.sites = res.data;
          this.siteData = this.sites[0].siteCode;
          console.log("The sites are", this.siteData);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getsubject(currentsite) {
      const idtoken = store.getters.getIdToken;
      await axios
        .get(
          `${this.baseapi}/management/patient/getallpatient?Siteid=${currentsite}&sortProperty=subjectId`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log(res);
          this.subjects = res.data;
          this.subjectData = this.subjects[0].subjectId;
          console.log("subjectssss", res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    validateDate(fromDate, toDate) {
      if (fromDate > toDate) {
        this.validation[0] = true;
      } else {
        this.validation[0] = false;
      }
    },
    loadPage: function (page) {
      this.currentPage = page;
      this.searchData.pageNo = page;
      this.searchSchedule();
    },
    async getUsers() {
      const valnow = await store.getters.getStudyIs;
      const idtoken = store.getters.getIdToken;
      console.log("get recipients" + valnow);
      await axios
        .get(
          `${this.baseapi}/management/study/getusersinstudy?StudyId=${valnow}`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log(res);
          this.users = res.data;
          console.log("Users......", this.users);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getpatientId() {
      const idtoken = store.getters.getIdToken;
      const currentstudy = store.getters.getStudyIs;
      console.log(currentstudy);
      console.log("selectedsiteeeeeeeeeeeeeeeeeeeee", this.selectedsite);
      this.siteId = this.selectedsite;
      this.subjectid = this.selectedsubject;
      await axios
        .get(
           `${this.baseUrl}patient/listpatientforsubjectschedule?SiteId=${this.siteId}&Studyid=${currentstudy}&SubjectId=${this.subjectid}`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.patientdata = res.data.results[0];
          if (res.data.results[0] == null || res.data.results[0] == "") {
            this.sheduleList = [];
            alert(this.$t('navigations.no records found'));
          }
          console.log("patientdataaaaaaaaaaa", this.patientdata);
          this.patientsId = this.patientdata.patientId;
          console.log("patientIddddddddddddd", this.patientsId);
          if (this.patientsId != "" && this.patientsId != "null") {
            this.getIscheduled();
          }
          this.sheduleList = [];
        });
    },
    async getIscheduled() {
      const idtoken = store.getters.getIdToken;
      await axios
        .get(
          `${this.baseapi}/management/patient/isschedule?patientId=${this.patientsId}`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
            },
          }
        )
        .then((res) => {
          this.isScheduled = res.data;
          console.log("The schedule value is", this.isScheduled);
          if (this.isScheduled != true) {
            console.log("Condition entered, not scheduled");
            this.noSchedulefound();
            console.log("hello");
          } else {
            console.log(" Scheduled");
            this.searchSchedule();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async searchScheduleClicked() {
      const idtoken = store.getters.getIdToken;
      // if (this.searchData.visitStatus == "All") {
      //   this.searchData.visitStatus = "";
      // }
      if (this.searchData.fromDate > this.searchData.toDate) {
        alert(this.$t('navigations.from date should be less than to date'));
      }
      if (this.patientsId == "") {
         this.sheduleList = [];
        alert(this.$t('navigations.no records found'));
      } else {
        console.log("in list");
        await axios
          .get(
            this.baseUrls +
              "getpatientshedule" +
              "?patientID=" +
              this.patientsId +
              "&visit=" +
              this.searchData.visitName +
              "&fromDate=" +
              this.searchData.fromDate +
              "&toDate=" +
              this.searchData.toDate +
              "&visitStatus=" +
              this.searchData.visitStatus +
              "&PageNo=" +
              this.searchData.pageNo +
              "&ItemCount=" +
              this.searchData.pageSize,              
            {
              headers: {
                Authorization: "Bearer " + idtoken,
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            this.sheduleList = [];
            console.log("dat is:", this.minDate);
            console.log("result:", res.data);
            // if(res.data.data!=0 || res.data.data!=null)
            this.sheduleList = res.data.data;

            // console.log("invsti",this.popupdata.nursename);
            // this.popupdata.popstudyid
            this.totalCount = res.data.totalCount;
            this.totalPage = parseInt(
              this.totalCount / this.searchData.pageSize
            );
            if (this.totalCount % this.searchData.pageSize != 0) {
              this.totalPage = this.totalPage + 1;
            }
          })
          .catch((err) => {
            console.log("error is", err);
          })
          .catch((err) => {
            console.log("error is", err);
          });
      }
    },

    async noSchedulefound() {
       this.sheduleList = [];
      alert(this.$t('navigations.no records found'));
      this.sheduleList = [];
    },

    async searchSchedule() {
      const idtoken = store.getters.getIdToken;
      // this.validateDate(this.searchData.fromDate, this.searchData.toDate);
      // if (this.validation.includes(true)) {
      //   alert("From date should be less than to date.");
      // } else {
      console.log("in list");
      await axios
        .get(
          this.baseUrls +
            "getpatientshedule" +
            "?patientID=" +
            this.patientsId +
            "&visit=" +
            this.searchData.visitName +
            "&fromDate=" +
            this.searchData.fromDate +
            "&toDate=" +
            this.searchData.toDate +
            "&visitStatus=" +
            this.searchData.visitStatus +
            "&PageNo=" +
            this.searchData.pageNo +
            "&ItemCount=" +
            this.searchData.pageSize +
            "&sortprop=" +
            this.sProp +
            "&isDes=" +
            this.IsDesc,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.sheduleList = [];
          console.log("dat is:", this.minDate);
          console.log("result:", res.data);
          // if(res.data.data!=0 || res.data.data!=null)
          this.sheduleList = res.data.data;

          // console.log("invsti",this.popupdata.nursename);
          // this.popupdata.popstudyid
          this.totalCount = res.data.totalCount;
          this.totalPage = parseInt(this.totalCount / this.searchData.pageSize);
          if (this.totalCount % this.searchData.pageSize != 0) {
            this.totalPage = this.totalPage + 1;
          }
        })
        .catch((err) => {
          console.log("error is", err);
        })
        .catch((err) => {
          console.log("error is", err);
        });
      // }
    },

    async getschedule() {
      await this.validate();
      if (this.validations.includes(false)) {
      } else {
        this.searchData.pageNo=1;
        this.currentPage=1;
        this.searchScheduleClicked();
      }
    },
  },
};
</script>

<style>
@import "../../assets/formpage.css";
.editicon {
  position: relative;
}
.tdtext {
  text-align: center;
}
.editicon .pointer {
  visibility: hidden;
  width: 150px;
  background-color: #616161;
  color: #fff;
  text-align: center;
  border-radius: 0.3em;
  padding: 0.3rem 0.2rem;
  right: 0;
  top: -35px;
  font-size: 0.8rem;
  font-weight: 500;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  transition: visibility 0.4s ease-in;
}
.editicon:hover .pointer {
  visibility: visible;
}
/*pagination styles*/
.pagination {
  margin: 0;
}
.Page,
.PaginationControl {
  border: 1px solid #e7eaec;
}
.Page-active {
  padding: 1rem;
  color: #ffffff;
  border: 1px solid var(--pagination-active-clr);
  border-radius: inherit;
  margin: 0;
}
.Page {
  padding: 1rem;
  border-radius: inherit;
  margin: 0;
}
.PaginationControl {
  padding: 0.42rem;
}
.scheduleBlock {
  display: flex;
  justify-content: flex-end;
}

/*.modal__wrapper {
  position: fixed;
  left: 0;
  top: 0;
 background: rgb(0 0 0 / 18%);
  height:50%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
  transition: cubic-bezier;
  overflow: scroll;
}
*/
.titleClass {
  background-color: var(--pop-up-background);
  color: white;
  height: 35px;
  padding-left: 5px;
  padding-top: 5px;
}
.form-control {
  padding: 0.2rem 0.75rem !important;
}
.form-select {
  border-radius: 0 !important;
  padding: 0.2rem 0.75rem !important;
}
.form-select:focus {
  border-color: #145faf !important;
  box-shadow: 0 0 0 !important;
}
.badge-genre {
  background-color: #b6bdbf;
  color: #ffffff;
}
.badge-grn {
  background-color: #1ab394;
  color: #ffffff;
}
.bgcolor {
  background-color: #d0f1f1;
}
</style>