<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-10 col-lg-10">
      <h2>{{ $t("navigations.visits") }}</h2>
    </div>
    <div class="col-2 col-lg-2">
      <i class="fa fa-times close my-2" @click="onclosed()"></i>
    </div>
  </div>
  <div class="wrapper wrapper-content">
    <div class="ibox-content mb-1 px-3 py-2">
      <div class="form-group row mb-0">
        <div class="col-12 col-lg-3 my-1">
          <div class="form-group mb-lg-0 pr-1">
            <label class="mb-0">{{ $t("navigations.visit no") }}</label>
            <input
              type="text"
              v-model="searchData.visitNo"
              class="form-control my-lg-0"
            />
          </div>
        </div>
        <div class="col-12 col-lg-7 my-1 pr-1">
          <div class="form-group mb-lg-0">
            <label class="mb-0">{{ $t("navigations.visit name") }}</label>
            <input
              type="text"
              v-model="searchData.FormName"
              class="form-control my-lg-0"
            />
          </div>
        </div>
        <div class="col-12 col-lg-2 align-self-end my-1">
          <span
            type="button"
            class="save_btn px-5 w-100"
            @click="SearchButtonClicked()"
            >{{ $t("navigations.search") }}</span
          >
        </div>
      </div>
    </div>
    <div class="ibox-body">
      <div class="ibox-title style_2 d-flex justify-content-between">
        <h5 class="mt-1">{{ $t("navigations.visit list") }}</h5>
        <div
          class="position-relative align-self-center"
          aria-label="Add visit"
          tabindex="0"
          v-if="roleprivileges.includes('Visit Add Visit')"
        >
          <img
            class="cursor-pointer"
            src="../../assets/img/create.png"
            alt="Add visit"
            @click="addvisit(0)"
          />
          <div class="tool-tip">{{ $t("navigations.add visit") }}</div>
        </div>
      </div>
      <div class="ibox-content">
        <div class="table-responsive">
          <table class="table table-striped table-bordered dataTables">
            <thead>
              <tr>
                <!-- <th>Study Ref No. </th> -->
                <th>
                  {{ $t("navigations.visit no") }}
                </th>
                <th
                  class="sort_block"
                  @click="SortSelected('visitFormName', 2)"
                  id="2"
                >
                  <div class="d-flex flex-row justify-content-between">
                    {{ $t("navigations.visit name") }}
                    <span>
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span>
                  </div>
                </th>
                <th
                  class="sort_block"
                  @click="SortSelected('visitType', 3)"
                  id="3"
                >
                  <div class="d-flex flex-row justify-content-between">
                    {{ $t("navigations.visit type") }}
                    <span>
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span>
                  </div>
                </th>
                <th
                  class="sort_block"
                  @click="SortSelected('preVisit', 4)"
                  id="4"
                >
                  <div class="d-flex flex-row justify-content-between">
                    {{ $t("navigations.pre-visit") }}
                    <span>
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span>
                  </div>
                </th>
                <th
                  class="sort_block"
                  @click="SortSelected('preVisit', 5)"
                  id="5"
                >
                  <div class="d-flex flex-row justify-content-between">
                    {{ $t("navigations.post-visit") }}
                    <span>
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span>
                  </div>
                </th>
                <th>
                  {{ $t("navigations.visit period days") }}
                </th>
                <th>
                  {{ $t("navigations.visit period weeks") }}
                </th>
                <th>
                  {{ $t("navigations.visit period months") }}
                </th>
                <th>
                  {{ $t("navigations.visit period year") }}
                </th>
                <!--	<th>Visit period</th>-->
                <th
                  class="sort_block"
                  @click="SortSelected('templateName', 10)"
                  id="10"
                >
                  <div class="d-flex flex-row justify-content-between">
                    {{ $t("navigations.forms") }}
                    <span>
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span>
                  </div>
                </th>
                <th>
                  {{ $t("navigations.version") }}
                </th>
                <th>{{ $t("navigations.active") }}</th>
                <th v-if="roleprivileges.includes('Visit Assign Template')">
                  {{ $t("navigations.assign template") }}
                </th>
                <th v-if="roleprivileges.includes('Visit Edit')">
                  {{ $t("navigations.edit") }}
                </th>
                <th v-if="roleprivileges.includes('Visit Delete')">
                  {{ $t("navigations.delete") }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="visit in visitList" :key="visit.visitId">
                <!-- <td>{{ visit.studyId }}</td> -->
                <td>{{ visit.visitNo }}</td>
                <td>{{ visit.visitFormName }}</td>
                <td>{{ visit.visitType }}</td>
                <td>{{ visit.preVisit }}</td>
                <td>{{ visit.postVisit }}</td>
                <td>{{ visit.visitDayPeriod }}</td>
                <td>{{ visit.visitWeekPeriod }}</td>
                <td>{{ visit.visitMonthPeriod }}</td>
                <td>{{ visit.visitYearPeriod }}</td>
                <!--<td>{{ visit.visitPeriod }}</td>-->
                <!-- <td>{{ visit.template_Name }}</td> -->
                <td class="text-center">
                  <i
                    class="fa fa-file-text-o"
                    @click.prevent="openFormsModal(visit.visitId)"
                    ></i>
                </td>
                <td>{{ visit.version }}</td>
                <td class="text-center">
                  <div class="i-checks">
                    <input
                      type="checkbox"
                      :checked="visit.active == true ? true : false"
                      name="status"
                      onclick="return false"
                    />
                  </div>
                </td>
                <td
                  class="text-center"
                  v-if="roleprivileges.includes('Visit Assign Template')"
                >
                  <i
                    class="fa fa-external-link-square edit-delete"
                    @click.prevent="openModal(visit.visitId)"
                  ></i>
                </td>
                <td
                  class="text-center"
                  v-if="roleprivileges.includes('Visit Edit')"
                >
                  <span v-if="visit.isScheduled && visit.isPatientVisit">
                    {{}}
                  </span>
                  <span v-else>
                    <i
                      class="fa fa-edit edit-delete"
                      @click="addvisit(visit.visitId)"
                    ></i>
                  </span>
                </td>
                <td
                  class="text-center"
                  v-if="roleprivileges.includes('Visit Delete')"
                >
                  <i
                    class="fa fa-trash edit-delete"
                    @click="deleted(visit.visitId)"
                  ></i>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="row mt-3 mx-0">
          <div class="col-lg-12 mx-0 px-0">
            <div
              class="dataTables_paginate paging_simple_numbers pagination float-right"
            >
              <div class="col-lg-12 d-flex justify-content-lg-end px-0">
                <v-pagination
                  v-model="currentPageNumber"
                  :pages="totalPages"
                  :range-size="0"
                  active-color="#618bd7"
                  @update:modelValue="loadPage"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- modal starts -->
  <div class="modal-wrapper-template"  v-if="openModalWrapper==true">
    <div class="modal-container-template">
      <div class="modal-title d-flex justify-content-between align-items-center">
        <h4 class="my-0">{{ $t("navigations.assign template") }}</h4>
        <div class="close-link cursor-pointer">
            <i class="fa fa-times" title="Close" @click.prevent="closeModal"></i>
        </div>
      </div>
      <div class="modal-template-body">
        <!-- test -->
          <div class="form-group row my-lg-2">
            <div class="d-flex justify-content-center">
              <div class="col-10 my-2">
                <Multiselect
                v-model="selectedTemplateIds"
                mode="multiple"
                valueProp="templateId"
                placeholder="Select Template"
                label="templateName"
                :options="templatelist"
                :hideSelected="false"
                :closeOnSelect="false"
                class="multiselect-template-theme  ml-1 mr-0"
              >
              </Multiselect>
              </div>
            </div>

            <div class="d-flex justify-content-center my-3">
                <button
                  class="btn btn-sm btn-primary px-5 mx-1"
                  @click.prevent="assignFun()"
                >
                  {{ $t("navigations.assign") }}
                </button>
            </div>
          </div>
        <!-- test -->
      </div>
    </div>
  </div>
  <!-- modal ends -->
  <!-- List visit forms modal starts -->
  <div class="modal-wrapper-forms">
    <div class="modal-container">
      <div class="titleclass">
        <h4 class="ml-lg-2">{{ $t("navigations.forms") }}</h4>
        <div class="closebutton">
          <a style="color: white" class="close-link">
            <i
              class="fa fa-times"
              title="Close"
              @click.prevent="closeModalForms"
            ></i>
          </a>
        </div>
      </div>
      <div class="modelbody">
        <!-- test -->
        <div>
          <div class="form-group row my-lg-2">
            <div class="d-flex flex-column flex-lg-row mb-lg-0">
              <div class="col-lg-12">
                <table class="table features-table">
                  <tbody>
                    <tr v-for="forms in formItems" :key="forms.id">
                      <td>{{ forms.templateName }}</td>
                      <td class="text-center">
                        <i
                          class="fa fa-eye edit-delete"
                          @click.prevent="viewForm(forms.template_ID)"
                        ></i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <!-- test -->
      </div>
    </div>
  </div>
  <!-- End of list visit forms modal -->
</template>
<script>
/* eslint-disable */
import axios from "axios";
import store from "../../store/index";
import VPagination from "@hennge/vue3-pagination";
import Multiselect from "@vueform/multiselect";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
export default {
  props: { stdid: String },
  name: "visitlist",
  components: {
    VPagination,
    Multiselect
  },
  data() {
    return {
      selectedvisitid: "",
      roleprivileges: "",
      openModalWrapper:false,
      templatelist: [],
      baseurl: process.env.VUE_APP_Service_URL,
      studyid: "",
      currentPageSize: 0,
      currentPageNumber: 0,
      visitList: [],
      selectedTemplateIds:[],
      formsArray:[],
      totalItems: 0,
      totalPages: 0,
      searchData: {
        pagesize: 0,
        pageindex: 0,
        visitNo: "",
        FormName: "",
        SortProperty: "",
        SortOrder: 0,
      },
      requestData: {
        pagesize: 0,
        pageindex: 0,
        visitNo: "",
        FormName: "",
        SortProperty: "",
        SortOrder: 0,
      },
      pageNumbers: [],
      visitides: [],
      formItems: [],
    };
  },
  beforeRouteEnter(to, from, next) {
    const previousRouteName = from.name;
    next((vm) => {
      vm.previousRouteName = previousRouteName;
    });
  },
  async mounted() {
    await this.setroleprivileges();
    await this.setstudy();
    this.currentPageSize = 10;
    this.currentPageNumber = 1;
    this.requestData.pagesize = 10;
    this.requestData.pageindex = 1;
    console.log("pas data", this.requestData);
    await this.searchAndGetData();
    await this.gettemplatedata();
  },
  computed: {
    selectedmodeltemplate() {
      return this.selectedTemplateIds.map(id => {
        return this.templatelist.find(template => template.templateId === id);
      });
    }
  },
  methods: {
    onclosed() {
      // console.log(" pathe is............", this.beforeRouteEnter)
      this.$router.push(`/studylist`);
    },
    async setroleprivileges() {
      this.roleprivileges = await store.getters.getRolesprivilegeData;
    },
    async assignFun() {
      if (this.selectedTemplateIds.length==0) {
        alert(this.$t("navigations.please select a template"));
      } else {
        const idtoken = store.getters.getIdToken;
        console.log("Value is",this.selectedmodeltemplate)
        this.selectedmodeltemplate.forEach((item,index)=>{
          this.formsArray[index]={
            "template_ID": "",
            "templateName": "",
            "version": ""
          },
          this.formsArray[index].template_ID=item.templateId
          this.formsArray[index].templateName=item.templateName
          this.formsArray[index].version=item.version
        })
        await axios
          .put(
            `${this.baseurl}/management/visit/assigntemplate`,
            {
              "visitID": this.selectedvisitid,
              "forms": this.formsArray
            },
            {
              headers: {
                Authorization: "Bearer " + idtoken,
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            alert(this.$t("navigations.template assigned successfully"));
            this.selectedmodeltemplate = []
            this.formsArray=[]
            this.selectedTemplateIds=[]
            console.log(res.data);
          })
          .catch((err) => {
            console.log("error");
            console.log(err);
          });
        
        this.closeModal();
        this.search();
      }
    },
    async gettemplatedata() {
      const idtoken = store.getters.getIdToken;
      console.log("template data function");
      console.log(this.baseurl);
      await axios
        .get(        
          `${this.baseurl}/forms/template/listtemplate?Status=Approved&StudyID=${this.studyid}&SortProperty=templateName&SortOrder=0`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log("template data is");
          console.log(res.data);
          this.templatelist = res.data.results;
        })
        .catch((err) => {
          console.log("error");
          console.log(err);
        });
    },
    async getAllForms(visitId) {
      const idtoken = store.getters.getIdToken;
      await axios
        .get(
          `${this.baseurl}/management/visit/getallforms?studyId=${this.studyid}&visitId=${visitId}`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.formItems = res.data[0];
          console.log("visit form list is...", this.formItems);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async viewForm(id) {
    this.$router.push(`/viewTemplate/studyVist/${id}`);
  },
    openModal(visitid) {
      this.selectedvisitid = visitid;
      this.openModalWrapper=true
    },
    closeModal() {
      this.selectedmodeltemplate = [];
      this.openModalWrapper=false
    },
    openFormsModal(visitid) {
      this.selectedvisitid = visitid;
      this.getAllForms(visitid);
      const modalWrapper = document.querySelector('.modal-wrapper-forms');
      modalWrapper.classList.add('active');
    },
    closeModalForms() {
      const modalWrapper = document.querySelector('.modal-wrapper-forms');
      modalWrapper.classList.remove('active');
    },
    async setstudy() {
      this.studyid = this.stdid;
      await store.dispatch("setVisitStudyID", this.stdid);
    },
    async addvisit(id) {
      this.$router.push(`/addvisit/${id}`);
    },
    async search() {
      this.requestData.visitNo = this.searchData.visitNo;
      this.requestData.FormName = this.searchData.FormName;
      console.log(this.requestData);
      await this.searchAndGetData();
    },
    async deleted(visitId) {
      this.visitides.push(visitId);
      const idtoken = store.getters.getIdToken;
      //TODO
      //  `${this.baseurl}/management/visit`,{data: this.visitides},
      if (confirm("Are you sure you want to delete this visit?")) {
        await axios
          .delete(`${this.baseurl}/management/visit/delete?id=` + visitId, {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          })
          .then((res) => {
            console.log(res);
            this.userList = res.data;
            alert(this.$t("navigations.visit deleted successfully"));
          })
          .catch((err) => {
            console.log(err);
          });
      }
      this.visitides = [];
      this.searchAndGetData();
    },
    async onPageClick(event) {
      this.requestData.pageindex = event;
      this.searchAndGetData();
    },
    async SearchButtonClicked() {
      this.requestData.pageindex = 1;
      this.currentPageNumber = 1;
      this.searchAndGetData();
    },
    SortSelected(sortProp, id) {
      console.log("SortPropChanged");
      const selectedElement = document.getElementById(id);
      const selectedElementID = document.getElementById(id).id;
      if (sortProp != null && id == selectedElementID) {
        if (this.requestData.SortOrder == 0) {
          // if(selectedElement.classList.contains("sortDesc")){
          //   return
          // }
          if (selectedElement.classList.contains("sortAsc")) {
            selectedElement.classList.remove("sortAsc");
            selectedElement.classList.add("sortDesc");
          } else selectedElement.classList.add("sortDesc");
        } else {
          if (selectedElement.classList.contains("sortDesc")) {
            selectedElement.classList.remove("sortDesc");
            selectedElement.classList.add("sortAsc");
          }
          // else if(selectedElement.classList.contains("sortAsc"))
          // {
          //   return
          // }
          else selectedElement.classList.add("sortAsc");
        }
      }
      this.requestData.pageindex = 1;
      this.currentPageNumber = 1;
      this.requestData.SortProperty = sortProp;
      this.requestData.SortOrder = this.requestData.SortOrder == 0 ? 1 : 0;
      this.searchAndGetData();
    },
    async searchAndGetData() {
      this.requestData.visitNo = this.searchData.visitNo;
      this.requestData.FormName = this.searchData.FormName;
      const pIndex = Number(this.requestData.pageindex);
      const pSize = Number(this.requestData.pagesize);
      const idtoken = store.getters.getIdToken;
      await axios
        .get(
          //`https://localhost:5001/visit/listvisit?VisitName=&VisitNo=&StudyId=be36e830-568e-47d8-9f58-b5ca2ea6adce&pageindex=1&pagesize=10`,
          `${this.baseurl}/management/visit/listvisit?VisitName=${this.requestData.FormName}&VisitNo=${this.requestData.visitNo}&StudyId=${this.studyid}&pageindex=${pIndex}&pagesize=${pSize}&SortProperty=${this.requestData.SortProperty}&SortOrder=${this.requestData.SortOrder}`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log("respp", res.data);
          if (res.data.data === "No Visit Found") {
            alert("");
            alert(this.$t("navigations.no visits were found"));
            this.visitList = [];
          } else {
            this.visitList = res.data.results;
            this.totalItems = res.data.visitCont;
            this.totalCount = res.data.totalCount;
            console.log("total count is", this.totalCount);
            this.totalPages = parseInt(this.totalCount / pSize);
            if (this.totalCount % pSize != 0) {
              this.totalPages = this.totalPages + 1;
            }
          }
          this.totalItems = res.data.userCont;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    loadPage: function (page) {
      this.currentPageNumber = page;
      this.requestData.pageindex = page;
      this.searchAndGetData();
    },
  
  },
};
</script>

<style scoped>
@import "../../assets/style.css";
@import "../../assets/formpage.css";
@import "./modal.css";
.modelbody {
  overflow: unset !important;
}

.modelbody {
  background: #fff;
  /* background-color: aquamarine;  */
  margin: 0;
  padding: 5%;
}
.modal-container {
  background: #fff;
  width: min(30%, 90%);
  border-radius: 4px;
  position: fixed;
  transition: all 0.3s ease-in-out;
  height: auto !important;
  margin-top: 200px;
}
.create-block {
  display: flex;
  justify-content: flex-end;
  padding: 0.5rem 0.75rem;
  margin-bottom: 0.25rem;
}
.headingunderline {
  text-decoration-line: underline;
}
.multiselect-template-theme {
  --ms-option-bg-selected-pointed: rgb(210, 210, 210);
  --ms-option-bg-selected: rgb(210, 210, 210);
  --ms-option-color-selected: #000;
  --ms-option-color-selected-pointed: #000;
  --ms-radius:0;
  --ms-line-height: 0.8;
  --ms-option-font-size: 0.8rem;
  --ms-font-size: 0.8rem;
}
</style>