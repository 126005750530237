<template>
    <div class="stat-card-white d-flex align-items-center">
      <div class="drilldown-path-block p-2">
        <span>Drilldown Path:</span>
      </div>
  
      <nav aria-label="breadcrumb" class="ml-2">
        <ul class="custom-breadcrumb d-flex flex-wrap">
          <li 
            v-for="(history, index) in historyarray" 
            :key="index" 
            class="custom-breadcrumb-item"
            aria-current="page"
          >
            <template v-if="index === historyarray.length - 1">
              <span class="linkclass" @click.prevent="gotolevel(history)">{{ history }}</span>
            </template>
            <template v-else>
              <span  class="linkclass" @click.prevent="gotolevel(history)">{{ history }}</span>
              <span class="separator">/</span>
            </template>
          </li>
        </ul>
      </nav>
    </div>
  </template>
  <script>
  /* eslint-disable */
  import axios from "axios";
  import moment from "moment";
  import * as d3 from "d3";
   
  export default {
    name: "historytab",
    props: {
      historyarray: {
        type: Object,
        required: true,
        default: () => [],
      },
    },
    data() {
      return {

      };
    },
    async mounted() {
    },
    methods: {
      gotolevel(history){
        this.$emit("gotohistorylevel", history);
      }
    },

  };
  </script>
   
   <style>
   @import "../../custom_lib/AiCharts/charts.css";
   </style>