`<template>
  <div class="row wrapper border-bottom white-bg page-heading">
		<div class="col-6 col-lg-10">
			<h2>{{$t('navigations.nurse diary')}}</h2>
		</div>
		<div class="col-6 col-lg-2 text-end align-self-center" v-if="defaultdashboard != 'nursediary'">
			<button type="button" class="btn btn-setdefault fontprop" @click="setasdefault()">{{$t('navigations.set as default')}} </button>
		</div>
	</div>
  <div class="wrapper wrapper-content">
    <div class="row">
        <div class="col-lg-3 mb-1 pr-0 pl-1">
            <div class="ibox-body cursor-pointer" @click.prevent="$router.push('/submitForm')">
                <div class="ibox-title style_2">
                  <span class="label label-success float-right">{{$t('navigations.next month')}}</span>
                  <h5>{{$t('navigations.upcoming visits')}}</h5>
                </div>
                <div class="ibox-content">
                    <h1 class="no-margins">{{plannedCount}}</h1>
                    <small>{{$t('navigations.upcoming visits')}}</small>
                </div>
            </div>
        </div>
         <div class="col-lg-3 mb-1 pr-0 pl-1">
            <div class="ibox-body cursor-pointer" @click.prevent="$router.push('/submitForm')">
                <div class="ibox-title style_2">
                  <span class="label label-info float-right">{{$t('navigations.last month')}}</span>
                    <h5>{{$t('navigations.overdue visits')}}</h5>
                </div>
                <div class="ibox-content">
                    <h1 class="no-margins">{{overdueCount}}</h1>
                    <small>{{$t('navigations.overdue visits')}}</small>
                </div>
            </div>
        </div>
        <div class="col-lg-3 mb-1 pr-0 pl-1">
            <div class="ibox-body cursor-pointer" @click.prevent="$router.push('/listform')">
                <div class="ibox-title style_2">
                  <div class="label label-info float-right">{{$t('navigations.last month')}}</div>
                    <h5>{{$t('navigations.completed visits')}}</h5>
                </div>
                <div class="ibox-content">
                    <h1 class="no-margins">{{completedCount}}</h1>
                    <small>{{$t('navigations.completed visits')}}</small>
                </div>
            </div>
        </div>
        <div class="col-lg-3 mb-1 pr-0 pl-1">
            <div class="ibox-body cursor-pointer" @click.prevent="$router.push('/allQueryList')">
                <div class="ibox-title style_2">
                  <div class="ibox-tools">
                                <span class="label primary-bg float-right">{{$t('navigations.monthly')}}</span>
                            </div>
                    <h5>{{$t('navigations.open query')}}</h5>
                </div>
                <div class="ibox-content">
                    <h1 class="no-margins">{{querycountstd}}</h1>
                    <small>{{$t('navigations.open query')}}</small>
                </div>
            </div>
        </div>
        <div class="col-lg-6 pr-0 pl-1 mb-1">
          <div class="ibox-body">
              <div class="ibox-title style_2 d-flex justify-content-between">
                 <h5 class="mt-1">{{$t('navigations.upcoming visits')}}</h5>      
                <div class="graphbtncontainer">
                  <div class="graphbutton"><img src="../../assets/images/bar_clr.png" @click.prevent="upcommingchange('bar')"></div>
                  <div class="graphbutton"><img src="../../assets/images/horiz_bar_clr.png" @click.prevent="upcommingchange('barhorizondal')"></div>
                  <div class="graphbutton"><img src="../../assets/images/Line_clr.png" @click.prevent="upcommingchange('line')"></div>
                  <div class="graphbutton"><img src="../../assets/images/Pie_clr.png" @click.prevent="upcommingchange('pie')"></div>
                  <div class="graphbutton"><img src="../../assets/images/bubble_clr.png" @click.prevent="upcommingchange('scatter')"></div>
                </div>
              </div>
            <div class="ibox-content">
                <div>
                  <apexchart v-if="upcommingvisitchart.charttype != 'pie'" width="100%" height="250px" :type="upcommingvisitchart.charttype" :options="upcommingvisitchart.chartOptions" :series="upcommingvisitchart.series"></apexchart>
                  <apexchart v-if="upcommingvisitchart.charttype == 'pie'" width="100%" height="250px" :type="upcommingvisitchart.charttype" :options="upcommingpiechartoptions" :series="upcommingvisitchart.piedataseries"></apexchart>
                </div>
            </div>
          </div>
        </div>
        <!-- <div class="col-lg-6 my-1 my-lg-2">
          <div class="titlemsg style_2">
             Received queries
          </div>     
          <div class="ibox-body minwidthdiv px-3">
            <div class="table-responsive strechtable">
                <table class="table table-striped table-bordered dataTables " >
                    <thead>
                    <tr>
                        <th>Query Id</th>
                        <th>Subject ID</th>
                        <th>Raised by</th>
                        <th>Date</th>
                    </tr>
                    </thead>

                    <tbody>
                        <tr v-for="list in receivedquerylist" :key="list.id">
                            <td>{{list.queryId}}</td>
                            <td>{{list.subjectId}}</td>
                            <td>{{list.createdUserEmail}}</td>
                            <td>{{list.createdAt}}</td>
                            </tr>
                    </tbody>
                </table>
            </div>
          </div>
        </div> -->

        <div class="col-lg-6 pr-0 pl-1 mb-1">
          <div class="ibox-title style_2 d-flex justify-content-between">
              <h5 class="mt-1">{{$t('navigations.past visits')}}</h5>
              <div class="graphbtncontainer">
                <div class="graphbutton"><img src="../../assets/images/bar_clr.png" @click.prevent="pastchange('bar')"></div>
                <div class="graphbutton"><img src="../../assets/images/horiz_bar_clr.png" @click.prevent="pastchange('barhorizondal')"></div>
                <div class="graphbutton"><img src="../../assets/images/Line_clr.png" @click.prevent="pastchange('line')"></div>
                <div class="graphbutton"><img src="../../assets/images/Pie_clr.png" @click.prevent="pastchange('pie')"></div>
                <div class="graphbutton"><img src="../../assets/images/bubble_clr.png" @click.prevent="pastchange('scatter')"></div>
              </div>
            </div>
      
                      <div class="ibox-content">
                <div>
                  <apexchart v-if="overduevisitchart.charttype != 'pie'" width="100%" height="250px" :type="overduevisitchart.charttype" :options="overduevisitchart.chartOptions" :series="overduevisitchart.series"></apexchart>
                  <apexchart v-if="overduevisitchart.charttype == 'pie'" width="100%" height="250px" :type="overduevisitchart.charttype" :options="overduepiechartoptions" :series="overduevisitchart.piedataseries"></apexchart>
                </div>
            </div> 
          <!-- <div class="ibox-body minwidthdiv px-3">
            <div class="table-responsive strechtable">
                <table class="table table-striped table-bordered dataTables " >
                    <thead>
                    <tr>
                        <th>Query Id</th>
                        <th>Subject ID</th>
                        <th>Raised by</th>
                        <th>Date</th>
                    </tr>
                    </thead>

                    <tbody>
                        <tr v-for="list in receivedquerylist" :key="list.id">
                            <td>{{list.queryId}}</td>
                            <td>{{list.subjectId}}</td>
                            <td>{{list.createdUserEmail}}</td>
                            <td>{{list.createdAt}}</td>
                            </tr>
                    </tbody>
                </table>
            </div>
          </div> -->
        </div>
         <div class="col-lg-6 pr-0 pl-1">
           <div class="ibox-title style_2">
              <h5>{{$t('navigations.overdue visits')}}</h5>
            </div>
           <div class="ibox-content">
            <div class="table-responsive strechtable minwidthdiv">
              <table class="table table-striped table-bordered dataTables" >
                  <thead>
                  <tr>
                      <th>{{$t('navigations.subject id')}}</th>
                      <th>{{$t('navigations.site id')}}</th>
                      <th>{{$t('navigations.visit no')}}</th>
                      <th class="sort_block" @click="SortSelected('visitDate',4)" id="4">
                        <div class="d-flex flex-row justify-content-between">{{$t('navigations.date')}}
                        <span>
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span></div></th>
                  </tr>
                  </thead>
                  <tbody>
                    <tr v-for="list in overdueVisitList" :key="list.sheduleId">
                        <td> {{list.patientNo}} </td>
                        <td> {{list.siteCode}} </td>
                        <td> {{list.visitNo}} </td>
                        <td> {{filter(list.visitDate)}} </td>
                    </tr>                  
                  </tbody>
              </table>
              </div>
               <div class="row mx-0">
                 <div class="col-lg-12 mx-0 px-0">
                  <div
                    class="
                      dataTables_paginate
                      paging_simple_numbers
                      pagination
                      float-right
                    "
                  >
                      <div class="col-lg-12 d-flex justify-content-lg-end px-0">
                        <v-pagination
                          v-model="currentoverduePage"
                          :pages="totaloverduePages"
                          active-color="#618bd7"
                          @update:modelValue="loadOverdue"
                        />
                      </div>
                  </div>
                </div>
              </div>
           </div>
        </div>
         <div class="col-lg-6 pr-0 pl-1">
           <div class="ibox-title style_2">
              <h5>{{$t('navigations.imminent visits')}}</h5>
            </div>
           <div class="ibox-content">
            <div class="table-responsive strechtable minwidthdiv">
              <table class="table table-striped table-bordered dataTables" >
                  <thead>
                  <tr>
                    <th>{{$t('navigations.subject id')}}</th>
                    <th>{{$t('navigations.site id')}}</th>
                    <th>
                      {{$t('navigations.visit no')}}
                    </th>
                    <th class="sort_block" @click="SortSelectedIm('visitDate',4)" id="4">
                      <div class="d-flex flex-row justify-content-between">
                      {{$t('navigations.date')}}
                      <span>
                    <i class="fa fa-long-arrow-up"></i>
                    <i class="fa fa-long-arrow-down"></i>
                  </span></div></th>
                  </tr>
                  </thead>
                  <tbody>
                      <tr  v-for="item in imminentVisitList" :key="item.patientId">
                        <td>{{item.patientNo}}</td>
                        <td>{{item.siteCode}}</td>
                        <td>{{item.visitNo}}</td>
                        <td> {{filter(item.visitDate)}} </td>                          
                      </tr>
                  </tbody>
              </table>
            </div>
            <div class="row mx-0">
              <div class="col-lg-12 mx-0 px-0">
                <div
                  class="
                    dataTables_paginate
                    paging_simple_numbers
                    pagination
                    float-right
                  "
                >
                    <div class="col-lg-12 d-flex justify-content-lg-end px-0">
                      <v-pagination
                        v-model="currentimminentPage"
                        :pages="totalimminentPages"
                        active-color="#618bd7"
                        @update:modelValue="loadImminent"
                      />
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
         <!-- <div class="col-lg-12 my-4 my-lg-2">
            <div class="titlemsg style_2">
                <span class="titlemsg">Patient Count</span>
            </div>
            <div class="ibox-body">
              <div class="ibox-content patientcountchartdiv">
                  <div>
                    <apexchart width="100%" height='400px' :type="secondchart.charttype" :options="secondchart.chartOptions" :series="secondchart.series"></apexchart>
                  </div>
              </div>
            </div>
        </div> -->
      <!--new form table-->
      <!-- <div class="col-lg-12 my-1 my-lg-2">
        <div class="ibox-body p-2">
          <div class="ibox p-1">
            <div class="table-responsive">
                <table class="table table-striped table-bordered dataTables" >
                    <thead>
                    <tr>
                        <th>Site code</th>
                        <th>Patient ID</th>
                        <th>Visit</th>
                        <th>Status</th>
                    </tr>
                    </thead>
                    <tbody>
                        <tr  v-for="list in formlists" :key="list.formId">
                            <td>{{list.siteId}}</td>
                            <td>{{list.subjectId}}</td>
                            <td>{{list.visitName}}</td>
                            <td>{{list.formStatus}}</td>
                            <td><i class="fa fa-eye editicon" @click.prevent="viewforms(list.formId)"></i></td>
                        </tr>
                    </tbody>
                </table>
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>
<script src="./NurseDiary.js"></script>
<style scoped>
@import "../../assets/style.css";
@import "../../assets/font-awesome/css/font-awesome.css";
.widget {
  margin-top: 0rem;
  margin-bottom: 0rem;
}
.widgetmargin {
  margin-top: -13px !important;
}
.ibox-body {
  background-color: #ffffff;
  margin: 3rem, 0;
}

.style1 {
  background-color: #3c4192 !important;
  color: #ffffff;
}
.btn-view {
  color: #fff !important;
  background-color: #bb458f !important;
  border-color: #bb458f !important;
}
.btn-view:hover {
  color: #fff;
  background-color: #666ccb;
  border-color: #666ccb;
}
.btn-view:active {
  color: rgb(255, 255, 255) !important;
  background-color: #dda2c7 !important;
  border-color: #dda2c7 !important;
}
.ibox-title {
  color: inherit;
  margin-bottom: 0;
  padding: 1rem;
  position: relative;
  clear: both;
  right: 0;
  margin-right: 0px;
  width: unset !important;
  margin-left: 0px !important;
  margin-top: 0px !important;
}
.padding_bottom {
  padding-bottom: 2rem;
}
.ibox-title h5 {
  height: 0rem;
  padding: 0rem;

}
.titlemsg {
   padding: 20px 20px;
  font-size: 15px;
  font-weight: bold;
  color: #145faf;
}
.switchbtn {
  background-color: #bb458f !important;
  height: max-content;
  border-color: #bb458f !important;
  color: white !important;
  float: right;
}
.switchbtn:active {
  height: max-content;
  border-color: #bb458f !important;
  color: white !important;
  float: right;
  background-color: #bb458f;
}
.switchbtn:hover {
  height: max-content;
  border-color: #bb458f !important;
  color: white !important;
  float: right;
  background-color: #bb458f;
}
.queryhover:hover {
  color: #3c4192 !important;
  -webkit-transition: color ease-in 400ms;
  transition: color ease-in 400ms;
  cursor: pointer;
}

.widgetminheight {
  min-height: 102px;
}
.graphbutton{
width: fit-content;
padding: 3px;
cursor: pointer
}
.graphbtncontainer{
display: flex;
gap: 5px;
}
img{
  margin-left: 0px !important;
}
.minwidthdiv{
    height: 420px;
    overflow: hidden;
}
.ibox-title h5{
    float:none;
}
   
.tableheight{
  height: 100%;
    padding: 7px;
}
.strechtable{
  height: 100%;
}
.mt-1, .my-1{
  margin-left: 0 !important;
}
.patientcountchartdiv{
overflow: auto
}
.btn-setdefault {
  position: relative;
  padding:0.2rem 0.4rem;
  text-align: center;
  font-size:0.7rem;
  background-color: #ec4758;
  color: white !important;
  border-radius:3px;
}
.ibox-content h1{
  font-weight:400;
  color:#676a6c
}
</style>
