<template>
  <div class="dynamic-dashboard-block d-flex py-3">
    <div class="dashlet-block">
      <h3>Menu</h3>
      <draggable
        class="list-group setheight"
        :list="availableDashlets"
        :group="{ name: 'people', pull: 'clone', put: false }"
        @change="log"
      >
        <div
          class="list-group-items cursor-pointer p-2 mb-2"
          v-for="element in availableDashlets"
          :key="element.name"
          @mouseenter="showTooltip($event, element.name)"
          @mouseleave="hideTooltip"
        >
          <div class="containerimage">
            <img
              :src="require('../../assets/dashlet_preview/' + element.component + '.png')"
              :width="element.minWidth - 20 + '%'"
              :height="element.minLength + 'px'"
            />
          </div>
          <h6 class="mb-0">{{ element.name }}</h6>
        </div>
      </draggable>
    </div>
    <div class="wrapper wrapper-content col-lg-10">
          <ul class="nav nav-tabs" role="tablist">
            <li v-for="tabs in usertabDashlets.tabarray" :key="tabs">
              <a
                :class="{ activeTab: showBlock === tabs.tabName }"
                @click="(showBlock = tabs.tabName), (tabname = tabs.tabName)"
                data-toggle="tab"
              >
                {{ tabs.tabName }}
              </a>
            </li>
            <li>
              <a class="createnewtab" @click="opencreatetabmodel()">
                <i class="fa fa-plus-square-o" aria-hidden="true"></i>
                <span class="tool-tip">Add new tab</span>
              </a>
            </li>
            <li>
              <a class="removecurrenttab" @click="removetab()">
                <i class="fa fa-minus-square-o redminus" aria-hidden="true"></i>
                <span class="r-tool-tip">Delete current tab</span>
              </a>
            </li>
          </ul>
          <div role="tabpanel">
            <div class="ibox-title style_2">
              <draggable
                v-if="currentTabDashlets"
                class="list-group setminheight col-lg-12 d-flex justify-content-start flex-row flex-wrap"
                :list="
                  usertabDashlets.tabarray.find((tab) => tab.tabName === showBlock)
                    .dashletElements
                "
                group="people"
                @change="log"
              >
                <div
                  class="list-group-items cursor-pointer"
                  v-for="(dashlet, index) in usertabDashlets.tabarray.find(
                    (tab) => tab.tabName === showBlock
                  ).dashletElements"
                  :key="dashlet.name"
                  
                  :style="{ height: dashlet.length + 'px', width: dashlet.width + '%' }"
                >
                  <div class="edit-btn-div" @click="editsize(index)">
                    <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                  </div>
                  <div class="delete-btn-div" @click="deleteDashlet(index)">
                    <i class="fa fa-trash" aria-hidden="true"></i>
                  </div>
                  <component :is="dashlet.component" @click.prevent="handleDashletClick(dashlet.name)"></component>
                </div>
              </draggable>
              <div class="col-12 text-right">
                <button
                  type="button"
                  class="save_btn py-1 px-4"
                  @click="saveorupdate()"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

  <!-- modal starts -->
  <div v-if="opencreatetabmodelvar" class="privilege__modal__wrapper">
    <div class="privilege__modal__container">
      <div class="privilege__modal__title d-flex justify-content-between py-2 px-3">
        <h4 class="my-0">Create Tab</h4>
        <div class="cursor-pointer">
          <i class="fa fa-times" @click.prevent="closecreatetab()"></i>
        </div>
      </div>
      <!-- Popup -->
      <div class="privilege__modal__content p-3">
        <form>
          <div class="form-group">
            <label for="exampleInputEmail1">Tab name </label>
            <input
              v-model="tabname"
              maxlength="20"
              type="test"
              class="form-control"
              id="exampleInputEmail1"
            />
            <div v-if="errors[0]" class="errormsg">Please enter a name</div>
          </div>
          <div class="form-group text-center">
            <button type="button" class="btn btn-primary py-1" @click="savetab()">
              Save
            </button>
          </div>
        </form>
        <!-- <h1> content here</h1> -->
      </div>
    </div>
  </div>
  <!-- modal starts -->
  <div v-if="setsizepopup == true" class="privilege__modal__wrapper">
    <div class="privilege__modal__container">
      <div class="privilege__modal__title d-flex justify-content-between py-2 px-3">
        <h4 class="my-0">Select width</h4>
        <div class="cursor-pointer">
          <i class="fa fa-times" @click.prevent="closepopup()"></i>
        </div>
      </div>
      <!-- Popup -->
      <div class="privilege__modal__content p-3">
        <form>
          <div class="form-group">
            <div class="d-flex">
              <label for="exampleInputEmail1"
                >height:{{
                  usertabDashlets.tabarray.find((tab) => tab.tabName === showBlock)
                    .dashletElements[selectedindex].length
                }}
              </label>
            </div>
            <div class="d-flex">
              {{
                usertabDashlets.tabarray.find((tab) => tab.tabName === showBlock)
                  .dashletElements[selectedindex].minLength
              }}
              <input
                v-model="
                  usertabDashlets.tabarray.find((tab) => tab.tabName === showBlock)
                    .dashletElements[selectedindex].length
                "
                type="range"
                :min="
                  usertabDashlets.tabarray.find((tab) => tab.tabName === showBlock)
                    .dashletElements[selectedindex].minLength
                "
                max="700"
                class="form-range"
                id="exampleInputEmail1"
              />700
            </div>
          </div>
          <div class="form-group">
            <label for="exampleInputPassword1"
              >width:
              {{
                usertabDashlets.tabarray.find((tab) => tab.tabName === showBlock)
                  .dashletElements[selectedindex].width
              }}</label
            >
            <div class="d-flex">
              {{
                usertabDashlets.tabarray.find((tab) => tab.tabName === showBlock)
                  .dashletElements[selectedindex].minWidth
              }}<input
                v-model="
                  usertabDashlets.tabarray.find((tab) => tab.tabName === showBlock)
                    .dashletElements[selectedindex].width
                "
                type="range"
                :min="
                  usertabDashlets.tabarray.find((tab) => tab.tabName === showBlock)
                    .dashletElements[selectedindex].minWidth
                "
                max="100"
                class="form-range"
                id="exampleInputPassword1"
                placeholder="Password"
              />100
            </div>
          </div>
        </form>
        <!-- <h1> content here</h1> -->
      </div>
    </div>
  </div>
</template>
<script src="./dynamicdashboard.js"></script>
<style scoped>
.containerimage img {
  width: 100%;
  height: 100%;
  object-fit: contain; /* The image is scaled to maintain its aspect ratio while fitting within the element’s content box */
}
.setheight {
  height: 620px;
  overflow-y: auto;
}
.privilege__modal__title {
  background-color: var(--pop-up-background);
  color: #ffffff;
}
.privilege__modal__container {
  width: min(90%, 300px);
  position: fixed;
  background: rgb(255, 255, 255);
  border-radius: 4px;
  overflow-y: hidden;
}
.privilege__modal__wrapper {
  position: fixed;
  inset: 0;
  background: rgb(0 0 0 / 18%);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 3000;
}
.setminheight {
  min-height: 310px;
  min-width: 45px;
  background-color: white;
}
.col-10.bg-white {
  position: relative;
}
.autowidth {
  width: fit-content;
  border: none;
}
.list-group-items {
  margin-bottom: 1px;
  position: relative; /* Ensure the delete button is positioned relative to this container */
}
.delete-btn-div {
  position: absolute;
  top: 0;
  right: 0;
  display: none; /* Hide by default */
  cursor: pointer;
  z-index: 100;
  color: red;
  border-color: red;
  font-size: 18px;
}
.list-group-items:hover .delete-btn-div {
  display: block; /* Show the delete button on hover */
}
.edit-btn-div {
  position: absolute;
  top: 0;
  right: 20px;
  display: none; /* Hide by default */
  cursor: pointer;
  z-index: 100;
  color: blue;
  border-color: blue;
  font-size: 18px;
}
.list-group-item:hover .edit-btn-div {
  display: block; /* Show the delete button on hover */
}
.tabs-container .panel-body {
  background: #fff;
  border: 1px solid #e7eaec;
  border-radius: 2px;
  padding: 20px;
  position: relative;
}

.tabs-container .nav-tabs > li.active > a,
.tabs-container .nav-tabs > li.active > a:hover,
.tabs-container .nav-tabs > li.active > a:focus {
  border: 1px solid #e7eaec;
  border-bottom-color: transparent;
  background-color: #fff;
}

.tabs-container .nav-tabs > li {
  float: left;
  margin-bottom: -1px;
}

.tabs-container .tab-pane .panel-body {
  border-top: none;
}

.tabs-container .nav-tabs > li.active > a,
.tabs-container .nav-tabs > li.active > a:hover,
.tabs-container .nav-tabs > li.active > a:focus {
  border: 1px solid #e7eaec;
  border-bottom-color: transparent;
}

.tabs-container .nav-tabs {
  border-bottom: 1px solid #e7eaec;
}

.tabs-container .tab-pane .panel-body {
  border-top: none;
}

.tabs-container .tabs-left .tab-pane .panel-body,
.tabs-container .tabs-right .tab-pane .panel-body {
  border-top: 1px solid #e7eaec;
}

.tabs-container .tabs-below > .nav-tabs,
.tabs-container .tabs-right > .nav-tabs,
.tabs-container .tabs-left > .nav-tabs {
  border-bottom: 0;
}

.tabs-container .tabs-left .panel-body {
  position: static;
}

.tabs-container .tabs-left > .nav-tabs,
.tabs-container .tabs-right > .nav-tabs {
  width: 20%;
}

.tabs-container .tabs-left .panel-body {
  width: 80%;
  margin-left: 20%;
}

.tabs-container .tabs-right .panel-body {
  width: 80%;
  margin-right: 20%;
}

.tabs-container .tab-content > .tab-pane,
.tabs-container .pill-content > .pill-pane {
  display: none;
}

.tabs-container .tab-content > .active,
.tabs-container .pill-content > .active {
  display: block;
}

.tabs-container .tabs-below > .nav-tabs {
  border-top: 1px solid #e7eaec;
}

.tabs-container .tabs-below > .nav-tabs > li {
  margin-top: -1px;
  margin-bottom: 0;
}

.tabs-container .tabs-below > .nav-tabs > li > a {
  -webkit-border-radius: 0 0 4px 4px;
  -moz-border-radius: 0 0 4px 4px;
  border-radius: 0 0 4px 4px;
}

.tabs-container .tabs-below > .nav-tabs > li > a:hover,
.tabs-container .tabs-below > .nav-tabs > li > a:focus {
  border-top-color: #e7eaec;
  border-bottom-color: transparent;
}

.tabs-container .tabs-left > .nav-tabs > li,
.tabs-container .tabs-right > .nav-tabs > li {
  float: none;
  word-break: break-word;
  width: 100%;
}

.tabs-container .tabs-left > .nav-tabs > li > a,
.tabs-container .tabs-right > .nav-tabs > li > a {
  margin-right: 0;
  margin-bottom: 3px;
}

.tabs-container .tabs-left > .nav-tabs {
  float: left;
  margin-right: 19px;
}

.tabs-container .tabs-left > .nav-tabs > li > a {
  margin-right: -1px;
  -webkit-border-radius: 4px 0 0 4px;
  -moz-border-radius: 4px 0 0 4px;
  border-radius: 4px 0 0 4px;
}

.tabs-container .tabs-left > .nav-tabs a.active,
.tabs-container .tabs-left > .nav-tabs a.active:hover,
.tabs-container .tabs-left > .nav-tabs a.active:focus {
  border-color: #e7eaec transparent #e7eaec #e7eaec;
}

.tabs-container .tabs-right > .nav-tabs {
  float: right;
  margin-left: 19px;
}

.tabs-container .tabs-right > .nav-tabs > li > a {
  margin-left: -1px;
  -webkit-border-radius: 0 4px 4px 0;
  -moz-border-radius: 0 4px 4px 0;
  border-radius: 0 4px 4px 0;
}

.tabs-container .tabs-right > .nav-tabs a.active,
.tabs-container .tabs-right > .nav-tabs a.active:hover,
.tabs-container .tabs-right > .nav-tabs a.active:focus {
  border-color: #e7eaec #e7eaec #e7eaec transparent;
  z-index: 1;
}

.tabs-container .tabs-right > .nav-tabs li {
  z-index: 1;
}

.nav-tabs .nav-link:not(.active):focus,
.nav-tabs .nav-link:not(.active):hover {
  border-color: transparent;
}

@media (max-width: 767px) {
  .tabs-container .nav-tabs > li {
    float: none !important;
  }

  .tabs-container .nav-tabs > li.active > a {
    border-bottom: 1px solid #e7eaec !important;
    margin: 0;
  }
}
.tool-tip {
  visibility: hidden;
  width: max-content;
  background-color: #616161;
  color: #fff;
  text-align: center;
  border-radius: 0.3em;
  padding: 0.3rem 0.8rem;
  left: 40px;
  top: -15px;
  font-size: 0.8rem;

  /* Position the tooltip */
  position: absolute;
  z-index: 1000;
  -webkit-transition: visibility 75ms ease-in;
  transition: visibility 75ms ease-in;
}

.createnewtab:hover .tool-tip,
.createnewtab:focus .tool-tip {
  visibility: visible;
  -webkit-transition: visibility 30ms ease-in;
  transition: visibility 30ms ease-in;
}
.createnewtab {
  background: white;
  border: solid 1px #c1c1c1;
  border-radius: 5px;
  font-size: 15px;
  padding: 0.4rem 0.8rem;
  margin-left: 4px;
  position: relative;
}
.r-tool-tip {
  visibility: hidden;
  width: max-content;
  background-color: #616161;
  color: #fff;
  text-align: center;
  border-radius: 0.3em;
  padding: 0.3rem 0.8rem;
  right: 44px;
  top: -15px;
  font-size: 0.8rem;

  /* Position the tooltip */
  position: absolute;
  z-index: 1000;
  -webkit-transition: visibility 75ms ease-in;
  transition: visibility 75ms ease-in;
}
.removecurrenttab {
  background: white;
  border: solid 1px red;
  border-radius: 5px;
  font-size: 15px;
  padding: 0.4rem 0.8rem;
  right: 2%;
  position: absolute;
}

.removecurrenttab:hover .r-tool-tip,
.removecurrenttab:focus .r-tool-tip {
  visibility: visible;
  -webkit-transition: visibility 30ms ease-in;
  transition: visibility 30ms ease-in;
}
.redminus {
  color: red;
}
.list-group-items {
  position: relative;
  display: block;
  padding: 0.2rem;
  color: #212529;
  text-decoration: none;
  background-color: #fff;
  /* border: 1px solid rgba(0,0,0,.125); */
}
.errormsg {
  font-size: 14px;
  color: red;
}
.dashlet-block {
  width: 200px;
  position: sticky;
  top:0;
}
.dynamic-dashboard-block
{
  height:700px;
  overflow-y: scroll;
}
</style>
