<template>
    <div class="row wrapper border-bottom white-bg page-heading">
        <div class="col-lg-6 align-self-center">
            <h2 class="mb-0">{{ $t("navigations.vital signs result overtime") }}</h2>
        </div>
        <div class="col-lg-2">
            <i class="fa fa-times close" @click="$router.push(`/analyticalDashboard`)"></i>
        </div>
    </div>
    <div class="wrapper wrapper-content">
        <div class="row">
            <div class="col-md-12 pl-1 mb-2">
                <historytab :historyarray="historykeys" @gotohistorylevel="gotohistorylevel" />
            </div>
            <div class="col-12 col-md-6 mb-2 pl-1">
                <stackedbarchart :availableColumns="arrayofkeys" xAxisKey="SITEID" yAxisKey="CMTRT"
                    segmentKey="CMTRT" :loader="loader" :data="data" :templateId="''"
                    :fromPage="'descriptiveStatistics'" chartlabel="Subject-Specific Medication Profile"
                    @drilldowncurrentchart="drilldowncurrentchartforbar" @opendrillpopup="drillpopupopened" />
            </div>
            <div class="col-12 col-md-6 mb-2">
                <D3LineChart :data="data"  :loader="loader" :templateId="templateId" :chartlabel="'Trends in Assessment Results Over Time'" @opendrillpopup="drillpopupopened" @drilldowncurrentchart="drilldowncurrentchartforbar" />
            </div>
            <div class="col-md-12 mb-2  pl-1">
                <histogram
                xAxisKey="VSORRES"
                yAxisKey="Frequency"
                xAxislabel="VSORRES"
                yAxislabel="Frequency (Count of subjects) "
                :loader="loader"
                :data="data"
                :tableData="data"
                :xaxisvariables="arrayofkeys"
                :templateId="templateId"
                chartlabel=" Distribution of Original Results/Dosage Amounts "
                :fromPage="'descriptiveStatistics'"
                @drilldowncurrentchart="drilldowncurrentchartforbar"
                @opendrillpopup="drillpopupopened"
                />
            </div>
            <div class="col-lg-12 pl-1 mb-1">
                <div class="stat-card-white ">
                    <div class="stat-header-white">
                        <span>Patient Distribution</span>
                    </div>
                    <div class="stat-body-white">
                        <advtable :loader="loader" :data="data" fromPage="patientRetention"
                            @drilldowncurrentchart="drilldowncurrentchartforbar" />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <chartdrill v-if="drillpopup" :drilldata="drilldata" :templateId="templateId" :fromPage="'adverseEventsPop'"
    @closepopup="drillpopup=false" />
</template>
<script src="./vitalSignsResultOvertime.js"></script>
<style scoped>
@import url("../../custom_lib/AiCharts/charts.css");
</style>