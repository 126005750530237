<template>
  <div class="stat-card-white">
    <div class="stat-header-white">
      <span>{{ chartlabel }}</span>
      <div v-show="!loader" class="d-flex align-items-center" v-if="!loader">
        <select class="graph-select py-0" v-model="selectedXAxis" id="x-axis-select" @change="updateChart">
          <option v-for="option in xAxisOptions" :key="option" :value="option">{{ option }}</option>
        </select>
        <div v-if="!loader" class="expand-block px-2 mb-1 cursor-pointer position-relative">
            <img @click="showtable()" src="../../assets//img/table_view.png" alt="More block">
            <div class="more-expand">
              <span>Data Overview</span>
            </div>
          </div>
        <div class="expand-block px-2 mb-1 cursor-pointer" @click.prevent="expandBlock = true">
          <img src="../../assets//img/expand.png" alt="Expand block">
        </div>
      </div>
    </div>

    <div class="stat-body-white chart-container d-flex flex-column" ref="chartContainerBlock">
      <div v-if="loader" class="loader"></div>
      <div v-show="!loader">
        <div v-if="!loader && selectedGroups.length!=0" class="selectdrill d-flex flex-row-reverse gap-2 w-100">
        <img class="cursor-pointer" src="../../assets/img/chart_cancel.png" @click.prevent="cancelselection()" alt="Save profile"/>
        <img class="cursor-pointer" src="../../assets/img/chart_submit.png" @click.prevent="recreatechart()" alt="Save profile"/>
      </div>
        <svg ref="chartContainer"></svg>
        <div ref="tooltip" class="tooltip"></div>
      </div>
    </div>
  </div>
  <enlargerpopup v-if="expandBlock==true" @closeModal="expandBlock=false,createChart()">
    <div
      class="stat-body-white d-flex flex-column col-md-12">
      <div v-show="!loader" class="histogram-container">
        <div v-if="!loader && selectedGroups.length!=0" class="selectdrill d-flex flex-row-reverse gap-2 w-100">
        <img class="cursor-pointer" src="../../assets/img/chart_cancel.png" @click.prevent="cancelselection()" alt="Save profile"/>
        <img class="cursor-pointer" src="../../assets/img/chart_submit.png" @click.prevent="recreatechart()" alt="Save profile"/>
      </div>
      <div class="histogram-content">
        <svg ref="popupChartContainer"></svg>
      </div>
  </div>
    </div>
</enlargerpopup>
</template>

<script>
import * as d3 from 'd3';
import axios from "axios";
import store from "../../store/index";
import enlargerpopup from "../AiCharts/popupfiles/enlarge_popup.vue"
export default {
  name: 'LineChart',
  props: {
    data: {
      type: Array,
      required: true,
      default: () => [],
    },
    templateId: {
      type: String,
      default: ''
    },
    chartlabel: {
      type: String,
      default: "Interactive Analysis",
    },
    loader: {
      type: Boolean,
      default: false,
    },
    fromPage:{
      type: String,
      default: ''
    },
    graphwidth: {
      type: Number,
      default: 500,
    },
    graphheight: {
      type: Number,
      default: 350,
    },
  },
  components:{
    enlargerpopup
  },
  data() {
    return {
      idtoken: "",
      baseApi: process.env.VUE_APP_Service_URL,
      mappings: {},
      showMore:false,
      expandBlock:false,
      svg: null,
      tooltip: null,
      chartContainer:null,
      selectedXAxis: '',
      selectedYAxis: '',
      selectedStatistic: 'Count',
      selectedCategory: 'GENDER',
      xAxisOptions: [],
      yAxisOptions: [],
      statisticOptions: ['Count'],
      categoryOptions: ['GENDER', 'MARITALSTATUS'],
      sortdata: [],
      filterkeyword:"",
      filterarray:[],
      selectedGroups: [],
      mouseX: 0,
      mouseY: 0,
    };
  },
  watch: {
    data: {
      handler() {
        this.loadQuantitativeAttributes();
        this.cancelselection();
        this.createChart();
      },
      deep: true,
    },
    expandBlock(newValue) {
      if (newValue) {
        this.$nextTick(() => {
          this.cancelselection();
          this.createChart();
        });
      }
    },
    selectedXAxis() {
      this.cancelselection();

    },
  },
  async beforeMount() {
    this.idtoken = store.getters.getIdToken;
    await this.createmapping();
    this.loadQuantitativeAttributes();
  },
  mounted() {
    window.addEventListener('mousemove', this.updateMousePosition);
    this.chartContainer = this.$refs.chartContainer;
    this.tooltip = this.$refs.tooltip;
    this.createChart();
  },
  beforeUnmount() {
    window.removeEventListener('mousemove', this.updateMousePosition);
  },
  methods: {
    async cancelselection() {
  this.selectedGroups = [];
  this.sortdata = [];
  this.createChart();
},
backtooriginal() {
      this.sortdata = [];
      this.selectedGroups = []; // Reset selection
    },
    showtable() {
      this.showMore = false
      this.$emit("opendrillpopup", this.data);
    },
    updateMousePosition(event) {
      // Get the bounding rectangle of the component
      if (this.$refs.chartContainer) {
        const rect = this.$refs.chartContainer.getBoundingClientRect();

        // Calculate the mouse X and Y positions relative to the component
        this.mouseX = event.clientX - rect.left;
        this.mouseY = event.clientY - rect.top;
      }
      else {
        return
      }

    },
    async createmapping() {
      try {
        const response = await axios.get(
          `${this.baseApi}/forms/templatedesign/getpagesbyid?formId=${this.templateId}&version=1.0`,
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          }
        );
        if (response.data) {
          const pages = response.data.pages;
          pages.forEach(page => {
            page.field.forEach(field => {
              if (field.options && field.options.length > 0) {
                this.mappings[field.fieldName] = field.options.reduce((map, option) => {
                  map[option.codevalue] = option.optionname;
                  return map;
                }, {});
              }
            });
          });
        }
        console.log("this.mappings", this.mappings);
      } catch (error) {
        console.log("Error creating mappings", error);
      }
    },
    loadQuantitativeAttributes() {
      const sampleData = (this.data && this.data.length > 0 && this.data[0]) ? this.data[0] : {};

      const quantitativeAttributes = [];
      const qualitativeAttributes = [];
      const categories = [];

      Object.keys(sampleData).forEach(key => {
        const uniqueValues = new Set(this.data.map(item => item[key])).size;
        if (uniqueValues < 6) {
          qualitativeAttributes.push(key);
          categories.push(key);
        } else {
          quantitativeAttributes.push(key);
        }
      });
      this.categoryOptions = categories;
      this.selectedCategory = categories[0];
      this.xAxisOptions = qualitativeAttributes;
      this.selectedXAxis = qualitativeAttributes[1] || '';
      this.yAxisOptions = quantitativeAttributes;
      this.selectedYAxis = quantitativeAttributes[0] || '';
    },
    updateChart() {
      // Clear existing chart
      d3.select(this.$refs.chartContainer).selectAll('*').remove();
      // Re-create the chart with new settings
      this.createChart();
    },
    createChart() {
      this.chartContainer = this.expandBlock ? this.$refs.popupChartContainer : this.$refs.chartContainer;
      const margin = { top: 20, right: 30, bottom: 20, left: 60 };
      const width = this.graphwidth - margin.left - margin.right;
      const height = this.graphheight - margin.top - margin.bottom;
      const chartWidth = width - margin.left - margin.right;
      const chartHeight = height - margin.top - margin.bottom;
      const aggregatedData = this.aggregateData(this.data);

      if (aggregatedData.length === 0) {
        console.error('Aggregated data is empty');
        return;
      }

      const subCategories = Array.from(new Set(aggregatedData.flatMap(d => d.values.map(v => v.category))));

      const customColors = [
        '#1f77b4', '#ff7f0e', '#2ca02c', '#d62728', '#9467bd', '#8c564b'
      ];

      const colorScale = d3.scaleOrdinal()
        .domain(subCategories)
        .range(customColors);

      this.xScale = d3.scaleBand()
        .domain(aggregatedData.map(d => d.key))
        .range([0, chartWidth])
        .padding(0.1);

      const maxYValue = d3.max(aggregatedData, d => d3.max(d.values, v => v.value)) || 0;
      const yAxisLimit = maxYValue + maxYValue * 0.1;

      this.yScale = d3.scaleLinear()
        .domain([0, yAxisLimit])
        .nice()
        .range([chartHeight, 0]);

      d3.select(this.chartContainer).selectAll('*').remove();

      this.svg = d3.select(this.chartContainer)
        .attr('width', width + margin.left + margin.right)
        .attr('height', height + margin.top + margin.bottom)
        .append('g')
        .attr('transform', `translate(${margin.left},${margin.top})`);

      const tooltip = d3.select(this.$refs.tooltip);

      const line = d3.line()
        .x(d => this.xScale(d.key) + this.xScale.bandwidth() / 2)
        .y(d => this.yScale(d.value))
        .curve(d3.curveMonotoneX);

      subCategories.forEach(category => {
        const categoryData = aggregatedData.map(d => {
          const foundValue = d.values.find(v => v.category === category);

          return {
            key: d.key,
            value: foundValue ? foundValue.value : 0,
            fullData: foundValue ? foundValue.fullData : []
          };

        });

        this.svg.append('path')
          .datum(categoryData)
          .attr('fill', 'none')
          .attr('stroke', colorScale(category))
          .attr('stroke-width', 1.5)
          .attr('d', line)
          .on('mouseover', (event, d) => {
            d3.select(this.tooltip)
              .style('opacity', 1)
              .html(`Category : ${d.key}<br/> Value : ${d.value}`)
              .style('left', this.mouseX + "px")
              .style('top', this.mouseY + "px");
          })
          .on('mousemove', (event, d) => {
            d3.select(this.tooltip)              
              .style('left', `${this.mouseX}px`)
              .style('top', `${this.mouseY}px`);
          })
          .on('mouseout', function () {
            tooltip.style('opacity', 0);
          });

        this.svg.selectAll('.dot')
          .data(categoryData)
          .enter().append('circle')
          .attr('class', 'dot')
          .attr('cx', d => this.xScale(d.key) + this.xScale.bandwidth() / 2)
          .attr('cy', d => this.yScale(d.value))
          .attr('r', 3)
          .attr('fill', colorScale(category))
          .attr("fill", (d) =>
          this.selectedGroups.length!=0?(this.selectedGroups.includes(d.key) ? "red" : colorScale(category)):colorScale(category)
        )
          .on('mouseover', (event, d) => {
            const originalColor = d3.select(event.currentTarget).attr('fill');
            d3.select(event.currentTarget)
      .attr('fill', 'orange');
            d3.select(this.tooltip)
              .style('opacity', 1)
              .html(`Category : ${d.key}<br/> Value : ${d.value}`)
              .style('left', this.mouseX + "px")
              .style('top', this.mouseY + "px");
              d3.select(event.currentTarget).attr('data-original-color', originalColor);
          })
          .on('mousemove', (event, d) => {
            d3.select(this.tooltip)            
              .style('left', this.mouseX + "px")
              .style('top', this.mouseY + "px");
          })
          .on('mouseout', function () {
            tooltip.style('opacity', 0);
            const originalColor = d3.select(event.currentTarget).attr('data-original-color');
            d3.select(event.currentTarget)
      .attr('fill', originalColor);
          })
          .on('click', (event, d) => {
      // Log the data for the clicked node
      // this.drilldownfuntion(d);
      this.clickbarinchart(d.fullData,d.key )
    });
      });

      this.svg.append('g')
        .attr('class', 'x-axis')
        .attr('transform', `translate(0,${chartHeight})`)
        .call(d3.axisBottom(this.xScale))
        .selectAll("text")
        .style("text-anchor", "end")
        .attr("dx", "-.8em")
        .attr("dy", ".15em")
        .attr("transform", "rotate(-45)");

      this.svg.append('g')
        .attr('class', 'y-axis')
        .call(d3.axisLeft(this.yScale));
    },
    validateData(data) {
      return data.filter(
        (d) => d[this.selectedXAxis] !== undefined
      );
    },
    aggregateData(data) {
  const validData = this.validateData(data);

  const nestedData = d3.group(validData, d => this.getMappedValue(d[this.selectedXAxis], this.selectedXAxis), d => d[this.selectedCategory]);
  const aggregatedData = Array.from(nestedData, ([key, values]) => ({
    key,
    values: Array.from(values, ([category, records]) => ({
      category: this.mappings[this.selectedCategory],
      value: records.length,
      fullData: records // Store the full data for each node
    }))
  }));
  return aggregatedData;
},
clickbarinchart(filteredData, filteredkeyword) {
    if (this.selectedGroups.includes(filteredkeyword)) {
        this.selectedGroups = this.selectedGroups.filter((k) => k !== filteredkeyword);
        let result = this.sortdata.filter(item => !filteredData.includes(item));
        this.sortdata = result;
        let filterarraydeleted = this.filterarray.filter(item => `(${filteredkeyword})` != item);
        this.filterarray = filterarraydeleted;
        this.filterkeyword = `${this.selectedXAxis}(${this.selectedGroups})`;
      } else {
    this.selectedGroups.push(filteredkeyword);
        this.filterarray.push(`(${filteredkeyword})`)
        this.filterkeyword = `${this.selectedXAxis}(${this.selectedGroups})`;
        this.sortdata = this.sortdata.concat(filteredData);
      }
      this.createChart();
  },
  recreatechart() {
      // this.$emit("opendrillpopup", this.sortdata);
      const dataToEmit = {
    sortedData: this.sortdata,
    filterkeyword: this.filterkeyword
  };
      this.$emit("drilldowncurrentchart", dataToEmit);
    },
drilldownfuntion(value){
      const dataToEmit = {
        sortedData: value.fullData,
        filterkeyword: `${this.selectedXAxis}(${value.key})`
      };
      this.$emit("drilldowncurrentchart", dataToEmit);
      console.log('Data array for clicked bar:', value);
    },
    getMappedValue(value, key) {
       // If the value is empty or undefined, return "null"
  if (!value || value === "") {
    return "null";
  }
      return this.mappings[key] ? this.mappings[key][value] || value : value;
    },
  },
};
</script>

<style scoped>
@import "../../custom_lib/AiCharts/charts.css";

.line-chart-wrapper {
  overflow-x: hidden;
  width: 100%;
}

.line-chart {
  position: relative;
}
</style>
