<template>
  <!-- modal starts -->
  <div class="study_profile_modal__wrapper">
    <div class="study_profile_modal__container custom-width">
      <div class="modal__title d-flex justify-content-between py-2 px-3">
        <h4 class="my-0">{{ $t("navigations.create subject") }}</h4>
        <div class="cursor-pointer" title="Close" @click.prevent="closeModal">
          <i class="fa fa-times"></i>
        </div>
      </div>
      <div class="ibox-content p-3">
        <div class="form-group row">
          <input type="hidden" name="patientId" v-model="values.patientId" />
          <div class="col-lg-4 mb-1">
            <label class="col-form-label"
              >{{ $t("navigations.site code")
              }}<sup><i class="fa fa-asterisk required"></i></sup
            ></label>
            <select
              class="form-control"
              name="account"
              v-model="values.siteId"
              :disabled="values.status != 'New' && values.status != ''"
            >
              <option
                v-for="site in sites"
                :key="site.siteID"
                :value="site.siteID"
              >
                {{ site.siteCode }}
              </option>
            </select>
            <span class="errorClass" v-if="errors[0] == false">{{
              $t("navigations.please select a site code")
            }}</span>
          </div>
          <div class="col-lg-4 mb-1">
            <label class="col-form-label"
              >{{ $t("navigations.subject id")
              }}<sup><i class="fa fa-asterisk required"></i></sup
            ></label>
            <input
              type="text"
              class="form-control"
              maxlength="20"
              v-model="values.subjectId"
              :disabled="values.status != 'New' && values.status != ''"
            />
            <span class="errorClass" v-if="errors[1] == false">{{
              $t("navigations.please enter a subject ID")
            }}</span>
          </div>
          <div class="col-lg-4 mb-1">
            <label class="col-form-label"
              >{{ $t("navigations.enrolment date")
              }}<sup
                ><i class="fa fa-asterisk required" v-if="showMandatory"></i
              ></sup>
            </label>
            <input
              type="date"
              max="9999-12-31"
              class="form-control"
              v-model="values.enrollmentDate"
              :disabled="patientId != '0' && values.isSchedule == true"
            />
            <span class="errorClass" v-if="errors[2] == false">{{
              $t("navigations.please select enrolment date")
            }}</span>
          </div>

          <div class="col-lg-4 mb-1">
            <label class="col-form-label">{{
              $t("navigations.investigator")
            }}</label>
            <select
              class="form-select"
              name="account"
              v-model="values.nurseName"
            >
              <option
                v-for="user in users"
                :key="user.userID"
                :value="user.email"
              >
                {{ user.name }}
              </option>
            </select>
          </div>
          <div class="col-lg-4 mb-1">
            <label class="col-form-label">{{
              $t("navigations.pre-visit")
            }}</label>
            <input
              type="text"
              class="form-control"
              maxlength="3"
              v-model="values.preVisit"
              :disabled="patientId != '0' && values.isSchedule == true"
              @change.prevent="numberonlyvalidation('preVisit')"
            />
          </div>
          <div class="col-lg-4 mb-1">
            <label class="col-form-label">{{
              $t("navigations.post-visit")
            }}</label>
            <input
              type="text"
              class="form-control"
              maxlength="3"
              v-model="values.postVisit"
              :disabled="patientId != '0' && values.isSchedule == true"
              @change.prevent="numberonlyvalidation('postVisit')"
            />
          </div>
          <div class="col-lg-3 mb-1">
            <label class="col-form-label"
              >{{ $t("navigations.country") }}
            </label>
            <select class="form-select" name="account" v-model="values.country">
              <option
                v-for="country in countries"
                :key="country.code"
                :value="country.code"
              >
                {{ country.name }}
              </option>
            </select>
          </div>
          <div class="col-lg-3 mb-1">
            <label class="col-form-label">{{ $t("navigations.city") }}</label>
            <input
              type="text"
              class="form-control"
              v-model="values.city"
              :maxlength="maxLength"
            />
          </div>
          <div class="col-lg-3 mb-1">
            <label class="col-form-label">{{
              $t("navigations.post/zip code")
            }}</label>
            <input
              type="text"
              class="form-control"
              v-model="values.zipCode"
              :maxlength="maxZip"
            />
          </div>
          <div class="col-lg-3 mb-1">
            <label class="col-form-label"
              >{{ $t("navigations.language")
              }}<sup><i class="fa fa-asterisk required"></i></sup
            ></label>
            <select
              class="form-control"
              name="account"
              v-model="values.language"
              :disabled="patientId != '0' && values.isSchedule == true"
            >
              <option v-for="language in languages" :key="language.id">
                {{ language.language }}
              </option>
            </select>
          </div>
          <div class="col-lg-12">
            <label class="col-form-label"
              >{{ $t("navigations.subject notes") }}
            </label>
            <input
              type="text"
              class="form-control"
              maxLength="500"
              v-model="values.patientNotes"
            />
          </div>
          <div class="col-lg-6 mt-3 d-flex">
            <div class="input-group date">
              <div class="i-checkss">
                <label class="mr-2">{{ $t("navigations.active") }}</label>
                <input
                  type="checkbox"
                  checked="checked"
                  value=""
                  v-model="values.active"
                />
              </div>
            </div>
            <div
              class="input-group date"
              v-if="patientId == '0' && isVisitCreated > 0"
            >
              <div class="i-checkss">
                <label class="mr-2">{{
                  $t("navigations.create schedule")
                }}</label>
                <input
                  type="checkbox"
                  v-model="values.isSchedule"
                  @change="handleCheck($event)"
                />
              </div>
            </div>
          </div>
          <div class="col-lg-6 mt-3 d-flex justify-content-lg-end">
            <button
              class="cancel_btn py-1 px-4 mx-3"
              type="button"
              @click="clearForm()"
            >
              {{ $t("navigations.clear") }}
            </button>
            <button
              class="save_btn py-1 px-4"
              type="button"
              @click="saveOrEditPatient()"
            >
              {{ $t("navigations.save") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./managePatientModal.js"></script>

<style scoped>
.study_profile_modal__wrapper {
  position: fixed;
  inset: 0;
  background: rgb(0 0 0 / 18%);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  z-index: 3000;
}
.study_profile_modal__container {
  border-radius: 4px;
  background: #fff;
  overflow-x: hidden;
}
.modal__title {
  background-color: var(--pop-up-background);
  color: white;
}
.cancel_btn {
  padding: 0.43rem 0.4rem;
  border-radius: 0.25rem;
}
.custom-width {
  width: min(700px, 90%);
}
</style>