<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>{{$t('navigations.received queries')}}</h2>
    </div>
  </div>
  <div class="wrapper wrapper-content">
    <div class="ibox-content mb-1 px-3 py-2">
      <div class="form-group row mb-1">
        <!-- <div class="col-lg-7 d-flex flex-column flex-lg-row my-2 mb-lg-0"> -->
        <div class="d-flex flex-column flex-lg-row my-2 my-lg-0 mb-lg-0">
          <div class="form-group w-100 mb-md-0 pr-1">
            <label class="mb-0">{{$t('navigations.query id')}}</label>
              <input
                v-model="this.QueryID"
                type="text"
                class="form-control my-md-2 my-lg-0"
              />
          </div>
          <div class="form-group w-100 mb-md-0 pr-1">
            <label class="mb-0">{{$t('navigations.site code')}}</label>
            <select
              class="form-select my-md-2 my-lg-0"
              name="account"
              placeholder="Site Code"
              v-model="this.SiteCode"
              @change="getpatients($event.target.value)"
            >
              <option selected value="">{{$t('navigations.all')}}</option>
              <option
                v-for="site in sites"
                :key="site.siteID"
                :value="site.siteID"
              >
                {{ site.siteCode }}
              </option>
              <!--<option>Archived</option>-->
            </select>
          </div>
          <div class="form-group w-100 mb-md-0 pr-1">
            <label class="mb-0">{{$t('navigations.subject id')}}</label>
            <select
              class="form-select my-md-2 my-lg-0"
              v-model="this.QuerySubject"
              placeholder="Search by Subject Code"
            >
              <option selected value="">{{$t('navigations.all')}}</option>
              <option
                v-for="patient in this.patients"
                :key="patient.patientId"
                :value="patient.patientId"
              >
                {{ patient.subjectId }}
              </option>
            </select>
          </div>
          <div class="form-group w-100 mb-md-0 pr-1">
            <label class="mb-0">{{$t('navigations.query status')}}</label>
            <select
              class="form-select my-md-2 my-lg-0"
              v-model="this.QueryStatus"
              placeholder="Search by query status"
            >
              <option selected value="">{{$t('navigations.all')}}</option>
              <option value="Closed">{{$t('navigations.closed')}}</option>
              <option value="Open">{{$t('navigations.open')}}</option>
            </select>
          </div>
          <div class="col-12 col-md-2 px-0 align-self-end">
            <span type="button"
              @click="search()"
              class="save_btn  px-md-1 w-100"
            >
              {{$t('navigations.search')}}
            </span>
          </div>
        </div>
      </div>
      <div></div>
    </div>

    <div class="ibox-body">
       <div class="ibox-title style_2">
          <h5 class="float-left">{{$t('navigations.received query list')}}</h5>
      </div>
      <div class="ibox-content">
        <div class="table-responsive">
          <table class="table table-striped table-bordered dataTables">
            <thead>
              <tr>
                <th class="sort_block" @click="SortSelected('queryId',1)" id="1">                  
                  <div class="d-flex flex-row justify-content-between"> 
                    {{$t('navigations.query id')}}             
                    <span>
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span>
                  </div>
                </th>
                <th>
                  {{$t('navigations.site code')}}
                 
                </th>
                <th >
                  {{$t('navigations.subject id')}}
                  
                </th>
                <th >
                  {{$t('navigations.visit name')}}
                  
                </th>
                <th class="sort_block" @click="SortSelected('queryOpenStatus',5)" id="5">                  
                  <div class="d-flex flex-row justify-content-between">   
                    {{$t('navigations.status')}}           
                    <span>
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span>
                  </div>
                </th>
                <th class="sort_block" @click="SortSelected('queryFor',6)" id="6">                 
                  <div class="d-flex flex-row justify-content-between">       
                    {{$t('navigations.query for')}}       
                    <span>
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span>
                  </div>
                </th>
                <th class="sort_block" @click="SortSelected('priority',7)" id="7">                  
                  <div class="d-flex flex-row justify-content-between">        
                    {{$t('navigations.priority')}}      
                    <span>
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span>
                  </div>
                </th>
                <th class="sort_block" @click="SortSelected('query_Subject',8)" id="8">                  
                  <div class="d-flex flex-row justify-content-between">       
                    {{$t('navigations.query subject')}}       
                    <span>
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span>
                  </div>
                </th>
                <th class="sort_block" @click="SortSelected('raisedByName',9)" id="9">
                  <div class="d-flex flex-row justify-content-between">    
                    {{$t('navigations.raised by')}}          
                    <span>
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span>
                  </div> 
                </th>
                <th class="sort_block" @click="SortSelected('createdAt',10)" id="10">
                  {{$t('navigations.raised date')}}
                  <span >
                    <i class="fa fa-long-arrow-up"></i>
                    <i class="fa fa-long-arrow-down"></i>
                  </span>
                </th>
                <th class="sort_block" @click="SortSelected('closeByName',11)" id="11">                  
                  <div class="d-flex flex-row justify-content-between">  
                    {{$t('navigations.closed by')}}            
                    <span>
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span>
                  </div>
                </th>
                <th class="sort_block" @click="SortSelected('clodedDate',12)" id="12">
                  <div class="d-flex flex-row justify-content-between">
                    {{$t('navigations.closed date')}}              
                    <span>
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span>
                  </div>
                </th>
                <th class="text-center">{{$t('navigations.view')}}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="query in returnList" :key="query.queryId">
                <td>{{ query.queryId }}</td>
                <td>{{ query.siteId }}</td>
                <td>{{ query.subjectId }}</td>
                <td>{{ query.visitName }}</td>
                <td>{{ query.queryOpenStatus }}</td>
                <td>{{ query.queryFor }}</td>
                <td>{{ query.priority }}</td>
                <td>{{ query.query_Subject }}</td>
                <td>{{ query.raisedByName }}</td>
                <td v-if="query.createdAt">
                  {{ filter(query.createdAt) }}
                </td>
                <td v-else>{{}}</td>
                <td>{{ query.closeByName }}</td>
                <td v-if="query.closedDate">
                  {{ filter(query.closedDate) }}
                </td>
                <td v-else>
                  {{}}
                </td>
                <td class="text-center">
                    <i
                      class="fa fa-eye editicon"
                      @click.prevent="viewforms(query.id)"
                    ></i
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!------ pagination -------->
          <div class="row mt-3 mx-0">
            <div class="col-lg-12 mx-0 px-0">
              <div
                class="
                  dataTables_paginate
                  paging_simple_numbers
                  pagination
                  float-right
                "
              >
                <div class="col-lg-12 d-flex justify-content-lg-end px-0">
                  <v-pagination
                    v-model="currentPage"
                    :pages="totalPages"
                    :range-size="1"
                    active-color="#618bd7"
                    @update:modelValue="loadPage"
                  />
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import axios from "axios";
import store from "../../store/index";
import moment from "moment";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
export default {
  name: "queryList",
  components: {
    VPagination,
  },
  data() {
    return {
      defaultdate: "",
      searchSiteCode: "",
      searchQueryId: "",
      searchQuerySubject: "",
      searchQueryStatus:"",
      baseUrl: `${process.env.VUE_APP_Service_URL}/forms/query/`,
      baseapi: process.env.VUE_APP_Service_URL,
      idToken: "",
      searchValue: "",
      returnList: [],
      totalCount: 0,
      totalPages : 0,
      StudyID: "",
      totalPage: 1,
      sites: "",
      patients: "",
      currentPage: 1,
      SiteCode: "",
      QueryID: "",
      QuerySubject: "",
      QueryStatus:"",
      pageindex: 1,
      pagesize: 10,
      SortProperty: "",
      SortOrder: 1,
    };
  },
  async mounted() {
    this.defaultdate = localStorage.getItem("dateformat");
    window.addEventListener("studyIdChanged", (event) => {
      console.log("StudyIDChanged into" + event.detail.storage);
      // window.location.reload();
      this.idToken = store.getters.getIdToken;
      this.getAndSetData();
      this.getdefaultpatients();
      this.getsites();
      this.filter();
    });
    this.idToken = store.getters.getIdToken;
    await this.getAndSetData();
    await this.getdefaultpatients();
    await this.getsites();
    this.filter();
  },
  methods: {
    filter(data) {
      if (data) {
        return moment(data).format(this.defaultdate);
      }
      console.log("Reverse is");
      console.log(data);
    },
    async viewforms(id) {
      this.$router.push(`/query/queryList/${id}`);
    },
   async getdefaultpatients() {
      const currentstudy = store.getters.getStudyIs;
      const idtoken = store.getters.getIdToken;
      await axios
        .get(
          `${this.baseapi}/management/patient/listpatient?Studyid=${currentstudy}&SortProperty=subjectId`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.patients = res.data.results;
          console.log("Subjects without site id are",this.patients )
        })
        .catch((err) => {
          console.log(err);
        });
    },
  async getpatients(siteID)
  {
    if(siteID == "All"){
      this.getdefaultpatients();
    }
    else
    {
      this.getsitepatients(siteID)
    }
  },
  async getsitepatients(siteID) {
      this.patients=[]
      const currentstudy = store.getters.getStudyIs;
      const idtoken = store.getters.getIdToken;
      console.log("Site Id is",siteID)
      await axios
        .get(
          `${this.baseapi}/management/patient/listpatient?SiteId=${siteID}&Studyid=${currentstudy}&SortProperty=subjectId`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.patients = res.data.results;
          console.log("Subjects are",this.patients )
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getsites() {
      const currentstudy = store.getters.getStudyIs;
      const idtoken = store.getters.getIdToken;

      await axios
        .get(`${this.baseapi}/management/site/getallsitesbyuser?studyId=${currentstudy}&sortProperty=siteCode&IsDes=false&getAll=false`, {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          this.sites = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async search() {
      this.pageindex=1;
      this.currentPage=1;
      this.setSearchData();
      this.getAndSetData();
    },
    setSearchData() {
      //   if(this.SiteCode=="All")
      // {
      //   this.SiteCode=""
      // }
      // if(this.QuerySubject=="All")
      // {
      //   this.QuerySubject=""
      // } 
      // if(this.QueryStatus=="All")
      // {
      //   this.QueryStatus=""
      // }
      this.searchSiteCode = this.SiteCode;
      this.searchQueryId = this.QueryID;
      this.searchQuerySubject = this.QuerySubject;
      this.searchQueryStatus=this.QueryStatus;
      console.log(
        "Hello" + this.searchSiteCode + this.searchSiteName + this.searchStatus
      );
    },
    //search data items are passed as parameters
    async getAndSetData() {
     
      console.log("page index is.............." , this.pageindex);
      const currentstudy = store.getters.getStudyIs;
      const idtoken = store.getters.getIdToken;
      console.log(currentstudy);
      await axios
        .get(
            this.baseUrl +
            "queryrecivedlist?StudyId=" +
           //"https://localhost:5001/query/queryrecivedlist?StudyId="+
            currentstudy +
            "&pageindex=" +
            this.pageindex +
            "&pagesize=" +
            this.pagesize +
            "&SortProperty=" +
            this.SortProperty +
            "&SortOrder=" +
            this.SortOrder +
            "&SiteId=" +
            this.searchSiteCode +
            "&QueryId=" +
            this.searchQueryId +
            "&SubjectId=" +
            this.searchQuerySubject +
            "&QueryStatus=" +
            this.searchQueryStatus,

          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log("Query list");
          console.log(res);
          this.returnList = res.data.results;
          this.totalCount = res.data.totalCount;
          this.totalPages = parseInt(res.data.totalCount / this.pagesize);
          if (this.totalCount % this.pagesize != 0) {
            this.totalPages = this.totalPages + 1;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getnextpage() {
      console.log("NextPage");
      console.log(this.totalCount);
      console.log(this.totalCount / 10 > this.pageindex);
      if (this.totalCount / 10 > this.pageindex) {
        this.pageindex++;
        await this.getAndSetData();
      }
    },
    async getpreviouspage() {
      console.log("PreviousPage");
      if (this.pageindex > 1) {
        this.pageindex--;
        await this.getAndSetData();
      }
    },
    async generateQuery() {
      this.$router.push(`/generalQuery/`);
    },
      loadPage: function (page) {
      this.currentPage = page;
      this.pageindex = this.currentPage;
      this.getAndSetData();
    },
    SortSelected(sortProp,id){
      console.log('SortPropChanged');
      const selectedElement=document.getElementById(id)
      const selectedElementID=document.getElementById(id).id
      if(sortProp!=null && id==selectedElementID)
      {
        this.sortDesc=!this.sortDesc
        if(this.SortOrder == 0)
        {
          if(selectedElement.classList.contains("sortDesc")){
            return
          }
          else if(selectedElement.classList.contains("sortAsc"))
          {
            selectedElement.classList.remove("sortAsc")
            selectedElement.classList.add("sortDesc")
          }    
          else selectedElement.classList.add("sortDesc")
        }
        else
        {
          if(selectedElement.classList.contains("sortDesc")){
            selectedElement.classList.remove("sortDesc")
            selectedElement.classList.add("sortAsc")
          }
          else if(selectedElement.classList.contains("sortAsc"))
          {
            return
          }          
          else selectedElement.classList.add("sortAsc")
        }
      }
      this.pageindex=1;
      this.currentPage=1;
      this.SortProperty=sortProp;
      this.SortOrder = this.SortOrder==0?1:0;
      this.setSearchData();
      this.getAndSetData();
    }

  },
};
</script>

<style>
@import "../../assets/style.css";
@import "../../assets/formpage.css";
.previousbtn,
.nextbtn {
  margin: 0;
  border-radius: 0px;
  border: 0.5px solid #eeeeee;
}
.page-links {
  border-radius: 0px;
}
.form-control{
  height: inherit !important;
}
.form-select{
  border-radius:0!important;
}
.form-select:focus{
  border-color:#145faf!important;
  box-shadow: 0 0 0!important;
}
</style>