<template>
    <div class="enlarger_modal__wrapper">
        <div class="enlarger__block__modal p-0">
            <div class="enlarger__block__title d-flex justify-content-between py-2 px-3">
                <h4 class="my-0">Detailed Analysis</h4>
                <div class="cursor-pointer">
                    <i class="fa fa-times" title="Close" @click.prevent="closepopup()"></i>
                </div>
            </div>
            <div class="ibox-content d-flex justify-content-center">
                <slot></slot>
            </div>
        </div>
    </div>
</template>
<script>
/* eslint-disable */
import axios from "axios";
import moment from "moment";
import store from "../../../store/index";
import * as d3 from "d3";
export default {
    name: "enlargerpopup",
    data() {
        return {

        }
    },
    methods:{
        closepopup(){
            this.$emit("closeModal")
        }
    }
}
</script>
<style>
.enlarger_modal__wrapper {
    position: fixed;
    inset: 0;
    background: rgb(0 0 0 / 18%);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    z-index: 3000;
}

.enlarger__block__modal {
    width:min(calc(100% - 30rem), 90%);
    position: fixed;
    background: rgb(255, 255, 255);
    border-radius: 4px;
    overflow-x: hidden;
}

.enlarger__block__title {
    background-color: var(--pop-up-background);
    color: #ffffff;
}
</style>