<template>
  <div class="study_profile_modal__wrapper">
    <div class="study_profile_modal__container">
      <div class="modal__title d-flex justify-content-between py-2 px-3">
        <h4 v-if="selectedvisit == 0" class="my-0">
          {{ $t("navigations.create visit") }}
        </h4>
        <h4 v-else class="my-0">{{ $t("navigations.edit visit") }}</h4>
        <div class="cursor-pointer"  @click.prevent="closeModal">
          <i class="fa fa-times" title="Close"></i>
        </div>
      </div>
      <div class="ibox-content p-0">
        <div class="modal-body pb-0">
          <div class="form-group row">
            <div class="col-lg-4 my-0">
              <label class="col-form-label">
                {{ $t("navigations.visit no")
                }}<sup><i class="fa fa-asterisk imp"></i></sup>
              </label>
              <input
                type="text"
                class="form-control"
                v-model="values.visitNo"
                maxlength="3"
              />
              <span class="errormsg" v-if="errors[0] == false">
                {{ $t("navigations.please enter visit no") }}
              </span>
            </div>
            <div class="col-lg-4 my-0">
              <label class="col-form-label">
                {{ $t("navigations.visit name") }}
                <sup><i class="fa fa-asterisk imp"></i></sup
              ></label>
              <input
                type="text"
                class="form-control"
                v-model="values.visitFormName"
                maxlength="500"
              />
              <span class="errormsg" v-if="errors[1] == false">{{
                $t("navigations.please enter visit form name")
              }}</span>
            </div>
            <div class="col-lg-4 my-0">
              <label class="col-form-label"
                >{{ $t("navigations.visit type")
                }}<sup><i class="fa fa-asterisk imp"></i></sup
              ></label>
              <select
                class="form-select"
                v-model="values.visitType"
                valueProp="VisitTypeName"
              >
                <option v-for="options in visitList" :key="options.typename">
                  {{ options.typename }}
                </option>
              </select>
              <span class="errormsg" v-if="errors[2] == false">{{
                $t("navigations.please select a visit type")
              }}</span>
            </div>

            <div class="col-lg-12 my-0">
              <label class="col-form-label">{{
                $t("navigations.visit notes")
              }}</label>
              <input
                type="text"
                class="form-control"
                v-model="values.visitNotes"
              />
            </div>

            <div class="col-lg-12 my-0 mt-3 mb-2">
              <span class="text-muted">{{
                $t(
                  "navigations.you have the option to either select from the existing templates or utilize templates available in the form library"
                )
              }}</span>
            </div>

            <div class="col-lg-6 my-0">
              <label class="col-form-label">{{
                $t("navigations.assign template")
              }}</label>
               <Multiselect
                v-model="assignTemplateId"
                mode="multiple"
                valueProp="templateId"
                placeholder="Select Template"
                label="templateName"
                :options="templatelist"
                :hideSelected="false"
                :closeOnSelect="false"
                class="multiselect-template-theme mr-0"
              >
              </Multiselect>
            </div>

            <div class="col-lg-6 my-0">
              <label class="col-form-label">{{
                $t("navigations.import from form library")
              }}</label>
              <select
                class="form-select"

                v-model="importTemplateId"
                :disabled="assignTemplateSelected"
                valueProp="templateId"
                @change="handleDropdownChange"
              >
                <option value="null" disabled>
      {{ $t("navigations.none") }}
    </option>
                <!-- <option value="" disabled selected>{{ $t("navigations.none") }}</option> -->
                <option
                  v-for="temps in formlibrarylist"
                  :key="temps.formLibraryId"
                  :value="temps.formLibraryId"
                >
                  {{ temps.formLibraryName }} ({{ temps.version }})
                </option>
              </select>
            </div>

            <div class="col-lg-12 my-0 mt-3 mb-2">
              <span class="text-muted"
                >If you wish to establish a visit schedule based on the
                enrollment date, kindly choose the necessary visit interval
                according to the subjects's enrollment date.</span
              >
            </div>

            <div class="col-lg-6 my-0">
              <label class="col-form-label">
                {{ $t("navigations.pre visit days") }}</label
              >
              <input
                min="0"
                type="number"
                class="form-control"
                v-model="values.preVisit"
                maxlength="3"
                @input.prevent="limitInputLength('preVisit', 3)"
              />
              <span class="errormsg" v-if="numerrors['preVisit'] == false">{{
                $t("navigations.please enter a number")
              }}</span>
            </div>
            <div class="col-lg-6 my-0">
              <label class="col-form-label">Post-visit (Days)</label>
              <input
                min="0"
                type="number"
                class="form-control"
                v-model="values.postVisit"
                maxlength="3"
                @input.prevent="limitInputLength('postVisit', 3)"
              />
              <span class="errormsg" v-if="numerrors['postVisit'] == false">{{
                $t("navigations.please enter a number")
              }}</span>
            </div>

            <div class="col-lg-3 my-0">
              <label class="col-form-label">{{
                $t("navigations.visit period days")
              }}</label>
              <input
                min="0"
                type="number"
                class="form-control"
                v-model="values.visitDayPeriod"
                @input.prevent="limitInputLength('visitDayPeriod', 4)"
              />
              <span
                class="errormsg"
                v-if="numerrors['visitDayPeriod'] == false"
                >{{ $t("navigations.please enter a number") }}</span
              >
            </div>
            <div class="col-lg-3 my-0">
              <label class="col-form-label">{{
                $t("navigations.visit period weeks")
              }}</label>
              <input
                min="0"
                type="number"
                class="form-control"
                v-model="values.visitWeekPeriod"
                @input.prevent="limitInputLength('visitWeekPeriod', 4)"
              />
              <span
                class="errormsg"
                v-if="numerrors['visitWeekPeriod'] == false"
                >{{ $t("navigations.please enter a number") }}</span
              >
            </div>
            <div class="col-lg-3 my-0 px-0">
              <label class="col-form-label">{{
                $t("navigations.visit period months")
              }}</label>
              <input
                min="0"
                type="number"
                class="form-control"
                v-model="values.visitMonthPeriod"
                @input.prevent="limitInputLength('visitMonthPeriod', 4)"
              />
              <span
                class="errormsg"
                v-if="numerrors['visitMonthPeriod'] == false"
                >{{ $t("navigations.please enter a number") }}</span
              >
            </div>
            <div class="col-lg-3 my-0">
              <label class="col-form-label">{{
                $t("navigations.visit period year")
              }}</label>
              <input
                min="0"
                type="number"
                class="form-control"
                v-model="values.visitYearPeriod"
                @input.prevent="limitInputLength('visitYearPeriod', 4)"
              />
              <span
                class="errormsg"
                v-if="numerrors['visitYearPeriod'] == false"
                >{{ $t("navigations.please enter a number") }}</span
              >
            </div>

            <div class="mt-3 ml-3 form-check align-self-center d-flex">
              <input
                type="checkbox"
                :checked="values.visitType == 'Patient Visit'"
                v-model="values.isPatientVisit"
                :disabled="values.visitType == 'Patient Visit'"
                class="form-check-input"
              />
              <label class="form-check-label ml-2">
                {{
                  $t(
                    "navigations.select this to signify that the visit is completed by the subject"
                  )
                }}
              </label>
            </div>

            <div class="mt-1 ml-3 form-check align-self-center d-flex">
              <input
                type="checkbox"
                v-model="values.active"
                class="form-check-input"
              />
              <label class="mr-2">{{ $t("navigations.active") }}</label>
            </div>

            <div class="col-lg-12 mt-1 d-flex justify-content-lg-end">
              <button
                class="cancel_btn py-1 px-4 mx-3"
                type="button"
                @click.prevent="clear()"
              >
                {{ $t("navigations.clear") }}
              </button>
              <button
                class="save_btn py-1 px-4"
                type="button"
                @click.prevent="addVisit()"
              >
                {{ $t("navigations.save") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal -->
  <div class="simple-wrapper" v-if="showPopup">
    <div class="simple-popup">
      <p>
        {{ $t("navigations.are you sure you want to import the template") }} "{{
          selectedFormLibraryName
        }}" {{ $t("navigations.from the form library") }}
      </p>

      <div class="d-flex justify-content-around mt-3">
        <div class="col-lg-12 mt-1 d-flex justify-content-lg-end">
          <button
            class="cancel_btn py-1 px-4 mx-3"
            type="button"
            @click.prevent="handleCancel()"
          >
            {{ $t("navigations.cancel") }}
          </button>
          <button
            class="save_btn py-1 px-4"
            type="button"
            @click.prevent="handleConfirm()"
          >
            {{ $t("navigations.confirm") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import store from "../../store/index";
import Multiselect from "@vueform/multiselect";
import { useI18n } from "vue-i18n";
export default {
  setup() {
    const { t, locale } = useI18n({ useScope: "global" });
    return { t, locale };
  },
  components:{
    Multiselect
  },
  props: {
    visitId: {
      type: String,
      default: "",
    },
  },
  async mounted() {
    window.addEventListener("studyIdChanged", async (event) => {
      this.studyId = store.getters.getStudyIs;
      this.selectedvisit = this.visitId;
      this.retrieveinfo();
      this.getVisitType();
      this.gettemplatedata();
      this.getformLibrarydata();
    });
    this.studyId = store.getters.getStudyIs;
    this.selectedvisit = this.visitId;
    this.retrieveinfo();
    this.getVisitType();
    this.gettemplatedata();
    this.getformLibrarydata();
  },
  data() {
    return {
      baseurl: process.env.VUE_APP_Service_URL,
      selectedStudy: "",
      selectedvisit: "",
      values: {
        visitId: "",
        isPatientVisit: false,
        visitNo: "",
        visitFormName: "",
        visitNotes: "",
        active: true,
        preVisit: "",
        postVisit: "",
        visitType: "",
        folderId: "",
        studyId: "",
        isDeleted: false,
        visitDayPeriod: "",
        visitWeekPeriod: "",
        visitMonthPeriod: "",
        visitYearPeriod: "",
        templateId: [],
      },
      numerrors: {
        preVisit: true,
        postVisit: true,
        visitDayPeriod: true,
        visitWeekPeriod: true,
        visitMonthPeriod: true,
        visitYearPeriod: true,
      },
      validations: [false, false, false],
      errors: [],
      visitList: [],
      templatelist: [],
      studyId: "",
      assignTemplateId:[],
      importTemplateId: null,
      importvalue: {
        formLibraryIds: [],
        studyId: "",
      },
      formlibrarylist: "",
      showPopup: false,
      selectedFormLibraryName: "",
    };
  },
  computed: {
    importFromLibrarySelected() {
      return this.importTemplateId !== null && this.importTemplateId !== "none";
    },
    assignTemplateSelected() {
      return this.assignTemplateId.length != [] ;
    },
  },
  methods: {
    async updateSelectedFormLibraryName(name) {
      this.selectedFormLibraryName = name;
      console.log(name, "heyyyyy", this.selectedFormLibraryName);
    },
    async handleDropdownChange(event) {
      if (event.target.value !== "none") {
        // Find the selected form library in the formlibrarylist
        const selectedFormLibrary = this.formlibrarylist.find(
          (item) => item.formLibraryId === event.target.value
        );
        console.log("selectedFormLibrary", selectedFormLibrary);
        // Set the selectedFormLibraryName
        this.selectedFormLibraryName = selectedFormLibrary
          ? selectedFormLibrary.formLibraryName
          : "";

        // Open the popup when a value other than 'None' is selected
        this.showPopup = true;
      } else {
        // Optionally, you can close the popup when 'None' is selected
        this.showPopup = false;
      }
    },

    async handleConfirm() {
      // Handle confirm action
      await this.ImportFun();
      console.log("Confirmed");
      this.showPopup = false; // Close the popup
    },
    async handleCancel() {
      // Handle cancel action
      this.importTemplateId = null;
      console.log("Canceled");
      this.showPopup = false; // Close the popup
    },
    async mapTemplateIds() {
      if (this.assignTemplateId.length !=0) {
        this.values.templateId = this.assignTemplateId;
      }
    },
    async getVisitType() {
      const idtoken = store.getters.getIdToken;
      await axios
        .get(`${this.baseurl}/management/visittype/listvisittypes`, {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          console.log(res);
          this.visitList = res.data.entities;
          console.log("Result visit is", this.visitList);
        })
        .catch((err) => {
          console.log(err);
          alert(err.response.data.detail);
        });
    },
    async clear() {
      console.log("inside clear function");
      this.values = {
        visitId: this.values.visitId,
        visitNo: "",
        visitFormName: "",
        visitNotes: "",
        active: true,
        preVisit: "",
        postVisit: "",
        visitDayPeriod: "",
        visitWeekPeriod: "",
        visitMonthPeriod: "",
        visitYearPeriod: "",
        isPatientVisit: false,
        templateId: null,
        visitType: "",
        folderId: this.values.folderId,
        studyId: this.values.studyId,
        accountId: this.values.accountId,
        isDeleted: this.values.isDeleted,
        temp: this.values.temp,
      };
      (this.assignTemplateId = []), (this.importTemplateId = null);
    },
    async addVisit() {
      await this.validate();
      if (this.validations.includes(false)) {
        this.errors = this.validations;
      } else {
        await this.mapTemplateIds();
        if (this.selectedvisit == 0) {
          this.save();
        } else {
          this.edit();
        }
      }
    },
    async save() {
      this.values.preVisit = String(this.values.preVisit);
      this.values.postVisit = String(this.values.postVisit);
      this.values.visitMonthPeriod = String(this.values.visitMonthPeriod);
      this.values.visitWeekPeriod = String(this.values.visitWeekPeriod);
      this.values.visitYearPeriod = String(this.values.visitYearPeriod);
      this.values.visitDayPeriod = String(this.values.visitDayPeriod);
      this.values.studyId = store.getters.getStudyIs;
      this.values.folderId = store.getters.getStudyIs;
      const idtoken = store.getters.getIdToken;
      console.log("token is", idtoken);
      console.log("passing data");
      await axios
        .post(`${this.baseurl}/management/visit/create`, this.values, {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          console.log(res);
          if (res.data.data === "No visit Found") {
            alert("No visit Found");
          }

          this.userList = res.data.data;
          alert("Visit added successfully.");
          this.closeModal();
          this.$emit("createVisit");
        })
        .catch((err) => {
          alert(err.response.data.detail);
        });
    },
    async edit() {
      this.values.preVisit = String(this.values.preVisit);
      this.values.postVisit = String(this.values.postVisit);
      this.values.visitMonthPeriod = String(this.values.visitMonthPeriod);
      this.values.visitWeekPeriod = String(this.values.visitWeekPeriod);
      this.values.visitYearPeriod = String(this.values.visitYearPeriod);
      this.values.visitDayPeriod = String(this.values.visitDayPeriod);
      this.values.studyId = store.getters.getStudyIs;
      console.log("edit function", this.values);
      const idtoken = store.getters.getIdToken;
      console.log(idtoken);
      await axios
        .put(`${this.baseurl}/management/visit/update`, this.values, {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          console.log(res);
          if (res.data.data === "No Visit Found") {
            alert("No visit Found");
            //alert(this.$t('navigations.no visit found'));
          }
          this.userList = res.data.data;
          console.log("Result is", res.data.data);
          alert("Visit updated successfully.");
          this.closeModal();
          this.$emit("createVisit");
        })
        .catch((err) => {
          console.log(err);
          alert(err.response.data.detail);
        });
    },
    async retrieveinfo() {
      this.values.studyId = store.getters.getVisitStudyIs;
      console.log(this.values.studyId);
      if (this.selectedvisit != 0) {
        // console.log("edit function " + this.selecteduser);
        const idtoken = store.getters.getIdToken;
        await axios
          .get(
            //`https://localhost:5001/visit/getbyid/`+this.selectedvisit,
            `${this.baseurl}/management/visit/getbyid/` + this.selectedvisit,
            {
              headers: {
                Authorization: "Bearer " + idtoken,
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            console.log("get bindable data", res.data);
            if (res.data === "No Visit Found") {
              // alert(this.$t('navigations.no visit found'));
              alert("No visit Found");
            }
            this.values = res.data;
            if (res.data.template_ID != "" && res.data.template_ID != null) {
              this.values.templateId = res.data.template_ID;
              this.assignTemplateId = res.data.template_ID;
            } else {
              this.values.templateId = [];
              this.assignTemplateId = [];
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    async validate() {
      this.values.visitNo = this.values.visitNo.replace(/ +/g, " ").trim();

      this.values.visitFormName = this.values.visitFormName
        .replace(/ +/g, " ")
        .trim();
      this.values.visitFormName = this.values.visitFormName
        .replace(/ +/g, " ")
        .trim();
      if (this.values.visitNo == "") {
        this.validations[0] = false;
      } else {
        this.validations[0] = true;
      }
      if (this.values.visitFormName == "") {
        this.validations[1] = false;
      } else {
        this.validations[1] = true;
      }

      if (this.values.visitType != "") {
        this.validations[2] = true;
      }
    },
    // async numberonlyvalidation(fieldname) {
    //   let isInteger = true;
    //   this.values[fieldname] = this.values[fieldname].replace(/\s/g, "");
    //   let fieldval = this.values[fieldname];

    //   if (fieldval === "" || /^\s*$/.test(fieldval)) {
    //     // Field is empty or contains only whitespace
    //     this.numerrors[fieldname] = true;
    //     return;
    //   }

    //   if (fieldval % 1 !== 0) {
    //     isInteger = false;
    //   }

    //   if (!isInteger) {
    //     this.values[fieldname] = 0;
    //   } else {
    //     if (parseInt(this.values[fieldname]) >= 0) {
    //       this.numerrors[fieldname] = true;
    //     } else {
    //       this.values[fieldname] = 0;
    //     }
    //   }
    // },
    async closeModal() {
      this.$emit("closeModal");
      this.$emit("createVisit");
    },
    limitInputLength(field, maxLength) {
      const inputValue = this.values[field].toString();

      if (inputValue.length > maxLength) {
        this.values[field] = Number(inputValue.slice(0, maxLength));
      }
    },
    async gettemplatedata() {
      console.log("template data function");
      const idtoken = store.getters.getIdToken;
      await axios
        .get(
          `${this.baseurl}/forms/template/listtemplate?Status=Approved&StudyID=${this.studyId}`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log("template data is");
          console.log(res.data);
          // this.templatelist = res.data.results; sorted
           this.templatelist = (res.data.results || []).sort((a, b) =>
          a.templateName.localeCompare(b.templateName)
        );
        })
        .catch((err) => {
          console.log("error in get template details ", err);
        });
    },
    async getformLibrarydata() {
      console.log("template data function");
      const idtoken = store.getters.getIdToken;
      await axios
        .get(`${this.baseurl}/forms/formslibrary/listformlibrary`, {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          console.log("template data is");
          console.log(res.data);
          // this.formlibrarylist = res.data.results;sorted
           this.formlibrarylist = (res.data.results || []).sort((a, b) =>
          a.formLibraryName.localeCompare(b.formLibraryName)
        );
        })
        .catch((err) => {
          console.log("error in get template details ", err);
        });
    },
    async ImportFun() {
      this.importvalue.formLibraryIds = [];
      this.importvalue.formLibraryIds.push(this.importTemplateId);
      this.importvalue.studyId = store.getters.getStudyIs;
      const idtoken = store.getters.getIdToken;
      await axios
        .post(
          `${this.baseurl}/forms/template/copyfromlibrary`,
          this.importvalue,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log(res);
          this.importTemplateId = null;
          this.assignTemplateId = res.data[0];
          this.gettemplatedata();
          // alert(this.$t('navigations.template imported succesfully'));
          alert("Template imported succesfully.");
        })
        .catch((err) => {
          alert(err.response.data.detail);
        });
    },
  },
};
</script>

<style scoped>
@import "../../assets/style.css";
@import "../../assets/formpage.css";
@import "../../assets/savepages.css";
.study_profile_modal__wrapper {
  position: fixed;
  inset: 0;
  background: rgb(0 0 0 / 18%);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  z-index: 3000;
}
.study_profile_modal__container {
  position: fixed;
  width: min(50%, 90%);
  border-radius: 4px;
  background: #fff;
  overflow-x: hidden;
}
.modal__title {
  background-color: var(--pop-up-background);
  color: white;
}

.cancel_btn {
  padding: 0.43rem 0.4rem;
  border-radius: 0.25rem;
}
.import-popup {
  position: relative;
}
.import-popup .tool-tip {
  visibility: hidden;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  background-color: #616161;
  color: #fff;
  text-align: center;
  border-radius: 0.3em;
  padding: 0.3rem 0.8rem;
  right: 0;
  top: -40px;
  font-size: 0.8rem;
  position: absolute;
  z-index: 1;
  transition: visibility 75ms ease-in;
}

.import-popup:hover .tool-tip,
.import-popup:focus .tool-tip {
  visibility: visible;
  -webkit-transition: visibility 30ms ease-in;
  transition: visibility 30ms ease-in;
}
.custom-popup {
  text-align: center;
}

.simple-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  padding: 33px; /* Adjust the padding to increase the size */
  width: 29%; /* Adjust the width as needed */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  z-index: 4000; /* Increase the z-index value if needed */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.simple-wrapper {
  position: fixed;
  inset: 0;
  background: rgb(0 0 0 / 18%);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  z-index: 3000;
}
.multiselect-template-theme {
  --ms-option-bg-selected-pointed: rgb(210, 210, 210);
  --ms-option-bg-selected: rgb(210, 210, 210);
  --ms-option-color-selected: #000;
  --ms-option-color-selected-pointed: #000;
  --ms-radius:0;
  --ms-line-height: 0.8;
  --ms-option-font-size: 0.8rem;
  --ms-font-size: 0.8rem;
}
</style>