<template>
    <div class="row wrapper border-bottom white-bg page-heading">
        <div class="col-lg-10">
        <h2>{{ $t('navigations.add/edit subject') }}</h2>
    </div>
    <div class="col-lg-2">
        <i class="fa fa-times close my-2" title="Close" @click="onclosed()"></i>
    </div>
    </div>
        <div class="wrapper wrapper-content">   
            <div class="ibox-content p-3">      
                <div class="form-group row">
                     <input type="hidden" name="patientId" v-model="values.patientId">
                    <div class="col-lg-4 mb-1">
                        <label class="col-form-label">{{ $t('navigations.site code') }}<sup><i class="fa fa-asterisk required"></i></sup></label>
                        <select class="form-control" name="account" v-model="values.siteId" :disabled="values.status !='New' && values.status !=''">
                            <!-- <option value="" selected disabled> -- select -- </option> -->
                            <option v-for="site in sites" :key="site.siteID" :value="site.siteID" >{{site.siteCode}}</option>
                        </select> 
                        <span class="errorClass" v-if="errors[0] == false">{{ $t('navigations.please select a site code') }}</span>
                    </div>
                     <div class="col-lg-4 mb-1">
                        <label class="col-form-label">{{ $t('navigations.subject id') }}<sup><i class="fa fa-asterisk required"></i></sup></label>
                        <input type="text" class="form-control" maxlength="20" v-model="values.subjectId" :disabled="values.status !='New' && values.status !=''">
                        <span class="errorClass" v-if="errors[1]== false">{{ $t('navigations.please enter a subject ID') }}</span>
                    </div>
					<div class="col-lg-4  mb-1">
                        <label class="col-form-label">{{ $t('navigations.enrolment date') }}<sup><i class="fa fa-asterisk required"></i></sup> </label>
                        <input type="date" max="9999-12-31" class="form-control" v-model="values.enrollmentDate"  :disabled="patientId!='0' && values.isSchedule == true">
                        <span class="errorClass" v-if="errors[2]== false">{{ $t('navigations.please select enrolment date') }}</span>
                    </div>
                    <div class="col-lg-4  mb-1">
                        <label class="col-form-label">{{ $t('navigations.country') }} </label>
                        <select class="form-select" name="account" v-model="values.country">
                             <option disabled value="">--{{ $t('navigations.select') }}--</option> <!-- Placeholder -->
                            <option v-for="country in countries" :key="country.code" :value="country.code">{{country.name}}</option>
                        </select> 
                    </div>
                    <div class="col-lg-4 mb-1">
                        <label class="col-form-label">{{ $t('navigations.investigator') }}</label>
                         <select class="form-select" name="account" v-model="values.nurseName" >
                             <option disabled value="">--{{ $t('navigations.select') }}--</option> <!-- Placeholder -->
                            <option v-for="user in users" :key="user.userID" :value="user.email">{{user.name}}</option>
                        </select>
                    </div>
                    <div v-if="patientId!=0" class="col-lg-4  mb-1">
                        <label class="col-form-label">{{ $t('navigations.status') }}</label>
                        <input type="text" class="form-control" v-model="values.status" disabled>
                        <!-- <select disabled class="form-control m-b" name="account" v-model="values.status">
                             <option>New</option>
                            <option>Enrolled</option>
                            <option>Archived</option>
                            <option>Completed</option> 
                         </select>  --> 
                    </div>
                    <div class="col-lg-4  mb-1">
                        <label class="col-form-label">{{ $t('navigations.pre-visit') }}</label>
                        <input type="text" class="form-control" maxlength="3" v-model="values.preVisit" :disabled="patientId!='0' && values.isSchedule == true"   @change.prevent="numberonlyvalidation('preVisit')">
                        
                    </div>
                    <div class="col-lg-4  mb-1">
                        <label class="col-form-label">{{ $t('navigations.post-visit') }}</label>
                        <input type="text" class="form-control" maxlength="3" v-model="values.postVisit"  :disabled="patientId!='0' && values.isSchedule == true"  @change.prevent="numberonlyvalidation('postVisit')">
                          
                    </div>
                    <div class="col-lg-4  mb-1">
                        <label class="col-form-label">{{ $t('navigations.city') }}</label>
                        <input type="text" class="form-control" v-model="values.city"  :maxlength="maxLength">
                    </div>
                    <div class="col-lg-4  mb-1">
                        <label class="col-form-label">{{ $t('navigations.post/zip code') }}</label>
                        <input type="text"  class="form-control" v-model="values.zipCode" :maxlength="maxZip">
                    </div>
                    <div class="col-lg-4  mb-1">
                        <label class="col-form-label">{{ $t('navigations.language') }}<sup><i class="fa fa-asterisk required"></i></sup></label>
                        <select class="form-control" name="account" v-model="values.language" :disabled="patientId!='0' && values.isSchedule == true">
                            <option v-for="language in languages" :key="language.id">{{language.language}}</option>
                        </select> 
                    </div>
                     <div class="col-lg-12">
                        <label class="col-form-label">{{ $t('navigations.subject notes') }} </label>             
                        <input type="text" class="form-control" maxLength="500" v-model="values.patientNotes">  
                    </div>
                    <div class="col-lg-6 my-3 d-flex ">
                        <div class="input-group date">
                            <div class="i-checkss"><label class="mr-2">{{ $t('navigations.active') }}</label> <input type="checkbox" checked="checked" value="" v-model="values.active"></div>            
                        </div> 
                        <div class="input-group date" v-if="patientId=='0' && isVisitCreated > 0">
                            <div class="i-checkss"><label class="mr-2">{{ $t('navigations.create schedule') }}</label> <input type="checkbox" v-model="values.isSchedule"></div>            
                        </div>                        
                    </div>
                    <div class="col-lg-6 my-3 d-flex justify-content-lg-end">                        
                        <button class="cancel_btn py-1 px-4 mx-3" type="submit"  @click="clearForm()">{{ $t('navigations.clear') }}</button>
                        <button class="save_btn py-1 px-4" type="submit" @click="saveOrEditPatient()">{{ $t('navigations.save') }}</button>                                
                    </div>
                  
                </div>      
            </div>        
        </div>
</template>

<script src="./manage_Patient.js"></script>

<style>
@import "../../assets/savepages.css";

    .required{
            -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    --breakpoint-xs: 0;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;
    --font-family-sans-serif: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    --font-family-monospace: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
    --primary-clr: #bb458f;
    --secondary-clr: #3c4192;
    text-align: left;
    box-sizing: border-box;
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    color: #ff0000;
    font-size: 7px;
    }

    .checkbox{
           -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    --breakpoint-xs: 0;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;
    --font-family-sans-serif: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    --font-family-monospace: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
    --primary-clr: #bb458f;
    --secondary-clr: #3c4192;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    font-family: "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 13px;
    color: #676a6c;
    cursor: pointer;
    box-sizing: border-box;
    text-decoration: none;
    position: absolute;
    top: 0%;
    left: 0%;
    display: block;
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    background: rgb(255, 255, 255);
    border: 0px;
    opacity: 0;
    }
    .impcl {
    color: #ff4747;;
    font-size: 25px;
    float: right;
}
.impcl:hover {
    color: #a30202;;
    font-size: 25px;
    float: right;
}
.errorClass{
    color: red;
    font-size: 15px;
}
.form-select{
  border-radius:0!important;
}
.form-select:focus{
  border-color:#145faf!important;
  box-shadow: 0 0 0!important;
}
</style>