<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-8">
      <h2 class="">{{ $t("navigations.analytical dashboards") }}</h2>
    </div>
    <div class="col-lg-4">
      <div class="title-action">
        <!-- <router-link to="#" class="btn ip-btn mr-1 align-self-center mb-0"><i class="fa fa-bar-chart-o"></i> Insights Portal</router-link> -->
        <!-- <span
          class="text-end align-self-center"
          v-if="defaultdashboard != 'analyticaldashboard'"
        >
          <button
            type="button"
            class="btn btn-setdefault fontprop"
            @click="setasdefault()"
          >
            {{ $t("navigations.set as default") }}
          </button>
        </span> -->
      </div>
    </div>
    <!-- <div class="col-lg-2">
        <div class="text-end align-self-center" v-if="defaultdashboard != 'analyticaldashboard'">
          <button type="button" class="btn btn-setdefault fontprop" @click="setasdefault()">{{ $t('navigations.set as default')}}</button>
        </div>
        <button type="button" class="btn btn-insights right-sidebar-toggle"><img src="../../assets/img/Insights_Portal.png" alt="Insights Portal"></button>
      </div> -->
  </div>

  <div class="wrapper wrapper-content animated fadeInRight">
    <div class="row">
      <ul class="nav nav-tabs col-lg-12 mb-2" role="tablist">
        <li>
          <a
            href="#tab-1"
            class="nav-link"
            :class="{ activeTab: refreshFlags['DataAnalytics'] === true }"
            data-toggle="tab"
            @click="handleTabClick('DataAnalytics')"
          
          >
          {{ $t("navigations.clinical trial data analytics") }}
        </a>
        </li>
        <li>
          <a
            href="#tab-2"
            class="nav-link"
            :class="{
              activeTab: refreshFlags['OperationalAnalytics'] === true,
            }"
            data-toggle="tab"
            @click="handleTabClick('OperationalAnalytics')"
          >
           {{ $t("navigations.operational analytics") }}</a
          >
        </li>
      </ul>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="ibox">
          <!-- <div class="ibox-title style_2">
             <small class="float-right text-muted">Informations</small>
              text
          </div> -->
          <div class="row" v-if="refreshFlags['DataAnalytics']">
            <div class="col-md-9">
              <div class="row">
                <div class="col-md-4 pl-0 mb-2">
                  <router-link to="/demographicReport">
                    <div class="ip-widget">
                      <div class="row">
                        <div
                          class="col-2 justify-content-center align-self-center pl-1 py-0 pr-0 mt-1"
                        >
                          <span class="ip-badge dm-color align-middle mr-1"
                            >DE</span
                          >
                        </div>
                        <div
                          class="col-10 text-left justify-content-center align-self-center mt-1"
                        >
                          <h4 class="font-bold">Demographics</h4>
                          <span>
                            <h4>
                              <small>Interactive Dashboard Overview</small>
                            </h4>
                          </span>
                        </div>
                      </div>
                    </div>
                 </router-link>
                </div>
                <div class="col-md-4 pl-0 mb-2">
                  <router-link to="/aereport">
                    <div class="ip-widget">
                      <div class="row">
                        <div
                          class="col-2 justify-content-center align-self-center pl-1 py-0 pr-0 mt-1"
                        >
                          <span class="ip-badge ae-color align-middle mr-1"
                            >AE</span
                          >
                        </div>
                        <div
                          class="col-10 text-left justify-content-center align-self-center mt-1"
                        >
                          <h4 class="font-bold">Adverse Events Summary</h4>
                          <span>
                            <h4>
                              <small
                                >Critical Insights: Adverse Events
                                Dashboard</small
                              >
                            </h4>
                          </span>
                        </div>
                      </div>
                    </div>
                 </router-link>
                </div>
                <div class="col-md-4 pl-0 mb-2">
                  <router-link to="/subjectstatusreport">
                    <div class="ip-widget">
                      <div class="row">
                        <div
                          class="col-2 justify-content-center align-self-center pl-1 py-0 pr-0 mt-1"
                        >
                          <span class="ip-badge ss-color align-middle mr-1"
                            >SS</span
                          >
                        </div>
                        <div
                          class="col-10 text-left justify-content-center align-self-center"
                        >
                          <h4 class="font-bold">Subject Status Report</h4>
                          <span>
                            <h4>
                              <small>Mortality Analysis: Summary Report</small>
                            </h4>
                          </span>
                        </div>
                      </div>
                    </div>
                 </router-link>
                </div>
                <div class="col-md-4 pl-0 mb-2">
                  <router-link to="/deathsummaryreport">
                    <div class="ip-widget">
                      <div class="row">
                        <div
                          class="col-2 justify-content-center align-self-center pl-1 py-0 pr-0 mt-1"
                        >
                          <span class="ip-badge ds-color align-middle mr-1"
                            >DS</span
                          >
                        </div>
                        <div
                          class="col-10 text-left justify-content-center align-self-center mt-1"
                        >
                          <h4 class="font-bold">Death Summary Report</h4>
                          <span>
                            <h4>
                              <small>Mortality Analysis: Summary Report</small>
                            </h4>
                          </span>
                        </div>
                      </div>
                    </div>
                 </router-link>
                </div>
                <div class="col-md-4 pl-0 mb-2">
                  <router-link to="/labsummaryreport">
                    <div class="ip-widget">
                      <div class="row">
                        <div
                          class="col-2 justify-content-center align-self-center pl-1 py-0 pr-0 mt-1"
                        >
                          <span class="ip-badge lb-color align-middle mr-1"
                            >LS</span
                          >
                        </div>
                        <div
                          class="col-10 text-left justify-content-center align-self-center"
                        >
                          <h4 class="font-bold">Lab Summary Report</h4>
                          <span>
                            <h4>
                              <small>Laboratory Findings: Summary Report</small>
                            </h4>
                          </span>
                        </div>
                      </div>
                    </div>
                 </router-link>
                </div>
                <div class="col-md-4 pl-0 mb-2">
                  <router-link to="/labresultreview">
                    <div class="ip-widget">
                      <div class="row">
                        <div
                          class="col-2 justify-content-center align-self-center pl-1 py-0 pr-0 mt-1"
                        >
                          <span class="ip-badge lb-color align-middle mr-1"
                            >LS</span
                          >
                        </div>
                        <div
                          class="col-10 text-left justify-content-center align-self-center"
                        >
                          <h4 class="font-bold">Lab Result Review Overtime</h4>
                          <span>
                            <h4>
                              <small
                                >Trend Analysis: Lab Results Over Time</small
                              >
                            </h4>
                          </span>
                        </div>
                      </div>
                    </div>
                 </router-link>
                </div>
                <div class="col-md-4 pl-0 mb-2">
                  <router-link to="/labdescriptivestatus">
                    <div class="ip-widget">
                      <div class="row">
                        <div
                          class="col-2 justify-content-center align-self-center pl-1 py-0 pr-0 mt-1"
                        >
                          <span class="ip-badge lb-color align-middle mr-1"
                            >LS</span
                          >
                        </div>
                        <div
                          class="col-10 text-left justify-content-center align-self-center"
                        >
                          <h4 class="font-bold">Lab Descriptive Status</h4>
                          <span>
                            <h4>
                              <small
                                >Lab Descriptive Status</small
                              >
                            </h4>
                          </span>
                        </div>
                      </div>
                    </div>
                 </router-link>
                </div>
                <div class="col-md-4 pl-0 mb-2">
                  <router-link to="/vitalsignreport">
                    <div class="ip-widget">
                      <div class="row">
                        <div
                          class="col-2 justify-content-center align-self-center pl-1 py-0 pr-0 mt-1"
                        >
                          <span class="ip-badge vs-color align-middle mr-1"
                            >VS</span
                          >
                        </div>
                        <div
                          class="col-10 text-left justify-content-center align-self-center"
                        >
                          <h4 class="font-bold">Vital Signs Result Trends</h4>
                          <span>
                            <h4>
                              <small
                                >Health Monitoring: Vital Signs Trends</small
                              >
                            </h4>
                          </span>
                        </div>
                      </div>
                    </div>
                 </router-link>
                </div>
                <div class="col-md-4 pl-0 mb-2">
                  <router-link to="/vitalsigndescriptivestats">
                    <div class="ip-widget">
                      <div class="row">
                        <div
                          class="col-2 justify-content-center align-self-center pl-1 py-0 pr-0 mt-1"
                        >
                          <span class="ip-badge vs-color align-middle mr-1"
                            >VS</span
                          >
                        </div>
                        <div
                          class="col-10 text-left justify-content-center align-self-center"
                        >
                          <h4 class="font-bold">Vital Signs Descriptive Status</h4>
                          <span>
                            <h4>
                              <small
                                >Vital Signs Descriptive Status</small
                              >
                            </h4>
                          </span>
                        </div>
                      </div>
                    </div>
                 </router-link>
                </div>
                <div class="col-md-4 pl-0 mb-2">
                  <router-link to="/medicalhistory">
                    <div class="ip-widget">
                      <div class="row">
                        <div
                          class="col-2 justify-content-center align-self-center pl-1 py-0 pr-0 mt-1"
                        >
                          <span class="ip-badge mh-color align-middle mr-1"
                            >MH</span
                          >
                        </div>
                        <div
                          class="col-10 text-left justify-content-center align-self-center"
                        >
                          <h4 class="font-bold">Medical History Summary</h4>
                          <span>
                            <h4>
                              <small>Mortality Analysis: Summary Report</small>
                            </h4>
                          </span>
                        </div>
                      </div>
                    </div>
                 </router-link>
                </div>
                <div class="col-md-4 pl-0 mb-2">
                  <router-link to="/medicalhistorycrosstab">
                    <div class="ip-widget">
                      <div class="row">
                        <div
                          class="col-2 justify-content-center align-self-center pl-1 py-0 pr-0 mt-1"
                        >
                          <span class="ip-badge mh-color align-middle mr-1"
                            >MH</span
                          >
                        </div>
                        <div
                          class="col-10 text-left justify-content-center align-self-center"
                        >
                          <h4 class="font-bold">Medical History Cross Tabulation</h4>
                          <span>
                            <h4>
                              <small>Medical History Cross Tabulation</small>
                            </h4>
                          </span>
                        </div>
                      </div>
                    </div>
                 </router-link>
                </div>
                <div class="col-md-4 pl-0 mb-2">
                  <router-link to="/advereventcrosstabulation">
                    <div class="ip-widget">
                      <div class="row">
                        <div
                          class="col-2 justify-content-center align-self-center pl-1 py-0 pr-0 mt-1"
                        >
                          <span class="ip-badge ae-color align-middle mr-1"
                            >AE</span
                          >
                        </div>
                        <div
                          class="col-10 text-left justify-content-center align-self-center"
                        >
                          <h4 class="font-bold">
                            Adverse Events Cross Tabulation
                          </h4>
                          <span>
                            <h4>
                              <small
                                >Event Correlations: AE Cross Tabulation</small
                              >
                            </h4>
                          </span>
                        </div>
                      </div>
                    </div>
                 </router-link>
                </div>
                <div class="col-md-4 pl-0 mb-2">
                  <router-link to="/labResultTrend">
                    <div class="ip-widget">
                      <div class="row">
                        <div
                          class="col-2 justify-content-center align-self-center pl-1 py-0 pr-0 mt-1"
                        >
                          <span class="ip-badge lb-color align-middle mr-1"
                            >LT</span
                          >
                        </div>
                        <div
                          class="col-10 text-left justify-content-center align-self-center"
                        >
                          <h4 class="font-bold"> Lab Result Trends </h4>
                          <span>
                            <h4>
                              <small> Lab Result Trends </small>
                            </h4>
                          </span>
                        </div>
                      </div>
                    </div>
                 </router-link>
                </div>
                <div class="col-md-4 pl-0 mb-2">
                  <router-link to="/treatmentEmergentAE">
                    <div class="ip-widget">
                      <div class="row">
                        <div
                          class="col-2 justify-content-center align-self-center pl-1 py-0 pr-0 mt-1"
                        >
                          <span class="ip-badge ae-color align-middle mr-1"
                            >TE</span
                          >
                        </div>
                        <div
                          class="col-10 text-left justify-content-center align-self-center"
                        >
                          <h4 class="font-bold">
                            Treatment Emergent AE
                          </h4>
                          <span>
                            <h4>
                              <small
                                > Treatment Emergent AE</small
                              >
                            </h4>
                          </span>
                        </div>
                      </div>
                    </div>
                 </router-link>
                </div>
                <div class="col-md-4 pl-0 mb-2">
                  <router-link to="/ae_OverlayLabsReport">
                    <div class="ip-widget">
                      <div class="row">
                        <div
                          class="col-2 justify-content-center align-self-center pl-1 py-0 pr-0 mt-1"
                        >
                          <span class="ip-badge ae-color align-middle mr-1">AO</span>
                        </div>
                        <div
                          class="col-10 text-left justify-content-center align-self-center"
                        >
                          <h4 class="font-bold">Adverse Events Overlay Labs </h4>
                          <span>
                            <h4>
                              <small>Adverse Events Overlay Labs</small>
                            </h4>
                          </span>
                        </div>
                      </div>
                    </div>
                 </router-link>
                </div>

                <div class="col-md-4 pl-0 mb-2">
                  <router-link to="/vitalsignsresultovertime">
                    <div class="ip-widget">
                      <div class="row">
                        <div
                          class="col-2 justify-content-center align-self-center pl-1 py-0 pr-0 mt-1"
                        >
                          <span class="ip-badge ae-color align-middle mr-1">VS</span>
                        </div>
                        <div
                          class="col-10 text-left justify-content-center align-self-center"
                        >
                          <h4 class="font-bold">Vital Signs Result Overtime </h4>
                          <span>
                            <h4>
                              <small>Vital Signs Result Overtime</small>
                            </h4>
                          </span>
                        </div>
                      </div>
                    </div>
                 </router-link>
                </div>
                <div class="col-md-4 pl-0 mb-2">
                  <router-link to="/aeincidencetable">
                    <div class="ip-widget">
                      <div class="row">
                        <div
                          class="col-2 justify-content-center align-self-center pl-1 py-0 pr-0 mt-1"
                        >
                          <span class="ip-badge ae-color align-middle mr-1">AE</span>
                        </div>
                        <div
                          class="col-10 text-left justify-content-center align-self-center"
                        >
                          <h4 class="font-bold">AE Incidence Table</h4>
                          <span>
                            <h4>
                              <small>AE Incidence Table</small>
                            </h4>
                          </span>
                        </div>
                      </div>
                    </div>
                 </router-link>
                </div>
                <div class="col-md-4 pl-0 mb-2">
                  <router-link to="/exposurereport">
                    <div class="ip-widget">
                      <div class="row">
                        <div
                          class="col-2 justify-content-center align-self-center pl-1 py-0 pr-0 mt-1"
                        >
                          <span class="ip-badge ae-color align-middle mr-1">ER</span>
                        </div>
                        <div
                          class="col-10 text-left justify-content-center align-self-center"
                        >
                          <h4 class="font-bold">Exposure Data Report</h4>
                          <span>
                            <h4>
                              <small>Exposure Data Report</small>
                            </h4>
                          </span>
                        </div>
                      </div>
                    </div>
                 </router-link>
            </div>
            <div class="col-md-4 pl-0 mb-2">
                  <router-link to="/exposureandadverseevents">
                    <div class="ip-widget">
                      <div class="row">
                        <div
                          class="col-2 justify-content-center align-self-center pl-1 py-0 pr-0 mt-1"
                        >
                          <span class="ip-badge ae-color align-middle mr-1">EA</span>
                        </div>
                        <div
                          class="col-10 text-left justify-content-center align-self-center"
                        >
                          <h4 class="font-bold">Exposure And Adverse Events</h4>
                          <span>
                            <h4>
                              <small>Exposure And Adverse Events</small>
                            </h4>
                          </span>
                        </div>
                      </div>
                    </div>
                 </router-link>
                 </div>
                 </div>
            </div>
            <div class="col-md-3 dash-widget">
              <h4>Insights Portal</h4>
              <div>
                <ul class="list-group">
                  <router-link to="/demographicReport">
                    <li class="ip-list-item d-flex ip-text align-items-center mb-1 p-1">
                      <span class="ip-badge-side dm-color align-middle mr-1"
                        >DE</span
                      >
                      <span>Demographics</span>                      
                    </li>
                 </router-link>
                  <router-link to="/aereport">
                    <li class="ip-list-item d-flex ip-text align-items-center mb-1 p-1">
                      <span class="ip-badge-side ae-color align-middle mr-1"
                        >AE</span
                      >
                      <span>Adverse Events Summary</span>
                    </li>
                 </router-link>
                  <router-link to="/subjectstatusreport">
                    <li class="ip-list-item d-flex ip-text align-items-center mb-1 p-1">
                      <span class="ip-badge-side ss-color align-middle mr-1"
                        >SS</span
                      >
                      <span>Subject Status Report</span>
                    </li>
                 </router-link>
                  <router-link to="/deathsummaryreport">
                    <li class="ip-list-item d-flex ip-text align-items-center mb-1 p-1">
                      <span class="ip-badge-side ds-color align-middle mr-1"
                        >DS</span
                      >
                      <span>Death Summary Report</span>
                    </li>
                 </router-link>
                  <router-link to="/labsummaryreport">
                    <li class="ip-list-item d-flex ip-text align-items-center mb-1 p-1">
                      <span class="ip-badge-side lb-color align-middle mr-1"
                        >LS</span
                      >
                      <span>Lab Summary Report</span>
                    </li>
                 </router-link>
                  <router-link to="/labresultreview">
                    <li class="ip-list-item d-flex ip-text align-items-center mb-1 p-1">
                      <span class="ip-badge-side lb-color align-middle mr-1"
                        >LS</span
                      >
                      <span>Lab Result Review Overtime</span>
                    </li>
                 </router-link>
                 <router-link to="/labdescriptivestatus">
                    <li class="ip-list-item d-flex ip-text mb-1 p-1">
                      <span class="ip-badge-side lb-color align-middle mr-1"
                        >LS</span
                      >
                      <span>Lab Descriptive Status</span>
                    </li>
                 </router-link>
                  <router-link to="/vitalsignreport">
                    <li class="ip-list-item d-flex ip-text align-items-center mb-1 p-1">
                      <span class="ip-badge-side vs-color align-middle mr-1"
                        >VS</span
                      >
                      <span>Vital Sign Result Trends</span>
                    </li>
                 </router-link>
                 <router-link to="/vitalsigndescriptivestats">
                    <li class="ip-list-item d-flex ip-text mb-1 p-1">
                      <span class="ip-badge-side vs-color align-middle mr-1"
                        >VS</span
                      >
                      <span>Vital Sign Descriptive Status</span>
                    </li>
                 </router-link>
                  <router-link to="/medicalhistory">
                    <li class="ip-list-item d-flex ip-text align-items-center mb-1 p-1">
                      <span class="ip-badge-side mh-color align-middle mr-1"
                        >MH</span
                      >
                      <span>Medical History Summary</span>
                    </li>
                 </router-link>
                 <router-link to="/medicalhistorycrosstab">
                    <li class="ip-list-item d-flex ip-text mb-1 p-1">
                      <span class="ip-badge-side mh-color align-middle mr-1"
                        >MH</span
                      >
                      <span>Medical History Cross Tabulation</span>
                    </li>
                 </router-link>
                  <router-link to="/advereventcrosstabulation">
                    <li class="ip-list-item d-flex ip-text align-items-center mb-1 p-1">
                      <span class="ip-badge-side ae-color align-middle mr-1"
                        >AE</span
                      >
                      <span class="text-wrap">Adverse Events Cross Tabulation</span>
                    </li>
                 </router-link>
                 <router-link to="/labResultTrend">
                    <li class="ip-list-item d-flex ip-text align-items-center mb-1 p-1">
                      <span class="ip-badge-side ae-color align-middle mr-1"
                        >LR</span
                      >
                      <span class="text-wrap">Lab result trends</span>
                    </li>
                  </router-link>
                 <router-link to="/treatmentEmergentAE">
                    <li class="ip-list-item d-flex ip-text align-items-center mb-1 p-1">
                      <span class="ip-badge-side ae-color align-middle mr-1"
                        >AE</span
                      >
                      <span class="text-wrap">Treatment Emergent AE</span>
                    </li>
                 </router-link>
                 <router-link to="/ae_OverlayLabsReport">
                    <li class="ip-list-item d-flex ip-text mb-1 p-1">
                      <span class="ip-badge-side ae-color align-middle mr-1"
                        >AO</span
                      >
                      <span class="text-wrap">Adverse Events Overlay Labs </span>
                    </li>
                  </router-link>
                 <router-link to="/vitalsignsresultovertime">
                    <li class="ip-list-item d-flex ip-text align-items-center mb-1 p-1">
                      <span class="ip-badge-side ae-color align-middle mr-1"
                        >VS</span
                      >
                      <span class="text-wrap">Vital Signs Result Overtime</span>
                    </li>
                 </router-link>
                 <router-link to="/aeincidencetable">
                    <li class="ip-list-item d-flex ip-text align-items-center mb-1 p-1">
                      <span class="ip-badge-side ae-color align-middle mr-1"
                        >AE</span
                      >
                      <span class="text-wrap">AE Incidence Table</span>
                    </li>
                 </router-link>
                 <router-link to="/priorconcomitantsummary">
                    <li class="ip-list-item d-flex ip-text align-items-center mb-1 p-1">
                      <span class="ip-badge-side ae-color align-middle mr-1"
                        >PC</span
                      >
                      <span class="text-wrap">Prior and Concomitant Treatment Summary</span>
                    </li>
                 </router-link>
                 <router-link to="/comparativelabresult">
                    <li class="ip-list-item d-flex ip-text align-items-center mb-1 p-1">
                      <span class="ip-badge-side ae-color align-middle mr-1"
                        >CL</span
                      >
                      <span class="text-wrap">Comparative Lab Results</span>
                    </li>
                </router-link>
                 <router-link to="/exposurereport">
                    <li class="ip-list-item d-flex ip-text align-items-center mb-1 p-1">
                      <span class="ip-badge-side ae-color align-middle mr-1"
                        >ER</span
                      >
                      <span class="text-wrap">Exposure Data Report</span>
                    </li>
                 </router-link>
                 <router-link to="/exposureandadverseevents">
                    <li class="ip-list-item d-flex ip-text align-items-center mb-1 p-1">
                      <span class="ip-badge-side ae-color align-middle mr-1"
                        >EA</span
                      >
                      <span class="text-wrap">Exposure And Adverse Events</span>
                    </li>
                 </router-link>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script src="./Analytical_Dashboards"></script>
<style scoped>
.activeTab {
  color: #495057;
  background-color: #fff !important;
  border-color: #dee2e6 #dee2e6 #fff;
}
</style>