import axios from "axios";
import historytab from '../../custom_lib/AiCharts/historytab.vue';
import advtable from '../../custom_lib/AiCharts/advenenttable.vue';
import barchart from '../../custom_lib/AiCharts/adbarchart.vue';
import D3LineChart from '../../custom_lib/AiCharts/D3LineChart.vue';
import chartdrill from '../../custom_lib/AiCharts/popupfiles/chartdrillPopup.vue';
import sunburst from '../../custom_lib/AiCharts/sunburst.vue';
export default{
    name: "ae_OverlayLabsReport",
    components: {
        historytab,
        advtable,
        barchart,
        D3LineChart,
        chartdrill,
        sunburst
    },
    async mounted(){
        await this.getdata();
    },
    data() {
        return {
            basetestUrl: process.env.VUE_APP_Service_URL,
            data: [],
            datahistory:[],
            historykeys: [],
            historydata: {},
            drilldata:{},
            drilldowndata:[],
            drillpopup:false,
            loader: true,
            
            arrayofkeys: ["AETERM", "AESEV", "AESTDTC", "AEENDTC", "LBTEST",
                          "LBORRES_PRE", "LBORRES_POST", "LBDTC", "LBSTNRIND_PRE", 
                          "LBSTNRIND_POST", "AEOUT"]
        }
    },
    methods: {
        async pageclosed(){
            this.$router.push(`/analyticalDashboard`);
        },
        async getdata() {
            this.loader = true;
            await axios.post(
              `${this.basetestUrl}/forms-ml/report/getfilteredreports?table_name=AEOverlay`)
              .then((res) => {
                this.data = res.data;
                this.loader = false;
                this.datahistory.push(this.data);
                this.historydata['Home'] = this.data;
                this.historykeys.push('Home');
              })
              .catch((err) => console.log("error in screen list", err));
          },
        async gotohistorylevel(history){
            this.deleteAfterKeyvaluearrays(history);
            this.deleteAfterKeyarrays(history);
            this.data = this.historydata[history];
        },
        async deleteAfterKeyvaluearrays(selectedKey) {
            const entries = Object.entries(this.historydata); // Convert the object to an array of entries
            const selectedIndex = entries.findIndex(([key, value]) => key === selectedKey);
          
            // If the key is found, slice the array to keep only the entries up to the selected key
            const newEntries = selectedIndex !== -1 ? entries.slice(0, selectedIndex + 1) : entries;
          
            // Convert the array of entries back into an object
            return Object.fromEntries(newEntries);
        },
        async deleteAfterKeyarrays(selectedKey) {
            let index = this.historykeys.indexOf(selectedKey);
            if (index !== -1) {
                // Slice the array up to the element (including the element)
                this.historykeys = this.historykeys.slice(0, index + 1);
            }
        },
        async drilldowncurrentchartforbar(datafromchild){
            const lastkey = this.historykeys[this.historykeys.length - 1];
            if(this.historydata[lastkey].length != datafromchild.sortedData.length){
             this.historykeys.push(datafromchild.filterkeyword);
            this.historydata[datafromchild.filterkeyword] = datafromchild.sortedData;
            this.data = this.historydata[datafromchild.filterkeyword];
            }
        },
        async drillpopupopened(datafromchild) {
            this.drilldata = datafromchild;
            this.drillpopup = true;
        },
        async onclosed(){
            this.drillpopup = false;
          },
        
    }
}
