import store from "../../store/index";
import axios from "axios";
import moment from "moment";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
export default {
  name: "eConsent",
  components: {
    VPagination,
  },
  data() {
    return {
      auditpopup: false,
      audithistortpopup: false,
      changeHeight: false,
      inviteButtonstats: true,
      defaultdate: "",
      xlsFile: "",
      roleprivileges: "",
      ItemID: "",
      AuditId: "",
      auditHistoryLists: [],
      currentPage: 1,
      sites: "",
      baseUrl: `${process.env.VUE_APP_Service_URL}/management/patient/`,
      baseapi: process.env.VUE_APP_Service_URL,
      auditLists: [],
      patientLists: [],
      patientIds: [],
      countries: [],
      enablePrevious: true,
      enableNext: true,
      showPopup:false,
      totalPages: 1,
      siteId: "",
      err:"",
      subjectid: "",
      name:"",
      status: "",
      pageIndex: 1,
      pageSize: 5,
      sortProperty: "enrollmentDate",
      inviteEmail:"",
      sortorder: 1,

      searchData: {
        siteId: "",
        subjectid: "",
        status: "",
        pageIndex: 1,
        pageSize: 10,
        sortProperty: "createdAt",
        sortorder: 0,
      },
      sheduleList: [],
      isVisitCreated: 0,
      siteCode: "",
    };
  },
  async mounted() {
    console.clear();
    this.defaultdate = localStorage.getItem("dateformat");
    const idToken = store.getters.getIdToken;
    window.addEventListener("studyIdChanged", (event) => {
      this.search();
      this.getsites();
    });
    await this.setroleprivileges()
    await this.search();
    await this.getsites();
  },
  methods: {
    SortSelected(sortProp, id) {
      const selectedElement = document.getElementById(id);
      const selectedElementID = document.getElementById(id).id;
      if (sortProp != null && id == selectedElementID)
      {
        if (this.sortorder == 0) {
          if (selectedElement.classList.contains("sortAsc")) {
            selectedElement.classList.remove("sortAsc");
            selectedElement.classList.add("sortDesc");
          } else selectedElement.classList.add("sortDesc");
        } else {
          if (selectedElement.classList.contains("sortDesc")) {
            selectedElement.classList.remove("sortDesc");
            selectedElement.classList.add("sortAsc");
          } else selectedElement.classList.add("sortAsc");
        }
      }
      this.searchData.pageIndex = 1;
      this.currentPage = 1;
      this.sortProperty = sortProp;
      this.sortorder = this.sortorder == 0 ? 1 : 0;
      this.search();
    },
    async getsites() {
      const idtoken = store.getters.getIdToken;
      const currentstudy = store.getters.getStudyIs;
      await axios
        .get(
          `${this.baseapi}/management/site/getallsitesbyuser?studyId=${currentstudy}&sortProperty=siteCode&IsDes=false&getAll=false`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.sites = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    filter(data) {
      if (data) {
        return moment(data).format(this.defaultdate);
      }
    },
    async setroleprivileges() {
        this.roleprivileges = await store.getters.getRolesprivilegeData;
    },
    OnUserTypeOnEmailField() {
      const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      if (emailPattern.test(this.inviteEmail)) {
        this.inviteButtonstats = false;
      } else {
        this.inviteButtonstats = true;
      }
    },
    async editPatient(id) {
      this.$router.push(`/managePatient/${id}`);
    },
    openPopup(id,name){
      this.inviteEmail=""
      this.showPopup=true
      this.inviteButtonstats=true
      this.subjectid=id
      this.name=name
    },
    async inviteSubject() {
      const idtoken = store.getters.getIdToken;
      await axios
        .post(
          `${this.baseapi}/account-core/user/invitesubject`,
          {
            "emailID": this.inviteEmail,
            "studyName": this.name,
            "subjectID": this.subjectid
          },
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          alert(this.$t("navigations.e-consent sent successfully"));
          this.search();
          this.showPopup=false;
          this.inviteEmail=""
        })
        .catch((err) => {
          if(err.response.data)
          {
            if(err.response.data.errors.EmailID[0]=='The EmailID field is required.')
            {
              this.err=this.err.response.data.errors.EmailID[0]
            }
            else if(err.response.data.errors.StudyName[0]=='The StudyName field is required.')
            {
              this.err=this.err.response.data.errors.StudyName[0]
            }
            else if(err.response.data.errors.SubjectID[0]=='The SubjectID field is required.')
            {
              this.err=this.err.response.data.errors.SubjectID[0]
            }
          }
        });
    },
    async search() {
        const idtoken = store.getters.getIdToken;
        const currentstudy = store.getters.getStudyIs;
        // if (this.searchData.siteId == "All") {
        //   this.searchData.siteId = "";
        // }
        this.siteId = this.searchData.siteId;
        this.subjectid = this.searchData.subjectid;
        this.status = this.searchData.status;
        this.pageIndex = this.searchData.pageIndex;
        this.pageSize = this.searchData.pageSize;
        await axios
          .get(
            `${this.baseUrl}listpatient?SiteId=${this.siteId}&Studyid=${currentstudy}&SubjectId=${this.subjectid}&Status=${this.searchData.status}&pageindex=${this.pageIndex}&pagesize=${this.pageSize}&SortProperty=${this.sortProperty}&SortOrder=${this.sortorder}&api-version=1.0`,
            {
              headers: {
                Authorization: "Bearer " + idtoken,
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {           
            this.patientLists = res.data.results
            this.siteCode = this.patientLists.subjectId;
            this.totalPages = parseInt(res.data.totalCount / this.pageSize);             
              if (res.data.totalCount % this.pageSize != 0) {
                this.totalPages = this.totalPages + 1;
              }         
          });
      },
    async loadPage(page) {
      this.currentPage = page;
      this.searchData.pageIndex = page;
      this.search();
    },
    async SearchButtonClicked() {
      this.currentPage = 1;
      this.searchData.pageIndex = 1;
      this.search();
    }
    }
}
