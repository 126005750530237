<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>{{ $t("navigations.form summary") }}</h2>
    </div>
  </div>
  <div class="wrapper wrapper-content">
    <div class="ibox-content mb-1 px-3 py-2">
      <div class="form-group row mb-1">
        <div class="d-flex flex-column flex-lg-row my-2 my-lg-0 mb-lg-0">
          <div class="form-group mb-lg-0 w-100 pl-0 pr-1">
            <label class="mb-0">{{ $t("navigations.site code") }}</label>
            <select
              class="form-select my-md-2 my-lg-0"
              v-model="searchdata.SiteCode"
              name="account"
              @change="getpatients()"
            >
              <option value="All">{{ $t("navigations.all") }}</option>
              <option v-for="site in siteList" :key="site.sitetId" :value="site.siteID">
                {{ site.siteCode }}
              </option>
            </select>
          </div>
          <div class="form-group mb-lg-0 w-100 pr-1">
            <label class="mb-0">{{ $t("navigations.subject id") }}</label>
            <select
              class="form-select my-md-2 my-lg-0"
              v-model="searchdata.PatientId"
              name="account"
            >
              <option value="All">{{ $t("navigations.all") }}</option>
              <option
                v-for="patient in patients"
                :key="patient.patientId"
                :value="patient.patientId"
              >
                {{ patient.subjectId }}
              </option>
            </select>
          </div>
          <div class="form-group mb-lg-0 w-100 pr-1">
            <label class="mb-0">{{ $t("navigations.visit name") }}</label>
            <input
              type="search"
              v-model="searchdata.FormName"
              class="form-control my-md-2 my-lg-0"
            />
          </div>
          <div class="form-group mb-lg-0 w-100 pr-1">
            <label class="mb-0">{{ $t("navigations.status") }}</label>
            <select
              class="form-select my-md-2 my-lg-0"
              v-model="searchdata.FormStatus"
              name="account"
            >
              <option value="All">{{ $t("navigations.all") }}</option>
              <option value="New">{{ $t("navigations.new") }}</option>
              <option value="Unverified">{{ $t("navigations.unverified") }}</option>
              <option value="Verified">{{ $t("navigations.verified") }}</option>
            </select>
          </div>
          <!-- <span type="button" @click.prevent="filteredLists" -->
          <span
            type="button"
            class="col-12 col-lg-2 save_btn mx-lg-1 align-self-end"
            @click="searchData()"
          >
            {{ $t("navigations.search") }}
          </span>
        </div>
      </div>
    </div>
    <div class="ibox-body">
      <div class="ibox-title style_2">
        <h5 class="float-left">{{ $t("navigations.form list") }}</h5>
      </div>
      <div class="ibox-content">
        <div class="table-responsive">
          <table class="table table-striped table-bordered dataTables">
            <thead>
              <tr>
                <th>{{ $t("navigations.site code") }}</th>
                <th>{{ $t("navigations.subject id") }}</th>
                <th class="sort_block" @click="SortSelected('visitName', 3)" id="3">
                  <div class="d-flex flex-row justify-content-between">
                    {{ $t("navigations.visit name") }}
                    <span>
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span>
                  </div>
                </th>
                <th class="sort_block" @click="SortSelected('FormStatus', 4)" id="4">
                  <div class="d-flex flex-row justify-content-between">
                    {{ $t("navigations.status") }}
                    <span>
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span>
                  </div>
                </th>
                <th class="sort_block" @click="SortSelected('createdUserName', 5)" id="5">
                  <div class="d-flex flex-row justify-content-between">
                    {{ $t("navigations.uploaded by") }}
                    <span>
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span>
                  </div>
                </th>
                <th class="sort_block" @click="SortSelected('createdAt', 6)" id="6">
                  <div class="d-flex flex-row justify-content-between">
                    {{ $t("navigations.uploaded date") }}
                    <span>
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span>
                  </div>
                </th>
                <th v-if="roleprivileges.includes('Form Summary View Form')" class="text-center">
                  {{ $t("navigations.view") }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in dataList" :key="item.formId">
                <td>{{ item.siteId }}</td>
                <td>{{ item.subjectId }}</td>
                <td>{{ item.visitName }}</td>
                <td>{{ item.formStatus }}</td>
                <td>{{ item.createdUserName }}</td>
                <td>{{ filter(item.createdAt) }}</td>
                <td class="text-center" v-if="roleprivileges.includes('Form Summary View Form')">
                  <i
                    class="fa fa-eye editicon"
                    @click.prevent="viewforms(item.formId)"
                  ></i>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!------ pagination -------->
        <div class="row mx-0">
          <div class="col-lg-12 mx-0 px-0">
            <div class="dataTables_paginate paging_simple_numbers pagination float-right">
              <div class="col-lg-12 d-flex justify-content-lg-end px-0">
                <v-pagination
                  v-model="currentPageNumber"
                  :pages="totalLists"
                  :range-size="1"
                  active-color="#618bd7"
                  @update:modelValue="loadPage"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-1">
      <div class="col-lg-6 mb-1 pe-1">
        <div class="ibox-body">
          <div class="ibox-title style_2">
            <h5 class="float-left">{{ $t("navigations.visits") }}</h5>
          </div>
          <div class="ibox-content">
            <ul class="tree">
              <li v-for="(site, index) in siteList" :key="site.siteID">
                <input
                  type="checkbox"
                  :id="site.siteID"
                  @change="getpatient(site.siteID, index)"
                />
                <label class="tree_label" :for="site.siteID">{{ site.siteCode }}</label>
                <ul
                  v-for="(patient, index) in patientLists[index]"
                  :for="site.siteID"
                  :key="patient.patientId"
                >
                  <li>
                    <input
                      type="checkbox"
                      :id="patient.patientId"
                      @change="getVisits(patient.patientId, index)"
                    />
                    <label :for="patient.patientId" class="tree_label">{{
                      patient.subjectId
                    }}</label>
                    <ul
                      v-for="visit in visitobj[patient.patientId][index]"
                      :for="patient.patientId"
                      :key="visit.visitId"
                    >
                      <li :for="patient.patientId" @click.prevent="gotoform(visit)">
                        <input type="checkbox" />
                        <label class="tree_label"
                          >{{ visit.visitNo }} - {{ visit.visitName }} -
                          {{ filter(visit.visitDate) }} -
                          <span
                            class="badge badge-grn"
                            v-if="visit.visitStatus == 'Completed'"
                            >{{ visit.visitStatus }}</span
                          >
                          <span
                            class="badge badge-danger"
                            v-if="visit.visitStatus == 'Overdue'"
                            >{{ visit.visitStatus }}</span
                          >
                          <span
                            class="badge badge-warning"
                            v-if="visit.visitStatus == 'Imminent'"
                            >{{ visit.visitStatus }}</span
                          >
                          <span
                            class="badge badge-genre"
                            v-if="
                              visit.visitStatus == 'Withdrawn' ||
                              visit.visitStatus == 'Planned'
                            "
                            >{{ visit.visitStatus }}</span
                          >
                        </label>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-lg-6 pl-1 mb-1">
        <div class="ibox-body">
          <div class="ibox-body">
            <div class="ibox-title style_2 d-flex justify-content-between">
              <h5 class="mt-1">{{ $t("navigations.forms") }}</h5>
              <div class="d-flex align-items-center me-1 w-50">
                <span class="fit-content me-1">{{ $t("navigations.site code") }}:</span>
                <select
                  class="form-select"
                  v-model="siteId"
                  @change="
                    formchartdata($event.target.value);
                    formstabledata($event.target.value);
                  "
                  placeholder="Search"
                >
                  <option class="options" value="">{{ $t("navigations.all") }}</option>
                  <option
                    class="options"
                    v-for="site in siteList"
                    :key="site.siteID"
                    :value="site.siteID"
                  >
                    {{ site.siteCode }}
                  </option>
                </select>
              </div>
              <div class="graphbtncontainer">
                <div class="graphbutton">
                  <img
                    src="../../assets/images/bar_clr.png"
                    @click.prevent="secondchartchange('bar')"
                  />
                </div>
                <div class="graphbutton">
                  <img
                    src="../../assets/images/horiz_bar_clr.png"
                    @click.prevent="secondchartchange('barhorizondal')"
                  />
                </div>
                <div class="graphbutton">
                  <img
                    src="../../assets/images/Line_clr.png"
                    @click.prevent="secondchartchange('line')"
                  />
                </div>
                <div class="graphbutton">
                  <img
                    src="../../assets/images/Pie_clr.png"
                    @click.prevent="secondchartchange('pie')"
                  />
                </div>
                <div class="graphbutton">
                  <img
                    src="../../assets/images/bubble_clr.png"
                    @click.prevent="secondchartchange('scatter')"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="ibox-content">
            <apexchart
              v-if="
                formchart.charttype != 'pie' && formchart.charttype != 'barhorizondal'
              "
              width="100%"
              height="250px"
              :type="formchart.charttype"
              :options="formchart.chartOptions"
              :series="formchart.dataseries"
            ></apexchart>
            <apexchart
              v-if="formchart.charttype == 'barhorizondal'"
              width="100%"
              height="250px"
              type="bar"
              :options="formbarchart.chartOptions"
              :series="formbarchart.dataseries"
            ></apexchart>
            <apexchart
              v-if="formchart.charttype == 'pie'"
              width="100%"
              height="250px"
              :type="formchart.charttype"
              :options="formchart.piechartOptions"
              :series="formchart.piedataseries"
            ></apexchart>
            <!-- <canvas id="barChart" height="140"></canvas> -->
          </div>
        </div>
      </div>
    </div>
    <!-- <v-jstree :data="data" show-checkbox multiple allow-batch whole-row @item-click="itemClick"></v-jstree> -->
    <!-- <div v-if="roleprivileges.includes('Submit Form Patient Schedule')" class="col-lg-6 mb-1"> -->

    <!-- </div> -->
  </div>
</template>

<script src="./formSummary.js" type="text/javascript"></script>
<style scoped>
@import "../../assets/style.css";
@import "../../assets/formpage.css";
@import "../../assets/bootstrap.css";
@import "../../assets/font-awesome/css/font-awesome.css";
.previousbtn,
.nextbtn {
  margin: 0;
  border-radius: 0px;
  border: 0.5px solid #eeeeee;
}
.page-links {
  border-radius: 0px;
}
.form-select {
  border-radius: 0 !important;
}
.form-select:focus {
  border-color: #145faf !important;
  box-shadow: 0 0 0 !important;
}
.widget {
  margin-top: 0rem;
  margin-bottom: 0rem;
}
.widgetmargin {
  margin-top: -13px !important;
}
.ibox-body {
  background-color: #ffffff;
  margin: 3rem, 0;
}

.style1 {
  background-color: black !important;
  color: #ffffff;
}
.btn-view {
  color: #fff !important;
  background-color: #bb458f !important;
  border-color: #bb458f !important;
}
.btn-view:hover {
  color: #fff;
  background-color: #666ccb;
  border-color: #666ccb;
}
.btn-view:active {
  color: rgb(255, 255, 255) !important;
  background-color: #dda2c7 !important;
  border-color: #dda2c7 !important;
}
.ibox-title {
  color: inherit;
  margin-bottom: 0;
  padding: 1rem;
  position: relative;
  clear: both;
  right: 0;
  margin-right: 0px;
  width: unset !important;
  margin-left: 0px !important;
  margin-top: 0px !important;
}
.padding_bottom {
  padding-bottom: 2rem;
}
.ibox-title h5 {
  height: 0rem;
  padding: 0rem;
}
.ibox-title h5:hover {
  background-color: inherit;
  padding: 0;
  cursor: not-allowed;
}
.titlemsg {
  font-size: 15px;
  font-weight: bold;
  color: var(--primary-color);
}
.switchbtn {
  background-color: #bb458f !important;
  height: max-content;
  border-color: #bb458f !important;
  color: white !important;
  float: right;
}
.switchbtn:active {
  height: max-content;
  border-color: #bb458f !important;
  color: white !important;
  float: right;
  background-color: #bb458f;
}
.switchbtn:hover {
  height: max-content;
  border-color: #bb458f !important;
  color: white !important;
  float: right;
  background-color: #bb458f;
}
.queryhover:hover {
  color: #3c4192 !important;
  -webkit-transition: color ease-in 400ms;
  transition: color ease-in 400ms;
  cursor: pointer;
}

.widgetminheight {
  min-height: 102px;
}
.graphbutton {
  width: fit-content;
  padding: 3px;
}
.graphbutton:hover {
  cursor: pointer;
  border-color: #3c4192;
}
.graphbtncontainer {
  display: flex;
  gap: 5px;
}
img {
  margin-left: 0px !important;
}
.fixed-height {
  min-height: 200px;
  overflow-y: scroll;
  margin-bottom: 30px;
  max-height: 400px;
}
thead tr th {
  background: white;
  position: sticky;
  top: 0;
  z-index: 10;
}
.selectdefault {
  left: 0;
}
.selectdefaultcontainer {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
}
.btn:focus {
  outline: none;
  box-shadow: none;
}
.fontprop {
  font-size: 10px !important;
}
.btn-setdefault {
  position: relative;
  padding: 0.2rem 0.4rem;
  text-align: center;
  font-size: 0.7rem;
  background-color: #ec4758;
  color: white !important;
  border-radius: 3px;
}
.ibox-title h5 {
  float: none;
}
.width-10 {
  width: 50%;
}
.form-select {
  border-radius: 0 !important;
}
.form-select:focus {
  border-color: #145faf !important;
  box-shadow: 0 0 0 !important;
}
.fit-content {
  white-space: nowrap;
}
</style>
