<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>{{ $t("navigations.documents") }}</h2>
    </div>
  </div>
  <div class="wrapper wrapper-content">
    <div class="ibox-content mb-1 px-3 py-2">
      <div class="form-group row mb-0 my-lg-0">
        <div
          class="
            d-flex
            flex-column flex-lg-row
            justify-content-lg-start
            my-2 my-lg-0
            mb-lg-0
          "
        >
          <div class="col-lg-2 pl-lg-2">
            <div class="form-group mb-md-0">
              <label class="mb-0">{{
                $t("navigations.document storage area")
              }}</label>
              <input
                type="search"
                class="form-control my-md-2 my-lg-0"
                v-model="searchData.documentStorage"
              />
            </div>
          </div>
          <div class="col-lg-3 pl-lg-2">
            <div class="form-group mb-md-0">
              <label class="mb-0">{{ $t("navigations.document name") }}</label>
              <input
                type="search"
                class="form-control my-md-2 my-lg-0"
                v-model="searchData.docName"
              />
            </div>
          </div>
          <div class="col-lg-5 d-flex flex-column pl-lg-2">
            <div class="form-group mb-0">
              <label class="mb-0">{{ $t("navigations.uploaded date") }}</label>
              <div class="d-flex mr-0 pr-0">
                <input
                  type="date"
                  max="9999-12-31"
                  class="form-control"
                  v-model="searchData.fromDate"
                />
                <div class="align-self-center px-1">
                  {{ $t("navigations.to") }}
                </div>
                <input
                  type="date"
                  max="9999-12-31"
                  class="form-control"
                  v-model="searchData.toDate"
                />
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-2 mt-2 mt-md-0 px-0 px-md-2 align-self-end">
            <span
              type="button"
              class="save_btn mx-md-1 w-100"
              @click="searchDoc()"
            >
              {{ $t("navigations.search") }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="ibox-body">
      <div class="ibox-title style_2">
        <h5 class="float-left">
          {{ $t("navigations.document list") }}
        </h5>
      </div>
      <div class="ibox-content">
        <div class="table-responsive">
          <table class="table table-striped table-bordered dataTables">
            <thead>
              <tr>
                <th
                  class="sort_block"
                  @click="SortSelected('documentType', 1)"
                  id="1"
                >
                  <div class="d-flex flex-row justify-content-between">   
                    {{ $t("navigations.document type") }}           
                    <span>
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span>
                </div>
                  
                </th>
                <th
                  class="sort_block"
                  @click="SortSelected('typeValue', 2)"
                  id="2"
                >
                  <div class="d-flex flex-row justify-content-between"> 
                    {{ $t("navigations.document value") }}             
                    <span>
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span>
                  </div>
                  
                </th>
                <th
                  class="sort_block"
                  @click="SortSelected('documentName', 3)"
                  id="3"
                >
                  <div class="d-flex flex-row justify-content-between">  
                    {{ $t("navigations.document name") }}            
                    <span>
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span>
                  </div>
                </th>
                <th
                  class="sort_block"
                  @click="SortSelected('CreatedAt', 4)"
                  id="4"
                >
                  <div class="d-flex flex-row justify-content-between">  
                    {{ $t("navigations.uploaded date") }}            
                    <span>
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span>
                  </div>                  
                </th>
                <th
                  class="sort_block"
                  @click="SortSelected('createdUser', 5)"
                  id="5"
                >
                  <div class="d-flex flex-row justify-content-between">   
                    {{ $t("navigations.uploaded by") }}           
                    <span>
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span>
                  </div>                  
                </th>
                <th
                  class="text-center"
                  v-if="roleprivileges.includes('Documents Assign Approver')"
                >
                  {{ $t("navigations.approvals") }}
                </th>
                <th class="text-center">{{ $t("navigations.history") }}</th>
                <th class="text-center" v-if="roleprivileges.includes('Documents View Document')">{{ $t("navigations.view") }}</th>
                <th class="text-center" v-if="roleprivileges.includes('Documents Download Document')">{{ $t("navigations.download") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="doc in docsummary" :key="doc.id">
                <td>{{ doc.documentType }}</td>
                <td>{{ doc.typeValue }}</td>
                <td>{{ doc.documentName }}</td>
                <td>{{ doc.CreatedAt ? filter(doc.CreatedAt) : "" }}</td>
                <td>{{ doc.createdUser }}</td>
                <td
                  class="text-center"
                  v-if="roleprivileges.includes('Documents Assign Approver')"
                >
                  <i
                    class="fa fa-check-circle-o edit-delete"
                    @click="
                      AddApproverPopuup(
                        doc.id,
                        doc.documentVersions[doc.documentVersions.length - 1]
                          .documentVersionId,
                        doc.documentVersions[doc.documentVersions.length - 1]
                          .versionNumber,
                        doc.documentVersions[doc.documentVersions.length - 1]
                          .approval
                      )
                    "
                  ></i>
                </td>
                <td class="text-center">
                  <i
                    class="fa fa-history edit-delete"
                    @click.prevent="OpenHistoryModal(doc.id)"
                  >
                  </i>
                </td>
                <td class="text-center" v-if="roleprivileges.includes('Documents View Document')">
                  <i
                    class="fa fa-eye edit-delete"
                    @click="viewDocument(doc.id, 0, '')"
                  ></i>
                </td>
                <td class="text-center" v-if="roleprivileges.includes('Documents Download Document')">
                  <i
                    class="fa fa-download edit-delete"
                    @click="downloadfile(doc.id, 0)"
                  ></i>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- pagination -->

        <div class="row mx-0">
          <div class="col-lg-12 mx-0 px-0">
            <div
              class="
                dataTables_paginate
                paging_simple_numbers
                pagination
                float-right
              "
            >
              <div class="col-lg-12 d-flex justify-content-lg-end px-0">
                <v-pagination
                  v-model="currentPage"
                  :pages="totalPage"
                  :range-size="1"
                  active-color="#618bd7"
                  @update:modelValue="loadPage"
                />
              </div>
            </div>
          </div>
        </div>

        <!-- pagination ends -->
      </div>
    </div>
  </div>

  <AddApproverLibrary
    v-if="popactive"
    :id="documentPopupId"
    :versionId="PopupVersionId"
    :versionNumber="PopupVersionNumber"
    @libactive="popactive = false"
    @docsumm="getdocumentsummary()"
  >
  </AddApproverLibrary>

  <listApproverPopup
    v-if="popupmodal"
    :id="documentPopupId"
    :versionId="PopupVersionId"
    :versionNumber="PopupVersionNumber"
    @libactive="popupmodal = false"
    @docsumm="getdocumentsummary()"
  >
  </listApproverPopup>

  <!--  popup-->
  <!-- Document history modal starts -->
  <div class="approver_modal__wrapper1" v-if="documentHistory">
    <div class="history-modal-container">
      <div
        class="approver__modal__title d-flex justify-content-between py-2 px-3"
      >
        <h4 class="my-0">{{ $t("navigations.document history") }}</h4>
        <div class="cursor-pointer" @click.prevent="closeHistoryModal">
          <i class="fa fa-times"></i>
        </div>
      </div>
      <div class="p-3">
        <div class="table-responsive">
          <table class="table table-bordered dataTables">
            <thead>
              <tr>
                <th>{{ $t("navigations.version number") }}</th>
                <th>{{ $t("navigations.uploaded date") }}</th>
                <th>{{ $t("navigations.uploaded by") }}</th>
                <th class="text-center" v-if="roleprivileges.includes('Documents View Document')">{{ $t("navigations.view") }}</th>
                <th class="text-center" v-if="roleprivileges.includes('Documents Download Document')">{{ $t("navigations.download") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="doc in docHistoryList" :key="doc.id">
                <td>{{ doc.versionNumber }}</td>
                <td>{{ doc.uploadedOn ? filter(doc.uploadedOn) : "" }}</td>
                <td>{{ doc.uploadedBy }}</td>
                <td class="text-center" v-if="roleprivileges.includes('Documents View Document')">
                  <i
                    class="fa fa-eye editicon"
                    @click="
                      viewDocument(
                        documentId,
                        doc.documentVersionId,
                        doc.docFilename
                      )
                    "
                  ></i>
                </td>
                <td class="text-center" v-if="roleprivileges.includes('Documents Download Document')">
                  <i
                    class="fa fa-download edit-delete"
                    @click="downloadfile(documentId, doc.documentVersionId)"
                  ></i>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- pagination -->
        <div class="row mx-0">
          <div class="col-lg-12 mx-0 px-0">
            <div
              class="
                dataTables_paginate
                paging_simple_numbers
                pagination
                float-right
              "
            >
              <div class="col-lg-12 d-flex justify-content-lg-end px-0">
                <v-pagination
                  v-model="historyCurrentPage"
                  :pages="historyTotalPage"
                  :range-size="1"
                  active-color="#618bd7"
                  @update:modelValue="loadHistoryPage"
                />
              </div>
            </div>
          </div>
        </div>
        <!-- pagination ends -->
      </div>
    </div>
  </div>
  <!-- Document history modal ends -->
</template>

<script>
/* eslint-disable */
import axios from "axios";
import store from "../../store/index";
import moment from "moment";
import AddApproverLibrary from "../AddApproverLibrary/AddApproverLibrary.vue";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import listApproverPopup from "../listApprover/listApproverPopup.vue";
const idtoken = store.getters.getIdToken;

export default {
  name: "documentSummary",
  components: {
    VPagination,
    AddApproverLibrary,
    listApproverPopup,
  },

  data() {
    return {
      popupmodal: false,
      popactive: false,
      roleprivileges: "",
      documentHistory: false,
      assignBlock: false,
      displayModal: false,
      folderbyid: "",
      errors: [],
      validations: [],
      xlsFile: "",
      documentVersionId: [],
      showDoc: false,
      versionNumber: "",
      values: { Approver: [] },
      searchData: {
        docName: "",
        documentStorage: "",
        fromDate: "",
        toDate: "",
        pageSize: 10,
        pageNo: 1,
      },
      validation: [false],
      requestData: {
        docName: "",
        documentStorage: "",
        fromDate: "",
        toDate: "",
        pageSize: 10,
        pageNo: 1,
      },
      currentdocId: "",
      currentPage: 1,
      totalPage: 0,
      totalCount: 1,
      docsummary: "",
      documentPopupId: "",

      documentId: "",
      docHistoryList: [],
      historyTotalCount: 1,
      historyTotalPage: 0,
      historyCurrentPage: 1,
      historyPageSize: 10,
      historyPageNo: 1,
      baseurl: process.env.VUE_APP_Service_URL,
      baseapi: process.env.VUE_APP_Service_URL,
      fileuploadurl: process.env.VUE_APP_File_upload_URL+"/document",
      approverList: "",
      docApprvId: "",
      docVersionNumber: "",
      approverlistsforPopup: "",
      ApproversName: [],
      displaySidePopup: false,
      versionIdForPopup: "",
      pageindex: 1,
      currentPage: 1,
      SortProperty: "CreatedAt",
      SortOrder: 1,
    };
  },

  async mounted() {
    this.defaultdate = localStorage.getItem("dateformat");
    this.getdocumentsummary();
    this.filter();
    await this.setroleprivileges();
  },
  methods: {
    async getDocumentById(docId) {
      this.currentdocId = docId;
      const idtoken = store.getters.getIdToken;
      await axios
        .get(
          `${this.baseurl}/management/document/getdocumentbyid?id=` + docId,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.Document = res.data;
          this.loaddata = true;
          this.documentName = this.Document.documentName;
        });
    },
    async setroleprivileges() {
      this.roleprivileges = await store.getters.getRolesprivilegeData;
      console.log(this.roleprivileges);
    },
    async AddApproverPopuup(doc, versionId, versionNumber, approvallength) {
      this.documentPopupId = doc;
      this.PopupVersionId = versionId;
      this.PopupVersionNumber = versionNumber;
      console.log("Hellooooiii", doc, versionId, approvallength);
      if (approvallength.length == 0 || approvallength == null) {
        this.popactive = true;
      } else {
        this.popupmodal = true;
      }
    },
    async AddapproverOrDetails(docId, versionNumber) {
      this.verNumber = versionNumber;

      console.log("hiiiiiiiiii ver id is", versionNumber);
      console.log("hiiiiiiiiii add again approver", this.docId);
      // console.log("status is ", this.selectStatus);
      this.assignBlock = !this.assignBlock;
    },
    async closemodalll() {
      console.log("close modal");
      this.assignBlock = !this.assignBlock;
    },
    async getdmsdocument() {
      this.documentById = "";
      console.log("document download", this.Document);
      await axios
        .get(
          `${this.baseapi}/dms/document/getallfiles/${this.Document.documentReferenceId}`,
          {
            headers: {
              Authorization: "Bearer " + this.idToken,

              "Content-Type": "application/json",
              responseType: "blob",
            },
          }
        )
        .then((res) => {
          this.documentById = res.data;
          console.log("Docdownld............", this.documentById);
        })
        .catch((err) => {
          alert(this.$t("navigations.document is not available"));
          console.log(err);
        });
    },
    async getDocumentHistory(docId) {
      const idtoken = store.getters.getIdToken;
      await axios
        .get(
          `${this.baseapi}/management/document/getdocumenthistory?documentid=${docId}&pageindex=${this.historyPageNo}&pagesize=${this.historyPageSize}`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.docHistoryList = res.data.results;
          console.log("document history", res);
          this.historyTotalCount = res.data.totalCount;
          this.historyTotalPage = parseInt(
            this.historyTotalCount / this.historyPageSize
          );
          if (this.historyTotalCount % this.historyPageSize != 0) {
            this.historyTotalPage = this.historyTotalPage + 1;
          }
        })
        .catch((err) => {
          console.log("error", err);
        });
    },
    filter(data) {
      if (data) {
        return moment(data).format(this.defaultdate);
      }
      console.log("Reverse is");
      console.log(data);
    },
    SortSelected(sortProp, id) {
      console.log("SortPropChanged", sortProp);
      const selectedElement = document.getElementById(id);
      const selectedElementID = document.getElementById(id).id;
      if (sortProp != null && id == selectedElementID) {
        this.sortDesc = !this.sortDesc;
        if (this.SortOrder == 0) {
          if (selectedElement.classList.contains("sortAsc")) {
            selectedElement.classList.remove("sortAsc");
            selectedElement.classList.add("sortDesc");
          } else selectedElement.classList.add("sortDesc");
        } else {
          if (selectedElement.classList.contains("sortDesc")) {
            selectedElement.classList.remove("sortDesc");
            selectedElement.classList.add("sortAsc");
          } else selectedElement.classList.add("sortAsc");
        }
      }
      this.pageindex = 1;
      this.currentPage = 1;
      this.SortProperty = sortProp;
      this.SortOrder = this.SortOrder == 0 ? 1 : 0;
      this.getdocumentsummary();
    },
    async viewDocument(docId, versionID, fileName) {
      this.ShowDocName = false;
      var versionId = "";
      var filename = "";
      await this.getDocumentById(docId);
      await this.getdmsdocument(docId);
      if (versionID == 0) {
        versionId =
          this.Document.documentVersions[
            this.Document.documentVersions.length - 1
          ].documentVersionId;
      } else {
        versionId = versionID;
      }
      if (fileName == "") {
        filename =
          this.Document.documentVersions[
            this.Document.documentVersions.length - 1
          ].fileName;
      } else {
        filename = fileName;
      }

      console.log("docid....!", docId, versionID, filename);
      if (this.documentById != "") {
        this.$router.push(
          `/documentview/${this.Document.documentReferenceId}/${filename}/${versionId}`
        );
      }
    },
    async downloadfile(docId, versionID) {
      this.ShowDocName = false;
      var versionId;
      await this.getDocumentById(docId);
      await this.getdmsdocument(docId);
      if (versionID == 0) {
        versionId =
          this.Document.documentVersions[
            this.Document.documentVersions.length - 1
          ].documentVersionId;
      } else {
        versionId = versionID;
      }
      var a = document.createElement("a");
      a.href = `${this.fileuploadurl}/file/${this.Document.documentReferenceId}?versionid=${versionId}`;
      a.download = "FILENAME";
      a.click();
    },
    async getdocumentsummary() {
      const idtoken = store.getters.getIdToken;
      this.validateDate(this.searchData.fromDate, this.searchData.toDate);
      if (this.validation.includes(true)) {
        alert(this.$t("navigations.from date should be less than to date"));
      } else {
        await axios
          .get(
            `${this.baseurl}/management/document/listdocumentsummary?documentStorage=${this.requestData.documentStorage}&documentName=${this.requestData.docName}&FromDate=${this.requestData.fromDate}&ToDate=${this.requestData.toDate}&pageindex=${this.searchData.pageNo}&pagesize=${this.searchData.pageSize}&SortProperty=${this.SortProperty}&SortOrder=${this.SortOrder}`,
            {
              headers: {
                Authorization: "Bearer " + idtoken,
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            console.log(res);

            this.docsummary = res.data.results;
            console.log("Doc summary......", this.docsummary);
            this.totalCount = res.data.totalCount;
            this.totalPage = parseInt(
              this.totalCount / this.searchData.pageSize
            );
            if (this.totalCount % this.searchData.pageSize != 0) {
              this.totalPage = this.totalPage + 1;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    validateDate(fromDate, toDate) {
      if (fromDate > toDate) {
        this.validation[0] = true;
      } else {
        this.validation[0] = false;
      }
    },
    OpenHistoryModal(documentId) {
      this.documentId = documentId;
      document.body.style.overflowY = "hidden";
      this.getDocumentHistory(this.documentId);
      this.documentHistory = !this.documentHistory;
    },
    closeHistoryModal() {
      document.body.style.overflowY = "auto";
      this.documentHistory = !this.documentHistory;
      this.documentId = "";
      this.docHistoryList = [];
      this.historyTotalCount = 1;
      this.historyTotalPage = 0;
      this.historyCurrentPage = 1;
      this.historyPageSize = 10;
      this.historyPageNo = 1;
    },
    async searchDoc() {
      this.searchData.pageNo = 1;
      this.currentPage = 1;
      this.requestData.docName = this.searchData.docName;
      this.requestData.fromDate = this.searchData.fromDate;
      this.requestData.toDate = this.searchData.toDate;
      this.requestData.documentStorage = this.searchData.documentStorage;
      console.log(this.requestData);
      await this.getdocumentsummary();
    },
    loadPage: function (page) {
      this.currentPage = page;
      this.searchData.pageNo = page;
      this.getdocumentsummary();
    },
    loadHistoryPage: function (page) {
      this.historyCurrentPage = page;
      this.historyPageNo = page;
      this.getDocumentHistory(this.documentId);
    },
  },
};
</script>
<style>
@import "../style.css";
@import "../../assets/formpage.css";
.editicon {
  position: relative;
}
.modelbody {
  background-color: white !important ;
}
.tdtext {
  text-align: center;
}
.editicon .pointer {
  visibility: hidden;
  width: 150px;
  background-color: #616161;
  color: #fff;
  text-align: center;
  border-radius: 0.3em;
  padding: 0.3rem 0.2rem;
  right: 0;
  top: -35px;
  font-size: 0.8rem;
  font-weight: 500;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  transition: visibility 0.4s ease-in;
}
.editicon:hover .pointer {
  visibility: visible;
}
/*pagination styles*/
.pagination {
  margin: 0;
}
.Page,
.PaginationControl {
  border: 1px solid #e7eaec;
}
.Page-active {
  padding: 1rem;
  color: #ffffff;
  border: 1px solid var(--pagination-active-clr);
  border-radius: inherit;
  margin: 0;
}
.scrollable {
  overflow-y: scroll;
  height: 350px;
  background-color: #ffffff;
}
.Page {
  padding: 1rem;
  border-radius: inherit;
  margin: 0;
}
.PaginationControl {
  padding: 0.42rem;
}
.scheduleBlock {
  display: flex;
  justify-content: flex-end;
}
.titleClass {
  background-color: var(--pop-up-background);
  color: white;
  height: 35px;
  padding-left: 5px;
  padding-top: 5px;
}
.approver__modal__title {
  background-color: var(--pop-up-background);
  color: white;
}
.form-control {
  padding: 0.2rem 0.75rem !important;
}
.form-select {
  border-radius: 0 !important;
  padding: 0.2rem 0.75rem !important;
}
.form-select:focus {
  border-color: #145faf !important;
  box-shadow: 0 0 0 !important;
}
.badge-genre {
  background-color: #b6bdbf;
  color: #ffffff;
}
.badge-grn {
  background-color: #1ab394;
  color: #ffffff;
}
.bgcolor {
  background-color: #d0f1f1;
}
.modal__container11 {
  width: 80%;
  max-width: 38%;
  position: fixed;
  background: rgb(255, 255, 255);
  border-radius: 4px;
  transition: all 0.3s ease-in-out 0s;
  overflow-x: hidden;
}
.approver_modal_container {
  width: 80%;
  max-width: 38%;
  height: 300px;
  position: fixed;
  background: rgb(255, 255, 255);
  border-radius: 4px;
  transition: all 0.3s ease-in-out 0s;
  overflow-x: hidden;
}
.modal__container111 {
  width: 80%;
  max-width: 38%;
  position: fixed;
  height: 30% !important;
  margin-top: -800px !important;
  background: rgb(255, 255, 255);
  border-radius: 4px;
  transition: all 0.3s ease-in-out 0s;
}
.modal__wrapper111 {
  position: fixed;
  left: 0;
  top: 0;
  background: rgb(0 0 0 / 18%);
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
  transition: cubic-bezier;
  overflow: scroll;
}
.widthApprve {
  width: 100%;
  padding-left: 50px;
}
.modal-contentApprover {
  background-clip: padding-box;
  background-color: #ffffff;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  box-shadow: 0 1px 3px rgb(0 0 0 / 30%);
  outline: 0 none;
  position: relative;
}
.modal-approver {
  margin-left: 51%;
  height: 400px;
}
.modal-body1 {
  padding: 20px 30px 30px 30px;
}
@media (min-width: 576px) {
  .modal-approver-list1 {
    max-width: 200px !important;
    max-height: 400px !important;
  }
}
.btn-default1 {
  color: inherit;
  background: white;
  border: 1px solid #e7eaec;
}
.form-check1 {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}
.form-check1-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check1-label {
  margin-bottom: 0;
}
.fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}
.btn1 {
  border-radius: 29px;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  line-height: 1.5;
  font-size: 1rem;

  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
button:not(:disabled) {
  cursor: pointer;
}
.approver_modal__wrapper1 {
  position: fixed;
  inset: 0;
  background: rgb(0 0 0 / 18%);
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 3000;
}
.close-button {
  color: #ffffff;
}
.model-approver-title {
  background-color: #ffffff;
}
.history-modal-container {
  background: #fff;
  width: min(50%, 90%);
  border-radius: 4px;
  position: fixed;
  height: auto !important;
  overflow-x: hidden;
}
</style>