<template>
  <div class="stat-card-white">
    <div class="stat-header-white">
      <span>{{ computedChartLabel }}</span>
      <div v-show="!loader" class="d-flex align-items-center" v-if="!loader">
        <select class="graph-select py-0" v-model="selectedXAxis" id="xFieldSelect">
          <option value="" disabled>Select X-Axis</option>
          <option v-for="(column, index) in availableColumns" :key="index" :value="column">
            {{ column }}
          </option>
        </select>
        <div v-if="!loader" class="expand-block px-2 mb-1 cursor-pointer position-relative">
          <img @click="showtable()" src="../../assets//img/table_view.png" alt="More block">
          <div class="more-expand">
            <span>Data Overview</span>
          </div>
        </div>
        <div class="expand-block px-2 mb-1 cursor-pointer" @click.prevent="expandBlock=true">
          <img src="../../assets//img/expand.png" alt="Expand block">
        </div>
      </div>
    </div>
    <div class="stat-body-white d-flex flex-column" :class="{'h-100': loader}">
      <div v-if="loader" class="loader"></div>
      <div v-if="!loader && selectedGroups.length!=0" class="selectdrill d-flex flex-row-reverse gap-2 w-100">
        <img class="cursor-pointer" src="../../assets/img/chart_cancel.png" @click.prevent="cancelselection()" alt="Save profile"/>
        <img class="cursor-pointer" src="../../assets/img/chart_submit.png" @click.prevent="recreatechart()" alt="Save profile"/>
      </div>
      <div v-show="!loader" class="w-100">
        <svg ref="chartContainer"></svg>
        <div ref="tooltip" class="tooltip" style="opacity: 0;"></div>
      </div>
    </div>
  </div>

  <enlargerpopup v-if="expandBlock==true" @closeModal="expandBlock=false,createStackedBarChart()">
    <div
      class="stat-body-white d-flex flex-column">
    <div v-if="!loader && selectedGroups.length!=0" class="selectdrill d-flex flex-row-reverse gap-2 w-100">
        <img class="cursor-pointer" src="../../assets/img/chart_cancel.png" @click.prevent="cancelselection()" alt="Save profile"/>
        <img class="cursor-pointer" src="../../assets/img/chart_submit.png" @click.prevent="recreatechart()" alt="Save profile"/>
      </div>
    <svg ref="popupChartContainer"></svg>
    </div>
  </enlargerpopup>
</template>

<script>
import * as d3 from 'd3';
import enlargerpopup from "../AiCharts/popupfiles/enlarge_popup.vue";

export default {
  name: 'StackedBarChart',
  components: {
    enlargerpopup
  },
  props: {
    data: {
      type: Array,
      required: true,
      default: () => [],
    },
    availableColumns: {
      type: Array,
      required: true,
      default: () => [
        "SEX",
        "RACE, ETHNIC",
        "PHASE",
        "ARM",
        "RELTRT",
        "AEOUT (FATAL)",
        "AESEV",
        "AEOUT",
        "MHDECOD",
        "CMDECOD",
        "DTHDTC, DTHFL",
        "DTHCAUS",
        "AUTOPSY"
      ],
    },
    loader: {
      type: Boolean,
      default: false,
    },
    xAxisKey: {
      type: String,
      default: '',
    },
    yAxisKey: {
      type: String,
      default: 'subjects',
    },
    segmentKey: {
      type: String,
      default: 'DTHCAUS',
    },
    chartwidth: {
      type: Number,
      default: 600,
    },
    chartheight: {
      type: Number,
      default: 400,
    },
    chartlabel: {
      type: String,
      default: "",
    },
    fromPage:{
      type: String,
      default: "",
    }
  },
  computed: {
  computedChartLabel() {
    return this.chartlabel.replace("$$", this.selectedXAxis || "");
  },
},
  data() {
    return {
      expandBlock: false,
      selectedXAxis: "",
      selectedYAxis: "subjects",
      selectedSegment: "",
      chartContainer: null,
      tooltip: null,
      resizeObserver: null,
      barselectedarray:[],
      sortdata: [],
      filterkeyword:"",
      filterarray:[],
      selectedGroups: [],
      segmentgropup:[],
      keygroup:[],
      color:{},
      mouseX:0,
      mouseY:0
    };
  },
  mounted() {
    window.addEventListener('mousemove', this.updateMousePosition);
    this.selectedXAxis = this.xAxisKey;
    this.selectedYAxis = this.yAxisKey;
    this.selectedSegment = this.segmentKey;
    this.chartContainer = this.$refs.chartContainer;
    this.tooltip = this.$refs.tooltip;
    this.createStackedBarChart();

    // Add resize observer for both main and popup charts
    this.resizeObserver = new ResizeObserver(() => this.createStackedBarChart());
    this.resizeObserver.observe(this.$refs.chartContainer);

    if (this.$refs.popupChartContainer) {
      this.resizeObserver.observe(this.$refs.popupChartContainer);
    }
  },
  watch: {
    data: {
      handler() {
        this.cancelselection();
        this.createStackedBarChart();
      },
      deep: true,
    },
    selectedXAxis() {
      this.cancelselection();
      this.createStackedBarChart();
    },
    selectedYAxis() {
      this.createStackedBarChart();
    },
    selectedSegment() {
      this.createStackedBarChart();
    },
    expandBlock(newValue) {
      if (newValue) {
        this.$nextTick(() => {
          this.createStackedBarChart();
        });
      }
    }
  },
  methods: {
    updateMousePosition(event) {
      // Get the bounding rectangle of the component
      if(this.$refs.chartContainer)
      {
        const rect = this.$refs.chartContainer.getBoundingClientRect();

        // Calculate the mouse X and Y positions relative to the component
        this.mouseX = event.clientX - rect.left;
        this.mouseY = event.clientY - rect.top;
      }
      else{
        return
      }
      
    },
    showtable(){
      this.showMore=false
      this.$emit("opendrillpopup", this.data);
    },
    backtooriginal() {
      this.sortdata = [];
      this.selectedGroups = []; // Reset selection
    },
    async cancelselection() {
  this.selectedGroups = [];
  this.sortdata = [];
  this.createStackedBarChart();
},
    createStackedBarChart() {
  const chartContainer = this.expandBlock ? this.$refs.popupChartContainer : this.$refs.chartContainer;
  if (!chartContainer || !this.tooltip) return;

  d3.select(chartContainer).selectAll('*').remove();

  const margin = { top: 30, right: 100, bottom: 70, left: 60 };
  const width = this.chartwidth - margin.left - margin.right;
  const height = this.chartheight - margin.top - margin.bottom;

  const svg = d3
    .select(chartContainer)
    .attr('width', width + margin.left + margin.right)
    .attr('height', height + margin.top + margin.bottom)
    .append('g')
    .attr('transform', `translate(${margin.left},${margin.top})`);

  const groupedData = d3.rollups(
    this.data,
    v => v.length,
    d => d[this.selectedXAxis],
    d => d[this.selectedSegment]
  );
    
  const stackedData = groupedData.map(([key, values]) => {
    const entry = { key };
    values.forEach(([segmentKey, count]) => {
      entry[segmentKey] = count;
    });
    return entry;
  });


  const keys = Array.from(new Set(this.data.map(d => d[this.selectedSegment])));

  const x = d3
    .scaleBand()
    .domain(stackedData.map(d => d.key))
    .range([0, width])
    .padding(0.2);

  const y = d3
    .scaleLinear()
    .domain([0, d3.max(stackedData, d => d3.sum(keys.map(key => d[key] || 0)))])
    .nice()
    .range([height, 0]);

  //   if (Object.keys(this.color).length == 0) {
  // this.color = d3.scaleOrdinal(d3.schemeCategory10).domain(keys);
  //   }
  // Check if this.color is a d3 ordinal scale by ensuring it has a domain method
if (!this.color || typeof this.color.domain !== 'function') {
    this.color = d3.scaleOrdinal(d3.schemeCategory10);
}
let currentDomain = this.color.domain();
let updatedDomain = [...currentDomain, ...keys.filter(key => !currentDomain.includes(key))];
this.color.domain(updatedDomain);
  const stack = d3.stack().keys(keys)(stackedData);

  // Add X-axis with truncated labels
  svg
    .append('g')
    .attr('transform', `translate(0,${height})`)
    .call(d3.axisBottom(x))
    .selectAll('text')
    .text(function(d) {
    // Handle null or empty string cases, and truncate the label if needed
    const label = d == null || d == '' ? 'Null' : d;
    return label.length > 13 ? `${label.slice(0, 10)}...` : label;
  })
    .attr('transform', 'translate(-10,0)rotate(-45)')
    .style('text-anchor', 'end')
    .append('title') // Show full text on hover
    .text(function(d) {
      return d; // Keep full text in tooltip
    });

  svg.append('g').call(d3.axisLeft(y));
    const tooltip= this.$refs.tooltip
      // Add stacked bars
      svg
  .selectAll('g.layer')
  .data(stack)
  .enter()
  .append('g')
  .attr('class', 'layer')
  .attr('fill', d => this.color(d.key))
  .selectAll('rect')
  .data(d => d)
  .enter()
  .append('rect')
  .attr('x', d => x(d.data.key))
  .attr('y', d => y(d[1]))
  .attr('height', d => y(d[0]) - y(d[1]))
  .attr('width', x.bandwidth())
  .style("opacity", (d, i, nodes) => {
    const segment = d3.select(nodes[i].parentNode).datum().key;
    const groupkeys = `(${this.selectedXAxis}:${d.data.key},${this.selectedSegment}:${segment})`;
    return this.selectedGroups.length != 0 && !this.selectedGroups.includes(groupkeys) ? 0.5 : 1;
  })
  .on('mouseover', (event, d) => {
    const segment = d3.select(event.currentTarget.parentNode).datum().key;
    d3.select(this.tooltip)
      .style('opacity', 1)
      .html(`Category: ${d.data.key}<br>Segment: ${segment}<br>Value: ${d[1] - d[0]}`)
      d3.select(this.tooltip)
      .style('left', `${this.mouseX}px`)
      .style('top',`${this.mouseY}px`);

  })
  .on('mouseout', () => {
    d3.select(this.tooltip).style('opacity', 0);
  })
  .on('mousemove', (event) => {
    d3.select(this.tooltip)
      .style('left', `${this.mouseX}px`)
      .style('top',`${this.mouseY}px`);
  })
  .on('click', (event, d) => {
    const segment = d3.select(event.currentTarget.parentNode).datum().key;
    const filteredData = this.data.filter(item => item[this.selectedXAxis] === d.data.key && item[this.selectedSegment] === segment);
    const filteredkey = `(${this.selectedXAxis}:${d.data.key},${this.selectedSegment}:${segment})`;
    this.clickbarinchart(filteredData, filteredkey);
  });
    },
    clickbarinchart(filteredData, filteredkeyword) {
    if (this.selectedGroups.includes(filteredkeyword)) {
        this.selectedGroups = this.selectedGroups.filter((k) => k !== filteredkeyword);
        let result = this.sortdata.filter(item => !filteredData.includes(item));
        this.sortdata = result;
        let filterarraydeleted = this.filterarray.filter(item => `(${filteredkeyword})` != item);
        this.filterarray = filterarraydeleted;
        this.filterkeyword = this.selectedGroups;
      } else {
    this.selectedGroups.push(filteredkeyword);
        this.filterarray.push(`(${filteredkeyword})`)
        this.filterkeyword = this.selectedGroups;
        this.sortdata = this.sortdata.concat(filteredData);
      }
      this.createStackedBarChart();
  },
  recreatechart() {
      // this.$emit("opendrillpopup", this.sortdata);
      const dataToEmit = {
    sortedData: this.sortdata,
    filterkeyword: this.filterkeyword
  };
      this.$emit("drilldowncurrentchart", dataToEmit);
    },
    sendselecteddata(filteredData, segment) {
      const dataToEmit = {
        sortedData: filteredData,
        filterkeyword: `${this.selectedXAxis}-${segment}`
      };
      this.$emit("drilldowncurrentchart", dataToEmit);
    }
  }
};
</script>

<style scoped>
@import "../../custom_lib/AiCharts/charts.css";

.bar-chart-container {
  width: 100%;
  overflow-x: auto;
  position: relative;
}

svg {
  font-family: sans-serif;
  font-size: 10px;
}

text.bar-label {
  fill: black;
  font-size: 12px;
}

.loader {
  border: 6px solid #f3f3f3;
  border-top: 6px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: 0 auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
