import axios from "axios";
import store from "../../store/index";
import Percentagechart from "../../custom_lib/AiCharts/percentagechart.vue";
import D3PieChart from "../../custom_lib/AiCharts/D3PieChart.vue";
import groupbarchart from  "../../custom_lib/AiCharts/groupbarchart.vue";
import sunburst from "../../custom_lib/AiCharts/sunburst.vue"
import d3table from "../../custom_lib/AiCharts/d3table.vue"
import historytab from "../../custom_lib/AiCharts/historytab.vue"
import chartdrill from '../../custom_lib/AiCharts/popupfiles/chartdrillPopup.vue';
export default {
  name: "descriptiveStatistic",
  components: {
      D3PieChart,
      Percentagechart,
      groupbarchart,
      sunburst,
      d3table,
      historytab,
      chartdrill
   },
   async mounted() {
        this.idtoken=store.getters.getIdToken  
        await this.getData()
        this.targetValue=this.data.length
        console.log("Value is",this.targetValue)
        await this.getSelectData()
        await this.startRolling()
   },
  data() {
        return {
            drillpopup:false,
            baseurl: process.env.VUE_APP_Service_URL,
            baseapi: `${process.env.VUE_APP_Service_URL}/forms-ml/`,
            selectedCategory:"",
            currentValue: 0, // Start value
            targetValue: 0, // The target value you want to roll to
            duration: 2000, // Duration of the animation in milliseconds
            displayValue: 0,
            historykeys:[],
            historydata:{},
            data:[],
            datahistory:[],
            drilldowndata:[],
            getSelectedData:{}
        }
    },
    methods:{
      async onclosed(){
        this.drillpopup = false;
      },
      async drilldowncurrentchart(datafromchild){
        console.log("Data is",datafromchild)
        this.data = datafromchild;
        this.datahistory.push(this.data);
      },
      async drilldowncurrentchartforbar(datafromchild){
        this.datahistory.push(this.data);
        this.historykeys.push(datafromchild.filterkeyword);
        this.historydata[datafromchild.filterkeyword] = datafromchild.sortedData;
        this.data = this.historydata[datafromchild.filterkeyword];
      },
      startRolling() {
        const start = this.currentValue;
        const end = this.targetValue;
        const duration = this.duration;
        const startTime = performance.now();
  
        const animate = (currentTime) => {
          const elapsedTime = currentTime - startTime;
          if (elapsedTime < duration) {
            const progress = elapsedTime / duration;
            this.displayValue = Math.floor(start + progress * (end - start));
            requestAnimationFrame(animate);
          } else {
            this.displayValue = end;
          }
        };
  
        requestAnimationFrame(animate);
      },
      async gotohistorylevel(history){
        this.deleteAfterKeyvaluearrays(history);
        this.deleteAfterKeyarrays(history);
        this.data = this.historydata[history];
      },
      async deleteAfterKeyvaluearrays(selectedKey) {
        const entries = Object.entries(this.historydata); // Convert the object to an array of entries
        const selectedIndex = entries.findIndex(([key, value]) => key === selectedKey);
      
        // If the key is found, slice the array to keep only the entries up to the selected key
        const newEntries = selectedIndex !== -1 ? entries.slice(0, selectedIndex + 1) : entries;
      
        // Convert the array of entries back into an object
        return Object.fromEntries(newEntries);
      },
      async deleteAfterKeyarrays(selectedKey) {
  
        let index = this.historykeys.indexOf(selectedKey);
  
        if (index !== -1) {
            // Slice the array up to the element (including the element)
            this.historykeys = this.historykeys.slice(0, index + 1);
        }
      },
      async drillpopupopened(datafromchild) {
        this.drilldata = datafromchild;
        this.drillpopup = true;  
      },
      async undobutton(){
        if(this.datahistory.length > 1){
        this.datahistory.pop();
        this.data=this.datahistory[this.datahistory.length-1];
        }
      },
      async getData() {			
        await axios
          .get(`${this.baseapi}report/getreports?table_name=SampleTable` 
          )
          .then((res) => {
            this.data = res.data;
            this.loader = false;
          this.datahistory.push(this.data);
          this.historydata['Home'] = this.data;
          this.historykeys.push('Home');

          })
          .catch((err) => {
            console.log(err);
          });
      },
      async getSelectData(){
        await axios
          .post(`${this.baseapi}report/getdescriptiveanalysis?table_name=SampleTable`,
          {
            "ignored_features": [
              "_id","Participant ID"
            ],
            "filter_condtions": {}
          }
          )
          .then((res) => {
            if (res.data && res.data.CategoricalStats) {
              this.getSelectedData = Object.fromEntries(
                Object.entries(res.data.CategoricalStats).filter(([key]) => key !== 'SubjectID')
              );

                this.selectedCategory=Object.keys(this.getSelectedData)[0]
              
            } else {

              this.getSelectedData = {}; 
           
            }
          
            console.log("Selected data is",this.getSelectedData)
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
}