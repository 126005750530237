<template>
    <!-- testwrapper -->
    <!-- <div v-if="defaultdashboard != 'studysummary'" class="row wrapper border-bottom white-bg page-heading">
<div class="col-lg-12 text-right">
<h2><button type="button" class="btn btn-outline-success fontprop" @click="setasdefault()">Set as default</button></h2>
</div>
</div> -->
<!-- testwrapper -->
	<div class="row wrapper border-bottom white-bg page-heading">
		<div class="col-6 col-md-10">
			<h2>{{$t('navigations.study summary')}}</h2>
		</div>
		<div class="col-6 col-md-2 text-end align-self-center" v-if="defaultdashboard != 'studysummary'">
			<button type="button" class="btn btn-setdefault fontprop" @click="setasdefault()">{{$t('navigations.set as default')}}</button>
		</div>
	</div>
	<div class="wrapper wrapper-content">
		<div class="row">
			<div class="col-lg-3 mb-1 pr-0 pl-1">
				<div class="dash-widget cursor-pointer" @click.prevent="$router.push('/listform')">
					<div class="row">
					<div class="col-8 text-left">
						<span> <h4>{{$t('navigations.active forms')}}</h4></span>
							<h1 class="font-bold">{{ activeformcount }}</h1>
					</div>
					<div class="col-4 justify-content-center align-self-center">
						<img src="../../assets/img/db_active_homes.png" alt="New Forms">
					</div>
					</div>
				</div>
			</div> 
			<!-- <div class="col-lg-3 mb-1 pr-0 pl-1">
				<div class="ibox-body cursor-pointer" @click.prevent="$router.push('/listform')">
					<div class="ibox-title style_2 d-flex justify-content-between">
						<div class="ibox-tools">
							<img src="../../assets/img/active_forms.png" alt="Active Forms Widget">
						</div>
						<h5>{{$t('navigations.active forms')}}</h5>
					</div>
					<div class="ibox-content">
						<h1 class="no-margins">{{ activeformcount }}</h1>
						<small>{{$t('navigations.active forms')}}</small>
					</div>
				</div>
			</div> -->
			<div class="col-lg-3 mb-1 pr-0 pl-1">
				<div class="dash-widget cursor-pointer" @click.prevent="$router.push('/listform')">
					<div class="row">
					<div class="col-8 text-left">
						<span> <h4>{{$t('navigations.new form')}}</h4></span>
							<h1 class="font-bold">{{ formcountstd }}</h1>
					</div>
					<div class="col-4 justify-content-center align-self-center">
						<img src="../../assets/img/db_newforms.png" alt="New Forms">
					</div>
					</div>
				</div>
			</div> 
			<!-- <div class="col-lg-3 mb-1 pr-0 pl-1">
				<div class="ibox-body cursor-pointer" @click.prevent="$router.push('/listform')">
					<div class="ibox-title style_2 d-flex justify-content-between">
						<div class="ibox-tools">
							<img src="../../assets/img/new_forms(study).png" alt="New Forms Widget">
						</div>
						<h5>{{$t('navigations.new form')}}</h5>
					</div>
					<div class="ibox-content">
						<h1 class="no-margins">{{ formcountstd }}</h1>
						<small>{{$t('navigations.new form')}}</small>
					</div>
				</div>
			</div> -->
			<div class="col-lg-3 mb-1 pr-0 pl-1">
				<div class="dash-widget cursor-pointer" @click.prevent="$router.push('/allQueryList')">
					<div class="row">
					<div class="col-8 text-left">
						<span> <h4>{{$t('navigations.open query')}}</h4></span>
							<h1 class="font-bold">{{ querycountstd }}</h1>
					</div>
					<div class="col-4 justify-content-center align-self-center">
						<img src="../../assets/img/db_open_queries.png" alt="New Forms">
					</div>
					</div>
				</div>
			</div> 
			<!-- <div class="col-lg-3 mb-1 pr-0 pl-1">
				<div class="ibox-body cursor-pointer" @click.prevent="$router.push('/allQueryList')">
					<div class="ibox-title style_2 d-flex justify-content-between">
						<div class="ibox-tools">
							<img src="../../assets/img/open_query.png" alt="Open Query Widget">
						</div>
						<h5>{{$t('navigations.open query')}}</h5>
					</div>
					<div class="ibox-content">
						<h1 class="no-margins">{{ querycountstd }}</h1>
						<small>{{$t('navigations.open query')}}</small>
					</div>
				</div>
			</div> -->
			<div class="col-lg-3 mb-1 pr-0 pl-1">
				<div class="dash-widget cursor-pointer" @click.prevent="$router.push('/allQueryList')">
					<div class="row">
					<div class="col-8 text-left">
						<span> <h4>{{$t('navigations.total query')}}</h4></span>
							<h1 class="font-bold">{{ totalquerycount }}</h1>
					</div>
					<div class="col-4 justify-content-center align-self-center">
						<img src="../../assets/img/db_total_queries.png" alt="New Forms">
					</div>
					</div>
				</div>
			</div> 
			<!-- <div class="col-lg-3 mb-1 pr-0 pl-1">
				<div class="ibox-body cursor-pointer" @click.prevent="$router.push('/allQueryList')">
					<div class="ibox-title style_2 d-flex justify-content-between">
						<div class="ibox-tools">
							<img src="../../assets/img/total_queries.png" alt="Total Queries Widget">
						</div>
						<h5>{{$t('navigations.total query')}}</h5>
					</div>
					<div class="ibox-content">
						<h1 class="no-margins">{{ totalquerycount }}</h1>
						<small>{{$t('navigations.total query')}}</small>
					</div>
				</div>
			</div> -->
			<!-- {{sendData.data.datasets[0].data}} and {{sendData.data.datasets[1].data}} and queries {{seconsData.data.datasets[0].data}} -->
			<div class="col-lg-6 pr-0 pl-1">
				<div class="ibox-body">
					<div class="ibox-title style_2 d-flex justify-content-between py-1">
						<h5 class="mt-2">{{$t('navigations.sites')}}</h5>
						<div class="graphbtncontainer">
							<div class="graphbutton">
								<img
									src="../../assets/images/bar_clr.png"
									@click.prevent="firstchartchange('bar')" alt="Vertical Bar Chart Button"
								/>
							</div>
							<div class="graphbutton">
								<img
									src="../../assets/images/horiz_bar_clr.png"
									@click.prevent="firstchartchange('barhorizondal')" alt="Horizaontal Bar Chart Button"
								/>
							</div>
							<div class="graphbutton">
								<img
									src="../../assets/images/Line_clr.png"
									@click.prevent="firstchartchange('line')" alt="Line Chart Button"
								/>
							</div>
							<div class="graphbutton">
								<img
									src="../../assets/images/Pie_clr.png"
									@click.prevent="firstchartchange('pie')" alt="Pie Chart Button"
								/>
							</div>
							<div class="graphbutton">
								<img
									src="../../assets/images/bubble_clr.png"
									@click.prevent="firstchartchange('scatter')" alt="Scatter Chart Button"
								/>
							</div>
						</div>
					</div>
					<div class="ibox-content">
						<div>
						<apexchart
								v-if="
									firstchart.charttype != 'pie' &&
										firstchart.charttype != 'barhorizondal'
								"
								width="100%"
								height="250px"
								:type="firstchart.charttype"
								:options="firstchart.chartOptions"
								:series="firstchart.dataseries"
							></apexchart>
							<apexchart
								v-if="firstchart.charttype == 'barhorizondal'"
								width="100%"
								height="250px"
								type="bar"
								:options="firstbarchart.chartOptions"
								:series="firstbarchart.dataseries"
							></apexchart>
							<apexchart
								v-if="firstchart.charttype == 'pie'"
								width="100%"
								height="250px"
								:type="firstchart.charttype"
								:options="firstpiechartoptions"
								:series="firstchart.piedataseries"
							></apexchart>
						</div>
					</div>
				</div>
			</div>
			<div class="col-lg-6 pr-0 pl-1">
				<div class="ibox-body">
					<div class="ibox-title style_2 d-flex justify-content-between py-1">
						<h5 class="mt-2">{{$t('navigations.subjects')}}</h5>
						<select class="forms-control width-40"
							v-model="siteCode"
							@change="patientSitebased($event.target.value)"
							placeholder="Search"
						>
							<option value=""
								selected hidden>{{$t('navigations.filter by site code')}}</option
							>
							<option
								v-for="site in sites"
								:key="site.siteID"
								:value="site.siteID"
								>{{ site.siteCode }}</option
							>
						</select>
						<div class="graphbtncontainer">
							<div class="graphbutton">
								<img
									src="../../assets/images/bar_clr.png"
									@click.prevent="secondchartchange('bar')" alt="Bar Chart Button"
								/>
							</div>
							<div class="graphbutton">
								<img
									src="../../assets/images/horiz_bar_clr.png"
									@click.prevent="secondchartchange('barhorizondal')" alt="Horizontal Bar Chart Button"
								/>
							</div>
							<div class="graphbutton">
								<img
									src="../../assets/images/Line_clr.png"
									@click.prevent="secondchartchange('line')" alt="Line Chart Button"
								/>
							</div>
							<div class="graphbutton">
								<img
									src="../../assets/images/Pie_clr.png"
									@click.prevent="secondchartchange('pie')" alt="Pie Chart Button"
								/>
							</div>
							<div class="graphbutton">
								<img
									src="../../assets/images/bubble_clr.png"
									@click.prevent="secondchartchange('scatter')"  alt="Scatter Chart Button"
								/>
							</div>
						</div>
					</div>
					<div class="ibox-content">
						<div>
							<apexchart
								v-if="patientChart.charttype != 'pie' && patientChart.charttype != 'barhorizondal'"
								width="100%"
								height="250px"
								:type="patientChart.charttype"
								:options="patientChart.chartOptions"
								:series="patientChart.dataseries"
							></apexchart>
							<apexchart
							v-if="patientChart.charttype == 'barhorizondal'"
							width="100%"
							height='250px'
							type="bar"
							:options="patientbarchart.chartOptions"
							:series="patientbarchart.dataseries"
						></apexchart>

							<apexchart
								v-if="patientChart.charttype == 'pie'"
								width="100%"
								height="250px"
								:type="patientChart.charttype"
								:options="patientChart.piechartOptions"
								:series="patientChart.piedataseries"
							></apexchart>
						</div>
					</div>
				</div>
			</div>
			<!--new form table-->
			<div class="col-lg-12 my-1 pr-0 pl-1">
				<div class="ibox-body">
					<div class="ibox-title style_2 d-flex justify-content-between py-1">
						<h5 class="mt-0 align-self-center">{{$t('navigations.queries')}}</h5>
						<div class="btncontainer">
							<div class="graphbtncontainer">
								<div class="graphbutton">
									<img
										src="../../assets/images/bar_clr.png"
										@click.prevent="thirdchartchange('bar')" alt="Bar Chart Button"
									/>
								</div>
								<div class="graphbutton">
									<img
										src="../../assets/images/horiz_bar_clr.png"
										@click.prevent="thirdchartchange('barhorizondal')" alt="Horizontal Bar Chart Button"
									/>
								</div>
								<div class="graphbutton">
									<img
										src="../../assets/images/Line_clr.png"
										@click.prevent="thirdchartchange('line')" alt="Line Chart Button"
									/>
								</div>
								<div class="graphbutton">
									<img
										src="../../assets/images/Pie_clr.png"
										@click.prevent="thirdchartchange('pie')" alt="Pie Chart Button"
									/>
								</div>
								<div class="graphbutton">
									<img
										src="../../assets/images/bubble_clr.png"
										@click.prevent="thirdchartchange('scatter')" alt="Scatter Chart Button"
									/>
								</div>
							</div>
						</div>
					</div>
					<div class="ibox-content">
						<apexchart
							v-if="
								querychart.charttype != 'pie' &&
									querychart.charttype != 'barhorizondal'
							"
							width="100%"
							height='300'
							:type="querychart.charttype == 'bar' ? 'bar': querychart.charttype"
							:options="querychart.chartOptions"
							:series="querychart.dataseries"
						></apexchart>
						<apexchart
							v-if="querychart.charttype == 'barhorizondal'"
							width="100%"
							height='300px'
							type="bar"
							:options="querybarchart.chartOptions"
							:series="querybarchart.dataseries"
						></apexchart>
						<apexchart
							v-if="querychart.charttype == 'pie'"
							width="100%"
							height='300px'
							:type="querychart.charttype"
							:options="querypiechartoptions"
							:series="querychart.piedataseries"
						></apexchart>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script src="./study_summary.js"></script>
<style scoped>
@import "../../assets/style.css";
@import "../../assets/bootstrap.css";
@import "../../assets/font-awesome/css/font-awesome.css";
.widget {
	margin-top: 0rem;
	margin-bottom: 0rem;
}
.widgetmargin {
	margin-top: -13px !important;
}
.ibox-body {
	background-color: #ffffff;
	margin: 3rem, 0;
}

.style1 {
	background-color: black !important;
	color: #ffffff;
}
.btn-view {
	color: #fff !important;
	background-color: #bb458f !important;
	border-color: #bb458f !important;
}
.btn-view:hover {
	color: #fff;
	background-color: #666ccb;
	border-color: #666ccb;
}
.btn-view:active {
	color: rgb(255, 255, 255) !important;
	background-color: #dda2c7 !important;
	border-color: #dda2c7 !important;
}
.ibox-title {
	color: inherit;
	margin-bottom: 0;
	padding: 1rem;
	position: relative;
	clear: both;
	right: 0;
	margin-right: 0px;
	width: unset !important;
	margin-left: 0px !important;
	margin-top: 0px !important;
}
.graph-block__title {
	display: flex;
	justify-content: space-between;
	padding: 0.7rem 1.7rem;
	clear: both;
	right: 0;
	border-bottom: 1px solid #f2f2f2;
	margin-bottom: 0;
}
.padding_bottom {
	padding-bottom: 2rem;
}
.ibox-title h5 {
	height: 0rem;
	padding: 0rem;
}
.ibox-title h5:hover {
	background-color: inherit;
	padding: 0;
	cursor: not-allowed;
}
.titlemsg {
	font-size: 15px;
	font-weight: bold;
	color: var(--primary-color);
}
.switchbtn {
	background-color: #bb458f !important;
	height: max-content;
	border-color: #bb458f !important;
	color: white !important;
	float: right;
}
.switchbtn:active {
	height: max-content;
	border-color: #bb458f !important;
	color: white !important;
	float: right;
	background-color: #bb458f;
}
.switchbtn:hover {
	height: max-content;
	border-color: #bb458f !important;
	color: white !important;
	float: right;
	background-color: #bb458f;
}
.queryhover:hover {
	color: #3c4192 !important;
	-webkit-transition: color ease-in 400ms;
	transition: color ease-in 400ms;
	cursor: pointer;
}

.widgetminheight {
	min-height: 102px;
}
.graphbutton {
	width: fit-content;
	padding: 3px;
}
.graphbutton:hover {
	cursor: pointer;
	border-color: #3c4192;
}
.graphbtncontainer {
	display: flex;
	gap: 5px;
}
img {
	margin-left: 0px !important;
}
.graph-block {
	background-color:#ffffff;
	border-radius: 7px;
	box-shadow: -1px 6px 40px -40px rgba(0, 0, 0, 0.75);
	-webkit-box-shadow: -1px 6px 40px -40px rgba(0, 0, 0, 0.75);
	-moz-box-shadow: -1px 6px 40px -40px rgba(0, 0, 0, 0.75);
}
.graph-block__content {
	padding: 0.5rem 1.3rem;
}

.site-block {
	position: relative;
}
.site-block select {
	font-size: 1rem;
	padding-left: 0.2rem;
	padding-right: 0.7rem;
	background-color: transparent;
	outline: none;
}
.select-border {
	display: block;
	position: absolute;
	bottom: -10px;
	left: 0;
	right: 0;
	background-color: red;
	height: 1px;
	width: 0%;
	transition: width 0.4s ease-in-out;
	z-index: 2;
}
.selectdefault{
  left: 0;
}

.btn:focus {
  outline: none;
  box-shadow: none;
}
.btn-setdefault {
  position: relative;
  padding:0.2rem 0.4rem;
  text-align: center;
  font-size:0.7rem;
  background-color: #ec4758;
  color: white !important;
  border-radius:3px;
}
.sitecodelabel {
     align-content: center;
    padding-top: 6px;
    margin-left: 104px;
}
.forms-control{
	display: block;
	width: 100%;
	padding: .125rem .75rem;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: #676a6c;
	background-color: #fff;
	background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right .75rem center;
    background-size: 16px 12px;
	border:1px solid #e7eaec;
    -moz-appearance: none;
    appearance: none;
	-webkit-appearance: none;
	transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.forms-control:focus, .forms-control:focus-visible,
.forms-control:focus-within{
	outline: none;
	border-color:#145faf!important
}
.width-40{
	width:40%;
}
.ibox-content h1{
  font-weight:400;
  color:#676a6c
}
</style>
