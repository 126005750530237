/* eslint-disable */
import axios from "axios";
import historytab from '../../custom_lib/AiCharts/historytab.vue';
import stackedbarchart from '../../custom_lib/AiCharts/StackedBarchart.vue';
import heatmap from "../../custom_lib/AiCharts/heatmap.vue"
import advtable from '../../custom_lib/AiCharts/advenenttable.vue';
import chartdrill from '../../custom_lib/AiCharts/popupfiles/chartdrillPopup.vue';
import piechart from '../../custom_lib/AiCharts/D3PieChart.vue';
export default {
    name: "vitalsignsresultovertime",
    components: {
        historytab,
        stackedbarchart,
        advtable,
        chartdrill,
        piechart,
        heatmap
    },
    props: {
        templateId: {
            type: String,
            default: ''
        },
    },
    data() {
        return {
            data: [],
            baseUrl: process.env.VUE_APP_Service_URL + "/forms-ml/report/",
            datahistory: [],
            historykeys: [],
            historydata: {},
            drilldata: {},
            drilldowndata: [],
            drillpopup: false,
            loader: true,
            scatteredy: ["AESEV"],
            xaxiskeys: {
                "groupbarchart": "",
                "barchart": "",
                "histogram": "",
                "piechart": "AGE",
                "advtable": "",
                "aepiechart": "",
                "historytab": "",
                "chartdrill": ""
            },
            arrayofkeys: ['USUBJID', 'STUDYID', 'TRTNAME', 'INDICATION', 'TRTSDT', 'TRTEDT', 'TRTTP', 
                'TRTDOS', 'TRTFREQ', 'TRTROUTE', 'TRTSTAT', 'TRTCLASS', 'TRTSDY', 'TRTEDY', 
                'CMTRT', 'TRTATC', 'TRTFORM', 'MANUFACTURER', 'TRTACT', 'TRTDISRSN', 
                'AERELATED', 'Relationship to Study Drug', 'TRTCOMPL']
            
        }
    },
    async mounted() {
        await this.getdata();
    },
    methods: {
        async drillpopupopened(datafromchild) {
            this.drilldata = datafromchild;
            this.drillpopup = true;
        },
        async drilldowncurrentchart(datafromchild) {
            this.data = datafromchild;
            this.datahistory.push(this.data);
        },
        async drilldowncurrentchartforbar(datafromchild) {
            const lastkey = this.historykeys[this.historykeys.length - 1];
            if (this.historydata[lastkey].length != datafromchild.sortedData.length) {
                this.historykeys.push(datafromchild.filterkeyword);
                this.historydata[datafromchild.filterkeyword] = datafromchild.sortedData;
                this.data = this.historydata[datafromchild.filterkeyword];
            }
        },
        async gotohistorylevel(history) {
            this.deleteAfterKeyvaluearrays(history);
            this.deleteAfterKeyarrays(history);
            this.data = this.historydata[history];
        },
        async deleteAfterKeyvaluearrays(selectedKey) {
            const entries = Object.entries(this.historydata); // Convert the object to an array of entries
            const selectedIndex = entries.findIndex(([key, value]) => key === selectedKey);

            // If the key is found, slice the array to keep only the entries up to the selected key
            const newEntries = selectedIndex !== -1 ? entries.slice(0, selectedIndex + 1) : entries;

            // Convert the array of entries back into an object
            return Object.fromEntries(newEntries);
        },
        async deleteAfterKeyarrays(selectedKey) {

            let index = this.historykeys.indexOf(selectedKey);

            if (index !== -1) {
                // Slice the array up to the element (including the element)
                this.historykeys = this.historykeys.slice(0, index + 1);
            }
        },
        async retainxaxiskey(xaxiskey) {
            if (xaxiskey != "") {
                this.xaxiskeys["piechart"] = xaxiskey;
            }
        },
        async getdata() {
            this.loader = true;
            await axios.post(
                `${this.baseUrl}getfilteredreports?table_name=TreatmentSummary`)
                .then((res) => {
                    this.data = res.data;
                    this.loader = false;
                    this.datahistory.push(this.data);
                    this.historydata['Home'] = this.data;
                    this.historykeys.push('Home');
                })
                .catch((err) => console.log("error in screen list", err));
        },
        async undobutton() {
            if (this.datahistory.length > 1) {
                this.datahistory.pop();
                this.data = this.datahistory[this.datahistory.length - 1];
            }
        },
    }

}