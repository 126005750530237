<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>Profile Settings</h2>
    </div>
  </div>
  <div class="wrapper wrapper-content">
    <div class="row">
      <div class="col-lg-8">
        <div class="ibox mb-0">
          <div class="ibox-title style_2">
            <h5>My Profile</h5>
            <div class="ibox-tools d-flex flex-column">
              <a
                data-toggle="tooltip"
                data-placement="top"
                title="Save"
                href="#"
                @click.prevent="UpdateUser()"
                ><img src="../../assets/img/save.svg" alt="Save profile"
              /></a>
              <span class="mobile-view">Save</span>
            </div>
          </div>
          <div class="ibox-content">
            <div class="form-group row">
              <div class="col-lg-4 my-2">
                <label class="col-form-label"
                  >First Name <sup><i class="fa fa-asterisk imp"></i></sup>
                </label>
                <input
                  v-model="ProfileData.firstName"
                  type="text"
                  class="form-control"
                  placeholder="Enter your first name"
                />
                <span class="errormsg" v-if="validations[0]"
                  >Please enter first name !</span
                >
              </div>
              <div class="col-lg-4 my-2">
                <label class="col-form-label"
                  >Last Name <sup><i class="fa fa-asterisk imp"></i></sup
                ></label>
                <input
                  v-model="ProfileData.lastName"
                  type="text"
                  class="form-control"
                  placeholder="Enter your last name"
                />
                <span class="errormsg" v-if="validations[1]"
                  >Please enter last name !</span
                >
              </div>
              <div class="col-lg-4 my-2">
                <label class="col-form-label"
                  >Primary Email <sup><i class="fa fa-asterisk imp"></i></sup
                ></label>
                <input
                  v-bind:readonly="true"
                  v-model="ProfileData.emailIdPrimary"
                  type="email"
                  class="form-control"
                  autocomplete="off"
                  placeholder="Enter your primary email id"
                />
                <span class="errormsg" v-if="validations[2]"
                  >You have entered an invalid email address !</span
                >
              </div>
              <div class="col-lg-4 my-2">
                <label class="col-form-label">Secondary Email</label>
                <input
                  v-model="ProfileData.emailIdSecondary"
                  type="email"
                  class="form-control"
                  autocomplete="off"
                  placeholder="Enter your secondary email id"
                />
                <span class="errormsg" v-if="validations[3]"
                  >You have entered an invalid email address !</span
                >
              </div>
              <div class="col-lg-4 my-2" id="data_1">
                <label class="col-form-label">Date of Birth</label>
                <div class="input-group date">
                  <input
                    type="date"
                    v-model="ProfileData.dateOfBirth"
                    max="9999-12-31"
                    class="form-control"
                  />
                </div>
                <span class="errormsg" v-if="validations[4]"
                  >Please enter a valid date</span
                >
              </div>
              <div class="col-lg-4 my-2">
                <label class="col-form-label">Phone Number</label>
                <div class="input-group date">
                  <input
                    type="text"
                    v-model="ProfileData.phoneNumber"
                    class="form-control"
                    maxlength="10"
                  />
                </div>
                <span class="errormsg" v-if="validations[5]"
                  >You have entered an invalid phone number !</span
                >
              </div>
              <div class="col-lg-4 my-2">
                <label class="col-form-label">Select Your Country</label>
                <select
                  v-model="ProfileData.country"
                  class="form-select"
                  name="account"
                >
                  <option>United States</option>
                  <option>India</option>
                  <option>Japan</option>
                  <option>China</option>
                </select>
              </div>
              <div class="col-lg-8 my-2">
                <label class="col-form-label col-lg-12 px-0">Address 1</label>
                <div class="input-group date">
                  <input
                    type="text"
                    v-model="ProfileData.addressLineOne"
                    class="form-control address"
                  />
                </div>
              </div>
              <div class="col-lg-12 my-2">
                <label class="col-form-label col-lg-12 px-0">Address 2</label>
                <div class="input-group date">
                  <input
                    type="text"
                    v-model="ProfileData.addressLineTwo"
                    class="form-control address"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="ibox mt-1">
          <div
            class="ibox-title collapse-btn style_2"
            @click.prevent="collapse()"
          >
            <h5>Change Password</h5>
            <i
              class="fa fa-chevron-up collapse-icon text-right"
              id="icon-rotate"
            ></i>
          </div>
          <div
            class="collapse-content animated-collapse collapseInDown"
            v-if="collapsed"
          >
            <form>
              <div class="form-group row">
                <div class="col-lg-12">
                  <input
                    type="password"
                    placeholder="Enter current password"
                    class="form-control"
                    maxlength="16"
                    autocomplete="off"
                    v-model="changepassword.currentpassword"
                  />
                  <span class="errmsg" v-if="currentvalidation[0]"
                    >Please enter current password !</span
                  >
                </div>
              </div>
              <div class="form-group row">
                <div class="col-lg-12">
                  <input
                    type="password"
                    placeholder="Enter new password"
                    class="form-control"
                    maxlength="16"
                    autocomplete="off"
                    v-model="changepassword.newpassword"
                  />
                  <span class="errormsg" v-if="validations[6]"
                    >Please enter a new password !</span
                  >
                  <span class="errormsg" v-if="validations[8]"
                    >Passwords must contain at least eight characters, including
                    uppercase, lowercase letters, special characters and
                    numbers.</span
                  >
                </div>
              </div>
              <div class="form-group row">
                <div class="col-lg-12">
                  <input
                    type="password"
                    placeholder="Confirm new password"
                    class="form-control"
                    maxlength="16"
                    autocomplete="off"
                    v-model="changepassword.confirmpassword"
                  />
                  <span class="errormsg" v-if="validations[7]"
                    >Please confirm new password !</span
                  >
                  <span class="errormsg" v-if="validations[9]"
                    >Passwords must contain at least eight characters, including
                    uppercase, lowercase letters, special characters and
                    numbers.</span
                  >
                  <span class="errormsg" v-if="validations[10]"
                    >Password and Confirm password mismatch !</span
                  >
                </div>
              </div>

              <div class="d-flex justify-content-end">
                <button
                  @click.prevent="checkpasswords()"
                  class="btn btn-primary save_btn py-1 px-3"
                >
                  Update
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="row">
          <div class="ibox mb-0">
            <div class="ibox-title style_2">
              <h5>Default Study Configuration</h5>
            </div>
            <div class="ibox-content">
              <div class="input-group">
                <select v-model="currentStudy" class="form-select">
                  <option value="" selected>
                    Select a study to set default
                  </option>
                  <option
                    v-for="study in studyList"
                    :key="study.studyId"
                    :value="study.studyId"
                  >
                    {{ study.studyRef }}
                  </option>
                </select>
                <button type="button" class="btn-1" @click="UpdateClicked()">
                  Save
                </button>
              </div>
            </div>
          </div>
          <div class="ibox mt-1 mb-0">
            <div class="ibox-title style_2">
              <h5>Set your local language</h5>
            </div>
            <div class="ibox-content">
              <div class="input-group">
                <select
                  v-model="defaultlanguage"
                  class="form-select"
                >
                  <option value="" selected>Select a local language</option>
                  <option
                    v-for="lang in langList"
                    :key="lang.code"
                    :value="lang.code"
                  >
                    {{ lang.language }}
                  </option>
                </select>
                <button
                  type="button"
                  class="btn-1"
                  @click="updateDefLanguage(defaultlanguage)"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
          <div class="ibox mt-1 mb-0">
            <div class="ibox-title style_2">
              <h5>Default date format</h5>
              <div class="ibox-tools"></div>
            </div>
            <div class="ibox-content">
              <div class="input-group">
                <select
                  class="form-select"
                  v-model="defaultdateFormat"
                >
               
                  <option value="" selected>Select a date format</option>
                  <option
                    v-for="date in dateList"
                    :key="date.dateFormat"
                    :value="date.dateFormat"
                  >
                    {{ date.dateFormat }}
                  </option>
                </select>
                <button
                  type="button"
                  class="btn-1"
                  @click="updateDefDateFormat()"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
          <div class="ibox mt-1">
            <div class="ibox-title style_2">
              <h5>Default time format</h5>
              <div class="ibox-tools"></div>
            </div>
            <div class="ibox-content">
              <div class="input-group">
                <select class="form-select"  v-model="defaultTimeFormat">
                  <option value="h:mm A">12 hrs</option>
                  <option value="HH:mm">24 hrs</option>
                </select>
                <button 
                  type="button" 
                  class="btn-1"
                  @click="updateTimeFormat()"
                >Save</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
/* eslint-disable */
import { useRouter } from "vue-router";
import axios from "axios";
import store from "../../store/index";
import jwt_decode from "jwt-decode";
import { useI18n } from "vue-i18n";
import { SUPPORT_LOCALES } from "@/i18n";
import auth0 from "auth0-js";
import { ref } from "vue";
import { router } from "@/router";
var webAuth = new auth0.WebAuth({
  domain: "dev-datamatica.eu.auth0.com",
  clientID: "VIG7qA5lTa7dH921HIP3AL5ITQ0ADk8Z",
  scope: "openid profile email offline_access",
  responseType: "token id_token",
  useRefreshTokens: true,
});
export default {
  setup() {
    let header = ref({});
    let profile = ref({});
    let validations = ref([
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
    ]);
    let router = useRouter();
    let errors = ref([]);
    let currentpassword = ref("");
    let confirmpassword = ref("");
    let newpassword = ref("");
    async function setprofile(profiledta, header) {
      header.value = header;
      profile.value = profiledta;
      console.log(profiledta);
    }
    async function executepassword(authResult) {
      if (profile.value.dateOfBirth === "") {
        profile.value.dateOfBirth = null;
      }
       const idtoken = authResult.idToken;
      
      await axios
        .put(
          //`https://localhost:5001/user/update`,
          `${process.env.VUE_APP_Service_URL}/account-core/user/` + "update",
          {
            ItemId :profile.value.id,
            //accountId: profile.value.id,
            username: profile.value.username,
            firstName: profile.value.firstName,
            lastName: profile.value.lastName,
            dateOfBirth: profile.value.dateOfBirth,
            active: profile.value.active,
            emailIdPrimary: profile.value.emailIdPrimary,
            emailIdSecondary: profile.value.emailIdSecondary,
            phoneNumber: profile.value.phoneNumber,
            addressLineOne: profile.value.addressLineOne,
            addressLineTwo: profile.value.addressLineTwo,
            country: profile.value.country,
            userType: profile.value.userType,
            role: profile.value.role,
            userId: profile.value.userId,
            password: newpassword.value,
            isVerified: true,
            isPersonalInfoVerified: true,
          },
           {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            }
          },
        )
        .then((res) => {
          console.log(res);
          alert("Password updated successfully");
          router.go();
        })
        .catch((err) => {
          console.log(err);
          alert(
            "This password has previously been used, Please use a new password"
          );
        });
    }
    async function login(useremail, currentpass, newpass, confirmedpass) {
      newpassword.value = newpass;
      confirmpassword.value = confirmedpass;
      currentpassword.value = currentpass;
      await webAuth.client.login(
        {
          realm: "Username-Password-Authentication",
          username: useremail,
          password: currentpass,
        },
        async function (err, authResult) {
          if (err) {
            console.log(err.description);
            if (
              err.description ==
              "Your account has been blocked after multiple consecutive login attempts. We've sent you an email with instructions on how to unblock it."
            ) {
              await alert(err.description);
            } else if (err.description == "Wrong email or password.") {
              await alert("Invalid current password");
            }
          } else {
            console.log("correct password", authResult);
            await ChangePasswordClick(authResult);
          }
        }
      );
    }
    async function ChangePasswordClick(authResult) {
      await validateallpassword();
      if (validations.value.includes(true)) {
        errors.value = validations.value;
      } else {
        executepassword(authResult);
      }
    }
    async function validateallpassword() {
      Validatepassword(newpassword.value);
      Validateconfirmpassword(confirmpassword.value);
      Validatematchpasswords(newpassword.value, confirmpassword.value);
    }
    async function Validatepassword(passwd) {
      let reNum = /[0-9]/;
      let reLow = /[a-z]/;
      let reUpp = /[A-Z]/;
      let spcl = /(?=.*[!@#$%^&*])/;
      if (passwd == "" || passwd == "" || passwd == null) {
        validations.value[6] = true;
        validations.value[8] = true;
      } else if (
        passwd.length < 8 ||
        !reLow.test(passwd) ||
        !reNum.test(passwd) ||
        !spcl.test(passwd) ||
        !reUpp.test(passwd)
      ) {
        validations.value[8] = true;
        validations.value[6] = false;
      } else {
        validations.value[6] = false;
        validations.value[8] = false;
      }
    }
    async function Validateconfirmpassword(passwd) {
      let reNum = /[0-9]/;
      let reLow = /[a-z]/;
      let reUpp = /[A-Z]/;
      let spcl = /(?=.*[!@#$%^&*])/;
      if (passwd == "" || passwd == "" || passwd == null) {
        validations.value[7] = true;
        validations.value[9] = true;
      } else if (
        passwd.length < 8 ||
        !reLow.test(passwd) ||
        !reNum.test(passwd) ||
        !spcl.test(passwd) ||
        !reUpp.test(passwd)
      ) {
        validations.value[9] = true;
        validations.value[7] = false;
      } else {
        validations.value[7] = false;
        validations.value[9] = false;
      }
    }
    async function Validatematchpasswords(passwd, confirmpaswd) {
      if (
        validations.value[6] == false &&
        validations.value[8] == false &&
        validations.value[7] == false &&
        validations.value[9] == false
      ) {
        console.log("validate paswds");
        if (passwd == confirmpaswd) {
          validations.value[10] = false;
          console.log(" paswds ok");
        } else {
          validations.value[10] = true;
          console.log("paswds mismatch");
        }
      } else {
        console.log("final condtn paswds");
        validations.value[10] = false;
      }
    }
    return {
      login,
      ChangePasswordClick,
      validateallpassword,
      validations,
      Validatematchpasswords,
      Validateconfirmpassword,
      errors,
      currentpassword,
      confirmpassword,
      newpassword,
      setprofile,
      profile,
      header,
      executepassword,
    };
  },
  name: "Settings",
  data() {
    return {
      defaultdate: "",
      languages: [
        { language: "English", code: "en" },
        { language: "Italian", code: "it" },
        { language: "Lithuanian", code: "lt" },
        { language: "Polish", code: "pl" },
        { language: "Portuguese (Portugal)", code: "pt-pt" },
        { language: "Romanian", code: "ro" },
        { language: "French", code: "fr" },
        { language: "German", code: "de" },
        { language: "Danish", code: "da" },
        { language: "Dutch", code: "nl" },
        { language: "Turkish", code: "tr" },
        { language: "Swedish", code: "sv" },
        { language: "Spanish", code: "es" },
        { language: "Hebrew", code: "he" },
        { language: "Czech", code: "cs" },
        { language: "Croatian", code: "hr" },
        { language: "Hungarian", code: "hu" },
      ],
      currentLocale: "",
      supportLocales: SUPPORT_LOCALES,
      baseapi: process.env.VUE_APP_Service_URL,
      baseUrl: `${process.env.VUE_APP_Service_URL}/management/study/`,
      accountBaseUrl: `${process.env.VUE_APP_Service_URL}/account-core/user/`,
      usersettingsBaseUrl: `${process.env.VUE_APP_Service_URL}/account-core/usersettings/`,
      studyList: [],
      currentStudy: "",
      idToken: "",
      isNew: false,
      userId: "",
      Headers: {},
      defaultdateFormat:"",
      defaultTimeFormat :"",
      defaultlanguage: "",
      langList: "",
      dateList: "",
      collapsed: false,
      ProfileData: {
        id: "",
        username: "",
        firstName: "",
        lastName: "",
        dateOfBirth: null,
        active: true,
        emailIdPrimary: "",
        emailIdSecondary: "",
        phoneNumber: "",
        userType: "",
        addressLineOne: "",
        addressLineTwo: "",
        country: "",
        userId: "",
        password: "",
        userType: "",
        role: "",
      },

      changepassword: {
        newpassword: "",
        confirmpassword: "",
        currentpassword: "",
      },

      value: {
        createdUserEmail: "vipinv@datamatica.uk",
        createdUserId: "auth0|61f28ddadaa5cc006ace40ba",
        createdAt: "2021-10-27T09:30:08.205Z",
        lastModifiedUserEmail: "vipinv@datamatica.uk",
        lastModifiedUserId: "auth0|61f28ddadaa5cc006ace40ba",
        lastModifiedAt: "2022-02-02T03:18:19.026Z",
        userID: "auth0|61f28ddadaa5cc006ace40ba",
        userEmail: "vipinv@datamatica.uk",
        studyID: "ee36418d-c121-4fd7-b332-ffccc6b690ff",
        createdDate: "2021-10-27T09:30:08.205Z",
      },
      currentvalidation: [false],
      errr: [],
    };
  },
  async mounted() {
    this.idToken = store.getters.getIdToken;
    // console.log(this.jwtDecrypt(this.idToken));
    this.userId = this.jwtDecrypt(this.idToken).sub;
    this.value.userEmail = this.jwtDecrypt(this.idToken).name;
    this.value.createdUserEmail = this.jwtDecrypt(this.idToken).name;
    this.value.lastModifiedUserEmail = this.jwtDecrypt(this.idToken).name;
    this.Headers = {
      headers: {
        Authorization: "Bearer " + this.idToken,
        "Content-Type": "application/json",
      },
    };
    await this.getAndSetProfileData();
    await this.getUserSettings();
    await this.getAndSetData();
    await this.setCurrentStudy();
    await this.getlanguagelist();
    await this.getdateformatlist();
  },
  methods: {
    collapse() {
      this.collapsed = !this.collapsed;
      const collapseIcon = document.querySelector(".collapse-icon");
      if (!collapseIcon.style.transform)
        collapseIcon.style.transform = "rotate(180deg)";
      else if (collapseIcon.style.transform == "rotate(180deg)") {
        collapseIcon.style.transform = "rotate(0deg)";
      } else if (collapseIcon.style.transform == "rotate(0deg)") {
        collapseIcon.style.transform = "rotate(180deg)";
      }
    },
    async languagechange(val) {
      localStorage.setItem("lang", val);
      this.$router.push({
        name: "settings",
        params: { locale: val },
      });
      this.defaultlanguage = val;
    },
    async checkpasswords() {
      console.log("username", this.ProfileData.emailIdPrimary);
      console.log("password", this.changepassword.currentpassword);
      if (
        this.changepassword.currentpassword == "" ||
        this.changepassword.currentpassword == null
      ) {
        console.log("No password");
        this.currentvalidation[0] = true;
        if (this.currentvalidation.includes(true)) {
          this.errr = this.currentvalidation;
        }
      } else {
        this.currentvalidation[0] = false;
        this.setprofile(this.ProfileData, this.Headers);
        this.login(
          this.ProfileData.emailIdPrimary,
          this.changepassword.currentpassword,
          this.changepassword.newpassword,
          this.changepassword.confirmpassword
        );
      }
    },
    async updateDefDateFormat() {
      if(this.defaultdateFormat==""){
        this.defaultdateFormat="MM/DD/YYYY"
      }
      localStorage.setItem("dateformat", this.defaultdateFormat);
      await axios
        .put(
          this.usersettingsBaseUrl + "updatedateformat",
          {
            userId: this.ProfileData.emailIdPrimary,
            dateFormat: this.defaultdateFormat,
          },
          this.Headers
        )
        .then((res) => {
          console.log(res);
          alert("Default date format updated successfully");
        })
        .catch((err) => {
          console.log(err);
        });
        this.getUserSettings();
    },
    async updateTimeFormat(){
      if(this.defaultTimeFormat==""){
        this.defaultTimeFormat="h:mm A"
      }
      localStorage.setItem("timeformat", this.defaultTimeFormat);
      await axios
        .put(
          this.usersettingsBaseUrl + "updatetimeformat",
          {
            userId: this.ProfileData.emailIdPrimary,
            timeFormat: this.defaultTimeFormat,
          },
          this.Headers
        )
        .then((res) => {
          console.log(res);
          alert("Default time format updated successfully");
        })
        .catch((err) => {
          console.log(err);
        });
        this.getUserSettings();
    },
    async getAndSetProfileData() {
      await axios
        .get(
          this.accountBaseUrl + "getuserwithauthid?id=" + this.userId,
          this.Headers
        )
        .then((res) => {
          res.data[0].dateOfBirth =
            res.data[0].dateOfBirth === null
              ? ""
              : res.data[0].dateOfBirth.slice(0, 10);
          this.ProfileData = res.data[0];
          console.log("user Data", res.data[0]);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getUserSettings() {
      await axios
        .get(
           this.usersettingsBaseUrl + "getbyid?userId=" +
            this.ProfileData.emailIdPrimary,
          this.Headers
        )
        .then((res) => {
          if (res.data == "No data found") 
          {
            this.CreateDefLanguage();
          } 
          else
           {
            
            this.defaultlanguage = res.data.localLanguage;
            this.defaultdateFormat = res.data.dateFormat;
            this.defaultTimeFormat = res.data.timeFormat;
            console.log("default language from api", this.defaultlanguage);
            console.log("default dateformat from api",this.defaultdateFormat);
            console.log("Date format is",this.defaultdateFormat);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async updateDefLanguage(defaultlanguage) {
      await axios
        .put(
          this.usersettingsBaseUrl + "updatelocallanguage",
          {
            userId: this.ProfileData.emailIdPrimary,
            localLanguage: this.defaultlanguage,
          },
          this.Headers
        )
        .then((res) => {
          console.log(res);
          this.languagechange(defaultlanguage);
          this.getUserSettings();
          alert("Default language updated successfully");
        
        })
        .catch((err) => {
          console.log(err);
        });
       
         
    },

    async CreateDefLanguage() {
      await axios
        .post(
          this.usersettingsBaseUrl + "create",
          {
            userId: this.ProfileData.emailIdPrimary,
            localLanguage: "en",
            dateFormat: "d/MM/yyyy",
          },
          this.Headers
        )
        .then((res) => {
          console.log(res);
          console.log("to create")
          this.getUserSettings();
        })
        .catch((err) => {
          console.log(err);
        });
          this.languagechange("en");
      this.currentLocale = "en";
    },

    async getlanguagelist() {
      await axios
        .get(this.usersettingsBaseUrl + "languagelist", this.Headers)
        .then((res) => {
          console.log("language list", res.data);
          this.langList = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getdateformatlist() {
      await axios
        .get( this.usersettingsBaseUrl + "dateformatlist", this.Headers)
        .then((res) => {
          console.log("dateformat list", res.data);
          this.dateList = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async updateProfileData() {
      if (this.ProfileData.dateOfBirth === "") {
        this.ProfileData.dateOfBirth = null;
      }
      await axios
        .put(
          this.accountBaseUrl + "update",
          {
            itemId: this.ProfileData.id,
            username: this.ProfileData.username,
            firstName: this.ProfileData.firstName,
            lastName: this.ProfileData.lastName,
            dateOfBirth: this.ProfileData.dateOfBirth,
            active: this.ProfileData.active,
            emailIdPrimary: this.ProfileData.emailIdPrimary,
            emailIdSecondary: this.ProfileData.emailIdSecondary,
            phoneNumber: this.ProfileData.phoneNumber,
            addressLineOne: this.ProfileData.addressLineOne,
            addressLineTwo: this.ProfileData.addressLineTwo,
            country: this.ProfileData.country,
            userType: this.ProfileData.userType,
            role: this.ProfileData.role,
            userId: this.ProfileData.userId,
            isVerified: true,
            isPersonalInfoVerified: true,
          },
          this.Headers
        )
        .then((res) => {
          console.log(res);
          alert("User profile updated successfully");
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async clear() {
      this.changepassword = {
        newpassword: "",
        confirmpassword: "",
        currentpassword: "",
      };
    },
    async getAndSetData() {
      console.log(this.Headers);
      const idtoken = store.getters.getIdToken;
      const usermail = jwt_decode(idtoken).email;
      await axios
        .get(`${this.baseapi}/management/study/getallstudy?user=${usermail}`, {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          console.log("study list");
          console.log(res.data);
          this.studyList = res.data;
          this.setCurrentStudy();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async setCurrentStudy() {
      const idtoken = store.getters.getIdToken;
      const usermail = jwt_decode(idtoken).email;
      await axios
        .get(this.baseUrl + "getdefaultstudy?id=" + usermail, {
          headers: {
            Authorization: "Bearer " + this.idToken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          console.log(res);
          // if (res.status == "204") {
          //   console.log("new study");
          //   this.currentStudy = "";
          //   this.isNew = true;
          // } else {
          //   console.log("old study");
          this.currentStudy = res.data.studyID;
          //   this.isNew = false;
          // }
          this.CreateDefStudy();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async CreateDefStudy() {
      this.setVAlue();
      await axios
        .post(this.baseUrl + "createdefaultstudy", this.value, {
          headers: {
            Authorization: "Bearer " + this.idToken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          // console.log(res);
          //alert("Default study assigned successfully");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async UpdateClicked() {
      // if(this.currentStudy=="All"){
      //   this.currentStudy=""
      // }
      // if (this.isNew) {
      //   this.CreateDefStudy();
      // } else {
      this.UpdateDefStudy();
      // }
    },
    async UpdateDefStudy() {
      this.setVAlue();
      await axios
        .post(this.baseUrl + "updatedefaultstudy", this.value, {
          headers: {
            Authorization: "Bearer " + this.idToken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          // console.log(res);
          alert("Default study updated successfully");
          store.dispatch("setStudyID", this.value.studyID);
          // router.push(`/home`);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    setVAlue() {
      this.value.userID = this.userId;
      this.value.createdUserId = this.userId;
      this.value.lastModifiedUserId = this.userId;
      if(this.currentStudy==""){
        this.value.studyID = null;
      }else
      {
         this.value.studyID = this.currentStudy;
      }
     
    },
    ClearValue() {
      this.value.userID = "";
      this.value.userEmail = ""; //?
      this.value.studyID = "";
    },
    jwtDecrypt(token) {
      const base64Url = token.split(".")[1];
      const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );
      return JSON.parse(jsonPayload);
    },

    async ValidateEmail(mail) {
      if (mail != "") {
        if (mail == this.ProfileData.EmailIdPrimary) {
          this.validations[3] = true;
        } else if (/^\w+([-]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
          this.validations[3] = false;
        } else {
          this.validations[3] = true;
        }
      } else {
        this.validations[3] = false;
      }
    },

    async validPhone(phno) {
      if (phno == "") {
        this.validations[5] = false;
      } else if (phno > 999999999 && phno < 10000000000) {
        this.validations[5] = false;
      } else {
        this.validations[5] = true;
      }
    },

    async validDob(dob) {
      let myDate = new Date(dob);
      let today = new Date();
      if (dob == "") {
        this.validations[4] = false;
      } else if (myDate > today) {
        this.validations[4] = true;
      } else {
        this.validations[4] = false;
      }
    },

    async Validatename(name, errindex) {
      console.log(" invalidate name", name, "+", errindex);
      if (name == "") {
        this.validations[errindex] = true;
      } else if (!/^[a-zA-Z\s]*$/g.test(name)) {
        this.validations[errindex] = true;
      } else {
        this.validations[errindex] = false;
      }
    },
    async validateAll() {
      console.log("Hello");
      //FirstName
      this.Validatename(this.ProfileData.firstName, 0);
      console.log(this.validations);
      //LastName
      this.Validatename(this.ProfileData.lastName, 1);
      //Dob
      this.validDob(this.ProfileData.dateOfBirth);
      //Phone Number
      this.validPhone(this.ProfileData.phoneNumber);
      //SecondaryEmail
      this.ValidateEmail(this.ProfileData.emailIdSecondary);
    },

    async UpdateUser() {
      this.validateAll();
      if (this.validations.includes(true)) {
        alert("One or more validation error occured ");
      } else {
        this.updateProfileData();
      }
    },
  },
};
</script>

<style scoped>
@import "../../assets/style.css";
@import "../../assets/formpage.css";
@media (max-width: 425px) {
  .save_btn {
    width: 100%;
  }
}
.ibox-content {
  overflow: hidden;
}
.ibox-title1 {
  background-color: #3c4192;
  border-color: #e7eaec;
  height: 48px;
  color: #e7eaec;
  border-radius: 5px;
  border-image: none;
  border-style: solid solid none;
  border-width: 1 px;
  color: inherit;
  margin-bottom: 0;
  padding: 12 px 90 px 8 px 15 px;
  min-height: 40 px;
  position: relative;
  text-align: left !important;
  clear: both;
  border-radius: 4 px;
}
.mtText {
  display: inline-block;
  float: none;
  padding: auto;
  color: aliceblue;
  margin-top: 15px;
  margin-left: 15px;
  margin-bottom: auto;
}
/* .newDiv {
  margin-top: 20px;
  background: orange;
} */
.form-select {
  border-radius: 0 !important;
}
.form-select:focus {
  border-color: #145faf !important;
  box-shadow: 0 0 0 !important;
}
.btn-1 {
  display: inline-block;
  padding: 0.55rem 0.75rem;
  font-weight: 400;
  background-color: #e7e7e7;
  user-select: none;
  border: 1px solid transparent;
  border-radius: 0 3px 3px 0;
}
.btn-1:hover,
.btn-1.focus {
  background-color: #618bd7;
  color: #ffffff;
}
.mobile-view {
  display: none;
}
.collapse-icon {
  transform-origin: 50% 50%;
  transition: transform 0.3s ease-in;
}
.collapse-btn i {
  float: right;
}
.collapse-btn {
  cursor: pointer;
}
.collapse-content {
  color: inherit;
  background-color: #ffffff;
  padding: 15px 20px 20px 20px;
  border-color: #eeeeee;
  -o-border-image: none;
  border-image: none;
  border-style: solid;
  border-width: 1px;
  overflow: hidden;
}
.animated-collapse {
  -webkit-animation-name: collapseInDown;
  animation-name: collapseInDown;
}
.animated-collapse {
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@keyframes collapseInDown {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
}

@media screen and (max-width: 768px) {
  .mobile-view {
    display: block;
  }
}
</style>
